@import "../../assets/css/theme/variables";

.inventory-stats-blc {
    margin-bottom: 10px;
    .container-fluid {
        padding-left: 10px;
        padding-right: 10px;
        .row {
            margin-left: -10px;
            margin-right: -10px;
            > * {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
}
