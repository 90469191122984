.profile-head {
  padding: 15px;
}
.profile-head .title .btn {
  padding: 10px 15px;
}
.profile-head .title .btn.back-btn {
  font-weight: 600;
  text-transform: uppercase;
  color: #1d1d1d;
}
.profile-head .title .btn.view-user-btn:hover svg path, .profile-head .title .btn.view-user-btn:focus svg path {
  fill: #3d3efd;
}
.profile-head .user-profile-cell {
  margin-bottom: 15px;
}
.profile-head .user-profile-cell .pic {
  width: 80px;
  height: 80px;
  margin-right: 15px;
}
.profile-head .user-profile-cell .pic > img {
  border-radius: 8px;
}
.profile-head .user-profile-cell .pic .badge-icon {
  top: -8px;
  right: -8px;
}
.profile-head .user-profile-cell .pic .pet-placeholder {
  border-radius: 8px;
}
.profile-head .user-profile-cell .txt h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
.profile-head .user-profile-cell .txt h3,
.profile-head .user-profile-cell .txt h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}
.profile-head .user-profile-cell .txt h3 {
  font-size: 14px;
  margin-bottom: 5px;
}
.profile-head .user-profile-cell .txt h4 {
  margin-bottom: 0;
}
.profile-head .user-profile-cell .txt h4.active {
  color: #11ca9d;
}
.profile-head .profile-btns {
  margin: 0 -5px;
  padding: 0 5px;
}
.profile-head .profile-btns .btn {
  border: 1px solid rgba(9, 9, 84, 0.12);
  filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.14));
  border-radius: 6px;
  text-transform: uppercase;
  color: #1d1d1d;
  padding: 6px 15px;
  margin: 0 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
}
.profile-head .profile-btns .btn:hover, .profile-head .profile-btns .btn:focus {
  color: #3d3efd;
  border-color: #3d3efd;
}

.profile-description p {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 9px;
}
.profile-description p label {
  min-width: 144px;
  max-width: 144px;
  margin-right: 6px;
}
.profile-description h5 {
  font-size: 18px;
  color: #282E65;
  font-weight: 600;
}

.petOwnerProfile {
  border-right: 1px solid #eeeef2;
  padding: 0 12px 0 7px;
}

.patient-detail-wrapper .left-col.petOwnerProfile {
  width: 300px;
}

.petOwnerProfile .profile-description p label {
  min-width: 132px;
  max-width: 132px;
}

.owner_filter_right {
  width: 290px;
}

.outstanding_invoice {
  border-radius: 10px;
  background: #3D3EFD;
  padding: 12px 20px;
}
.outstanding_invoice h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 10px;
}
.outstanding_invoice h3 {
  font-size: 15px;
  color: #fff;
  font-weight: 400;
}
.outstanding_invoice svg {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
}

.my_pet_box1 {
  border-radius: 10px;
  padding: 10px 15px !important;
  background: rgba(248, 248, 248, 0.9);
}

.addner_mypet_box {
  color: #11CA9D;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  border: 1px dashed #11CA9D;
  background: #FAFFF9;
  height: 511px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.position-relative .tab-pane .my-pets .row .col-md-4 {
  margin-bottom: 1rem;
}/*# sourceMappingURL=index.css.map */