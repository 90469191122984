.staff-profile-education {
    >.inner {
        .col-cell {
            width: calc(25% - 10px);
            margin-right: 10px;
            margin-bottom: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &.responsive {
        .col-cell {
            @media (max-width: 1350px) {
                width: calc(50% - 10px);
            }

            @media (max-width: 700px) {
                width: calc(100%);
                margin-right: 0;
            }
        }
    }
}

.staff-profile-education-item {
    background: #f8f8f8;
    padding: 10px;
    border-radius: 10px;

    >.pic {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        margin-right: 15px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    >.info {

        h2,
        h3 {
            font-size: 14px;
            font-weight: 300;
            line-height: 150%;
            margin: 2px 0;
        }

        h3 {
            color: #788693;
        }

        .thumbnail-list {
            li {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }

                span {
                    width: 24px;
                    height: 30px;
                    border-radius: 4px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}