.vitals-wrapper {
    &.vitals_options {
        .nav-item {
            .nav-link {
                border-right: 1px solid rgba(9, 9, 84, 0.12) !important;
                border-bottom: 0 !important;
                padding: 6px 8px;
                min-width: 150px;
                height: 100%;
            }

            &:last-child {
                .nav-link {
                    border-right: 0 !important;
                }
            }
        }

    }
}