@import "../../../assets/css/theme/variables";

.calendar-foot {
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;

	bottom: 3vh;
	border-radius: 100px;
background: #FFF;

/* Button Drop Shadow */
box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.14);
padding: 0px 20px;
display: flex;
height: 40px;
justify-content: center;
align-items: center;
gap: 20px;
width: fit-content;


	.item {
		font-size: 14px;
		margin: 0 10px;
		span {
			background: $success;
			border: 3px solid #ffffff;
			border-radius: 10px;
			margin-right: 8px;
			width: 14px;
			height: 14px;
		}
		&.clinic {
			span {
				background: #e0ce2a;
			}
		}
		&.phone {
			span {
				background: #9584fc;
			}
		}
		&.video-conference {
			span {
				background: #409de0;
			}
		}
	}
}
