@import "../../../../../../assets/css/theme/variables";

.diagnostic-filter-block {
    padding: 3px;
    margin-bottom: 15px;

    
	.add-btn {
        font-size: 19px;
        color: $success;
        &:hover,
        &:focus {
            color: $success-dark;
        }
    }
    > .left-col {
        margin-right: 20px;
        .item-col {
            margin-right: 8px;
            height: 32px;
            .form-control {
                height: 100%;
                background-color: rgba(228, 228, 228, 0.28);
                border-color: transparent;
                box-shadow: none;
                border-radius: 6px;
            }
            select.form-control {
                padding: 0px 30px 0 10px;
                background-size: 11px auto;
                background-position-x: calc(100% - 11px);
            }
            .search-cell {
                height: 100%;
                > span {
                    top: 0;
                    left: 0;
                    bottom: 0;
                    width: 40px;
                    color: #788693;
                    span {
                        font-size: 17px;
                    }
                }
                .form-control {
                    padding-left: 36px;
                    height: 100%;
                }
            }
            .date-blc {
                height: 100%;
                &.month-view {
                    .txt {
                        width: 100px;
                    }
                }
                &.week-view {
                    .txt {
                        width: 146px;
                    }
                }
                .btn,
                > span {
                    height: 100%;
                    background: rgba(228, 228, 228, 0.28);
                }
                .btn {
                    width: 28px;
                    color: $base-color-private;
                    &:hover {
                        background-color: #eeeef2;
                        color: $primary;
                    }
                    span {
                        font-size: 16px;
                    }
                    &.lft {
                        border-radius: 6px 0px 0px 6px;
                    }
                    &.rt {
                        border-radius: 0px 6px 6px 0px;
                    }
                }
                > .txt {
                    font-size: 14px;
                    margin: 0 1px;
                    padding: 0 16px;
                }
            }
        }
    }
    > .right-col {
        .btns-cell {
            height: 32px;
            .btn {
                height: 100%;
                background: rgba(228, 228, 228, 0.28);
                border-radius: 6px;
                margin-right: 10px;
                &:hover {
                    background-color: rgba($primary, 0.08);
                }
                &:last-child {
                    margin-right: 0;
                }
                &.download-btn {
                    width: 44px;
                }
                &.layout-view-btn {
                    color: #788693;
                    padding: 5px 10px;
                    &:hover {
                        color: $primary;
                    }
                    span {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
