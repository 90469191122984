@import "../../../assets/css/theme/variables";

.profile-head {
  padding: 0 15px;

  .title {
    .btn {
      padding: 10px 15px;

      &.back-btn {
        font-weight: 600;
        text-transform: uppercase;
        color: $base-color;
      }

      &.view-user-btn {
        &:hover,
        &:focus {
          svg path {
            fill: $primary;
          }
        }
      }
    }
  }

  .user-profile-cell {
    margin-top: 20px;
    margin-bottom: 15px;
    .pic {
      max-width: 80px;
      max-height: 80px;
      margin-right: 15px;

      img {
        border-radius: 50%;
      }
    }

    .txt {
      h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
      }

      h3,
      h4 {
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
      }

      h3 {
        font-size: 14px;
        margin-bottom: 5px;
      }
      h4 {
        margin-bottom: 0;

        &.active {
          color: $success;
        }
      }
    }
  }
}

@media screen and (max-width: 470px) {
  .user-profile-cell {
    margin-top: 0px !important;
    .txt {
      h2 {
        font-size: 16px !important;
        font-weight: 600;
        line-height: 24px;
      }

      h3,
      h4 {
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
      }

      h3 {
        font-size: 14px;
        margin-bottom: 5px;
      }
      h4 {
        margin-bottom: 0;

        &.active {
          color: $success;
        }
      }
    }
  }
}
