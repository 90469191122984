#add-schedule-container {
  .time-sel-block {
    position: relative;
    .in-block {
      height: 48px;
      background-color: #fff;
      border: 1px solid rgba(9, 9, 84, 0.12);
      border-radius: 10px 10px 10px 10px;
      padding-left: 16px;
    }
  }
  .time-popover {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    display: flex;
    padding: 5px;
    z-index: 99;
    .list-con {
      display: flex;
      flex-direction: column;
      width: calc(100% / 3);
      max-height: 150px;
      overflow: auto;
      .list-block {
        text-align: center;
        padding: 5px 0px;
        cursor: pointer;
        &:hover {
          background-color: #3f3ffd1a;
        }
      }
      .active-block {
        background-color: #3d3efd;
        color: #fff;
      }
    }
  }
  .select-icon-block {
    position: absolute;
    top: 0;
    right: 0px;
    background-color: #f8f8f8;
    display: flex;
    height: 100%;
    width: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 0px 10px 10px 0px;
    border: 1px solid rgba(9, 9, 84, 0.12);
  }
  .date-range-block {
    margin-bottom: 10px;
    label {
      padding-top: 4px;
    }
    input {
      height: 48px;
      padding: 14px 0px 0px 16px;
    }
    ::-ms-input-placeholder {
      font-size: 14px;
      font-weight: 400;
      color: var(--2E65);
    }

    ::placeholder {
      font-size: 14px;
      font-weight: 400;
      color: var(--2E65);
    }
  }
  .swtichCell.disable {
    .switch-btn {
      background-color: #8d9daf;
    }
    .icomoon-check {
      display: none;
    }
  }
  .dropdown_floating {
    .form-group-wrapper .btn {
      width: 60px;
    }
    input {
      font-size: 14px;
      font-weight: 400;
      color: var(--2E65);
    }
    label {
      font-size: 12px;
      font-weight: 400;
      color: var(--8693);
    }
    label::after {
      background-color: #fff;
    }
  }
}
