@import "../../../assets/css/theme/variables";

.calendar-wrapper {
    .calendar-day-view {
        table {
            height: 100%;
            table-layout: unset;

            thead th,
            tbody th {
                position: sticky;
            }

            .time-cell-wrapper,
            .syn-btn-wrapper {
                padding: 0;
            }

            .time-cell {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: $base-color-private;

                span {
                    font-size: 13px;
                    color: #788693;
                }
            }

            thead {
                th {
                    top: 0;
                    z-index: 1;
                    background-color: #fff !important;
                    border-right: 1px solid #eeeef2;

                    &:first-child {
                        left: 0;
                        z-index: 2;
                    }

                    &:last-child {
                        border-right: none;
                    }

                    &.cell-width {
                        width: 143px;
                    }

                    &.day-cell-hd {
                        font-weight: 400;
                        font-size: 13px;

                        span {
                            color: $base-color-private;
                        }
                    }

                    &.syn-btn-wrapper {
                        width: 40px;

                        .syn-btn {
                            span {
                                font-weight: 300;
                            }
                        }
                    }

                    &.user-pic-cell-wrapper {
                        padding: 10px;
                    }
                }
            }

            tbody {
                th {
                    left: 0;
                    z-index: 1;
                    background-color: #fff;
                }

                tr {

                    th,
                    td { 
                        height: 150px;

                        .data-container {
                            min-height: initial;
                            height: 100%;
                            overflow: auto;

                            &.blank-slot {
                                height: 100%;
                            }
                        }
                    }
                }
            }

            th:not(.time-cell-wrapper, .syn-btn-wrapper),
            td:not(.time-cell-wrapper, .syn-btn-wrapper){
                width: 320px;
                min-width: 320px;
                max-width: 320px;
            }

            th.time-cell-wrapper,
            th.syn-btn-wrapper,
            td.time-cell-wrapper,
            td.syn-btn-wrapper{
                background-color: #fff !important;
            }

            .slot-button-wrapper {

                .slot-above,
                .slot-below {
                    display: flex;
                }

                .slot-item { 
                    height: auto !important;
                    margin: 0;
                }
            }

            .slot-time-frame {
                .slot-button-wrapper:first-child:not(.slot-above-data) {
                    .slot-above {
                        height: 100%;
                    }
                }
            }

            .slot-button-wrapper.slot-above-data.slot-below-data {
                height: 100%;
            }

            .slot-button-wrapper.slot-above-data {
                height: 50%;

                .slot-above {
                    height: 100%;

                    .slot-item {
                        min-width: 50%;
                    }
                }
            }

            .slot-button-wrapper.slot-below-data {
                height: 50%;

                .slot-below {
                    height: 100%;

                    .slot-item {
                        min-width: 50%;
                    }
                }
            }
        }
    }
}