// filter--wrapper
.table-list-filter-blc-wrapper,
.table-list-product-filter-blc-wrapper,
.table-list-point-of-sale-filter-blc-wrapper,
.table-list-purchase-order-filter-blc-wrapper,
.table-supplier-filter-blc-wrapper,
.table-list-patients-filter-blc-wrapper {
    margin-bottom: 20px;
    > .left-col {
        margin-right: 20px;
        .table-list-filter-blc {
            margin-bottom: 0;
        }
    }
    > .right-col {
        .list-view-type-btn {
            color: $base-color-private;
            border: 1px solid #eeeef2;
            border-radius: 8px;
            padding: 6px 10px;
            span {
                margin-right: 9px;
                svg {
                    width: 17px;
                }
            }
            &:hover {
                color: $primary;
                border-color: $primary;
                span svg {
                    path {
                        fill: $primary;
                    }
                }
            }
        }
    }
}

.table-list-filter-blc-wrapper {
    &.responsive {
        @media (max-width: 767px) {
            flex-direction: column;
            align-items: stretch !important;
        }
        > .left-col {
            @media (max-width: 767px) {
                margin: 0 0 6px;
            }
            .table-list-filter-blc {
                @media (max-width: 767px) {
                    flex-direction: column;
                    align-items: stretch !important;
                }
                > div {
                    @media (max-width: 767px) {
                        width: 100%;
                        margin-bottom: 6px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        > .right-col {
            @media (max-width: 767px) {
                margin-left: unset !important;
            }
        }
    }
}

// filter-input & columm button background-color change
.table-list-product-filter-blc-wrapper,
.table-list-point-of-sale-filter-blc-wrapper,
.table-list-purchase-order-filter-blc-wrapper,
.table-supplier-filter-blc-wrapper,
.table-list-patients-filter-blc-wrapper {
    > .left-col {
        .table-list-filter-blc {
            .col-cell {
                .form-control,
                select.form-control {
                    padding-top: 2px;
                    padding-bottom: 2px;
                    background-color: #f8f8f8;
                    border-color: transparent;
                    box-shadow: none;
                    background-position-x: calc(100% - 13px);
                    background-size: 10px;
                    padding-right: 30px;
                }
            }
            .more-input-cell {
                height: 34px;
                .btn {
                    background-color: #f8f8f8;
                    padding: 4px 10px;
                    height: 100%;
                    text-transform: capitalize;
                    color: $base-color-private;
                    border-radius: 10px;
                    &:hover {
                        border-color: $primary;
                        color: $primary;
                    }
                    span {
                        font-size: 16px;
                        margin-left: 6px;
                    }
                }
            }
        }
    }
    > .right-col {
        .list-view-type-btn {
            background-color: #f8f8f8;
            border-color: transparent;
        }
    }
}

// filter--block without right-column
.story-filter-blc,
.story-filter-blc2,
.table-list-filter-blc,
.table-list-audit-log-blc,
.table-list-role-permission-filter-blc,
.table-list-product-filter-blc,
.table-list-point-of-sale-filter-blc {
    margin-bottom: 20px;
    padding: 3px;
    > * {
        flex-shrink: 0;
    }
    .col-cell {
        margin-right: 4px;
    }
    .form-control,
    .toggle-view-cell {
        height: 34px;
        border: 1px solid #eeeef2;
        border-radius: 10px;
    }
    .input-cell {
        width: 150px;
        select.form-control {
            padding-top: 2px;
            padding-bottom: 2px;
            background-color: #fff;
            background-position-x: calc(100% - 13px);
            background-size: 10px;
            padding-right: 30px;
        }
    }
    .search-cell {
        width: 240px;
        .form-control {
            padding-left: 36px;
        }
        span {
            left: 0;
            top: 0;
            bottom: 0;
            width: 40px;
        }
    }
    .toggle-view-cell {
        width: 190px;
        padding: 4px 10px;
        .swtich-cell2 {
            margin: 0 0 0 10px;
        }
    }
}

.story-filter-blc {
    &.responsive {
        @media (max-width: 800px) {
            flex-direction: column;
            align-self: stretch !important;
            overflow: visible !important;
        }
        @media (max-width: 800px) {
            > * {
                width: 100%;
                margin-bottom: 5px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.story-filter-blc2 {
    &.responsive {
        @media (max-width: 640px) {
            flex-direction: column;
            align-self: stretch !important;
            overflow: visible !important;
        }
        @media (max-width: 640px) {
            > * {
                width: 100%;
                margin-bottom: 5px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.table-list-role-permission-filter-blc {
    @media (max-width: 480px) {
        flex-direction: column;
        align-items: stretch !important;
        .search-cell,
        .input-cell {
            width: 100%;
        }
        .search-cell {
            margin-bottom: 6px;
        }
    }
}
.table-list-audit-log-blc {
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: stretch !important;
        overflow: visible !important;
    }
    > div {
        @media (max-width: 767px) {
            width: 100%;
            margin-bottom: 6px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.calendar-filter-blc {
    > .right-col {
        .list-view-type-btn {
            // background-color: #f8f8f8;
            background: rgba(228, 228, 228, 0.28);
            border-color: transparent;
            color: black;
            padding: 6px 10px;
            font-size: 14px;
            span {
                margin-right: 9px;
            }
            &:hover {
                background-color: #eeeef2;
                color: $primary;
                span svg {
                    path {
                        fill: $primary;
                    }
                }
            }
        }
    }
}
