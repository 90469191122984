@import "../../../../assets/css/theme/variables";

.documents-block {
    > .row-block {
        margin-bottom: 15px;
        &.available-docs {
            > .head {
                h2 {
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 150%;
                }
                p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 150%;
                }
            }
        }
        .title-header {
                margin-top: 0.7rem;
            .head
            {
                h2 {
                font-size: 18px !important;
                font-weight: 600 !important;
            }
        }
            .right-col {
                .btn-blc {
                    button {
                        font-size: 14px !important;
                    }
                }
            }
        }
    }
    
}
