
.table-striped.invoice-summary-table {
	>tbody {
		>tr {
			&:nth-of-type(odd) {
				>* {
                    --bs-table-accent-bg: rgba(228, 228, 228, 0.28) !important;
                    background: #efefef !important;

				}
			}
		}
	}
}


.invoice-summary-table.table-striped {
	>tbody {
		>tr {
			&:nth-of-type(even) {
				>* {
					--bs-table-accent-bg: rgb(255 255 255 / 28%) !important;
					background: transparent !important;
				}
			}
		}
	}
}

.table {
	& > :not(caption) {
		& > * {
			& > * {
				background-color: transparent !important;
			}
		}
	}
}




.package-info-blc {
	.table-striped {
		>tbody {
			>tr {
				&:nth-of-type(1) {
					>* {
					border-top:1px solid #eeeef2;
						
					}
				}
			}
		}
	}
}
.invoice-appointment-row{
    width:340px;
}


table {
	tr {
		&:last-child {
			td {
				border-bottom: 1px solid #e6e6e6;
			}
		}
	}
}


@media (max-width:826px) {
    .right-wrapper{
        .invoice-appointment-row{
            width:100%;
            padding: 10px;

            .p-\[15px\]{
                margin-top:10px
            }
        }
    }
   
  }