.staff-profile-items {
  li {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
    label {
      min-width: 100px;
      max-width: 100px;
      margin-right: 20px;
      @media (max-width: 640px) {
        min-width: 90px;
        max-width: 90px;
        margin-right: 15px;
      }
    }
  }
}
