.loaderWrapper,
.loaderOverlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
.loaderWrapper {
  .loaderOverlay {
    background-color: rgba(256, 256, 256, 0.6);
  }
  .loaderOverlay.rounded-10 {
    border-radius: 10px;
  }
  .spinner-border {
    z-index: 999;
  }
  .spinner-border.lg {
    width: 3rem;
    height: 3rem;
  }
  .spinner-blc {
    position: relative;
    z-index: 999;
    text-align: center;
    lottie-player {
      width: 60px;
      display: inline-block;
    }
  }
}
.loaderWrapper.innerPage {
  height: calc(100vh - 60px);
}
.loaderWrapper.FullWindow {
  position: fixed;
}
.custom-position {
  width: calc(100% - 240px);
  left: auto !important;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 99999;
}

.spinner-blc {
  .sr-only {
    display: none;
  }
  span {
    font-size: 18px;
    font-weight: 400;
    color: #000;
    margin-top: 0px;
  }
}

.loaderWrapper {
  &.modalLoader {
    z-index: 1055;
  }
}

@media (max-width: 767px) {
  .custom-position {
    width: 100% !important;
  }
}

// For Modal Overlay
.modal-overlay-level-1 {
  z-index: 1051;
  .loaderOverlay {
    background-color: rgba(0, 0, 0, 0.15);
  }
}
