#add-holiday-overlay {
  width: 500px !important;
  .offcanvas-header .btn-close {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
  }

  .date-range-block {
    margin-bottom: 0px;
    label {
      padding-top: 4px;
    }
    input {
      height: 48px;
      padding: 0px 0px 0px 16px;
      border-radius: 8px;
    }
    ::-ms-input-placeholder {
      font-size: 14px;
      font-weight: 400;
      color: var(--2E65);
    }

    ::placeholder {
      font-size: 14px;
      font-weight: 400;
      color: var(--2E65);
    }
    .select-icon-block {
      position: absolute;
      top: 0;
      right: 0px;
      background-color: #f8f8f8;
      display: flex;
      height: 100%;
      width: 60px;
      justify-content: center;
      align-items: center;
      border-radius: 0px 8px 8px 0px;
      border: 1px solid rgba(9, 9, 84, 0.12);
    }
  }
  .note-input-block {
    width: 100%;
    input {
      width: 100%;
      height: 48px;
      padding: 0px 0px 0px 16px;
      border-radius: 8px;
      border: 1px solid rgba(8, 8, 41, 0.1);
    }
    ::placeholder {
      font-size: 14px;
      font-weight: 400;
      color: var(--2E65);
    }
  }
  .radio-container-block {
    border: 1px solid rgba(8, 8, 41, 0.1);
    height: 48px;
    padding: 0px 0px 0px 16px;
    border-radius: 8px;
    .form-check-input {
      border-color: #3d3efd;
    }
    .form-check-input:checked {
      background-color: #3d3efd;
    }
  }
  .time-sel-block {
    position: relative;
    .in-block {
      height: 48px;
      background-color: #fff;
      border: 1px solid rgba(9, 9, 84, 0.12);
      border-radius: 10px 10px 10px 10px;
      padding-left: 16px;
      display: flex;
      align-items: center;
    }
  }
  .time-popover {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    display: flex;
    padding: 5px;
    z-index: 99;
    .list-con {
      display: flex;
      flex-direction: column;
      width: calc(100% / 3);
      max-height: 150px;
      overflow: auto;
      .list-block {
        text-align: center;
        padding: 5px 0px;
        cursor: pointer;
        &:hover {
          background-color: #3f3ffd1a;
        }
      }
      .active-block {
        background-color: #3d3efd;
        color: #fff;
      }
    }
  }
  .select-content-block {
    border: 1px solid rgba(9, 9, 84, 0.12);
  }
  .select-icon-block {
    position: absolute;
    top: 0;
    right: 0px;
    background-color: #f8f8f8;
    display: flex;
    height: 100%;
    width: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 0px 10px 10px 0px;
    border: 1px solid rgba(9, 9, 84, 0.12);
  }
  .swtichCell.disable {
    .switch-btn {
      background-color: #8d9daf;
    }
    .icomoon-check {
      display: none;
    }
  }
}
