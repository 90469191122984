.adress-blc {
    .adress-info {
        margin-right: 20px;
        p {
            display: flex;
            margin-bottom: 10px;
            label {
                min-width: 185px;
                color: #788693;
                margin-right: 15px;
            }
        }
    }
    .adres-map {
        width: 260px;
        iframe {
            width: 100%;
            height: 100%;
        }
    }
    &.responsive {
        @media (max-width: 1350px) {
            flex-direction: column;
        }
        .adress-info {
            margin: 0 0 20px;
            p {
                @media (max-width: 450px) {
                    flex-direction: column;
                }
                label {
                    @media (max-width: 1150px) {
                        min-width: 175px;
                    }
                }
            }
        }
        .adres-map {
            width: 100%;
            height: 250px;
        }
    }
}
