@import "../.././../assets/css/theme/variables";

.pets-file-card {
  > .head {
    // margin-bottom: 12px;
    margin-bottom: 12px;
    h3 {
      font-size: 20px;
      font-family: "Inter", sans-serif;
      line-height: 24px;
    }
  }
  > .inner {
    border-radius: 15px;
    > .head {
      padding: 22px 22px 18px;
      .pic {
        padding: 11px;
        border-radius: 10px;
        height: 215px;

        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-height: 100%;
        }
        &.bg-1 {
          background-color: rgba(61, 62, 253, 0.1);
        }
        &.bg-2 {
          background-color: rgba(253, 239, 235, 1);
        }
        .btn {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 18px;
          font-family: "Inter", sans-serif;
          line-height: 21px;
          min-width: 191px;
          padding: 13px 15px;
          background-color: #fff;
          opacity: 0;
          transition: 0.26s all;
          &:hover,
          &:focus,
          &:active {
            background-color: $primary;
            color: #fff;
          }
        }
        &:hover {
          .btn {
            opacity: 1;
          }
        }
      }
    }
    .card-row {
      border-bottom: 1px solid rgba(8, 8, 41, 0.1);
    }
    .desc {
      padding: 0 22px 18px;
      h4 {
        font-size: 20px;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        margin-bottom: 0;
        margin-right: 15px;
        span {
          margin-left: 20px;
        }
      }
      .btns-cell {
        .btn {
          border-radius: 6px;
          font-size: 16px;
          width: 32px;
          height: 32px;
          margin-right: 8px;
          background: rgba(19, 62, 216, 0.1);
          border: 1px solid rgba(19, 62, 216, 0.15);
          color: $primary;
          &:hover,
          &:focus {
            background-color: $primary;
            color: #fff;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        color: rgba(8, 8, 41, 0.65);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .profile {
      padding: 18px 22px 18px;
      .profile-cell {
        label {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 4px;
          color: rgba(8, 8, 41, 0.75);
          @media (max-width: 640px) {
            font-size: 14px;
          }
        }
        h5 {
          font-family: "Inter", sans-serif;
          font-size: 18px;
          line-height: 21px;
          letter-spacing: 0.01em;
          margin-bottom: 0;
          @media (max-width: 640px) {
            font-size: 16px;
          }
        }
      }
      > .row {
        > div {
          @media (max-width: 1199px) {
            margin-bottom: 14px;
          }
          @media (max-width: 575px) {
            width: 50%;
          }
          @media (max-width: 370px) {
            width: 100%;
          }
        }
      }
    }
  }
  .document-files {
    font-size: 88px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
