@import "../../assets/css/theme/variables";

.upload-blc {
    height: 206px;
    background: #f8f8f8;
    border-radius: 10px;
    margin-bottom: 15px;
    > .inner {
        * {
            cursor: pointer;
        }
        .pic {
            margin-bottom: 10px;
            span {
                width: 70px;
                height: 70px;
                background-color: #eeeef2;
            }
        }
        .txt {
            h3 {
                font-size: 24px;
                font-weight: 600;
                line-height: 29px;
            }
            h4 {
                font-size: 18px;
                font-weight: 400;
                line-height: 150%;
                color: #788c9f;
            }
        }
        input[type="file"] {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
        }
    }
}
.documents-block{
    .row-block{
        div{
            .upload_btn{
                background-color: #0cbd91;
                border-color: #0cbd91;
                height: 28px;
                min-width: 105px;
                border-radius: 10px;
               color: #f8f8f8;
            }
        }
    }
}

