#pet-owner-billing-container {
    width: 100%;

    .head {
        padding: 15px 18px;
    }




    .n_tab {
        padding: 0 18px;

        li {
            display: flex;
            gap: 20px;
            border-bottom: 1px solid #dee2e6;

            p {
                background-color: none;
                border: none;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                padding: 10px 0;
                cursor: pointer;

            }

            .active {
                border-bottom: 1px solid #3D3EFD;
                color: #3D3EFD;
            }
        }
    }


}