@import "../../../assets/css/theme/variables";

.product-detail-wrapper {
    padding: 15px;

    >.left-col {
        width: 285px;
        margin-right: 15px;
        padding: 0 3px;

        >.head {
            padding: 0 10px;
            margin-bottom: 10px;

            .search-blc2 {
                width: auto;
                margin-bottom: 10px;

                span {
                    color: #788693;
                }
            }

            .sidebar-ttl {
                font-size: 21px;
                font-weight: 600;
                line-height: 33px;
                margin-bottom: 10px;

                @media (max-width:575px) {
                    font-size: 18px;
                }
            }

            .add-product-btn {
                border: 1px dashed #788c9f;
                padding: 7px 10px;
                color: $success;

                span {
                    transition: all ease 0.1s 0s;
                    transform: scale();
                }

                &:hover {
                    background-color: rgba($success, 0.03);
                    border-color: $success-dark;

                    span {
                        transform: scale(1.1);
                    }
                }
            }
        }

        >.inner {
            padding: 0 10px;
        }
    }

    >.mid-col {
        background: #f8f8f8;
        border-radius: 10px 10px 0px 0px;
        margin-right: 15px;

        .section-ttl {
            font-size: 18px;
            font-weight: 600;
            line-height: 150%;
            margin-bottom: 10px;
        }

        .row-block {
            border-bottom: 1px solid #fff;
            padding: 10px 15px;

            &:last-child {
                border-bottom: none;
            }
        }
    }

    >.right-col {
        width: 310px;
    }
}


.general-info {
    &.position-relative {
        padding-right: 170px;
    }

    .qr_img {
        position: absolute;
        right: 0;
        top: 0;
    }

}