.profile-plan {
    .plan-box {
        height: 80px;
        background: #fff6d5;
        padding: 10px;
        border-radius: 10px;
        .pic {
            width: 60px;
            height: 60px;
            margin-right: 15px;
        }
        .txt {
            h3 {
                font-size: 18px;
                font-weight: 600;
                line-height: 150%;
                margin-bottom: 5px;
            }
            h4 {
                font-weight: 400;
                font-size: 13px;
                line-height: 150%;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
