.package-info-blc {
  table {
    tr {
      th,
      td {
        .form-control {
          height: 36px;
        }
        padding: 1px 15px;
      }
    }
    table-layout: fixed;
  }
  //discount button
  .discount-button {
    border: 1px solid lightgrey;
    border-radius: 5px;
    .private-Message-input {
      padding: 10px 18px;
      width: 100%;
      //   background-color: aquamarine;
    }
    .message-type {
      padding: 8px 5px 6px 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      border-right: 1px solid lightgrey;
      color: #282e65;
      font-size: 16px;
      //   background-color: lightblue;
    }
  }
}
.information {
  input[type="date"] {
    &::-webkit-inner-spin-button {
      opacity: 0;
      z-index: 99999;
      width: 20px;
    }
    &::-webkit-calendar-picker-indicator {
      opacity: 0;
      z-index: 99999;
      width: 20px;
    }
  }
  .w-50.me-2.relative {
    .absolute {
      position: absolute;
      top: 8px;
      font-size: 10px;
      right: 8px;
      z-index: 0;
    }
  }
  textarea {
    height: 70px;
    background: var(--table-bg, rgba(228, 228, 228, 0.28)) !important;
    border: none !important;
  }
  input {
    background: var(--table-bg, rgba(228, 228, 228, 0.28)) !important;
    border: none !important;
  }
  select {
    .css-13cymwt-control {
      border: none !important;
      background: var(--table-bg, rgba(228, 228, 228, 0.28)) !important;
      border-style: unset !important;
      border-width: 0px !important;
    }
  }
}

.package-info-blc {
  .table-striped {
    > tbody {
      > tr {
        td {
          padding: 10px;

          input {
            width: 100% !important;
          }
        }

        // &:nth-of-type(1) {
        // 	>* {
        // 	background-color: #fff !important ;
        // 	--bs-table-accent-bg: #fff !important;
        // 	padding: 10px;

        // 	}
        // }
      }
    }
  }
}

.package-info-blc {
  .table-striped {
    > tbody {
      > tr {
        &:nth-of-type(even) {
          > * {
            --bs-table-accent-bg: rgb(235 235 235 / 58%) !important;
          }
        }
      }
    }
  }
}

.package-info-blc {
  .table-striped {
    > tbody {
      > tr {
        &:nth-of-type(odd) {
          > * {
            --bs-table-accent-bg: rgba(235, 235, 235, 0.28);
          }
        }
      }
    }
  }
}

label.block {
  font-size: 18px !important;
}

.invoice-summary-table.table-striped {
  > tbody {
    > tr {
      &:nth-of-type(even) {
        > * {
          --bs-table-accent-bg: rgba(116, 114, 114, 0.28);
          background: transparent !important;
        }
      }
    }
  }
}

.inputName {
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 8px 5px 6px 6px;
  color: #282e65;
  font-size: 16px;
}
