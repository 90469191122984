@import "../../../../assets/css/theme/variables";

.product-order-blc {
	background: #f8f8f8;
	border-radius: 10px;
	padding: 15px;
	> .row-block {
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0;
		}
		.order-btns {
			.btn {
				margin-bottom: 15px;
				&:last-child {
					margin-bottom: 0;
				}
				&.order-history-btn {
					border: 1px solid rgba(9, 9, 84, 0.12);
					filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.14));
					border-radius: 8px;
					color: $base-color-private;
					&:hover {
						border-color: $primary;
						color: $primary;
					}
				}
			}
		}
		.order-breif {
			li {
				font-size: 14px;
				font-weight: 400;
				line-height: 150%;
				padding-bottom: 10px;
				&:last-child {
					padding-bottom: 0;
				}
				label {
					margin-right: 15px;
				}
				span {
					font-weight: 600;
				}
			}
		}
	}

	.order-msg {
		font-weight: 400;
		font-size: 13px;
		line-height: 150%;
		color: #788693;
	}
}
