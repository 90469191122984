.stepper_row {


    .rs-steps-item {
        position: relative;
        padding-bottom: 43px;
        max-width: 100% !important;
        width: 100%;

        &::before {
            content: "";
            position: absolute;
            left: 15px;
            height: 100%;
            width: 2px;
            background: #788C9F;
            z-index: 0;
        }

        &:last-child {
            &::before {
                content: unset;
            }
        }

        &.rs-steps-item-status-finish {
            &::before {
                background: #282E65;
            }

            .rs-steps-item-icon {
                position: relative;
                background-color: #282E65;

                &:before {
                    line-height: 1;
                    content: '1';
                }

                svg {
                    display: none;
                }
            }
        }

        &.rs-steps-item-status-finish:nth-child(2) {
            .rs-steps-item-icon {
                &:before {
                    content: '2';
                }
            }
        }

        &.rs-steps-item-status-finish:nth-child(3) {
            .rs-steps-item-icon {
                &:before {
                    content: '3';
                }
            }
        }

        &.rs-steps-item-active {
            &::before {
                background: #282E65;
            }

            .rs-steps-item-icon {
                position: relative;
                background-color: #282E65;
            }
        }

        .rs-steps-item-title {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            color: #282E65;
        }

        .rs-steps-item-description {
            font-size: 14px;
            color: #788693;
            font-weight: 400;
        }

        .rs-steps-item-icon {
            width: 31px;
            height: 31px;
            background-color: #788C9F;
            border-radius: 90px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #fff;
            font-weight: 400;
            position: relative;
        }

        .rs-steps-item-content {
            position: absolute;
            left: 50px;
            top: 0;
        }
    }

    .tabcontent_bg {
        border-radius: 10px;
        background: rgba(248, 248, 248, 0.90);
        padding: 10px 14px;
        height: 100%;
    }
}