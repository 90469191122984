@import "../../assets/css/theme/variables";

.location-info-blc {
    background: #f8f8f8;
    border-radius: 8px;
    padding: 20px;
    .info-blc {
        margin-bottom: 15px;
        .title {
            h3 {
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
            }
        }
        .row-block {
            line-height: 150%;
            @media (max-width: 640px) {
                font-size: 15px;
            }
            .icon {
                width: 18px;
                margin-right: 12px;
                flex-shrink: 0;
                &.mail-icon {
                    font-size: 20px;
                    font-weight: 300;
                }
            }
            .txt {
                .symbol {
                    margin: 0 8px;
                }
            }
        }
    }
}
