.tab_row1 {
  width: 100%;
}
.tab_row1.nav-tabs .nav-link {
  display: inline-flex;
  border-color: transparent;
  color: #282e65;
  font-weight: 400;
  padding: 10px 20px 10px 0;
  margin-bottom: 0;
  cursor: pointer;
  position: relative;
  border: 0;
}
.tab_row1.nav-tabs .nav-link.active {
  color: #3d3efd;
}
.tab_row1.nav-tabs .nav-link.active::before {
  content: "";
  width: 40px;
  height: 2px;
  background-color: #3d3efd;
  position: absolute;
  bottom: -1px;
}

.btn-blc .btn.r_d {
  height: 28px;
  padding: 0;
  background-color: #9584FC;
  border-color: #9584FC;
  color: #fff !important;
  min-width: 105px;
  border-radius: 100px;
  margin-right: 9px;
}
.btn-blc .btn.r_d:active {
  background-color: #9584FC;
  color: #fff !important;
}
.btn-blc .btn.btn-success {
  height: 28px;
  min-width: 105px;
  padding: 0;
}/*# sourceMappingURL=index.css.map */