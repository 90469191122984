.product_info_relative {
    padding-right: 170px;

    .qr_img1 {
        position: absolute;
        top: 39%;
        transform: translate(0, -50%);
        right: 15px;

        p {
            font-size: 13px;
            color: #282E65;
            font-weight: 400;
        }
    }
}