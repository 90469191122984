@import "../../../../assets/css/theme/variables";

.product-detail-wrapper {
    .product-list {
        li {
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
            &.active {
                .btn {
                    background-color: rgba($primary, 0.08);
                    border-color: $primary;
                }
            }
            .btn {
                background: #f8f8f8;
                border-radius: 10px;
                color: $base-color-private;
                padding: 8px 10px;
                &:hover {
                    background-color: rgba($primary, 0.08);
                    border-color: $primary;
                }
                .pic {
                    width: 32px;
                    height: 32px;
                    margin-right: 10px;
                    .inner {
                        background-color: #fff;
                        border-radius: 10px;
                        padding: 5px;
                        .img-placeholder span svg {
                            width: 14px;
                        }
                    }
                }
                .txt {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    label {
                        margin-bottom: 6px;
                    }
                }
            }
        }
    }
}
