@import "../../../../assets/css/theme/variables";


.patient-intake-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 15px;
    gap: 10px;


    


    /* BG */

    background: rgba(248, 248, 248, 0.9);
    border-radius: 10px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    .information-group {
        gap: 20px;
    }

    .intake-form {


        min-height: 40px;

        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 15px;
        align-self: stretch;

        justify-content: space-between;

        /* Pure White */

        background: #FFFFFF;
        border-radius: 8px;

    }


    .intake-options {
        gap: 10px;
    }

    p {
        margin-top: auto;
        margin-bottom: auto;
    }


    .txt {

        h2 {
            font-weight: 600;
            font-size: 18px;
            line-height: 150%;
            color: #282E65;
        }

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            /* identical to box height, or 21px */

            display: flex;
            align-items: center;

            /* Dark Blue */

            color: #282E65;
        }
    }


}