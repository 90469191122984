// Theme CSS
@import "./assets/css/theme/variables";
@import "./assets/css/theme/input";
@import "./assets/css/theme/buttons";
@import "./assets/css/theme/alert";
@import "./assets/css/theme/badge";
@import "./assets/css/theme/bg";
@import "./assets/css/theme/text";
@import "./assets/css/theme/switch";
@import "./assets/css/theme/checkbox";
@import "./assets/css/theme/date-picker";

// Common Style
@import "./assets/css/common/select";
@import "./assets/css/common/main-layout";
@import "./assets/css/common/card";
@import "./assets/css/common/dropdown-menu";
@import "./assets/css/common/form-block";
@import "./assets/css/common/swtich-cell";
@import "./assets/css/common/table-list";
@import "./assets/css/common/modal";
@import "./assets/css/common/text-clip";
@import "./assets/css/common/tooltip";
@import "./assets/css/common/_popover";
@import "./assets/css/common/inner-pages";
@import "./assets/css/common/toast";
@import "./assets/css/common/tabs";
@import "./assets/css/common/title";
@import "./assets/css/common/common";

// Shared Component Style
@import "./assets/css/component/petFileCard.scss";
@import "./assets/css/component/userSetting.scss";
@import "./assets/css/component/PaymentSuccessBlock.scss";
@import "./assets/css/component/PlanPricingBlock.scss";
@import "./assets/css/component/PlanPricing.scss";
@import "./assets/css/component/AddPetHealthForm.scss";
@import "./assets/css/component/Sidebar.scss";
@import "./assets/css/component/ListingFilterBlock.scss";
@import "./assets/css/component/staffProfileEducation.scss";
@import "./shared/UserThumbnailListBlock/index.scss";
@import "./assets/css/component/SettingTabBlock.scss";
@import "./assets/css/component/ManageProfileTabBlock.scss";
@import "./assets/css/component/UserProfileHead.scss";
@import "./shared/BackButton/index.scss";
@import "./assets/css/component/StaffDetailTabBlock.scss";
@import "./assets/css/component/CalendarSlotItem.scss";

body {
  font-family: $primary-font;
  font-weight: 400;
  font-size: 16px;
  color: $base-color;
  background-color: #fff;


  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    display:none !important;
    width: 3px !important;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }



  a {
    cursor: pointer;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  > iframe {
    display: none;
  }
}

html,
body {
  height: 100%;
}

#root,
#root > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}

:focus {
  outline: none;
}

a {
  color: $primary;
  transition: all ease 0.3s 0s;

  &:hover {
    color: $primary-dark;
    text-decoration: none;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  resize: none;
}

/* Cursor Pointer */
.cursor-pointer {
  cursor: pointer;
}

svg {
  height: 100%;
}

.ease {
  transition: all ease 0.3s 0s;
}

.collapse {
  visibility: visible;
}

@font-face {
  font-family: "SFProDisplay-Regular";
  src: url("../src/assets/font-face/SFProDisplay-Regular.eot");
  src: url("../src/assets/font-face/SFProDisplay-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/font-face/SFProDisplay-Regular.woff2") format("woff2"),
    url("../src/assets/font-face/SFProDisplay-Regular.woff") format("woff"),
    url("../src/assets/font-face/SFProDisplay-Regular.ttf") format("truetype"),
    url("../src/assets/font-face/SFProDisplay-Regular.svg#SFProDisplay-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProDisplay-Bold";
  src: url("../src/assets/font-face/SFProDisplay-Bold.eot");
  src: url("../src/assets/font-face/SFProDisplay-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/font-face/SFProDisplay-Bold.woff2") format("woff2"),
    url("../src/assets/font-face/SFProDisplay-Bold.woff") format("woff"),
    url("../src/assets/font-face/SFProDisplay-Bold.ttf") format("truetype"),
    url("../src/assets/font-face/SFProDisplay-Bold.svg#SFProDisplay-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProDisplay-Medium";
  src: url("../src/assets/font-face/SFProDisplay-Medium.eot");
  src: url("../src/assets/font-face/SFProDisplay-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/font-face/SFProDisplay-Medium.woff2") format("woff2"),
    url("../src/assets/font-face/SFProDisplay-Medium.woff") format("woff"),
    url("../src/assets/font-face/SFProDisplay-Medium.ttf") format("truetype"),
    url("../src/assets/font-face/SFProDisplay-Medium.svg#SFProDisplay-Medium")
      format("svg");
  font-family: "SFProDisplay-Medium";
  font-style: normal;
  font-display: swap;
}

// vetRecor Font-Icons
@font-face {
  font-family: "icomoon";
  src: url("./assets/font-icon/fonts/icomoon.ttf?2owaxb") format("truetype"),
    url("./assets/font-icon/fonts/icomoon.woff?2owaxb") format("woff"),
    url("./assets/font-icon/fonts/icomoon.svg?2owaxb#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icomoon-"],
[class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-webcam:before {
  content: "\e900";
  color: #282e65;
}

.icomoon-video-call:before {
  content: "\e901";
  color: #3d3efd;
}

.icomoon-user-multiple:before {
  content: "\e902";
  color: #282e65;
}

.icomoon-user-search:before {
  content: "\e903";
  color: #788693;
}

.icomoon-upload:before {
  content: "\e904";
  color: #3d3efd;
}

.icomoon-user-account:before {
  content: "\e905";
  color: #282e65;
}

.icomoon-trash:before {
  content: "\e906";
  color: #788693;
}

.icomoon-thumb-up:before {
  content: "\e907";
}

.icomoon-table-view:before {
  content: "\e908";
  color: #788693;
}

.icomoon-share:before {
  content: "\e909";
}

.icomoon-setting:before {
  content: "\e90a";
  color: #282e65;
}

.icon-phone-hang-up:before {
  content: "\e943";
  //color:#de2b2b;
}

.icomoon-setting-spark:before {
  content: "\e90b";
  color: #282e65;
}

.icomoon-search:before {
  content: "\e90c";
  color: #788693;
}

.icomoon-search-data:before {
  content: "\e90d";
  color: #788693;
}

.icomoon-room:before {
  content: "\e90e";
  color: #282e65;
}

.icomoon-report2 .path1:before {
  content: "\e90f";
  color: rgb(107, 63, 185);
}

.icomoon-report2 .path2:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(100, 57, 181);
}

.icomoon-report2 .path3:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(129, 91, 195);
}

.icomoon-report2 .path4:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icomoon-report2 .path5:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(183, 162, 221);
}

.icomoon-report2 .path6:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icomoon-report2 .path7:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icomoon-report2 .path8:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icomoon-report2 .path9:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icomoon-report2 .path10:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icomoon-report2 .path11:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icomoon-report:before {
  content: "\e91a";
  color: #282e65;
}

.icomoon-report-excel .path1:before {
  content: "\e91b";
  color: rgb(32, 164, 100);
}

.icomoon-report-excel .path2:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(20, 148, 86);
}

.icomoon-report-excel .path3:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(56, 174, 116);
}

.icomoon-report-excel .path4:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icomoon-report-excel .path5:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(142, 209, 177);
}

.icomoon-report-excel .path6:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icomoon-refresh:before {
  content: "\e921";
  color: #3d3efd;
}

.icomoon-plus-btn:before {
  content: "\e922";
}

.icomoon-plus-btn-line:before {
  content: "\e923";
  color: #3d3efd;
}

.icomoon-play-btn:before {
  content: "\e924";
  color: #409de0;
}

.icomoon-pill .path1:before {
  content: "\e925";
  color: rgb(120, 134, 147);
}

.icomoon-pill .path2:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icomoon-phone-chat:before {
  content: "\e927";
  color: #282e65;
}

.icomoon-pet-foot:before {
  content: "\e928";
  color: #282e65;
}

.icomoon-pencil:before {
  content: "\e929";
}

.icomoon-ortho:before {
  content: "\e92a";
  color: #788693;
}

.icomoon-more:before {
  content: "\e92b";
}

.icomoon-message:before {
  content: "\e92c";
  color: #282e65;
}

.icomoon-medical:before {
  content: "\e92d";
  color: #788693;
}

.icomoon-medical-verified:before {
  content: "\e92e";
}

.icomoon-lock:before {
  content: "\e92f";
}

.icomoon-location:before {
  content: "\e930";
  color: #282e65;
}

.icomoon-location-medical:before {
  content: "\e931";
  color: #282e65;
}

.icomoon-location-current:before {
  content: "\e932";
}

.icomoon-location-check:before {
  content: "\e933";
  color: #3d3efd;
}

.icomoon-leaf:before {
  content: "\e934";
  color: #282e65;
}

.icomoon-lab:before {
  content: "\e935";
  color: #fff;
}

.icomoon-inventory:before {
  content: "\e936";
  color: #282e65;
}

.icomoon-heart-beat:before {
  content: "\e937";
  color: #788693;
}

.icomoon-filter-list:before {
  content: "\e938";
  color: #788693;
}

.icomoon-email:before {
  content: "\e939";
}

.icomoon-email-fill:before {
  content: "\e93a";
}

.icomoon-download:before {
  content: "\e93b";
  color: #788693;
}

.icomoon-download-1:before {
  content: "\e93c";
  color: #3d3efd;
}

.icomoon-date:before {
  content: "\e93d";
  color: #788c9f;
}

.icomoon-date-range:before {
  content: "\e93e";
  color: #788693;
}

.icomoon-date-check:before {
  content: "\e93f";
  color: #282e65;
}

.icomoon-cross:before {
  content: "\e940";
  color: #282e65;
}

.icomoon-cross-btn:before {
  content: "\e941";
  color: #de2b2b;
}

.icomoon-column-view:before {
  content: "\e942";
  color: #788693;
}

.icomoon-close:before {
  content: "\e943";
  color: #788c9f;
}

.icomoon-clock:before {
  content: "\e944";
  color: #282e65;
}

.icomoon-circle-incomplete:before {
  content: "\e945";
  color: #fff;
}

.icomoon-check-btn:before {
  content: "\e946";
  color: #11ca9d;
}

.icomoon-check:before {
  content: "\e947";
  color: #fff;
}

.icomoon-check-btn1:before {
  content: "\e948";
  color: #11ca9d;
}

.icomoon-certificate:before {
  content: "\e949";
}

.icomoon-capsule:before {
  content: "\e94a";
  color: #11ca9d;
}

.icomoon-bullet-star:before {
  content: "\e94b";
  color: #282e65;
}

.icomoon-boarding:before {
  content: "\e94c";
  color: #282e65;
}

.icomoon-billing:before {
  content: "\e94d";
  color: #282e65;
}

.icomoon-auto-mode:before {
  content: "\e94e";
  color: #282e65;
}

.icomoon-attach:before {
  content: "\e94f";
  color: #3d3efd;
}

.icomoon-arrow-double-forward:before {
  content: "\e950";
  color: #788693;
}

.icomoon-arrow-double-backward:before {
  content: "\e951";
  color: #788693;
}

.icomoon-appointment:before {
  content: "\e952";
  color: #282e65;
}

.icomoon-star:before {
  content: "\e9d7";
  color: #282e65;
}

.icomoon-angle-down:before {
  content: "\e953";
  color: #788c9f;
}

.icomoon-alert:before {
  content: "\e954";
  color: #f5c004;
}

.btn-soft-primary {
  background: rgba(19, 62, 216, 0.1);
  border: 1px solid rgba(19, 62, 216, 0.15);
  color: $primary;

  &:hover,
  &:active,
  &:focus {
    background-color: rgba($primary, 0.8) !important;
    border-color: rgba($primary, 0.8) !important;
    color: #fff !important;
  }
}

.btn-soft-success {
  background-color: rgba($success, 0.1);
  border: 1px solid rgba($success, 0.3);
  color: $success;

  &:hover,
  &:active,
  &:focus {
    background-color: $success !important;
    border-color: $success !important;
    color: #fff !important;
  }
}

.btn-soft-warning {
  background-color: rgba($warning, 0.1);
  border: 1px solid rgba($warning, 0.3);
  color: $warning;

  &:hover,
  &:active,
  &:focus {
    background-color: $warning !important;
    border-color: $warning !important;
    color: #fff !important;
  }
}

.btn-soft-danger {
  background-color: rgba($danger, 0.1);
  border: 1px solid rgba($danger, 0.3);
  color: $danger;

  &:hover,
  &:active,
  &:focus {
    background-color: $danger !important;
    border-color: $danger !important;
    color: #fff !important;
  }
}

.btn-action-danger {
  background: rgba(216, 19, 19, 0.1);
  border: 1px solid rgba(216, 19, 19, 0.15);
  color: $danger;

  &:hover,
  &:active,
  &:focus {
    background-color: $danger;
    border-color: $danger;
    color: #fff;
  }
}

.btn-action {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 0px;
  line-height: 35px;
  margin: 0px 5px;
}

.btn-action-success {
  background-color: rgba($success, 0.1);
  border: 1px solid rgba($success, 0.3);
  color: $success;

  &:hover,
  &:active,
  &:focus {
    background-color: $success;
    border-color: $success;
    color: #fff;
  }
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-family: "SFProDisplay-Medium";
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-family: "Inter", sans-serif;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.media-thumb {
  width: 50px;
  height: 50px;
  border-radius: 0.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;

  &.placeholder-cell {
    font-size: 25px;
    background-color: rgba(237, 100, 54, 0.1);
  }

  .imgPlaceholder {
    color: rgba($base-color, 0.2);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
  }

  &.rounded-circle {
    img {
      border-radius: 50%;
    }
  }

  .availability {
    width: 14px;
    height: 14px;
    display: block;
    border: 2px solid #fff;
    border-radius: 50px;
    background-color: #ccc;
    position: absolute;
    bottom: 0;
    right: 0;

    &.online {
      background-color: $success;
    }

    &.offline {
      background-color: $danger;
    }

    &.away {
      background-color: $warning;
    }
  }

  .edit {
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    background-color: #fff;
    color: #707070;
    border-radius: 50px;
    font-size: 80%;
    text-align: center;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &.xs {
    width: 30px;
    height: 30px;

    .availability {
      width: 0.625rem;
      height: 0.625rem;
      border-width: 1.75px;
    }
  }

  &.sm {
    width: 40px;
    height: 40px;

    .imgPlaceholder {
      font-size: 21px;
    }
  }

  &.lg {
    width: 80px;
    height: 80px;

    .availability {
      width: 1rem;
      height: 1rem;
      right: 10px;
    }
  }

  &.xl {
    width: 100px;
    height: 100px;

    .availability {
      width: 1rem;
      height: 1rem;
      right: 13px;
      bottom: 3px;
    }
  }

  &.xxl {
    width: 150px;
    height: 150px;

    .availability {
      width: 1rem;
      height: 1rem;
      right: 30px;
      bottom: 3px;
    }
  }

  &.add-new {
    border: 1px dashed $primary;
    color: $primary;
    cursor: pointer;
    transition: all 0.3s linear;
    overflow: hidden;
    padding: 10px;

    img {
      & + i {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: all 0.3s linear;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $primary;
          z-index: -1;
        }
      }
    }

    &:hover {
      background-color: $primary;
      color: #fff;

      img {
        & + i {
          opacity: 1;
        }
      }
    }

    .label {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 6px 4px;
      text-align: center;
      background-color: #f6f7fd;
      color: rgba(27, 44, 77, 0.44);
      font-size: 13px;
    }

    &.document {
      border: 1px solid #f6f7fd;
      background-color: #f6f7fd;
      height: 100px;
      padding: 0;

      &:hover {
        background-color: $primary;
      }
    }
  }
}

.action {
  white-space: nowrap;
  text-align: right;

  .btn {
    width: 38px;
    height: 38px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 10px;
    font-size: 16px;

    [class*="icomoon-"] {
      font-size: 20px;
    }

    &.btn:not(:last-child) {
      margin-right: 10px;
    }

    svg {
      width: 19px;
    }

    &.delete-btn {
      svg {
        path {
          stroke: rgba($danger, 1);
        }
      }

      &:hover,
      &:active,
      &:focus {
        svg {
          path {
            stroke: rgba(#fff, 1);
          }
        }
      }
    }

    &.edit-btn {
      &:hover,
      &:active,
      &:focus {
        svg {
          g {
            fill: rgba(#fff, 1);
          }
        }
      }
    }

    &.reset-bttn {
      background: rgba(217, 136, 255, 0.15);
      border: 1px solid rgba(152, 63, 193, 0.2);

      &:hover,
      &:active,
      &:focus {
        background-color: rgb(187, 91, 232);

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    &.price-btn {
      background: rgba($success, 0.12);
      border-color: rgba($success, 0.1);

      &:hover,
      &:active,
      &:focus {
        background-color: rgba($success, 1);

        svg {
          path {
            fill: #fff;
            stroke: #fff;
          }
        }
      }
    }
  }

  &.sm {
    .btn {
      width: 28px;
      height: 28px;
    }
  }
}

.label-none {
  label {
    display: none;
  }
}

.active {
  .inner {
    .form-block {
      margin-bottom: 19px;
    }

    .btn-blc {
      .btn {
        &.btn-success {
          &.add-btn {
            padding: 6px;
            height: auto;
            margin-left: 10px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.msg_row {
  border-right: 1px solid #eeeef2;
  min-height: calc(100vh - 50px);
  overflow: auto;
}

.page-count {
  font-size: 14px;
  color: #172b4d;
  font-weight: 400;
}

.icomoon-check {
  &.icon {
    margin-bottom: 0 !important;
  }
}

.swtich-cell2 {
  .circle-icon {
    margin-bottom: 0 !important;
  }
}

.list-view-type-btn {
  span {
    margin-bottom: 0 !important;
  }
}

.icomoon-search,
.material-symbols-outlined,
.material-icons {
  margin-bottom: 0 !important;
}

.invoice_stats {
  .w-60 {
    overflow: hidden;
    border-radius: 13px;
  }
}

.calendar-filter-blc {
  span {
    &.inline-flex {
      margin-bottom: 0 !important;
    }
  }
}

.search-blc {
  span {
    margin-bottom: 0 !important;
  }
}

.swtich-cell2 {
  span {
    margin-bottom: 0 !important;
  }
}

span {
  &.icon {
    margin-bottom: 0 !important;
  }
}

.text_truncate_css {
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.solit_time_with_img {
  .time-txt {
    max-width: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.un_tetx {
  font-size: 13px;
  color: #788693;
  font-weight: 400;
}

.search-blc2 {
  span {
    margin-bottom: 0;
  }
}

@media (max-width: 1199px) {
  .product_layout_flex {
    .basis-\[18\%\] {
      flex-basis: 25%;
    }

    .basis-\[80\%\] {
      flex-basis: 75%;
    }
  }

  .invoice_stats {
    flex-wrap: wrap;
  }
}

@media (max-width: 991px) {
  .product_layout_flex {
    margin-left: 0;

    .basis-\[18\%\] {
      flex-basis: 100%;

      ol {
        display: flex;
        overflow: auto;

        &.border-l {
          border-left: 0;
        }

        li {
          min-width: 130px;
          text-align: center;
          margin: 0;
          padding: 0 10px;
          position: relative;

          &:after {
            content: "";
            content: "";
            position: absolute;
            right: -50%;
            top: 13px;
            width: 50%;
            height: 1px;
            background-color: rgb(40, 46, 101);
            transform: translate(-50%, 0);
          }

          &:last-child {
            &:after {
              content: unset;
            }
          }

          span {
            position: unset !important;
            margin: 0 auto 8px;
          }
        }
      }
    }

    .basis-\[80\%\] {
      flex-basis: 100%;
      margin-top: 17px;

      .ml-5,
      .ml-3 {
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .product_layout_flex {
    .basis-\[18\%\] {
      h3 {
        font-size: 14px;
      }

      p {
        font-size: 13px;
        line-height: unset;
        margin-top: 4px;
      }
    }

    .basis-\[80\%\] {
      .flex {
        flex-direction: column;
        align-items: start;

        input,
        select,
        textarea {
          width: 100%;
        }
      }

      .w-\[81\%\] {
        width: 100%;
        top: unset;

        .flex {
          width: 100%;
          flex-direction: row;
          align-items: center;

          input {
            width: 20px;
          }
        }

        label {
          padding: 6px 0 4px !important;
        }
      }
    }

    .bottom-\[39px\] {
      bottom: 0;
      margin: 13px 0;
    }
  }

  .product-detail-wrapper {
    flex-direction: column;

    .mid-col,
    .right-col {
      width: 100%;
      margin-right: 0;
    }
  }

  .invoice_stats {
    justify-content: space-between;
    margin-top: 10px;

    .w-60 {
      width: 48%;

      &.bg-blue {
        background-size: cover;
        background-position: center;
        padding: 15px 10px !important;
      }

      &.bg-grey {
        background-size: cover;
        background-position: center;
        padding: 15px 10px !important;

        .absolute {
          right: 13px !important;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .side-drawer {
    &.\!w-\[400px\] {
      width: 95% !important;
    }
  }
}

@media (max-width: 375px) {
  .invoice_stats {
    .bg-blue {
      height: auto;

      h5 {
        font-size: 17px !important;
      }

      svg {
        width: 20px;
      }

      .absolute {
        right: 12px !important;
      }
    }

    .bg-grey {
      height: auto;

      h5 {
        font-size: 17px !important;
      }

      .absolute {
        span {
          font-size: 12px;
        }

        svg {
          width: 13px;
        }
      }
    }
  }
}


.firebase-emulator-warning { display: none; }