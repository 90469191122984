@import "../../../assets/css/theme/variables";

.btn.add-slot-btn {
	color: #eeeef2;
	span {
		font-size: 24px;
	}
	&:hover {
		color: $primary;
		span {
			font-size: 26px;
		}
	}
}
