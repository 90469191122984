@import "../../../../../../assets/css/theme/variables";

.medication-plan-item {
    background: #f8f8f8;
    border-radius: 10px;
    padding: 10px;

    >.head {
        transition: all ease 0.3s 0s;

        margin-bottom: 12px;

        h2,
        h3 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        h2 {
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            margin-bottom: 6px;
        }

        h3 {
            font-size: 13px;
            font-weight: 400;
            line-height: 150%;
            color: #788693;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .chart-blc {
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 15px;

        .chart-item {
            width: 60px;
            margin-right: 15px;
        }
    }

    .treat-by-cell {
        border: 1px solid rgba(40, 46, 101, 0.1);
        padding: 6px 7px;
        border-radius: 8px;
        font-weight: 400;
        font-size: 13px;
        line-height: 150%;
        max-width: 100%;

        .pic {
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }

        .txt {
            margin-right: 8px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .icon {
            span {
                font-size: 16px;
            }
        }

        .status {
            border: 0.5px solid rgba(255, 255, 255, 0.2);
            border-radius: 3px;
            padding: 0px 5px;

            &.inprogress {
                background: #def1ff;
                color: #229cf4;
            }
        }
    }
}