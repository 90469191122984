.tab-wrapper-profile {
    @media (max-width: 767px) {
        flex: none !important;
    }
    .tab-content {
        .tab-row {
            background: #f8f8f8;
            padding: 20px;
            margin-bottom: 8px;
            border-radius: 8px;
            .tab-col {
                .form-group {
                    margin-bottom: 10px;
                }
                &.lft {
                    width: 400px;
                    margin-right: 30px;
                    flex-shrink: 0;
                    @media (min-width: 1023px) and (max-width: 1150px) {
                        width: 350px;
                    }
                    @media (max-width: 900px) {
                        width: 100%;
                        margin: 0 0 20px;
                    }
                    &.skills {
                        @media (max-width: 900px) {
                            margin-bottom: 0;
                        }
                    }
                }
                > .inner {
                    max-width: 400px;
                    width: 100%;
                }
                .info-block {
                    @media (max-width: 900px) {
                        margin-bottom: 10px;
                    }
                    h3 {
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 24px;
                        margin-bottom: 8px;
                    }
                    p {
                        font-size: 16px;
                        color: #788693;
                        margin-bottom: 0;
                    }
                }
                .msg-rw {
                    span {
                        margin-right: 11px;
                    }
                    .btn {
                        padding: 6px 10px;
                        @media (max-width: 900px) {
                            padding: 2px 10px;
                        }
                    }
                }
            }
        }
    }
}
