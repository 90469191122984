@import "../../../../../../../assets/css/theme/variables";

.story-list-item2 {
    padding: 15px;
    margin-bottom: 5px;
    border-radius: 8px;
    background: rgba(234, 246, 252, 0.54);

    &.inactive {
        background: rgba(252, 234, 234, 0.54);

        >.right-col .story-info-blc .title>.left-col span {
            margin: 4px 11px 0 0;
        }
    }

    >.left-col {
        margin-right: 20px;

        .pic-cell {
            width: 143px;
            background: #fff;
            padding: 10px;
            border-radius: 10px;

            .pic {
                width: 50px;
                height: 50px;

                img {
                    width: 100%;
                    height: 100%;
                }

                .user-placeholder {
                    span {
                        font-size: 50px;
                    }
                }
            }

            .txt {
                font-weight: 400;
                font-size: 13px;
                line-height: 150%;

                h4 {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    margin-bottom: 5px;
                }
            }
        }
    }

    >.right-col {
        .story-info-blc {
            .title {
                margin-bottom: 10px;

                >.left-col {
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 150%;
                    margin-right: 10px;

                    @media (max-width: 480px) {
                        font-size: 16px;
                    }

                    span {
                        margin: 3px 11px 0 0;
                        color: $success;
                    }
                }

                >.right-col {
                    .time {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 150%;
                    }
                }
            }

            .sub-ttl {
                margin-bottom: 10px;

                >span {
                    margin-right: 10px;
                    flex-shrink: 0;
                }

                .tags {
                    span {
                        background: rgba(228, 228, 228, 0.28);
                        border: 1px solid rgba(9, 9, 84, 0.12);
                        border-radius: 5px;
                        margin: 0 3px 5px 0;
                        padding: 4px 8px;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 16px;
                    }
                }
            }

            .info-blc {
                line-height: 150%;
            }
        }
    }

    &.responsive {
        @media (max-width: 640px) {
            flex-direction: column;
            align-items: center;
        }

        >.left-col {
            @media (max-width: 640px) {
                margin: 0 0 20px 0;
                width: 100%;
            }

            .pic-cell {
                @media (max-width: 640px) {
                    width: 100%;
                }
            }
        }

        .story-info-blc {
            @media (max-width: 480px) {
                font-size: 14px;
            }

            .title {
                @media (max-width: 480px) {
                    flex-direction: column;
                    align-items: flex-start !important;
                }

                >.left-col {
                    @media (max-width: 480px) {
                        margin: 0;
                    }
                }

                >.right-col {
                    @media (max-width: 480px) {
                        margin: 0 0 0 29px !important;
                    }
                }
            }

            .sub-ttl {
                @media (max-width: 1250px) {
                    flex-direction: column;
                }

                >span {
                    @media (max-width: 1250px) {
                        margin: 0 0 8px 0;
                    }
                }
            }
        }
    }
}