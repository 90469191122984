.form-select,
select.form-control {
  background: url("../../img/vetRecor/angle-down.svg") #fff calc(100% - 20px) center no-repeat;
  background-size: 17px auto;
  -webkit-appearance: none;
  padding-right: 44px;
}

// React Select

.css-b62m3t-container {
  .css-qc6sy-singleValue,
  .css-14el2xx-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: $base-color;
  }
  .css-6j8wv5-Input,
  .css-ackcql {
    margin: 0;
    padding: 0;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: $base-color !important;
    input {
      font-size: 14px !important;
      font-weight: 400 !important;
      color: $base-color !important;
    }
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-1s2u09g-control,
  .css-1pahdxg-control {
    min-height: 60px;
    height: auto;
    border-color: rgba(8, 8, 41, 0.1) !important;
    box-shadow: 0px 4px 10px rgba(61, 57, 137, 0.05);
    color: $base-color;
    border-radius: 10px;
    padding: 0.375rem 2.5rem 0.375rem 15px;
    background: url("../../img/vetRecor/angle-down.svg") #fff calc(100% - 20px) center no-repeat;
    background-size: 17px auto;

    @media (max-width: 1919px) {
      min-height: 50px;
      border-radius: 6px;
    }

    &:focus {
      color: $base-color;
      box-shadow: 0 0 0 0.2rem rgba($primary, 0.2) !important;
      border-color: $primary;
      &:hover {
        border-color: $primary;
      }
    }
  }
  // .css-1hb7zxy-IndicatorsContainer {
  //   display: none;
  // }
  .css-319lph-ValueContainer {
    padding: 0;
  }
}
