@import "../../assets/css/theme/variables";

.pagination-blc {
  .pagination {
    margin-top: 20px;
    flex-shrink: 0;
    padding: 0 0;
    border-radius: 8px;
    background: rgba(248, 248, 248, 0.90);

    li {
      a {
        color: #676767;
        padding: 0;
        margin: 0 0 !important;
        background-color: transparent !important;
        width: 32px;
        height: 32px;
        border-radius: 0;
        border: 0;
        font-size: 14px;
        text-align: center;
        line-height: 28px;
      }
    }

    li.active {
      a {
        background-color: $primary !important;
        border-color: $primary;
        color: #fff !important;
      }
    }

    .prev-btn,
    .next-btn {
      flex-shrink: 0;
    }
  }
}