.btn-close {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") !important;
}
.offcanvas-header {
  padding-bottom: unset !important;
}
#certificate-create-canvas {
  width: 800px;
}
.s-block {
  .swtichCell.disable .switch-btn .icon::before,
  .swtichCell .switch-btn .icon::before {
    content: "";
  }
  .swtichCell.disable .switch-btn {
    background-color: #8d9daf;
  }
}
.certificate-type-dropdown {
  .select__control {
    height: 48px;
    border: 1px solid #0909541f !important;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #282e65;
    &:hover {
      border: 1px solid #0909541f !important;
      box-shadow: unset;
    }
  }
  .select__indicator-separator {
    display: none;
  }
  .select__indicator {
    height: 100%;
    width: 60px;
    display: flex;
    justify-content: center;
    background-color: #f8f8f8;
    border-radius: 0px 8px 8px 0px;
    border-left: 1px solid #0909541f;
  }
  .select__placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #282e65;
  }
}
.certificate-issue-date {
  position: relative;
  input {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #0909541f !important;
    font-size: 14px;
    font-weight: 400;
    color: #282e65;
    padding-left: 10px;
  }
  ::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #282e65;
  }
  .io-block {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 60px;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    border-radius: 0px 8px 8px 0px;
    border-left: 1px solid #0909541f;
  }
}
.certificate-input {
  input {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #0909541f;
    font-size: 14px;
    font-weight: 400;
    color: #282e65;
    padding-left: 10px;
  }
  ::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #282e65;
  }
}
.sigCanvas {
  background-color: #fbfbfc;
  touch-action: none;
  border: 1px dashed #00000033;
}
.certificate-img {
  position: absolute;
  top: -34px;
  left: 42%;
}
.sign-text {
  position: absolute;
  left: 42%;
  top: 44%;
  font-size: 13px;
  font-weight: 400;
  color: #8d9daf;
}
.sign-img-block {
  position: absolute;
  top: 15px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.mh-60 {
  min-height: 60px;
}
