.add-new-appointment-wrapper {
    > .left-col {
        width: 300px;
        flex-shrink: 0;
        margin-right: 16px;
    }
    > .right-col {
        .tab-content {
            > .tab-pane {
                overflow: auto;
            }
        }
    }
}
