@import "../../../assets/css/theme/variables";

.chat-box-tray {
  display: flex;
  align-items: baseline;
  align-items: center;
  margin-top: 15px;
  position: relative;
  bottom: 0;
  overflow: hidden;
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid rgba(9, 9, 84, 0.12);
  // input {
  //   padding: 10px 18px;
  //   border-radius: 8px;
  //   border: 1px solid rgba(9, 9, 84, 0.12);
  //   width: 100%;
  // }
  .private-Message-input {
    padding: 10px 18px;
    width: 100%;
  }
  .message-type {
    padding: 10px 5px 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    // border-right: 1px solid rgba(9, 9, 84, 0.12);;
    color: #282e65;
    font-size: 16px;
  }

  i {
    color: grey;
    font-size: 30px;
    vertical-align: middle;

    &:last-of-type {
      margin-left: 25px;
    }
  }
}

.attachment-btn {
  display: flex;
  cursor: pointer;
  overflow: hidden;
  justify-content: center;
  position: relative;
}

.attachment-btn {
  #myfile {
    position: absolute;
    opacity: 0;
    z-index: 10;
  }
}

.file_box {
  width: 75px;
  height: 75px;
  border-radius: 10px;
}

.file-delete-icon {
  position: absolute;
  top: 0;
  right: 0 !important;
}
.add_files {
  display: flex;
  gap: 10px;
  padding: 15px;
  height: 100%;
}
.appointment-card-in-input {
  position: absolute;
  bottom: 80%;
  width: calc(100% - 10px);
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: rgba(216, 216, 216, 0.5);
  overflow-y: auto;
  .add_files {
    .file_box {
      width: 150px;
      min-width: 150px;
      height: 100%;
      aspect-ratio: 1 /1;
    }
  }
}

.voice_message_box {
  position: absolute;
  left: 0px;
  background: #ebf5ff;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  align-items: center;
  display: flex;
  gap: 12px;
  z-index: 990;
  svg {
    scale: 1.4;
    cursor: pointer;
  }
  p {
    font-size: 16px;
    color: #282e65;
    margin: 0;
  }
}

// .send_btn {
//   z-index: 10;
// }
.send_btn {
  z-index: 999;
}

.reminder {
  position: absolute;
  left: 0px;
  background: #ebf5ff;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  align-items: center;
  display: flex;
  gap: 12px;
  .clock_box {
    display: flex;
    align-items: center;
    gap: 10px;
    border-right: 1px solid #cecece;
    padding: 3px 15px 3px 5px;
  }

  .clock_close_btn {
    position: absolute;
    right: 200px;
  }

  .select_box {
    border-right: 1px solid #cecece;
    padding-right: 10px;
    input {
      width: 140px;
      border: none;
      text-transform: capitalize;
      outline: none;
      font-size: 14px;
      padding: 0 !important;
      color: #282e65;

      border-radius: 0;
      background-color: transparent;
    }
  }

  .add_nots {
    border: none;
    max-width: 300px;
    width: 100%;
    margin-right: 15px;
    padding: 0 10px;
    background-color: transparent;
    ::placeholder {
      color: #788693;
    }
  }
  svg {
    scale: 1.4;
    cursor: pointer;
  }
  p {
    font-size: 16px;
    color: #282e65;
    margin: 0;
  }
}

// audio::-webkit-media-controls-mute-button{
//   display: none;
//   background-color: red;
//   border-radius: 50%;
// padding: 10px;
// }
audio::-webkit-media-controls-play-button {
  background-color: #706f6f;
  border-radius: 50%;
  padding: 10px;
}
audio::-webkit-media-controls-timeline-container {
  background-color: #282e65;
}
// audio::-webkit-media-controls-panel {
//   background-color: #fff;
// }
// audio::-webkit-media-controls-current-time-display
// audio::-webkit-media-controls-time-remaining-display
// audio::-webkit-media-controls-timeline
// audio::-webkit-media-controls-volume-slider-container
// audio::-webkit-media-controls-volume-slider
// audio::-webkit-media-controls-seek-back-button
// audio::-webkit-media-controls-seek-forward-button
// audio::-webkit-media-controls-fullscreen-button
// audio::-webkit-media-controls-rewind-button
// audio::-webkit-media-controls-return-to-realtime-button
audio::-webkit-media-controls-toggle-closed-captions-button {
  display: none;
}

.chat_reminder {
  .collapse-content-wrapper {
    border-radius: 8px;
    background: rgba(228, 228, 228, 0.28);
    padding: 10px;

    .form-control {
      height: 40px;
    }
  }
  .reminder_close {
    position: absolute;
    right: 10px;
    font-size: 25px;
    cursor: pointer;
    z-index: 9;
  }
}

#next-upcoming-container {
  border-radius: 5px;
  .btn-block {
    padding-top: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background: #f8f8f8e5;
    width: 100%;
  }
  .item-block {
    display: flex;
    padding: 10px;
    background: #f8f8f8e5;
    width: 100%;
    gap: 10px;
    border-radius: 10px;
    .data-block {
      min-width: 280px;
      max-width: 280px;
    }
    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
}

.reminder-warpper-message {
  min-width: 250px;
  border-radius: 10px;
  background: #f8f8f8e5;
  padding: 10px;

  .reminder-heading {
    h4 {
      color: #282e65;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
  }

  p {
    color: #788c9f;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }
}
