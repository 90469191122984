.form-btm-msg {
  font-size: 16px;
  font-family: "SFProDisplay-Medium";
  padding: 10px 30px;
  @media (max-width: 767px) {
    font-size: 15px;
  }
  span {
    margin-right: 2px;
  }
  .btn-link {
    color: #ed6436;
    font-size: 16px;
    font-family: "SFProDisplay-Medium";
    &:hover,
    &:focus,
    &:active {
      color: #ce481c;
    }
  }
}
