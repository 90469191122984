@import "../../assets/css/theme/variables";

.clinic-profile {
  border-right: 1px solid #eeeef2;
  border-left: 1px solid #eeeef2;
  // height: 100vh;

  > .inner {
    padding: 0 15px;

    .row-block {
      margin-bottom: 15px;
    }

    .new-tag {
      padding-top: 10px;
    }
  }

  .section-head {
    margin-bottom: 12px;

    .left-col {
      line-height: 150%;
      margin-right: 10px;
    }

    .right-col {
      .close-btn {
        font-size: 19px;
        color: $danger;
        padding: 0;

        &:hover,
        &:focus {
          color: $danger-dark;
        }
      }

      .add-btn {
        font-size: 19px;
        color: $success;
        padding: 0;

        &:hover,
        &:focus {
          color: $success-dark;
        }
      }
    }
  }
}
