@import "../../../../assets/css/theme/variables";


.schedule-status-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 15px;
    gap: 10px;


    /* BG */

    background: rgba(248, 248, 248, 0.9);
    border-radius: 10px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;




    .txt {
     
        h2 {
            font-weight: 600;
            font-size: 18px;
            line-height: 150%;
            color: #282E65;
        }

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            /* identical to box height, or 21px */

            display: flex;
            align-items: center;

            /* Dark Blue */

            color: #282E65;
        }
    }

    .btn-group {
        display: flex;
        flex-wrap: wrap;
        gap: -1px;
        width: 100%;

        .btn {
           
            max-height: 28px;
            white-space: nowrap;
            /* Field Outline  */

            border-width: 1px 1px 1px 1px;
            border-style: solid;
            border-color: rgba(9, 9, 84, 0.12);

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            /* identical to box height, or 21px */

            display: flex;
            align-items: center;
            justify-content: center;

            /* Dark Blue */

            color: #282E65;

            text-transform: none;
            box-shadow: none;

            &:hover,
            &:focus {
                color: $primary;
                border-color: $primary;
            }

            &.active {

                background: rgba(61, 62, 253, 0.08);
/* Field Outline  */

                border: 1px solid rgba(9, 9, 84, 0.12);

            
                color: #3D3EFD;
            }


        }


    }


}