@import "../../assets/css/theme/variables";

.add-appointment-sections {
  .appointment-header {
    padding-right: 15px;
    > .left-col {
      margin-right: 20px;
      span {
        display: block;
      }
    }
    > .right-col {
      .btns-blc {
        .btn {
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
          &.cancel-btn {
            min-width: 150px;
          }
          &.book-btn {
            min-width: 200px;
          }
        }
      }
    }
  }
  .inner-content-wrapper {
    padding-right: 15px;
  }
}
