@import "../../../../../../assets/css/theme/variables";

.medication-plan-block {
  > .inner {
    padding: 0 5px;

    .plan-items-rw {
      margin: 0 -5px;

      .item-col {
        width: 25%;
        padding: 0 5px;

        @media (max-width: 1199px) {
          width: 30%;
        }

        &.more_link {
          @media (max-width: 1199px) {
            width: 10% !important;
          }

          .more-item-btn {
            font-size: 13px;
            font-weight: 600;
            line-height: 150%;
            color: #788c9f;
            transform: rotate(-90deg);

            @media (max-width: 1199px) {
              padding: 0;
            }

            &:hover,
            &:focus {
              color: $primary;
            }
          }
        }

        * {
          cursor: pointer;
        }
      }

      .healthSnap-card {
        width: 33%;
        padding: 0 5px;

        @media (max-width: 1199px) {
          width: 30%;
        }

        &.more_link {
          @media (max-width: 1199px) {
            width: 10% !important;
          }

          .more-item-btn {
            font-size: 13px;
            font-weight: 600;
            line-height: 150%;
            color: #788c9f;
            transform: rotate(-90deg);

            @media (max-width: 1199px) {
              padding: 0;
            }

            &:hover,
            &:focus {
              color: $primary;
            }
          }
        }

        * {
          cursor: pointer;
        }
      }
    }
  }
}

.medication-panel-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  min-width: 800px;
  max-width: 70%;
  width: 100%;
  background-color: #fff;
  padding: 15px;
  transition: all ease 0.3s 0s;
  transform: translateX(100%);

  &.active {
    transform: translateX(0);
  }

  .add-section-medication-btn {
    border: 1px dashed #788c9f;
    border-radius: 10px;
    color: $success;
    padding: 8px 15px;
    &:hover,
    &:focus {
      border-color: $success;
    }
    span {
      font-size: 22px;
    }
  }

  .head {
    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;

      @media (max-width: 767px) {
        font-size: 19px;
      }
    }

    .panel-close-btn {
      color: $base-color-private;
      padding: 10px;

      &:hover {
        color: $primary;
      }
    }
  }

  .table-listing-blc {
    .table {
      thead {
        tr {
          th {
            position: sticky;
            top: 0;
          }
        }
      }
    }
  }
}
