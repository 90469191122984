.side-open-tabs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60px;
    border-left: 1px solid #EEEEF2;
    height: 100vh;
    flex-shrink: 0;
    background-color: #fff;
    transition: all ease 0.3s 0s;
    position: fixed;
    right: 0;
    top: 0;
    padding: 10px;
    z-index: 9;


    li {
        margin-bottom: 10px;
    }

    button {
        border-radius: 10px;
        background: rgba(228, 228, 228, 0.28);
        width: 40px;
        height: 40px;
        text-align: center;

        svg {
            margin: auto;
        }
    }
}

.right-sidebar-open {
    position: relative;
    max-width: 450px !important;
    width: 100% !important;
    // min-width: 340px;
    padding: 15px 10px;

    .offcanvas-header {
        padding: 5px;

        

        .btn-close {
            opacity: 1 !important;
            position: relative;
            right: 2px;
            top: -17px;

            &::before {
                position: absolute;
                top: 7px;
                right: 15px;
                content: " ";
                height: 20px;
                width: 2px;
                background-color: #282e65;
                transform: rotate(45deg);
            }

            &::after {
                position: absolute;
                top: 7px;
                right: 15px;
                content: " ";
                height: 20px;
                width: 2px;
                background-color: #282e65;
                transform: rotate(-45deg);
            }
        }

    }

    .offcanvas-body {
        width: 100%;
        // min-width: 320px;
        overflow-x: visible;
        overflow-y: scroll;
        padding:0px;

        .tab-content{
            min-width: 310px;
        }

        .patient-profile {
            color: #282e65;
        }
    }
}


.right-side-navs.nav-pills {
    .nav-link {
        padding: 0;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        background-color: rgba(228, 228, 228, 0.28);
    }

    .nav-link.active {
        background-color: transparent;
        border: 1px solid #3d3efd;
    }
}

.nav-pills {
    .show {
        >.nav-link {
            background-color: #3d3efd;
        }
    }
}

.profile-vaccination>.inner {
    .vaccination-items {
        background: #f8f8f8;
        padding: 9px 10px;
        margin-bottom: 5px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
    }

}

.outstanding_invoice {
    background: #4999C6 !important;
}

.patient-profile {
    .profile-list {
        li {
            font-size: 13px !important;
        }

        .bg-bluee {
            background: #4999C6;
        }
    }
}

.message-collapse-form {
    border-radius: 8px;
    border: 1px dashed rgba(120, 140, 159, 0.50);
}

.collapse-content-wrapper {
    border-radius: 8px;
    background: rgba(228, 228, 228, 0.28);
    padding: 10px;

    .form-control {
        height: 40px;
    }
}


.collapse-content-btn {
    .btn {
        border: 1px solid rgba(9, 9, 84, 0.12);
        filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.14));
        border-radius: 6px;
        text-transform: uppercase;
        color: #1d1d1d;
        padding: 6px 15px;
        margin: 0 5px;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;

        &:hover {
            color: #3d3efd;
            border-color: #3d3efd;
        }

    }

    .btn-bluee {
        border-color: #3d3efd;
        background-color: #3d3efd;
        color: #fff;
    }
}


.remind-title,.direct-msg-wrapper {
    .nav-pills {
        .nav-link {
            border-radius: 10px;
            border: 1px solid var(--Field-Outline, rgba(9, 9, 84, 0.12));
            font-size: 13px;
            color: #282e65;
            padding: 4px 10px;

            &:not(:last-child) {
                margin-right: 8px;
            }
        }

        .nav-link.active {
            color: #3D3EFD;
            background: rgba(61, 62, 253, 0.08);
        }
    }

    .nav-item {
        overflow-x: auto;
    }
}



.notification-profile {
    border-radius: 10px;
    background: rgba(228, 228, 228, 0.28);
    color: rgba(9, 9, 84, 0.12);
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    padding: 25px;
}

.reminder-warpper {
    border-radius: 10px;
    background: rgba(228, 228, 228, 0.28);
    padding: 10px;

    .reminder-heading {
        h4 {
            color: #282E65;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
        }
    }

    p {
        color: #788C9F;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
    }
}

.appointment-list-wrapper {
    h4 {
        color: #282E65;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
    }
}

.appoint-pic {
    width: 50px;
    height: 55px;
    margin-right: 12px;
}
.contact-box{
    color: #282E65;
}


// .email_outside_box{
//     max-width: 400px !important;
// }