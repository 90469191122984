@import "../theme/variables";

.main-wrapper {
  // max-width: 1440px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  color: #282e65;
  //overflow: unset !important;

  .right-wrapper {
    &.super-wrapper {
      @media (max-width: 1440px) {
        //padding-left: 85px;
        padding-left: 80px;
      }

      @media (max-width: 1023px) {
        padding-left: 0px;
      }
    }

    &.main_wrapper1 {
      //padding-left: 247px;
      //padding-left: 240px;
      padding-right: 60px;
      //overflow: unset !important;

      @media (max-width: 1440px) {
        //padding-left: 85px;
        //padding-left: 80px;
      }

      @media (max-width: 1023px) {
        padding-left: 0px;
      }
    }

    .page-wrapper {
      .page-content {
        padding: 0 20px 20px 20px;

        @media (max-width: 767px) {
          overflow: visible !important;
        }

        @media (max-width: 575px) {
          padding: 0 5px 20px 5px;
        }

        &.no-header {
          padding-top: 15px;
        }
      }
    }
  }

  .right-wrapper {
    flex-shrink: 0;
  }
}

// Scroll Based style
body.on {
  .main-wrapper {
    .main-wrapper-inner {
      > .inner-wrapper {
        box-shadow: 0 0 28px #dfdfdf;
      }
    }
  }
}

// Main home page + banner Based style
.main-wrapper.banner-layout {
  .main-wrapper-inner > .inner-wrapper {
    padding-top: 0;
  }
}

// Loader CSS for full Right Container
.page-wrapper.loaderOn,
.page-wrapper.loaderOn .inner-wrapper,
.page-wrapper.loaderOn .file-uploding-wrapper,
.page-wrapper.loaderOn .file-uploding-wrapper > .row,
.page-wrapper.noRecord,
.page-wrapper.noRecord .inner-wrapper,
.page-wrapper.noRecord .inner-wrapper .sub-dashboard-wrapper,
.page-wrapper.noRecord .inner-wrapper .sub-dash-blc {
  display: flex;
  flex-direction: column;
  flex: 1;
}
