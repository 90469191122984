.custom-drop-down{
  height: 42px !important;
  width: 164px !important;
}
.side-drawer {
  /* In commentaries are the way to make it slide from left to right */
  position: fixed;
  height: 100%;
  background: #fff;
  top: 0;
  right: 0;
  padding: 15px;
  /* right: 100%; */
  width: 50%;
  z-index: 200;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  /* transform: translateX(0%); */
  transition: transform 0.3s ease-out;
}
.side-drawer.open {
  transform: translateX(0);
  /* transform: translateX(100%); */
}
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  top: 0;
  right: 0;
}  

.background-dark{
  background-image: url("../../assets/img/blueBg.png");
  // width: 21%;
  width: 253px;
  background-repeat: no-repeat;
  height: 105px;
  --tw-bg-opacity: 0;
  color: #fff;
}
.bg-grey{
  background-image: url("../../assets/img/greyBg.png");
  width: 253px;
  background-repeat: no-repeat;
  height: 105px;
  color:#282E65;

}

select::-ms-expand {
  display: none;
}






.status {

  select {
    -moz-appearance: none;
    -webkit-appearance: none;

}

select::-ms-expand {
    display: none;
  }
  
  width: fit-content;
  box-sizing: border-box;
  text-align: center;
  /* Tags/12 M */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 4px;
  gap: 10px;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  border-radius: 3px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

}

.status-Partially-Paid {

  color: #169677;
 
  background: #DBF8F1;

}

.status-Fully-Paid {

  color: #169677;
 
  background: #DBF8F1;

}

.status-Overdue {
 
  color: var(--main-buttons, #DE2B2B);
 

  background: #FFECF0;

}

.status-Pending {
 
  color: var(--main-buttons, #229CF4);


  background: #DEF1FF;

}


.status-Pending-Review {
 
  color: var(--main-buttons, #229CF4);


  background: #DEF1FF;

}
.invoice-table-wrapper {
  .table-responsive {
    background-color: '#fff';
    z-index: 1;
    max-height: 70vh;
    height: 70vh;
    overflow: auto;
  }

}