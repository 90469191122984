@media screen and (max-width: 767.98px) {
  .tab-wrapper {
    .nav-tabs {
      padding-left: 0 !important;
    }
  }
}

@media (max-width: 480px) {
  .page-header-wrapper {
    .right-column-visible {
      .row-block {
        flex-direction: row !important;
      }
    }
  }
}
