@import "../../assets/css/theme/variables";

.plan-block {
    > .head {
        .plan-type-box {
            min-height: 80px;
            border-radius: 10px;
            margin-bottom: 10px;
            &.type-1 {
                background-color: #d5e0ff;
            }
            .pic {
                width: 60px;
                height: 60px;
                margin-right: 10px;
            }
            .txt {
                h2 {
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 150%;
                    margin-bottom: 4px;
                }
                h3 {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 150%;
                }
            }
        }
    }
    > .inner {
        margin-bottom: 10px;
        &.plan-description {
            > h4 {
                font-size: 16px;
                font-weight: 600;
                line-height: 150%;
                margin-bottom: 15px;
            }
            ul {
                li {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 150%;
                    margin-bottom: 14px;
                    .icon {
                        margin-right: 6px;
                    }
                }
            }
        }
    }
}
