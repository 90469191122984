@import "../../../assets/css/theme/variables";

.appointment-list-view {
    table {
        height: 100%;
        table-layout: unset;
        min-width: 990px;
        thead th,
        tbody th {
            position: sticky;
        }
        thead {
            th {
                top: 0;
                z-index: 1;
                background: #fff;
                border: none;
                padding: 10px 15px;
                &:first-child {
                    left: 0;
                    z-index: 2;
                    border-radius: 6px 0 0 6px;
                }
                &:last-child {
                    border-radius: 0 6px 6px 0;
                }
                &.cell-width {
                    width: 143px;
                }
                &.user-filter-blc-wrapper {
                    .user-filter-blc {
                        .ttl {
                            font-weight: 400;
                            font-size: 18px;
                            margin-right: 10px;
                            color: $base-color-private;
                        }
                        .right-col {
                            .btn {
                                padding: 7px;
                                span {
                                    font-weight: 300;
                                }
                            }
                        }
                    }
                }
                &.day-cell-hd {
                    font-weight: 400;
                    font-size: 13px;
                    span {
                        color: $base-color-private;
                    }
                }
            }
            .space-rw {
                line-height: 5px;
                height: 5px;
                th {
                    background-color: #f8f8f8;
                    padding: 0;
                }
            }
        }
        .booked-info-blc {
            font-size: 13px;
            color: $base-color-private;
            .booked-time {
                h6 {
                    font-size: 13px;
                    font-weight: 400;
                    margin-bottom: 6px;
                }
                p {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .btns-cell {
                .btn {
                    padding: 0px 15px;
                    border-right: 1px solid rgba(9, 9, 84, 0.12);
                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }
    }
}
