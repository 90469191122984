.desc-cell{
    .tag-input-wrapper{
        position: relative;
        width: max-content;
    
        input{
            height: 40px;
            padding: 5px 42px 5px 5px;
            width: 200px;
    
        }
        button {
            background: #3d3efd;
            color: #fff;
            padding: 6px 6px;
            border-radius: 5px;
            position: absolute;
            top: 6px;
            right: 6px;
            svg{
                fill: #fff;
            }
        }
    }
    
    .cross_svg_img{
        top: -5px;
        right: 2px;
        font-size: 13px;
       svg {
            fill: red;
        }
    }
}