.profile-tags {
    .popover {
        background-color: #fff
    }
    .tag-list {
        button {
            height: 20px;
            display: flex;
            background-color: #e74c3c;
            width: 20px;
            align-items: center;
          }
        span {
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #fff;
            padding: 4px 10px;
            border: 0.5px solid rgba(255, 255, 255, 0.2);
            border-radius: 3px;
            margin-bottom: 10px;
            margin-right: 5px;
            &.orange {
                background: #e75c30;
            }
            &.yellow {
                background: #dd9323;
            }
            &.cyan {
                background: #359cd6;
            }
            &.green {
                background: green;
            }
        }
    }
    .patients-overview{
        // background-color: lightcoral;

        .tag{
            color: white;
            padding: 5px;
            border-radius: 5px;
        }
        .tag-one{
            background-color: lightgreen;
        }
        .tag-two{
            background-color: lightblue;
        }
        .tag-three{
            background-color: lightcoral;
        }
        .tag-four{
            background-color: lightseagreen;
        }
        .tag-five{
            background-color: lightslategray;
        }
        .lightseagreen{
            background-color: lightseagreen;
        }
        .darkgoldenrod{
            background-color: darkgoldenrod;
        }
        .orange{
            background-color: orange;
        }


        

            .reactions-select-dropdown{
                width:100%
            }
           .severity-text{
            color: white;
            .low{
                padding: 5px 10px;
                border-radius: 5px;
                background-color: lightseagreen;
                cursor: pointer;
            }
            .moderate{
                padding: 5px 10px;
                border-radius: 5px;
                background-color: darkgoldenrod;
                cursor: pointer;
            }
            .high{
                padding: 5px 10px;
                border-radius: 5px;
                background-color: orange;
                cursor: pointer;
            }
           } 
        
        
    }

 
}
.select-group-list-right-behaviour{
    color: rgba(40, 46, 101, 1);
    .offcanvas-header{
      .btn-close{
        opacity: 1 !important;
        position: relative;
        right: 10px;
        &::before{
          position: absolute;
          top: 5px;
          right:15px;
          content: " ";
          height: 25px;
          width: 2px;
          background-color: #282e65;
          transform: rotate(45deg);
        }
      &::after {
          position: absolute;
          top: 5px;
          right:15px;
          content: " ";
          height: 25px;
          width: 2px;
          background-color: #282e65;
          transform: rotate(-45deg);
      }
      }
      .offcanvas-title{
        color:  #282E65;
        font-family: Nunito Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
       
      }
    }
    .group-add-name{
      input{
      border-radius: 8px;
      border: 1px solid rgba(9, 9, 84, 0.12);
      color: rgba(120, 134, 147, 1);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      height:48px;
      font-size: 14px;
      }

      .cancel-button{
        color: black !important;
        background-color: white;
        padding: 10px 35px;
        border: 1px solid black;
        border-radius: 5px !important;
      }
      .cancel-button:hover{
        color: black !important;
        background-color: white;
        border: 1px solid black;
        border-radius: 5px !important;
      }
      .save-button{
        color: white;
        background-color: lightseagreen;
        padding: 10px 35px;
        border: none;
        border-radius: 5px !important;
    }
    
    .save-button:hover{
        color: white;
        background-color: lightseagreen;
    }
    }
    
  }