#inventory-management-container {
  .bg-filter-class {
    background-color: rgba(248, 248, 248, 0.9);
    border-radius: 10px;
    background: rgba(228, 228, 228, 0.28);
    padding: 15px;
  }

  .g-report {
    font-size: 13px;
    font-weight: 600;
    width: 100%;
    height: 40px;
    line-height: 0.5;
    text-wrap: wrap;
    border-radius: 8px;
    background: #3d3efd;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.14);
  }

  .details-section {
    border-radius: 10px;
    background: rgba(228, 228, 228, 0.28);
  }

  .table-one {
    // thead {
    //   tr {
    //     background-color: lightblue;
    //   }
    // }
    tbody {
      tr:nth-child(odd) {
        --bs-table-accent-bg: rgba(228, 228, 228, 0.28);
        background: transparent;
      }

      tr:nth-child(even) {
      }
      --bs-table-accent-bg: #fff;
      background: #fff;
    }
  }
}
