@import "../../../../../../../../assets/css/theme/variables";

.staff-profile-feature {
    li {
        font-size: 14px;
        margin-bottom: 12px;

        span {
            font-size: 18px;
            color: $success;
            margin-right: 14px;
        }
    }
}