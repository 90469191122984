@import "assets/css/theme/variables";

#pets-container {
  height: calc(100vh - 240px);
  overflow: auto;
  .Pets_box {
    background-color: #f8f8f8;
    padding: 15px;
    border-radius: 8px;
    margin: 5px 0;

    .box {
      h1 {
        color: #282e65;
        font-size: 18px;
        line-height: 27px;
        margin: 4px 0;
        font-weight: 600;
      }
      p {
        color: #788693;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }

      label {
        color: #1d1d1d;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 5px 0;
        display: flex;
        gap: 5px;
        span {
          color: #3d3efd;
          font-weight: 14px;
          cursor: pointer;
          font-weight: 400;
          line-height: 21px;
          margin: 0 10px;
        }
        img {
          width: 18px;
          object-fit: contain;
          height: 18px;
        }
      }
      .bg-cabf {
        background-color: var(--cabf) !important;
      }
      .bg-9323 {
        background-color: var(--9323) !important;
      }
      .bg-5c30 {
        background-color: var(--5c30) !important;
      }
      .table > :not(caption) > * > * {
        padding: unset;
      }
      table {
        tr {
          .custom-w {
            width: 152px;
          }

          .select {
            color: #3d3efd !important;
            cursor: pointer;
          }
          td {
            color: #282e65;
            padding: 5px 4px 5px;
          }
          &:last-child {
            td {
              border: none;
            }
          }
          &:hover {
            background-color: unset;
          }
        }
      }

      .table_box {
        tr {
          td {
            span {
              padding: 0;
              color: #de2b2b;
            }
          }
        }
      }
    }
    .profile_pic {
      text-align: center;
      h6 {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #282e65;
      }

      img {
        width: 80px;
        margin: 10px auto;
        height: 80px;
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }

  .add_pet {
    border: 1px dotted rgba(120, 140, 159, 0.5);
    height: 44px;
    display: flex;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    align-items: center;
    border-radius: 10px;
    margin: 10px 0;
    span {
      color: #788c9f;
      font-weight: 14px;
      font-weight: 400;
      line-height: 21px;
    }
    button {
      display: inline;
    }
    .icomoon-plus-btn {
      color: #11ca9d;
    }
  }
}
#pet-1,
#pet-2 {
  width: 50% !important;
}
.offcanvas {
  #pet-form-container {
    textarea.form-control {
      height: 150px !important;
    }
    .pic {
      width: 100px;
      height: 100px;
      &:hover {
        .upload-cell {
          opacity: 1;
        }
      }
      .user-placeholder {
        span {
          font-size: 80px;
        }
      }
      .upload-cell {
        background-color: rgba($primary, 0.8);
        z-index: 2;
        font-size: 25px;
        color: rgba(256, 256, 256, 0.7);
        cursor: pointer;
        opacity: 0;
        transition: all ease 0.3s 0s;
        * {
          cursor: pointer;
        }
        input {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0%;
          opacity: 0;
        }
      }
    }
  }
}
