
@import "../.././../../../assets/css/theme/variables";

.add-tags {
    .popover {
        background-color: #fff
    }

    .close-btn {
        font-size: 19px;
        color: $danger;
        padding: 5px;

        &:hover,
        &:focus {
            color: $danger-dark;
        }

    }

    .add-btn {
        font-size: 19px;
        color: $success;
        padding: 5px;

        &:hover,
        &:focus {
            color: $success-dark;
        }
    }
    .tag-list {
        background-color: #fff;
        border-radius: 6px;
        border: 1px solid rgba(8, 8, 41, 0.1);
        padding: 0.375rem 15px;


        .item-cell {
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #fff;
            padding: 4px 10px;
            border: 0.5px solid rgba(255, 255, 255, 0.2);
            border-radius: 3px;
            margin-bottom: 10px;
            margin-top: 10px; 
            margin-right: 5px;
            &.orange {
                background: #e75c30;
            }
            &.yellow {
                background: #dd9323;
            }
            &.cyan {
                background: #359cd6;
            }
            &.green {
                background: green;
            }
        }
    }
}
