@import "../../../assets/css/theme/variables";

.schedule-filter-blc {
    margin-bottom: 15px;
    z-index: 10;

    >.left-col {
        margin-right: 15px;

        .day-btns {
            border: 1px solid rgba(9, 9, 84, 0.12);
            border-radius: 6px;

            .btn {
                border-right: 1px solid rgba(9, 9, 84, 0.12);
                color: $base-color-private;
                padding: 5px 10px;

                &:last-child {
                    border-right: none;
                }

                &:hover {
                    background-color: rgba($primary, 0.04);
                }

                &.active {
                    background-color: rgba($primary, 0.08);
                    color: $primary;
                }
            }

            .date-wrapper {
                width: 104px;
              
                

                &.active {
                    .form-control {
                        background-color: rgba($primary, 0.08);
                        color: $primary !important;
                    }

                    input[type="text"] {
                        background-color: rgba($primary, 0.08);
                        color: $primary !important;
                    }
                }

                .react-datepicker-popper {
                    width: 250px;
                }

                .date-cell {
                    height: 100%;

                    .react-datepicker-wrapper {
                        height: 100%;

                        input[type="text"] {
                            background-color: transparent;
                            display: inline-flex;
                        }

                        .react-datepicker__input-container {
                            height: 100%;
                            display: inline-flex;

                            &::after {
                                display: none;
                            }

                            .form-control {
                                border: none;
                                font-size: 13px;
                                padding-right: 15px;
                                color: $base-color-private;
                                box-shadow: none;
                                height: 100%;
                                border-radius: 0;

                                &::placeholder {
                                    color: $base-color-private;
                                }

                                &:focus {
                                    box-shadow: none !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    >.right-col {
        .input-cell-blc {
            .item-col {
                height: 32px;
                margin-right: 5px;

                &:last-child {
                    margin-right: 0;
                }

                &.dropdown {
                    width: 160px;
                }

                &.search {
                    width: 220px;
                }

                .form-control {
                    height: 100%;
                    border-radius: 6px;
                    background-size: 11px auto;
                    background-color: rgba(228, 228, 228, 0.28);
                    border: 1px solid rgba(9, 9, 84, 0.12);
                    box-shadow: none;
                }

                select.form-control {
                    padding: 0px 30px 0 10px;
                    background-size: 11px auto;
                    background-position-x: calc(100% - 11px);
                }
            }

            .search-block {
                height: 100%;

                span {
                    top: 0;
                    left: 0;
                    bottom: 0;
                    width: 40px;
                    color: #788693;
                    font-size: 14px;
                }

                .form-control {
                    padding-left: 36px;
                }
            }
        }
    }
}

.swtich-cell-override {
    width: 32px;
    height: 16px;
    background-color: $success;
    padding: 2px 2px 2px 4px;
    margin-right: 12px;
    font-size: 7px;
    border-radius: 8px;
    cursor: pointer;
    * {
      cursor: pointer;
    }
    &:hover,
    &:focus {
      background-color: $success-dark;
    }
    .icon {
      font-family: "icomoon";
      &::before {
        content: "\e947";
        color: #fff;
      }
    }
    .circle-icon {
      width: 10px;
      height: 10px;
      background-color: #fff;
    }
    &.disable {
      background-color: $danger;
      padding-right: 0;
      font-size: 8px;
      .icon {
        order: 2;
        margin: auto;
        &::before {
          content: "\e940";
        }
      }
      .circle-icon {
        margin: 0 !important;
      }
    }
  }