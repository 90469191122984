.product-item {
    background: #f8f8f8;
    border-radius: 8px;
    .pic {
        margin-bottom: 10px;
    }
    .info {
        h2,
        h3 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        h2 {
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            color: #434c56;
        }
        h3 {
            font-size: 13px;
            font-weight: 400;
            line-height: 150%;
            color: #8d96a8;
        }
    }
}
