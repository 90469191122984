@import "../../../../../assets/css/theme/variables";

.patient-profile {
  .profile-head {
    padding: 8px 15px 15px;
    .discharge {
      button {
        padding: 6px !important;
      }
      background-color: #f8f8f8;
      border-radius: 10px;
    }
    .title {
      .btn {
        padding: 10px 15px;

        &.back-btn {
          font-weight: 600;
          text-transform: uppercase;
          color: $base-color;
        }

        &.view-user-btn {
          &:hover,
          &:focus {
            svg path {
              fill: $primary;
            }
          }
        }
      }
    }

    .user-profile-cell {
      margin-bottom: 15px;

      .pic {
        width: 80px;
        height: 80px;
        margin-right: 15px;

        > img {
          border-radius: 8px;
        }

        .badge-icon {
          top: -8px;
          right: -8px;
        }

        .pet-placeholder {
          border-radius: 8px;
        }
      }

      .txt {
        h2 {
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
        }

        h3,
        h4 {
          font-size: 14px;
          font-weight: 400;
          line-height: 150%;
        }

        h3 {
          font-size: 14px;
          margin-bottom: 5px;
        }

        h4 {
          margin-bottom: 0;

          &.active {
            color: $success;
          }
        }
      }
    }

    .profile-btns {
      margin: 0 -5px;
      padding: 0 5px;

      .btn {
        border: 1px solid rgba(9, 9, 84, 0.12);
        filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.14));
        border-radius: 6px;
        text-transform: uppercase;
        color: $base-color;
        padding: 6px 15px;
        margin: 0 5px;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;

        &:hover,
        &:focus {
          color: $primary;
          border-color: $primary;
        }
      }
    }

        .button-container{
            margin: 0 -5px;
            padding: 0 6px;

            .health-button-right-sidebar{
                width: 98%;
                border: 1px solid rgba(9, 9, 84, 0.12);
                border-radius: 6px;
                margin-left: 5px;
                margin-right: 5px;
                height: 30px;

            }
        }

    .outstanding_invoice {
      border-radius: 10px;
      background: #3d3efd;
      padding: 12px 20px;

      h4 {
        font-size: 18px;
        color: #fff;
        font-weight: 600;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 15px;
        color: #fff;
        font-weight: 400;
      }

      svg {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translate(0, -50%);
      }
    }

    .btn-blc {
      .btn.r_d {
        height: 24px;
        padding: 0;
        background-color: #9584fc;
        border-color: #9584fc;
        color: #fff !important;
        box-shadow: none;
        min-width: 100px;
        border-radius: 100px;
        margin-right: 9px;
        font-size: 12px;
      }
    }
    .amount-card {
      background-color: #4999c6;
      border-radius: 10px;
      .amount-value {
        font-size: 16px;
        font-weight: 600;
        color: #fff;
      }
      .amount-text {
        font-size: 13px;
        font-weight: 400;
        color: #fff;
      }
    }
    .discharge-card {
      background-color: #74c5f33d !important;
      border-radius: 10px;
    }
  }
}
