@import "../../assets/css/theme/variables";

.password-form-msg {
    font-size: 14px;
    ul {
        max-width: 500px;
        width: 100%;
        li {
            width: 50%;
            padding: 0 20px 16px 0;
            font-family: "Inter", sans-serif;
            @media (max-width: 480px) {
                width: 100%;
                padding-right: 0;
            }
            > span {
                display: inline-flex;
                width: 14px;
                margin-right: 8px;
                font-size: 14px;
                flex-shrink: 0;
                span {
                    width: 6px;
                    height: 6px;
                    background-color: rgba($base-color, 0.5);
                }
            }
            &.text-danger {
                > span {
                    span {
                        width: 8px;
                        height: 8px;
                        background-color: $danger;
                    }
                }
            }
            &.text {
                label {
                    color: rgba($base-color, 0.5);
                }
            }
        }
    }
}
