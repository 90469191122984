.clinic-detail-wrapper {
  > .left-col {
    width: 330px;

    // height: 100vh;
    flex-shrink: 0;
    margin-right: 16px;
  }

  > .right-col {
    margin-right: 16px;
    height: 100vh;
    span {
      // min-width: 77px;
      margin-bottom: 0;
    }

    .tab-content {
      > .tab-pane {
        overflow: auto;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .page-header-wrapper {
    padding: 10px;
    padding-bottom: 0px;
  }
  .super-setting {
    display: flex !important;
    flex-wrap: wrap;
  }
  .clinic-profile {
    width: 100% !important;
  }

  .setting-tab {
    width: 100% !important;
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 1260px) {
  .form-view {
    flex-direction: column !important;
  }
}
