@import "../../../../../../assets/css/theme/variables";


.edit-mar-panel {
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    max-width: 715px;
    width: 100%;
    background-color: #fff;
    padding: 15px;
    gap: 10px;
    transition: all ease 0.3s 0s;
    transform: translateX(100%);
    &.active {
        transform: translateX(0);
    }


    .img-fluid {
        max-width: 50px;
    }

    .badge-icon {
        top: -20px;
        right: 12px;
    }


    .button-section{ 
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        gap: 20px;

        .close-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px 60px;
            gap: 8px;


            width: 132px;
            height: 48px;

           

            /* Main Buttons */

            background: #DE2B2B;
            border-radius: 8px;

            .icomoon-cross:before {
                color: #fff;
            }
        }

        .submit-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px 60px;
            gap: 8px;

            width: 132px;
            height: 48px;

            /* Special / Green */

            background: #11CA9D;
            border-radius: 8px;
                    }
    }

    .section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;

        /* Inside auto layout */

        flex: none;
        align-self: stretch;
        flex-grow: 0;

        



        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */

        display: flex;

        /* Dark Blue */

        color: #282E65;
    

        .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px;

            /* BG */

            background: rgba(248, 248, 248, 0.9);
            border-radius: 10px;

            /* Inside auto layout */

            flex: none;
            align-self: stretch;
            flex-grow: 0;


            .text-region{
                display: flex;
                flex-direction: column;


                h2 {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    /* or 24px */
                    /* Dark Blue */

                    color: #282E65;
                }

                p {
                    /* 13 */

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 150%;
                    /* identical to box height, or 20px */

                
                }
        }



        }


        .input-group {
            .form-control {
                height: auto;
            }
        }

        .input-group-append {
           
            .btn.btn-secondary {
                box-sizing: border-box;

            

                 
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                /* identical to box height, or 24px */

                display: flex;
                align-items: center;

                /* Font Secondary */

                color: #788693 !important;
                 

                /* Auto layout */


                padding: 12px 20px;
                gap: 10px;


                /* BG */

                background: rgba(248, 248, 248, 0.9);
                /* Field Outline */

                border: 1px solid rgba(9, 9, 84, 0.12);
                border-radius: 0px 8px 8px 0px;

                /* Inside auto layout */

                flex: none;
             
                align-self: stretch;
                flex-grow: 0;
            }
        }
    }
}


.mar-panel-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.2);
    visibility: hidden;
    opacity: 0;
    transition: all ease 0.3s 0s;
    &.active {
        visibility: visible;
        opacity: 1;
    }
}