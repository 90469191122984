#time-picker-container {
  position: relative;
  .select-content-block {
    background-color: #ffff;
    border: 1px solid rgba(9, 9, 84, 0.12);
    cursor: pointer;
    height: 48px;
    border-radius: 10px;
    padding: 5px 16px;
  }
  .time-menu-popover {
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 99;
    display: flex;
    .list-con {
      display: flex;
      flex-direction: column;
      max-height: 150px;
      width: 50px;
      .list-block {
        text-align: center;
        padding: 5px 0px;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          background-color: #3f3ffd1a;
        }
      }
      .active-block {
        background-color: #3d3efd;
        color: #fff;
      }
    }
  }
}
