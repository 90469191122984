@import "../../../assets/css/theme/variables";

.btn.user-profile-btn {
	background: #fff;
	border: 1px solid #eeeef2;
	text-transform: capitalize;
	padding: 8px;
	border-radius: 10px;
	&:hover,
	&:focus {
		border-color: #ddd;
	}
	&.active {
		background-color: $primary;
		.txt {
			h4,
			h5 {
				color: #fff;
			}
		}
	}
	.pic {
		width: 50px;
		height: 50px;
		margin-right: 8px;
		flex-shrink: 0;
	}
	.txt {
		h4 {
			font-weight: 400;
			font-size: 16px;
			margin-bottom: 4px;
			color: $base-color;
		}
		h5 {
			font-weight: 400;
			font-size: 13px;
			color: $primary;
			margin: 0;
		}
	}
}
