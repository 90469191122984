@import "../../../assets/css/theme/variables";

.calendar-filter-blc {
  // margin-bottom: 15px;
  padding: 3px;
  flex-wrap: wrap;
  gap: 5px;
  @media (max-width: 767px) {
    flex-direction: row /*column tim changeback*/;
  }
  .appointment-types-selection {
    width: 200px;
    // background-color: rgba(228, 228, 228, 0.28);
    .Types__placeholder {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      font-weight: 300;
    }
    .Types__control {
      background-color: rgba(228, 228, 228, 0.28);
      border-color: transparent !important;
    }
    .Types__menu {
      z-index: 100;
    }
    .Types__option {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .input-cell {
    height: 32px;
    margin-right: 8px;
    width: 220px !important;
    input {
      font-size: 14px;
      font-weight: 400;
      &:focus {
        box-shadow: unset !important;
        border-color: rgba(228, 228, 228, 0.28);
      }
    }
    &:last-child {
      margin-right: 0;
    }
    .form-control {
      background-color: rgba(228, 228, 228, 0.28);
      border-color: transparent;
      border-radius: 6px;
      height: 100%;
      box-shadow: none;
    }
    select.form-control {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 10px;
      padding-right: 40px;
      background-size: 12px auto;
    }
  }
  > .left-col {
    margin-right: 8px;
    @media (max-width: 767px) {
      flex-direction: column;
      margin: 0 0 6px 0;
    }
    > div {
      @media (max-width: 767px) {
        width: 100%;
        margin: 0 0 8px 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  > .right-col {
    @media (max-width: 767px) {
      margin-left: unset !important;
    }
    @media (max-width: 550px) {
      flex-direction: column;
      align-items: stretch !important;
    }

    > div {
      @media (max-width: 767px) {
        flex: 1;
        @media (max-width: 550px) {
          flex: none;
        }
      }
    }
    .date-blc {
      @media (max-width: 550px) {
        margin: 0 0 6px;
      }
      &.month-view {
        .txt {
          width: 100px;
          @media (max-width: 767px) {
            flex: 1;
          }
        }
      }
      &.week-view {
        .txt {
          width: 146px;
        }
      }
      .btn,
      > span {
        height: 100%;
        background: rgba(228, 228, 228, 0.28);
      }
      .btn {
        width: 28px;
        color: $base-color-private;
        &:hover {
          background-color: #eeeef2;
          color: $primary;
        }
        span {
          font-size: 16px;
        }
        &.lft {
          border-radius: 6px 0px 0px 6px;
        }
        &.rt {
          border-radius: 0px 6px 6px 0px;
        }
      }
      > .txt {
        font-size: 14px;
        margin: 0 1px;
        padding: 0 16px;
      }
    }
    .date-type-blc {
      .btn {
        height: 100%;
        background: #f8f8f8;
        font-size: 14px;
        color: $base-color-private;
        width: 70px;
        text-transform: capitalize;
        &.lft {
          border-radius: 6px 0px 0px 6px;
        }
        &.rt {
          border-radius: 0px 6px 6px 0px;
        }
        &.active {
          background-color: #eeeef2;
          color: $primary;
        }
        &:hover {
          background-color: #eeeef2;
          color: $primary;
        }
        @media (max-width: 767px) {
          flex: 1;
        }
      }
    }
  }
  .select-d {
    position: relative;
    .css-1nmdiq5-menu {
      top: 100%;
      position: absolute;
      z-index: 999 !important;
    }
  }
}
