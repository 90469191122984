@import "../../../../assets/css/theme/variables";

.form-control.inline-date-time {
            color: #788C9F;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;   
                
                    height: auto;
                border-radius: 8px;
                padding: 5px;
                background-color: #fff;
                border: 1px solid rgba(8, 8, 41, 0.1);
                box-shadow: 0px 4px 10px rgba(61, 57, 137, 0.05);
}
.date-wrapper {
    width: 104px;
  
    

    &.active {
        .form-control {
            background-color: rgba($primary, 0.08);
            color: #788C9F;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
        }

        input[type="text"] {
            background-color: rgba($primary, 0.08);

            color: #788C9F;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
        }
    }

    .react-datepicker-popper {
        width: 250px;
    }

    .date-cell {
        height: 100%;

        .react-datepicker-wrapper {
            height: 100%;

            input[type="text"] {
                background-color: transparent;
                display: inline-flex;

                color: #788C9F;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
            }

            .react-datepicker__input-container {
                height: 100%;
                display: inline-flex;

                &::after {
                    display: none;
                }

                .form-control {
                    border: none;
                    padding-right: 0px;
                    box-shadow: none;
                    height: 100%;
                    border-radius: 0;
                    color: #788C9F;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;

                    &::placeholder {

                        color: #788C9F;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;                    }

                    &:focus {
                        box-shadow: none !important;
                    }
                }
            }
        }
    }
}