#drains-tubes-container {
  .collapse {
    overflow-x: auto;
  }
  .scroll-control {
    @media (max-width: 1300px) {
      display: none;
    }
    display: flex;
  }
  .drains-tubes-body {
    padding: 10px;
    background: #e4e4e447;
    .head-title {
      font-size: 24px;
      font-weight: 600;
      color: #282e65;
    }
    .new-pin {
      display: flex;
      flex-direction: row;
      gap: 6px;
      color: var(--Main, #3d3efd);
      /* 14 */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */

      .push-pin {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
        border-radius: 100px;
        background: var(--Main, #3d3efd);
      }
    }
    .pin-span {
      font-size: 14px;
      font-weight: 400;
      color: #3d3efd;
      cursor: pointer;
      .pin {
        width: 20px;
        height: 20px;
        background-color: #3d3efd;
        color: #fff;
        font-size: 14px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .accordion {
      --bs-accordion-bg: unset !important;
      --bs-accordion-border-color: unset !important;
      --bs-accordion-inner-border-radius: unset !important;
      --bs-accordion-btn-padding-x: unset !important;
      --bs-accordion-btn-padding-y: unset !important;
      --bs-accordion-body-padding-x: unset !important;
      --bs-accordion-body-padding-y: unset !important;
      --bs-accordion-active-bg: unset !important;
      .remove_btn {
        width: 15.83px;
        height: 15.83px;
        border-radius: 50%;
        color: #fff;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        font-size: 17px;
        background-color: #de2b2b;
      }
      .accordion-item {
        .accordion-header {
          padding: 10px 0px;
          border-bottom: 1.5px solid #0909541f;
          .icomoon-plus-btn {
            font-size: 19px;
            cursor: pointer;
            filter: invert(60%) sepia(37%) saturate(4029%) hue-rotate(125deg)
              brightness(103%) contrast(87%);
          }
          .location-detail-block {
            display: flex;
            align-items: center;
            padding-left: 20px;
            .location-number {
              width: 20px;
              height: 20px;
              line-height: 0;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #3d3efd;
              color: #fff;
              margin: 0 10px;
              font-size: 14px;
            }
            .location-title {
              font-size: 16px;
              font-weight: 400;
              color: #282e65;
            }
            .remove_btn {
              width: 15.83px !important;
              height: 15.83px !important;
              border-radius: 50%;
              font-size: 20px;
              display: flex;
              margin: 0 10px;
              align-items: center;
              justify-content: center;
              color: #fff;
              background-color: #de2b2b;
            }
          }
          .accordion-button {
            box-shadow: none;
            &:focus {
              box-shadow: none;
            }
            &::after {
              position: absolute;
              left: 2px;
              scale: 0.7;
            }
          }
          .remove_btn {
            width: 20px !important;
            height: 19px !important;
            border-radius: 50%;
            font-size: 20px;
            display: flex;
            margin: 0 10px;
            align-items: center;
            justify-content: center;
            color: #fff;
            background-color: #de2b2b;
          }
        }
        .accordion-body {
          padding-left: 10px;
          .head-row {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #eeeef2;
            min-width: fit-content;
            div {
              font-size: 13px;
              font-weight: 500;
              color: #788c9f;
              min-width: 180px;
              padding: 10px 0px 5px 0px;
              &:first-child {
                min-width: 195px;
                width: 195px;
              }
            }
          }
          .data-row {
            display: flex;
            align-items: center;
            min-width: fit-content;
            > div {
              font-size: 14px;
              font-weight: 400;
              color: #282e65;
              min-width: 180px;
              width: 180px;
              padding: 8px 0px 8px 0px;
              position: relative;
              .dropdown-container {
                width: 90%;
              }
              &:first-child {
                min-width: 195px;
                width: 195px;
              }

              .iv-input-block {
                input {
                  border-radius: 2px;
                  width: 100%;
                  padding: 5px 5px;
                  font-size: 14px;
                  border: 1px solid transparent;
                  overflow: hidden;
                  text-wrap: nowrap;
                  text-overflow: ellipsis;
                }
              }

              .date-tile-edit-popover {
                position: absolute;
                top: -5px;
                right: 50%;
                background-color: #fff;
                z-index: 999;
                padding: 10px;
                width: fit-content;
                input {
                  width: 100%;
                }
              }
              .no-access-field {
                display: flex;
              }
              .active-btn {
                color: #0085e5;
                font-size: 13px;
                font-weight: 400;
                background-color: #def1ff;
                border-radius: 3px;
                padding: 4px 7px;
                cursor: pointer;
                user-select: none;
              }
              .inactive-btn {
                color: #de2b2b;
                font-size: 13px;
                font-weight: 400;
                background-color: #f7cfcf;
                border-radius: 3px;
                padding: 4px 7px;
                cursor: pointer;
                user-select: none;
              }
            }
            .add_info {
              color: #3d3efd;
              cursor: pointer;
            }
            &:nth-child(even) {
              background-color: #e4e4e447;
              .iv-input-block {
                input {
                  border: 1px solid #e4e4e447;
                }
              }
            }
          }
        }
      }
    }
  }
  .add_file_block {
    cursor: pointer;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    input {
      cursor: pointer;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
