@import "../../../assets/css/theme/variables";

#sidebar-container {
  position: fixed;
  overflow-y: auto;
  width: 240px;
  height: 100vh;
  flex-shrink: 0;
  background-color: #fff;
  transition: all ease 0.3s 0s;
  display: flex;
  flex-direction: column;

  .hw-32 {
    height: 32px;
    width: 32px;
  }
  .hw-19 {
    height: 19px;
    width: 19px;
  }

  #pet-dropdown-container {
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 4px 10px 4px 4px;
    border-radius: 10px;
    margin-left: 10px;
    cursor: pointer;
    //overflow: visible;
  }
  .pet-dropdown-popover {
    width: 95%;
    position: absolute;
    top: 45px;
    left: 10px;
    //margin-right: 5px;
    // box-shadow: -5px 0px 10px 0px #0000001a;
    background-color: #fff;
    z-index: 9;
    padding: 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    transition: all ease 0.3s 0s;
    > div {
      padding: 5px;
      background-color: #e4e4e447;
      border-radius: 10px;
      cursor: pointer;
      user-select: none;
      &:hover {
        background-color: #e5e5ff;
      }
    }
    .active-op {
      background-color: #e5e5ff;
    }
    .add-block {
      border: 1px dashed #788c9f80;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-radius: 10px;
      height: 34px;
      &:hover {
        background-color: #fff;
      }
    }
  }
  .inner-wrapper {
    padding: 10px 10px 0;

    .sidebar-menu {
      h2 {
        font-size: 14px;
        font-weight: 400;
        color: #788c9f;
        margin: 0 0 10px 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      ul {
        li {
          &.active {
            .btn {
              background: #f8f8f8;
              color: $primary;
              border-radius: 10px;

              span {
                &::before {
                  color: $primary;
                }
              }
            }
          }

          .btn {
            font-size: 16px;
            padding: 12px 15px;
            color: $base-color;
            text-transform: capitalize;

            * {
              cursor: pointer;
            }

            &.staff-icon-mini {
              display: none !important;
            }

            &:hover,
            &:focus {
              color: $primary;

              span {
                &::before {
                  color: $primary;
                }
              }
            }

            span {
              width: 20px;
              margin-right: 10px;
              text-align: center;
              flex-shrink: 0;

              &.material-icons {
                font-size: 18px;
              }
            }

            label {
              font-weight: 400;
            }
          }
        }
      }
    }

    .footer-blc {
      padding: 10px 10px 0;
    }
  }
  .bottom-block {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0px 10px 20px 10px;
    .subscription-block {
      background-color: #fff6d4;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      gap: 15px;
      img {
        height: 60px;
        width: 60px;
      }
      .gold-title {
        font-size: 18px;
        font-weight: 600;
        color: #282e65;
      }
      .gold-sub-title {
        font-size: 13px;
        font-weight: 400;
        color: #282e65;
      }
    }
    .user-block {
      display: flex;
      align-items: center;
      gap: 12px;
      border: 1px solid #eeeef2;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 30px;
      img {
        height: 50px;
        width: 50px;
      }
      .name-block {
        font-size: 18px;
        font-weight: 600;
        color: #282e65;
      }
      .owner-block {
        font-size: 14px;
        font-weight: 400;
        color: #282e65;
      }
    }
    .copyright-block {
      font-size: 13px;
      font-weight: 400;
      color: #282e65;
      display: flex;
      align-items: center;
    }
  }
  @media (max-width: 1023px) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
    transform: translateX(-100%);
    transition: all ease 0.3s 0s;

    &.active {
      transform: translateX(0);
    }
  }
  @media (max-width: 1440px) {
    width: 80px;
    border: 1px solid #eeeef2;

    .logo-head2 {
      .icon {
        width: 42px;
        height: 42px;
        margin-bottom: 0;
      }

      .txt {
        display: none;
      }
    }

    .inner-wrapper {
      padding: 0;

      .sidebar-menu ul li {
        margin: 0 10px;

        .btn {
          justify-content: center;

          &.staff-icon {
            display: none !important;
          }

          &.staff-icon-mini {
            display: inline-flex !important;

            span {
              font-weight: 300;
            }
          }

          span {
            margin-right: 0;
          }

          label {
            display: none;
          }
        }
      }
    }

    .subscription-block {
      justify-content: center;
      div {
        &:first-child {
          img {
            height: 40px;
            width: 40px;
          }
        }
        &:last-child {
          display: none !important;
        }
      }
    }
    .user-block {
      border: unset !important;
      div {
        &:last-child {
          display: none !important;
        }
      }
    }
    .copyright-block {
      text-align: center;
      div {
        &:last-child {
          display: none !important;
        }
      }
    }
    .pet-dropdown-popover {
      .pet-img {
        display: none;
      }
    }
    #pet-dropdown-container {
      margin-bottom: 5px;
      
      div {
        &:nth-child(2) {
          display: none !important;
       
        }
      }
    }
  }
}
