@import "../../../../../../../assets/css/theme/variables";

.iv-assess-accordion-wrapper {
    width: 100%;
    .accordion-blc {
        border-radius: 10px;
        border-bottom: 2px solid #fff;
        background-color: #f8f8f8;
        &:last-child {
            margin-bottom: 0;
        }
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        gap: 10px;

        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;

        .accordion-btn-wrapper {
            width:100%;
            .active-display{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                width:100%;
                text-align: right;
            }
            .accordion-btn {
                font-size: 18px;
                font-weight: 600;
                line-height: 150%;
                color: $base-color-private;
                padding: 8px 5px;
                margin-right: 15px;
                &:hover,
                &:focus {
                    color: $base-color-private;
                }
                * {
                    cursor: pointer;
                }
            }
            label {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
            }
            .add-btn {
                color: $success;
                &:hover {
                    color: $success-dark;
                }
                span {
                    font-size: 20px;
                }
            }
        }

        .collapse {
            width: 100%
        }

        .collapse-data-blc {

            justify-content: space-between;
            /* Auto layout */

                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 0px;
                gap: 10px;
            width: 100%;




  


                /* Inside auto layout */

                flex: none;
                order: 1;
                align-self: stretch;
                flex-grow: 0;

                .left-col, .right-col{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                }

                .remove-button  {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    align-self: flex-end;
                    padding: 0px 10px;
                    gap: 10px;

                    width: 150px;
                    height: 28px;

                    /* Main */

                    background: #3D3EFD;
                    /* Button Drop Shadow */

                    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.14);
                    border-radius: 6px;

                    /* Inside auto layout */

                    flex: none;
                    order: 1;
                    flex-grow: 0;

                }
           
                        }
        .collapse-data-item {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 0px;
                gap: 5px;



                /* Inside auto layout */

                flex: none;
                order: 0;
                align-self: stretch;
                flex-grow: 0;


        }

        
        .button-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
            padding: 0px;
            gap: 10px;
            height: 28px;


            /* Inside auto layout */

            flex: none;
            order: 2;
            align-self: stretch;
            flex-grow: 0;


            .remove-button  {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                align-self: flex-end;
                padding: 0px 10px;
                gap: 10px;

                width: 150px;
                height: 28px;

                /* Main */

                background: #3D3EFD;
                /* Button Drop Shadow */

                box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.14);
                border-radius: 6px;

                /* Inside auto layout */

                flex: none;
                order: 1;
                flex-grow: 0;

            }
        }

       
    }

    .appointment-info-tab-blc {
        .nav-tabs {
            border: none;
            margin-bottom: 10px;

            .nav-item {
                border: 1px solid rgb(210 210 221);
                margin-left: -1px;
                min-width: 150px;

                &:first-child {
                    border-radius: 6px 0px 0px 6px;
                    margin-left: 0;
                }

                &:last-child {
                    border-radius: 0 6px 6px 0;
                }

                .nav-link {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    border: none;
                    color: $base-color-private;
                    background-color: transparent;
                    border-radius: 0;
                    margin-bottom: 0;
                    text-align: center;
                    padding: 7px 10px;

                    &.active {
                        color: $primary;
                        background: rgba($primary, 0.08);
                    }
                    &:hover {
                        background-color: rgba($primary, 0.04);
                    }
                }
            }
        }
        .appointment-form-blc {
            padding: 3px 3px 0 0;
            max-width: 700px;
            width: 100%;
            > .row-block {
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 10px;
                label {
                    width: 150px;
                    flex-shrink: 0;
                    margin-right: 15px;
                    margin-top: 10px;
                    &.required-input {
                        &:after {
                            margin-left: 3px;
                        }
                    }
                }
                .right-col {
                    .input-wrapper {
                        .form-control {
                            font-size: 14px;
                            height: 40px;
                            border-radius: 6px;
                        }
                        textarea.form-control {
                            height: 100px;
                        }
                    }
                    .form-control {
                        height: 40px;
                    }
                }
            }
            &.view-mode {
                .row-block {
                    align-items: center;
                    label {
                        margin-top: 0;
                    }
                    .right-col {
                        .form-control {
                            padding: 0;
                            background-color: transparent;
                            border-color: transparent;
                            box-shadow: none;
                            color: $base-color-private;
                            opacity: 1;
                        }
                        select.form-control {
                            background-image: none;
                        }
                    }
                }
            }
        }
    }
}
