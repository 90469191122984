@import "../../../../assets/css/theme/variables";
.select-user-list.offcanvas,
.select-user-list.offcanvas.offcanvas-end {
  width: 600px;
  --bs-offcanvas-width: 600px !important;
}
.select-user-list {
  color: rgba(40, 46, 101, 1);

  .offcanvas-header {
    .btn-close {
      opacity: 1 !important;
      position: relative;
      right: 10px;
      &::before {
        position: absolute;
        top: 5px;
        right: 15px;
        content: " ";
        height: 25px;
        width: 2px;
        background-color: #282e65;
        transform: rotate(45deg);
      }
      &::after {
        position: absolute;
        top: 5px;
        right: 15px;
        content: " ";
        height: 25px;
        width: 2px;
        background-color: #282e65;
        transform: rotate(-45deg);
      }
    }
    .offcanvas-title {
      color: #282e65;
      font-family: Nunito Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
    }
  }
  .group-add-name {
    input {
      border-radius: 8px;
      border: 1px solid rgba(9, 9, 84, 0.12);
      color: rgba(120, 134, 147, 1);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      height: 38px;
      font-size: 14px;
    }
  }
  .search-group-wrapp {
    svg {
      position: absolute;
      top: 2px;
      left: 8px;
    }
    input {
      padding-left: 35px;
      border-radius: 6px;
      background: rgba(228, 228, 228, 0.28);
      color: rgba(120, 134, 147, 1);
      height: 32px;
      border: 0;
      max-width: 178px;
    }
  }

  .filter-list-tabs {
    flex-grow: 1;
    display: flex;
    border: 1px solid rgba(9, 9, 84, 0.12);
    border-radius: 6px;

    .filter-tabs {
      cursor: pointer;
      min-width: 100px;
      padding: 10px;
      color: #282e65;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-wrap: nowrap;
      text-align: center;
      background-color: transparent;
      border-right: 1px solid rgba(9, 9, 84, 0.12);
    }
    .filter-tabs:last-child {
      border-right: none;
    }
    .filter-tabs.active {
      background: rgba(61, 62, 253, 0.08);
      color: rgba(61, 62, 253, 1);
    }
  }
  .start-message-btn {
    color: rgba(61, 62, 253, 1);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
  // .no-image{
  //   width: 20px;
  //   height: 20px;
  // }
}

@media screen and (max-width: 767px) {
  .select-user-list .search-group-wrapp {
    width: 405px;
    margin: 0 auto;
    input {
      width: 100%;
      max-width: 400px;
    }
  }
}
@media screen and (min-width: 414px) {
  .offcanvas {
    --bs-offcanvas-width: 400px !important;
  }
}
