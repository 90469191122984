#appointments-list-container {
  background: #f8f8f8e5;
  width: 100%;
  border-radius: 8px;
  .item-block {
    display: flex;
    padding: 10px;
    border-radius: 10px;
    // width: 480px;
    gap: 10px;
    .left-block {
      min-width: 280px;
      max-width: 280px;
    }
    .line {
      border: 1px solid #eeeef2;
      height: 21px;
    }
  }
  .yellow-border {
    border: 1px solid #e1e130;
  }

  .in-progress-appointment {
    border: 1px solid rgba(0, 0, 0, 0.05) !important;
    background: #efe !important;
    color: var(--special-green, #11ca9d) !important;
    text-transform: uppercase;
  }

  .completed-appointment {
    border: 1px solid rgba(0, 0, 0, 0.05) !important;
    color: #e1ac03 !important;
    background: #fff6e1 !important;
    text-transform: uppercase;
  }
  .in-pending-appointment {
    border: 1px solid rgba(0, 0, 0, 0.05) !important;
    color: var(--ffff) !important;
    background: var(--3EFD) !important;
    text-transform: uppercase;
  }

  // @media (max-width: 1680px) {
  //   .item-block {
  //     flex-direction: column;
  //   }

  //   .appo-note-buttons {
  //     justify-content: start !important;
  //   }
  // }
}
