.filter-canvas{
    height: 40px;

    .form-control {
        font-size: 16px;
        height: 100%;
        border-radius: 8px;
        color: #1d1d1d;
        padding: -0.625rem 15px;
        background-color: #fff;
        border: 1px solid rgba(8, 8, 41, 0.1);
        box-shadow: 0px 4px 10px rgba(61, 57, 137, 0.05);
    }
}
.offCanvas-Report-card {
    width: 33%;
    padding: 0 5px;
  }