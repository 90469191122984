@import "../../../assets/css/theme/variables";

.toggle-item-blc {
	.item-rw {
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0;
		}
		.form-check {
			margin-right: 5px;
			.form-check-input {
				background-color: #96a6b8;
				border-color: #96a6b8;
				background-image: url("../../../assets/img/vetRecorEMR/toggle-bg-img.svg");
				cursor: pointer;
				margin-top: 8px;
				&:hover {
					background-color: #8192a5;
				}
				&:checked {
					background-color: $primary !important;
				}
			}
		}
		.txt {
			margin-top: 2px;
			h4 {
				font-size: 16px;
				font-weight: 400;
			}
			h5 {
				font-size: 13px;
				font-weight: 400;
				color: #788693;
				margin-bottom: 0;
				line-height: 20px;
			}
		}
	}
}


.vital-table-responsive{
	thead{
		tr{
			th:first-child{
				width:50px !important;
			}
		
		}
		tr{
			td:first-child{
				width:50px !important
			}
		}
	}
}