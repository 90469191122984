// @import "../../assets/css/theme/variables";
@import "../../../../../assets/css/theme/variables";

.calendar-wrapper {
    table {
        table-layout: fixed;
       // min-width: 1239px;

        tbody {
            tr {
                &:hover {
                    td {
                        background-color: transparent;
                    }
                }

                td {
                    position: relative;
                    border: 1px solid #eeeef2;
                    padding: 10px;
                    font-size: 13px;

                    &:hover {
                        background-color: #f2f2fb !important;
                    }

                    &.no-hover {
                        background-color: transparent !important;
                    }
                    &.current-day-cell {
                        background-color: #f2f2fb !important;
                        .btn.add-slot-btn {
                            color: #d9d9e9;
                            &:hover {
                                color: $primary;
                            }
                        }
                    }
                    .data-container {
                        min-height: 110px;

                        &.blank-slot {
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                        }

                        &.add-slot-btn-wrapper {
                            padding: 0;
                        }
                    }

                    .day-no {
                        top: 5px;
                        right: 5px;
                        font-size: 13px;
                        &.disable {
                            color: #ccc;
                        }
                        &.current {
                            width: 22px;
                            height: 22px;
                            background-color: $primary;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    .calendar-no-data-rw {
        &:hover {
            background-color: transparent;
            td {
                &:hover {
                    background-color: transparent !important;
                }
            }
        }
        .not-data-found-blc {
            > .inner {
                max-width: 210px;
                width: 100%;
                h2 {
                    font-size: 18px;
                }
            }
        }
    }
}
