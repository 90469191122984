@import "../../../../../assets/css/theme/variables";

.visits-wrapper {

    display: flex;

    flex-direction: column;

    .upcoming-visits {
        .head {


            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 2px;

            // width: 1030px;
            height: 27px;


            /* Inside auto layout */

            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;


            span {


                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 150%;
                /* identical to box height, or 27px */
                /* Dark Blue */
                color: #282E65;


            }


        }

        .body {

            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 10px;

            // width: 1030px;
            //height: 317px;


            /* Inside auto layout */

            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
            flex-wrap: wrap;


        }
    }

    .past-visits {

        .head {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 2px;

            // width: 1030px;
            height: 27px;


            /* Inside auto layout */

            flex: none;
            order: 2;
            align-self: stretch;
            flex-grow: 0;



            span {


                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 150%;
                /* identical to box height, or 27px */
                /* Dark Blue */
                color: #282E65;
            }
        }

        .body {

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 10px;

            // width: 1030px;
            height: 488px;


            /* Inside auto layout */

            flex: none;
            order: 3;
            align-self: stretch;
            flex-grow: 0;




        }
    }


}