@import "../../assets/css/theme/variables";

.page-header {
  padding: 20px 20px 0;

  h2 {
    font-family: $secondary-font;
    font-size: 24px;
    line-height: 33px;
    font-weight: 700;
    margin-bottom: 4px;

    @media (max-width:575px) {
      font-size: 20px;
    }
  }

  h3 {
    font-family: $secondary-font-2;
    font-size: 14px;
    font-weight: 300;
    color: #788693;
    margin-bottom: 0;
  }
}

.page-header-wrapper {
  padding: 20px;

  .page-header {
    padding: 0;
  }

  .row-block {
    >.right-col {
      .btn-blc {
        .add-btn {
          min-width: 105px;
          font-size: 14px;
        }
      }
    }
  }

  &.toggle {
    @media (max-width: 991px) {
      .sidebar-toggle-btn {
        margin-right: 0;
      }

      .page-header {
        padding-left: 0;
      }
    }
  }

  &.right-column-visible {
    .page-header {
      margin-right: 20px;

      @media (max-width: 480px) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    .row-block {
      @media (max-width: 480px) {
        flex-direction: column;
      }
    }
  }
}