.react-datepicker {
  z-index: 5;
}

.react-datepicker__tab-loop {
  position: relative;
  z-index: 5;
}
.react-datepicker__input-container {
  input {
    z-index: 1;
    border-radius: 10px;
    padding: 0px 10px;
    border: 1px solid rgba(8, 8, 41, 0.1);
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    width: 100%;
  }
}

.mar-wrapper {
  //display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  max-height: 90vh;

  overflow: scroll;

  margin-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;

  /* Inside auto layout */

  flex: none;
  order: 1;

  .first-col {
      position: sticky;
      top:0;
      z-index:1 ;
  }


  
  //flex-grow: 1;


  .order-title {
      display: flex;
      // width: 280px;
      padding: 10px 10px 10px 10px;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      align-self: stretch;
      border-width: 1px 0px 1px 0px;
      border-style: solid;
      border-color: #EEEEF2;
      //width: fit-content;

  }



  .text-md {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      padding: 0;
      /* or 21px */



  }

  .text-sm {

      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      padding: 0;

  }

  .text-lg {
      text-transform: capitalize;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      padding: 0;


  }

  .header {
      th {
          background: #FFFFFF;
          /* Main 4% Opacity */

          border-width: 0px 1px 1px 0px;
          border-style: solid;
          border-color: #EEEEF2;


          * {

              display: flex;
              align-items: center;
              text-align: center;

              color: #282E65;
          }

      }
  }

  .purple {
      background: rgba(149, 132, 252, 0.2);
  }

  .blue {
      background: rgba(64, 157, 224, 0.1);
  }

      .yellow {
          background: rgba(219, 159, 42, 0.10);


      }

  



  .section-title {
      padding: 5px 10px;

      * {
          color: #282E65;
      }

      &.purple {
          background: rgba(149, 132, 252, 0.2);
      }

      &.blue {
          background: rgba(64, 157, 224, 0.1);
      }

          &.yellow {
              background: rgba(219, 159, 42, 0.10);


          }

      }



      .grid-cell {
          justify-content: center;
          align-items: center;
          flex: 1 0 0;
          align-self: stretch;
          min-width: 98px;
          min-height: 87px;
          
          border-width: 1px 0px 1px 1px;
          border-style: solid;
          border-color: #EEEEF2;
          .text-section {
              display: flex;
              padding: 0px 5px;
              flex-direction: column;
              justify-content: center;
              //align-items: center;
              gap: 3px;
              //flex: 1 0 0;

      }


      &.medication-given {

          * {
              color: #282E65;
          }

             &.yellow {

                  .bar {
                      width: 3px;
                      min-height: 87px;
                      align-self: stretch;
                      background: #DB9F2A;
                  }

                  background: rgba(219, 159, 42, 0.10);

             }



          &.blue {

                  .bar {
                      width: 3px;
                      min-height: 87px;
                      align-self: stretch;
                      background:  #409DE0;
                  }

                  background:rgba(64, 157, 224, 0.10);

              
                  
              }

          &.purple {
              .bar {
                  width: 3px;
                  min-height: 87px;
                  align-self: stretch;
                  background: var(--special-purple, #9584FC);
              }

              background: linear-gradient(0deg, #EAE6FE, #EAE6FE),
              #9584FC;

          }
      }

      &.medication-not-given {

          &.purple {
              background: #9584FC;
          }

          &.blue {
              background: #409DE0;
          }

              &.yellow {
                  background: #DB9F2A;
              }


          * {
              color: #FFFFFF;
          }
      }
  }



}

.mar-search-field input,.mar-search-field select.form-control  {
  height: 36px;
  background-color: rgba(228, 228, 228, 0.28) !important;
  background-size: 12px auto;
}

.mar-table-wrapper{
  .scheduled-badge{
      color: rgba(115, 91, 255, 1);
      background-color: rgba(240, 236, 255, 1);
  }
}

.mar-table-wrapper{
  .prn-badge{
      color: rgba(34, 156, 244, 1);
      background-color: rgba(222, 241, 255, 1);
  }
}
.mar-table-wrapper{
  .infusion-badge{
      color:rgba(219, 159, 42, 1);
      background-color:rgba(248, 236, 219, 1);
  }
}

.mar-table-wrapper {
  .dr-imgg{
      img {
          width: 20px;
          height: 20px;
      }
  }
}