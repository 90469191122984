@import "../../assets/css/theme/variables";

.no-data-blc {
  .inner {
    max-width: 350px;
    width: 100%;
    border: 1px solid #ededed;
    border-radius: 10px;
    padding: 35px;
    box-shadow: 0 0 80px #00000026;
    span {
      margin-bottom: 18px;
      img {
        width: 90px;
        opacity: 0.35;
      }
    }
    h2 {
      font-size: 17px;
      font-weight: 400;
      color: #444;
      margin-bottom: 0;
      span {
        font-family: "SFProDisplay-Medium";
        margin-right: 6px;
      }
    }
  }
}

.no-data-blc.table-list {
  height: 250px !important;
}
