@import "../../css/theme/variables";

.payment-success-blc-wrapper {
  max-width: 678px;
  width: 100%;
  .payment-success-blc {
    padding: 44px 40px;
    margin-bottom: 11px;
    @media (max-width: 640px) {
      padding: 20px;
    }
    > .inner {
      max-width: 400px;
      width: 100%;
      > .head {
        margin-bottom: 40px;
        .icon {
          margin-bottom: 14px;
          img {
            max-width: 84px;
          }
        }
        h2 {
          font-family: "Inter", sans-serif;
          font-size: 26px;
          line-height: 31px;
          color: #6aca15;
          margin-bottom: 15px;
        }
        h3 {
          font-family: "SFProDisplay-Medium";
          font-size: 20px;
          line-height: 24px;
          color: rgba($base-color, 0.75);
          margin-bottom: 11px;
        }
        .btn-block {
          margin-top: 25px;
          .btn {
            min-width: 200px;
          }
        }
        h4 {
          font-family: "SFProDisplay-Medium";
          font-size: 16px;
          line-height: 28px;
          color: rgba($base-color, 0.5);
        }
      }
      > .plan-info-blc {
        background: rgba(24, 211, 76, 0.1);
        border: 1px solid rgba(24, 211, 76, 0.2);
        border-radius: 15px;
        margin-bottom: 15px;
        > .head {
          border-bottom: 1px solid rgba($base-color, 0.1);
          padding: 22px 25px;
          .plan-name {
            margin-right: 20px;
            h4 {
              font-family: "Inter", sans-serif;
              font-size: 20px;
              line-height: 24px;
              margin-bottom: 9px;
            }
            h5 {
              font-family: "SFProDisplay-Medium";
              font-size: 14px;
              line-height: 21px;
              margin-bottom: 0;
              .icon {
                margin-right: 5px;
              }
              .txt {
                color: rgba($base-color, 0.75);
              }
            }
          }
          .plan-price {
            h4 {
              font-family: "Inter", sans-serif;
              font-size: 20px;
              line-height: 24px;
              span {
                font-family: "Inter", sans-serif;
                font-size: 14px;
                line-height: 16px;
                color: rgba($base-color, 0.5);
                margin-left: 6px;
              }
            }
          }
        }
        > .inner {
          font-family: "SFProDisplay-Medium";
          font-size: 14px;
          line-height: 21px;
          padding: 22px 25px;
        }
      }
    }
  }
  .btm-msg {
    font-style: italic;
    font-family: "SFProDisplay-Medium";
    font-size: 16px;
    line-height: 19px;
    color: rgba($base-color, 0.5);
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.failed-blc {
    .inner {
      max-width: 550px;
      > .head h2 {
        color: #f43e3e;
      }
    }
  }
}
