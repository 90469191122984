.input-wrapper2 {
    .form-floating {
        textarea {
            padding-top: 30px !important;
        }

        .textarea_col {
            font-size: 13px;
            color: #788693;
            font-weight: 400;

            span {
                color: #3D3EFD;
            }
        }
    }
}