#upcoming-container {
  min-width: 280px;
  background-color: #fff6e1;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  .time-to-start-text {
    border: 1px solid #0000000d;
    background-color: #ffecc0;
    color: #e1ac03;
    height: 25px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }
  .start-video-btn {
    background-color: #3d3efd;
    color: #fff;
    height: 30px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }
  .btn-cell {
    min-height: 40px;
    align-self: stretch;

    align-items: flex-start;
    display: flex;
    width: 100%;
    .appointment-status {
      display: flex;
      padding: 10px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      border-radius: 8px;
      //box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.14);
    }

    .in-progress {
      border: 1px solid rgba(0, 0, 0, 0.05);
      background: #efe;
      color: var(--special-green, #11ca9d);
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }

    .completed {
      border: 1px solid rgba(0, 0, 0, 0.05);
      color: #e1ac03;
      background: #ffecc0;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }

    .btn {
      display: flex;
      padding: 10px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;

      color: var(--Pure-White, #fff);
      text-align: center;
      /* Button/M */
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
    .btn-primary {
      background: #3d3efd;
    }
    .btn-outline-success {
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      background: #efe;
      color: #11ca9d;
    }
  }
}
