.staff-profile-document {
	> .inner {
		.col-cell {
			width: 20%;
			margin-right: 10px;
			margin-bottom: 10px;
			&:last-child {
				margin-right: 0;
			}
			@media (max-width: 1350px) {
				width: calc(33.3% - 10px);
			}
			@media (max-width: 700px) {
				width: calc(100%);
				margin-right: 0;
			}
			.staff-profile-doc-item {
				.pic {
					@media (max-width: 700px) {
						height: 150px;
					}
				}
			}
		}
	}
}
