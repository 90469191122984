@import "../../assets/css/theme/variables";

.tab-wrapper-inventory {}

.truncate_btn {
    span {
        max-width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.pending_label {
    border-radius: 6px;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    background-color: #E0E9FF;
    color: #7660FF;
    font-size: 13px;
    font-weight: 400;
    padding: 4px 8px;
}

.paid_label {
    border-radius: 6px;
    border: 0.5px solid rgba(255, 255, 255, 0.20);
    background-color: #DBF8F1;
    color: #169677;
    font-size: 13px;
    font-weight: 400;
    padding: 4px 8px;
}