$color_1: #3c3efd;

.text-\[15px\] {
  font-size: 15px;
}


.owner-tab-blc.discharge-patient-table {
.reconciliation-subtitle {

  color: var(--Dark-Blue, #282E65);
  /* 16 SB */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */

}}

.owner-tab-blc.discharge-patient-table table tr td {
  &:first-child {
    width: 180px;
    white-space: nowrap !important;
  }

  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  white-space: nowrap;
}

.outer-discharge-table{
  table{
      tr{
        td{
          font-size: 14px;
          font-weight: 400;
          line-height: 30px;
          white-space: nowrap;
          border:0;
          select{
            background: transparent;
            font-size: 15px;
          }
        }
      }
  }  
}

.testname-head {
  margin-bottom: 5px;
  color: #282E65;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
}

.diagnostic-table {
  thead {
    tr{
       td {
        background-color: transparent;
        color: #788C9F !important;
      }
    }
   
  }

  tr {
    td{
      padding: 10px 4px 10px 13px !important;
    }
  }
}

.x-ray-img img {
  width: 80px;
  height: 80px;
  border-radius: 10px;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.discharge_report_row {
  background-color: #F8F8F8E5;
  display: flex;
  width: 24.5%;
  border-radius: 10px;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: fixed;
  top: 90px;
  height: max-content;
  right: 65px;

  >.head {
    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0em;
      color: #282E65;
      text-align: center;
    }

    p {
      color: #404D61;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
    }
  }
}

.add-patient-wrapper{
  .inner-wrapper{
    & >.right-col {
      .patient-all-info{
        width: 72%;
     & >.row-block>.head {
        p{
          color: #404D61;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
        }
      }
     }
    }
  }
}

.state-badge {
  color: #068464 !important;
  background-color: #98ffe5;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.order-btn  {
  .btn{
    color: #3c3efd;
    border-color: #ddd;
    padding: 5px 20px;
    font-weight: 500;
      &:hover {
        background-color: #3c3efd;
        color: #fff;
      }
  }
}

.outer-discharge-table {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;

  table tr td {
    
    &:first-child {
      width: 150px;
    }

    &:nth-child(2) {
      color: #3c3efd !important;
    }
    input {
      &::placeholder {
        color: $color_1 !important;
      }
    }
  }
}

.outer-imaging{
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  .imgaging-table{
    table{
      table-layout: fixed;
      tr{
        td{
          border: 0;
        }
      }
    }
  }
  span{
    color: #3c3efd;
  }
}

.order-del {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 20px;

  &::before {
    color: red !important;
  }
}

.header-position {
  position: fixed;
 // justify-content: center;

  width: 95%;
  top: 0;
  background: #fff;
  z-index: 9;
}

.page-wrapper.add-patient-wrapper {
  .inner-wrapper{
    padding-top: 80px;

  }
}

.stv-radio-button {
  display: none;

  +label {
    float: left;
    cursor: pointer;
    border: 1px solid rgb(210, 210, 221);
    margin-right: -1px;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #282e65;
    background-color: transparent;
    text-align: center;
    padding: 7px 40px;
    position: relative;

    &:first-of-type {
      border-radius: 0.5em 0 0 0.5em;
    }

    &:last-of-type {
      border-radius: 0 0.5em 0.5em 0;
    }
  }

  &:checked+label {
    color: #3d3efd;
    background: rgba(61, 62, 253, 0.08);
  }
}

.medication-order-wrap {
  background: #fff;
  padding: 13px;
  border-radius: 10px;
  margin-bottom: 15px;
  h4,
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
}

.stv-radio-button:checked+label::before {
  content: '';
  display: block;
  position: absolute;
  top: 9px;
  right: 16px;
  width: 6px;
  height: 14px;
  border: solid #3c3efd;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}


.medicine-img {
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 100px;
  height: 100px;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


.avs-img-row {
  background: #F3F3F8;
  border-radius: 20px;

  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  gap: 10px;
  img{
    border-radius: 10px;
    //max-width: 350px;
    //max-height: 320px;
  }

}



.offcanvas.patient-offcanvas{
  --bs-offcanvas-width: 640px !important;
  background-color: transparent !important;
    .right-slide-offcanvas{
      border-radius: 0px;
      background: #fff;
      box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.14);
          .invoice-single{
            background-color: #282E65;
            border-radius: 10px;
              .inner-invoice-wrap{
                  h4{
                    color: #fff;
                    font-weight: 500;
                  }
                  p {
                    font-size: 14px;
                    color: #e3e2e2;
                    font-weight: 300;
                }
              }
          
              .right-side-invoice p {
                font-size: 14px;
                color: #e3e2e2;
                font-weight: 300;
            }
           
          }

        .invoice-list-table{
          background: #f8f8f8;
          border-radius: 10px;
          
            td{
              padding: 12px;
              & > * {
                padding: 0 !important;
                background-color: transparent !important;
              }
              
            }
        }
        .table-value{
          td,th{
            border-bottom: 1px solid #e6e7e7;
          }
          
        }
        table{
          tr{
            td{
              border:0 ;
            }
          }
        }
    }

    .layout-fixed{
      table-layout: fixed;
        td{
          padding: 3px 0 !important;
        }
    }

    .offcanvas-header {
      position: absolute;
      background: #fff;
      top: 50%;
      right: 640px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
        @media (max-width:767px) {
          top: 3%;
          right: 24px;
        }
  
      .btn-close {
        opacity: 1 !important;
        width: 100%;
        height: 100%;
        padding: 15px;
        position: relative;
        left: -15px;
          &::before
            {
              position: absolute;
              top: 4px;
              content: ' ';
              height: 25px;
              width: 2px;
              background-color: #282e65;
              transform: rotate(45deg);
              left: 15px;

            }

            &::after
            {
              position: absolute;
              top: 4px;
              content: ' ';
              height: 25px;
              width: 2px;
              background-color: #282e65;
              transform: rotate(-45deg);
              left: 15px;
            }
        }
    }
}


.back-btn  {
  label{
    color: #282E65;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-transform: capitalize;
  }
  
}



.vital-table-responsive {
  border-spacing: 8px 0;
  border-collapse: separate;
    th {
      width: 120px;
      font-size: 13px;
      font-weight: 500;
      white-space: nowrap;
    }
    td {
      width: 120px;
      border-bottom: 0 !important;
    }
  }



.file {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	& > input[type='file'] {
		display: none;
	}
	& > label {
		font-size: 1rem;
		font-weight: 300;
		cursor: pointer;
		user-select: none;
		color: hsl(204, 86%, 53%);
		border-radius: 10px;
    border: 1px dashed rgba(120, 140, 159, 0.50);
		display: flex;
		justify-content: center;
		align-items: center;
    height: 80px;
    margin-left:10px;
    padding: 10px;
	}
}

.add-patient-wrapper .inner-wrapper > .right-col .patient-all-info > .row-block > .inner{
  .owner-tab-blc{
    .nav-tabs{
      .nav-item {
        .nav-link{
          transition: all .4s ease-in-out;
        }
      }

    }

  }
 
}

@media (max-width:1644px) {
  .add-patient-wrapper {
    .inner-wrapper {
      & > .right-col {
        .patient-all-info {
          width: 71%;
          & > .row-block {
            & > .inner {
              .owner-tab-blc {
                .nav-tabs {
                  .nav-item {
                    min-width: 115px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }  
}


@media (max-width:1543px){
  .horizontal.steps-blc{
    ul{
      li{
        width: 75% !important;
        .txt{
          h3{
            height: 20px;
          }
        }
        &::after {
          left: 50px !important
        }
      }
    }
  }


.page-wrapper.add-patient-wrapper {
  .inner-wrapper{
    padding-top: 100px;

  }
  .header-block{
    padding: 30px 20px !important;
  }
}

.discharge_report_row {
  top: 110px;
}

 
}


@media (max-width:1440px){
  .page-wrapper.add-patient-wrapper {
    .inner-wrapper{
     & > .right-col{
      .patient-all-info{
        width: 74%;
      }
     }
    }
  }
}

@media (max-width:1299px){
  .page-wrapper.add-patient-wrapper {
    .inner-wrapper{
      padding-top: 100px;
      & > .right-col {
        .patient-all-info {
          & > .row-block {
            & > .inner {
              .owner-tab-blc {
                .nav-tabs {
                  .nav-item {
                    min-width: 98px !important;
                    .nav-link {
                      font-size: 13px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


@media (max-width:1199px) {
  .right-wrapper {
    .invoice-appointment-row {
      width: 100%;
      padding: 10px;

      .p-\[15px\] {
        margin-top: 10px
      }
    }
    .discharge_report_row{
      width: 100%;
      position: static;
      img{
        max-width: 350px;
      }
    }
  }

  .patient-header{
    margin-bottom: 20px;
  }

  .page-wrapper.add-patient-wrapper {
    .header-block{
      padding: 10px 20px !important;
    }
    .inner-wrapper{
      padding-top: 100px;
      & > .right-col {
        padding-right: 0;
        .patient-all-info {
          width: 100%;
          padding-right: 0 !important;
          margin-bottom: 15px;
        }
      }
  
    }
  }

}


@media (max-width:1023px) {
  .header-position{
    justify-content: center;
  }
  .patient-header{
    width: 90% !important;
    justify-content: center;
  }
  .add-patient-wrapper  {
    .header-block{
      padding: 20px 20px !important;
      width: 100% !important;
    }
  }

  .add-patient-wrapper{
    .inner-wrapper{
      padding-top: 170px !important;
     & > .right-col{
        padding: 0;
      }
    }
  }

}

@media (max-width:991px) {

  .patient-all-info  {
    >{
      .row-block{
        flex-wrap: wrap;
      }
    }
  }
  .w-\[25\%\],
  .w-\[65\%\],
  .w-\[73\%\]{
    width: 100% !important;
  }
}

@media (max-width:767px) {
  .offcanvas{
    width: 500px !important;
    
    .offcanvas-header {
      .btn-close {
        right: 510px;
      }
    }
  }
}

.offcanvas{
  .right-slide-offcanvas {
      .invoice-main-heading {
        text-align: center;
        color: #282E65;
        padding-bottom: 10px;
        font-size: 19px;
        font-weight: 700;
    }
    .invoice-new-wrapper {
      .inner-invoice-wrap, .right-side-invoice{
        h4{
          font-size: 15px;
          font-weight: 600;
          color: #282E65;
        }
        p{
          font-size: 13px;
          color: #282E65;
          line-height: 1.3;
        }
      }
    }
    .demo-table{
      table-layout: fixed;
      thead{
        th{
          background-color: #282E65;
          color:#fff;
          font-weight: 500;
          font-size: 16px;
          text-transform: uppercase;
          text-align: center;
          padding: 5px;
        }
      }
      th,td{
        border: 1px solid #282E65 !important;
        padding: 5px;
      }
      td{
        p{
          font-size: 12px;
          color: #282E65;
        }
        h4{
          font-size: 14px;
          color: #282E65;
          font-weight: 500;
        }
      }
      .surgeries-heading{
        font-weight: 500;
      }

    }
    .medication-table{
      border: 1px solid #282E65 !important;
      color: #282E65;

      thead{
        th{
          background-color: #282E65;
          color:#fff;
          font-weight: 500;
          font-size: 16px;
          text-transform: uppercase;
          text-align: center;
          padding: 5px;
        }
      }
      .bedtime-heading{
        font-weight: 500;
        text-transform: capitalize;
        font-size: 16px;
        border-top: 0;
      }
     
      td{
        border-top:1px solid #282E65;
        padding: 5px 8px;
        font-size: 12px;
        }

        .medication-td td{
          font-weight: 500;
          white-space: nowrap;
        }
    }
   .recent-color{
     color:rgb(61, 62, 253) !important;
     }
  }


}

  .icon-invoice{
    width: 45px;
    height: 45px;
    background-color: #3d3efd;
    border-radius: 10px;
  }


  .surgeries-table{
    td{
      h4{
        font-size: 13px !important;
      }
    }
  }