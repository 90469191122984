@import "../../../assets/css/theme/variables";

.slot-item {
  padding: 4px 6px;
  background: #e8f8fa;
  border-left: 3px solid #11ca9d;
  color: $base-color-private;
  border-radius: 4px;
  margin-bottom: 2px;
  text-align: left;
  width: 100%;
  &:hover,
  &:focus,
  &:focus:active {
    background: #def4f7;
    border-left: 3px solid #0cc496;
    color: $base-color-private;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.clinic {
    background-color: rgba(255, 254, 243, 1);
    border-left: 3px solid #e0ce2a;
    &:hover {
      background: rgb(251 249 231);
    }
  }
  &.phone {
    background-color: rgba(248, 247, 255, 1);
    border-left: 3px solid #9584fc;
    &:hover {
      background: rgb(242 240 255);
    }
  }

  &.video-conference {
    background: #eaf6fc;
    border-left: 3px solid #409de0;
    &:hover {
      background: #dfeef5;
    }
  }
  &.multiple-pet {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: center;
    .time-txt {
      margin: 0 0 4px;
    }
    .pics-cell {
      margin-left: 0 !important;
      overflow: auto;
      width: 100%;
      padding-bottom: 5px;
      .pic {
        margin-right: 8px;
      }
    }
  }
  &.description {
    .pics-cell {
      order: -1;
    }
    &.no-description {
      .time-txt {
        margin: 0;
      }
    }
  }
  &.no-time {
    .pics-cell {
      padding: 0;
    }
  }
  .time-txt {
    margin-right: 10px;
  }
  .pic {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    flex-shrink: 0;
    .user-placeholder {
      color: #aac8cb;
    }
  }
  .picCell {
    .cell {
      width: 20px;
      height: 20px;
      border-radius: 6px;
      margin-right: 4px;
      flex-shrink: 0;
      .user-placeholder {
        color: #aac8cb;
      }
    }
  }
}
