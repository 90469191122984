.tab-wrapper {
  .nav-tabs {
    overflow: auto;
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-bottom: 18px;

    .nav-link {
      display: inline-flex;
      border-color: transparent;
      color: $base-color-private;
      font-weight: 400;
      padding: 10px 20px 10px 0;
      margin-bottom: 0;
      cursor: pointer;
      position: relative;

      @media(max-width:575px) {
        font-size: 14px;
      }

      &:hover,
      &.active {
        color: $primary;
      }

      &.active {
        color: $primary;

        &::before {
          content: "";
          width: 40px;
          height: 2px;
          background-color: $primary;
          position: absolute;
          bottom: -1px;
        }


      }
    }

    &.box {
      display: inline-flex;
      border: 1px solid rgba($base-color, 0.1);
      border-radius: 12px;
      box-shadow: 0px 10px 30px rgba(109, 141, 173, 0.1);

      .nav-link {
        padding: 20px 55px;
        border: none;

        &.active {
          &:before {
            content: "";
            width: calc(85% - 110px);
            height: 4px;
            background-color: $primary;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            @media (max-width: 1919px) {
              width: calc(80% - 50px);
            }
          }
        }

        @media (max-width: 1919px) {
          padding: 12px 25px;
        }
      }
    }
  }
}