@import "../../../../../../../assets/css/theme/variables";

.search-blc {
    width: 220px;
    height: 35px;
    > span {
        top: 0;
        left: 13px;
        bottom: 0;
        color: $primary;
        color: #788693;
        span {
            font-size: 16px;
        }
    }
    .form-control {
        padding-left: 36px;
        height: 100%;
    }
}
