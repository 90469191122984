@import "../../../../../../assets/css/theme/variables";


.io-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 15px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;



    .io-stats-blc {
        // display: flex;
        // flex-direction: row;
        // padding: 0px;
        // gap: 10px;

        // height: 92px;


        /* Inside auto layout */

        // flex: none;
        // align-self: stretch;
        // flex-grow: 0;


        .stats-item {
            background-color: #f8f8f8;
            border-radius: 20px;
            padding: 15px 16px;

            >.head {
                margin-bottom: 10px;

                h3 {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;

                    /* Dark Blue */

                    color: #282E65;
                }

                .btn {
                    /* 13 */

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 150%;
                    /* identical to box height, or 20px */


                    /* Dark Blue */

                    color: #282E65;
                }
            }

            >.inner {
                h4 {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    /* identical to box height, or 24px */


                    /* Dark Blue */

                    color: #282E65;


                    /* Inside auto layout */

                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }
            }

            background-image: url("../../../../../../assets/img/vetRecorEMR/stat-left-bg.png"),
            url("../../../../../../assets/img//vetRecorEMR/stat-right-bg.png");
            background-position: left top,
            right bottom;
            background-size: 80px,
            120px;
            background-repeat: no-repeat,
            no-repeat;
        }
    }

    .header {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 2px;


        /* Inside auto layout */

        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;

        .page-title {

            width: 869px;
            height: 27px;

            align-self: flex-start;

            /* 18 SB */

            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 150%;
            /* identical to box height, or 27px */


            /* Dark Blue */

            color: #282E65;


            /* Inside auto layout */

            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
        }


    }

    .vital-filter-blc {
        padding: 0 3px;
        margin-bottom: 15px;

        .search-cell {
            width: 100%;
            height: 35px;
            margin-bottom: 10px;

            >span {
                top: 0;
                left: 0;
                bottom: 0;
                width: 40px;
                color: #788693;

                span {
                    font-size: 20px;
                }
            }

            .form-control {
                padding-left: 36px;
                height: 100%;
                border-color: transparent;
                background-color: rgba(228, 228, 228, 0.28);
                box-shadow: none;
            }
        }

        .date-blc {
            height: 32px;

            &.month-view {
                .txt {
                    width: 100px;
                }
            }

            &.week-view {
                .txt {
                    width: 146px;
                }
            }

            .btn,
            >span {
                height: 100%;
                background: rgba(228, 228, 228, 0.28);
            }

            .btn {
                width: 28px;
                color: $base-color-private;

                &:hover {
                    background-color: #eeeef2;
                    color: $primary;
                }

                span {
                    font-size: 16px;
                }

                &.lft {
                    border-radius: 6px 0px 0px 6px;
                }

                &.rt {
                    border-radius: 0px 6px 6px 0px;
                }
            }

            >.txt {
                font-size: 14px;
                margin: 0 1px;
                padding: 0 16px;
            }
        }
    }
}

.right-col {
    .add-btn {
        font-size: 19px;
        color: #11ca9d;
        padding: 10px;
    }

}