@import "../../../assets/css/theme/variables";

.steps-blc {
    ul {
        li {
            padding-bottom: 20px;

            &::after {
                content: "";
                position: absolute;
                top: 27px;
                left: 72px;
                bottom: 0;
                height: 2px;
                width: 100%;
                background-color: #b8b9c3;
                color: #fff;
                z-index: -1;
            }

            &:last-child {
                &::after {
                    display: none;
                    padding-bottom: 0;
                }
            }

            &.active {
                &::after {
                    background-color: $base-color-private;
                }

                .icon {
                    background-color: $base-color-private;
                }
            }

            .icon {
                width: 32px;
                height: 32px;
                margin-right: 16px;
                background-color: #b8b9c3;
                font-size: 10px;
                z-index: 1;
            }

            .txt {
                margin-top: 2px;

                h3,
                p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 150%;
                    text-overflow: ellipsis;
                }

                p {
                    color: #788693;
                }
            }
        }
    }
}
