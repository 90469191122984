// Fonts
$fontSizes: 8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24, 25, 26, 29,
  50;
$fonthweights: 400, 500, 600, 700, 800;
@mixin text-font-style($f-size, $f-weight) {
  font-size: $f-size !important;
  font-weight: $f-weight !important;
  // color: #282e65;
}
@each $fSize in $fontSizes {
  @each $fWeight in $fonthweights {
    .text-#{$fSize}-#{$fWeight} {
      @include text-font-style(#{$fSize}px, #{$fWeight});
    }
  }
}
// Custom Margin and Padding
$sizes: 2, 5, 8, 10, 14, 15, 16, 18, 20, 22, 24, 25, 26, 28, 30, 32, 36, 38, 40,
  45, 50, 60, 100;
@each $size in $sizes {
  .cp-#{$size} {
    padding: #{$size}px !important;
  }
  .cps-#{$size} {
    padding-left: #{$size}px !important;
  }
  .cpe-#{$size} {
    padding-right: #{$size}px !important;
  }
  .cpt-#{$size} {
    padding-top: #{$size}px !important;
  }
  .cpb-#{$size} {
    padding-bottom: #{$size}px !important;
  }

  .cm-#{$size} {
    margin: #{$size}px !important;
  }
  .cms-#{$size} {
    margin-left: #{$size}px !important;
  }
  .cme-#{$size} {
    margin-right: #{$size}px !important;
  }
  .cmt-#{$size} {
    margin-top: #{$size}px !important;
  }
  .cmb-#{$size} {
    margin-bottom: #{$size}px !important;
  }
}

// Height and width
$heights: 10, 14, 15, 16, 18, 19, 20, 22, 26, 28, 30, 32, 35, 40, 45, 49, 55, 80,
  99, 124, 130, 150, 175, 200, 210, 230, 250, 277, 280, 300, 320, 350, 420, 575,
  800;
@each $height in $heights {
  .h-#{$height} {
    height: #{$height}px !important;
    min-height: #{$height}px !important;
  }
  .w-#{$height} {
    width: #{$height}px !important;
  }
}

$borders: 2, 4, 8, 10, 12, 16, 18;

@each $border in $borders {
  .br-#{$border} {
    border-radius: #{$border}px !important;
  }
}
.fa-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.fj-center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.f-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.fb-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
button {
  background-color: unset;
  color: unset;
  border: unset;
  padding: unset;
}
img {
  object-fit: cover;
}
.aspect-ratio-1 {
  height: auto;
  aspect-ratio: 1 /1;
}
.pointer {
  cursor: pointer;
}
.un {
  user-select: none;
}
.fit-image {
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.shadow {
  box-shadow: 0px 4px 16px 0px #0000000f;
  background-color: var(--ffff);
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  background-color: var(--f8f8);
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--f8f8);
  scrollbar-width: 8px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--e3e3);
}
