#clinic-container {
  .clinic-list-scroll {
    max-height: calc(100vh - 165px);
    overflow-y: auto;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}

.page-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
  color: #788693;
}

.new-clinic-panel-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.2);
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s 0s !important;
  &.active {
    visibility: visible;
    opacity: 1;
  }
}

@media screen and (max-width: 760px) {
  .page-desc {
    display: none;
  }
}

// @media (max-width: 480px) {
//   .page-header-wrapper {
//     .right-column-visible {
//     }
//   }
// }

.row-block {
  flex-direction: row !important;
}
#dropdown-basic {
  background-color: unset;
  border: unset;
  color: #788c9f !important;
  box-shadow: unset;
  &:hover,
  &:focus {
    box-shadow: unset;
    background-color: unset !important;
    color: #3d3efd !important;
  }
}
.dropdown-toggle::after {
  display: none !important;
}
.action-menu-list {
  padding: 0px !important;
  a {
    font-size: 14px;
    color: #282e65;
    border-bottom: 1px #e3e3e3;
    padding: 10px 10px 10px 20px;
    border-bottom: 1px solid #e3e3e3;
    &:last-child {
      border-bottom: unset;
    }
  }
}
