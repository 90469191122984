@import "../../../../../../../../assets/css/theme/variables";


    .points-list {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        .time {
            font-size: 14px;
            color: #788C9F;
          //  border-bottom: 1px solid #EEEEF2;
        }
        .data {
            font-size: 16px;
            color: #282E65;
            justify-content: space-between;
            //border-bottom: 1px solid #EEEEF2;
        }

    }

    .io-popover {


        
    .points-list {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        .time {
            font-size: 14px;
            color: #788C9F;
          //  border-bottom: 1px solid #EEEEF2;
        }
        .data {
            font-size: 16px;
            color: #282E65;
            justify-content: space-between;
            //border-bottom: 1px solid #EEEEF2;
        }

    }
 

        .add-btn {
            font-size: 19px;
            color: $success !important;
            &:hover,
            &:focus {
                color: $success-dark !important;
            }

            margin-left: auto !important;
            margin-right: auto !important;

        }
        
    }
