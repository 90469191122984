@import "../../assets/css/theme/variables";

.upload-img-blc {
  margin-bottom: 28px;
  .pic {
    width: 124px;
    height: 124px;
    background-color: rgba(237, 100, 54, 0.1);
    margin-right: 24px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .close-btn {
      width: 28px;
      height: 28px;
      background: #ff7070;
      top: 8px;
      right: 5px;
      color: #fff;
      &:hover {
        background-color: rgb(224, 52, 52);
      }
    }
    .imgPlaceholder {
      font-size: 40px;
      color: #ccc0c3;
    }
    .petPlaceholder {
      background-color: $primary;
      svg {
        width: 50px;
        .path-1 {
          fill: #fff;
        }
      }
    }
  }
  .upload-btn {
    * {
      cursor: pointer;
    }
    .btn {
      font-size: 18px;
      font-family: 'Inter';
      font-weight: 700;
      min-width: 191px;
      box-shadow: 0px 4px 10px rgba(61, 57, 137, 0.05);
      @media (max-width: 480px) {
        min-width: 120px;
      }
    }
    .form-control-file {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      width: 100%;
    }
  }
  &.responsive-layout {
    .pic {
      width: 124px;
      height: 124px;
      background-color: rgba(237, 100, 54, 0.1);
      margin-right: 24px;
      flex-shrink: 0;
      @media (max-width: 1919px) {
        width: 114px;
        height: 114px;
      }
      @media (max-width: 640px) {
        width: 100px;
        height: 100px;
      }
    }
    @media (max-width: 370px) {
      display: flex;
      flex-direction: column;
    }
    .pic {
      @media (max-width: 370px) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}