@import "../../../../../assets/css/theme/variables";

.diagnostic-listing-wrapper {
    .table {
        tr {
            &:hover {
                background-color: transparent;
            }
            td {
                background-color: transparent;
            }
        }

        thead {
            th {
                border-bottom: none;
                background-color: #f8f8f8;
                padding: 10px 15px;
                position: sticky;
                border-bottom: 2px solid #fff;
                top: 0;
                &:first-child {
                    border-radius: 10px 0;
                }
                &:last-child {
                    border-radius: 0 10px;
                }
                &.reange-cell {
                    color: #9584fc;
                }
            }
        }
        tbody {
            background-color: #f8f8f8;
        }
    }
}

.vitals-block {
    .vital-filter-blc {
        padding: 0 3px;
        margin-bottom: 15px;
        .search-cell {
            width: 100%;
            height: 35px;
            margin-bottom: 10px;
            > span {
                top: 0;
                left: 0;
                bottom: 0;
                width: 40px;
                color: #788693;
                span {
                    font-size: 20px;
                }
            }
            .form-control {
                padding-left: 36px;
                height: 100%;
                border-color: transparent;
                background-color: rgba(228, 228, 228, 0.28);
                box-shadow: none;
            }
        }
        .date-blc {
            height: 32px;
            &.month-view {
                .txt {
                    width: 100px;
                }
            }
            &.week-view {
                .txt {
                    width: 146px;
                }
            }
            .btn,
            > span {
                height: 100%;
                background: rgba(228, 228, 228, 0.28);
            }
            .btn {
                width: 28px;
                color: $base-color-private;
                &:hover {
                    background-color: #eeeef2;
                    color: $primary;
                }
                span {
                    font-size: 16px;
                }
                &.lft {
                    border-radius: 6px 0px 0px 6px;
                }
                &.rt {
                    border-radius: 0px 6px 6px 0px;
                }
            }
            > .txt {
                font-size: 14px;
                margin: 0 1px;
                padding: 0 16px;
            }
        }
    }
}
