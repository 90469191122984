@import "../../../../../../../assets/css/theme/variables";

.note-breif-box {
    background: #f8f8f8;
    border: 1px solid transparent;
    padding: 10px;
    border-radius: 10px;
    transition: all ease 0.2s 0s;

    .date {
        font-size: 13px;
        font-weight: 400;
        line-height: 150%;
        background: #fff;
        width: 43px;
        height: 59px;
        margin-right: 10px;
        border-radius: 5px;

        span {
            font-size: 24px;
            font-weight: 500;
            line-height: 29px;
        }
    }

    .info {
        h2 {
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            color: #282E65;
        }

        .item {
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            &.user {
                font-size: 13px;
            }

            .pic {
                width: 20px;
                margin-right: 8px;

                span {
                    font-weight: 200;
                }
            }

            .txt {
                margin-right: 8px;
            }

            .icon {
                span {
                    font-size: 17px;
                    font-weight: 300;
                }
            }
        }
    }
}