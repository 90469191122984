#pet-owner-appointments-container {
  padding: 15px 10px 10px 10px;
  height: 100%;
  .accordion-button {
    background: #f8f8f8;
    border-color: unset;
    font-size: 16px;
    font-weight: 500;
    color: #282e65;
  }
  .accordion-button:focus {
    box-shadow: unset;
    border-color: unset;
  }
  .accordion-button:not(.collapsed) {
    // background-color: #f8f8f8;
    color: #282e65;
    box-shadow: unset;
  }
  .accordion-item {
    border: unset;
    // background: #f8f8f8;
    &:first-child {
      margin-bottom: 10px;
    }
  }
  .accordion-body {
    padding: unset;
  }
  .pending-acc-header {
    background-color: #282e65;
  }
  .reject-acc-header {
    background-color: #ffdada;
  }
  .acc-header {
    background: #f8f8f8e5;
  }

  // .appoinments-list-inner {
  //   display: flex;
  //   flex-wrap: wrap;
  //   gap: 10px;

  //   &:not(:last-child) {
  //     margin-bottom: 10px;
  //   }

  //   .appoinments-list-card {
  //     flex: 0 0 auto;
  //     width: calc(33.3333% - 6.67px);
  //     min-height: 200px;
  //   }
  // }

  // @media (max-width: 767px) {
  //   .appoinments-list-card {
  //     width: calc(50% - 4.99px) !important;
  //   }
  // }

  // @media (max-width: 576px) {
  //   .appoinments-list-card {
  //     width: 100% !important;
  //   }
  // }
}
