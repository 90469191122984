@import "../../assets/css/theme/variables";

.selected-operating-room {
    border-color: blue;
    border-width: 0px 0px 0px 3px !important;
    border-bottom-width: 3px !important;
    margin-bottom: -1px;
    &:hover {
       border-bottom-width: 3px !important;
       border-color: blue;
      }
    &:after { 
        border-color: blue;
        border-width: 0px 0px 0px 3px !important;
        border-bottom-width: 3px !important;
        &:hover {
           border-bottom-width: 3px !important;
           border-color: blue;
          }

    }
    &:before {
        border-color: blue;
        border-width: 0px 0px 0px 3px !important;
        border-bottom-width: 3px !important;
        &:hover {
           border-bottom-width: 3px !important;
           border-color: blue;
          }

    }

    

}









.operating-room-card {

    &:hover {
        border-bottom-width: 2px !important;
        border-color: blue;
       }
    &:before {

        &:hover {
            border-bottom-width: 2px !important;
            border-color: blue;
           }

    }

    &:after {

        &:hover {
            border-bottom-width: 2px !important;
            border-color: blue;
           }

    }

}



.operating-room {
    background: #f8f8f8;
    border-bottom: 3px solid #f8f8f8;


    &.active {border-bottom: 2px solid $primary;
    
        &:hover {
            border-bottom: 2px solid $primary;

        }
    }
    &:hover { 
        &.active {border-bottom: 2px solid $primary;}
    
        border-bottom: 2px solid $primary;}
    padding: 10px;
}


