#doctor-filter {
  position: relative;
  width: 250px;
  .doctor-selection-popup {
    position: absolute;
    width: 100%;
    left: 0;
    top: 40px;
    z-index: 999;
  }
}
