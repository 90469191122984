.input-wrapper2 .form-floating textarea {
  padding-top: 30px !important;
}
.input-wrapper2 .form-floating .textarea_col {
  font-size: 13px;
  color: #788693;
  font-weight: 400;
}
.input-wrapper2 .form-floating .textarea_col span {
  color: #3D3EFD;
}/*# sourceMappingURL=index.css.map */