body .signup-wrapper .left-col.terms_left_col {
    width: 25%;
    background: url("../../../assets/img/vetRecorEMR/terms_img.png")center bottom no-repeat !important;
    background-size: cover !important;

}

body .signup-wrapper .left-col.terms_left_col .head h1 .txt-2 {
    font-size: 24px;
    font-weight: 700;
    font-family: SF Pro Display, sans-serif !important;
    line-height: unset;
}

body .signup-wrapper .right-col.terms_right_col {
    width: 75%;
}

.terms_right_col h4 {
    font-size: 18px;
    color: #282E65;
    margin: 20px 0;
    font-weight: 600;
}

.terms_right_col h5 {
    font-size: 16px;
    color: #282E65;
    margin: 20px 0;
    font-weight: 600;
}

.terms_right_col .signup-head .d-flex a {
    font-size: 16px;
    font-weight: 500;
}

.flex_div .nav-tabs .nav-link {
    font-size: 16px;
    font-weight: 400;
    border: 0;
    color: #404D61;
    font-family: SF Pro Display, sans-serif !important;
    opacity: 0.3400000035762787;
    padding: 5px 2px;
    display: block;
}

.flex_div .nav-tabs .nav-link.active,
.flex_div .nav-tabs .nav-link:hover {
    opacity: 1;
    font-weight: 500;
}

.tab_options {
    margin-top: 210px;
}

.tab_height {
    height: calc(100vh - 200px);
    overflow: auto;
}

.left_terms_col {
    width: 80%;
}

.right_terms_col {
    width: 20%;
}

@media (max-width:1199px) {
    body .signup-wrapper .left-col.terms_left_col {
        padding: 40px 20px 0 !important;
    }
}

@media (max-width:991px) {

    body .signup-wrapper .left-col.terms_left_col {
        background-color: #efefe7 !important;
        background-image: unset !important;
        width: 100%;
        min-height: 205px;
    }

    body .signup-wrapper .right-col.terms_right_col {
        width: 95%;
        margin: auto;
    }
}

@media (max-width:767px) {
    .flex_div {
        flex-direction: column-reverse;
    }

    .left_terms_col,
    .right_terms_col {
        width: 100%;
    }

    .d-flex.tab_options {
        margin-top: 30px;
        margin-bottom: 40px;
        align-items: center !important;
        justify-content: center !important;
    }

    .tab_height {
        height: 100%;
    }

    body .signup-wrapper .left-col.terms_left_col .head h1 .txt-2 {
        font-size: 18px !important;
    }
}