@import "../../../../assets/css/theme/variables";

body {
    .skill-item-blc {
        max-width: 359px;
        width: 100%;
        .search-cell {
            margin-bottom: 5px;
            .btn {
                width: 64px;
                background: #f8f8f8;
                border: 1px solid rgba(9, 9, 84, 0.12);
                border-radius: 0px 8px 8px 0px;
                font-size: 18px;
                padding: 0;
                box-shadow: none;
            }
        }
        .chip-items-list {
            .chip-item {
                font-size: 14px;
                height: 32px;
                background: #eeeef2;
                padding: 2px 12px;
                margin-bottom: 5px;
                margin-right: 5px;
                border-radius: 6px;
                .btn {
                    padding: 4px 8px 4px 13px;
                    margin-right: -4px;
                    font-size: 10px;
                    &:hover {
                        i {
                            &::before {
                                color: $primary;
                            }
                        }
                    }
                }
            }
        }
    }
}
