@import "../../assets/css/theme/variables";

.search-blc2 {
    width: 300px;
    height: 35px;
    @media (max-width: 1050px) {
        width: 250px;
    }
    @media (max-width: 800px) {
        width: 50%;
        margin-bottom: 6px;
        padding-right: 6px;
    }
    @media (max-width: 700px) {
        width: 100%;
        padding-right: 0;
    }
    @media (max-width: 400px) {
        margin-right: 0;
        margin-bottom: 10px;
    }
    span {
        top: 0;
        left: 0;
        bottom: 0;
        width: 40px;
        color: $primary;
        font-size: 14px;
    }
    .form-control {
        padding-left: 36px;
        height: 100%;
    }
 
}
