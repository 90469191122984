@import "../../../assets/css/theme/variables";

.add-patient-wrapper {
    .inner-wrapper {
        >.left-col {
            padding-left: 20px;
            padding-right: 20px;

            >h2 {
                font-weight: 400;
                font-size: 18px;
                line-height: 150%;
                margin-bottom: 15px;
            }
        }

        >.right-col {
            padding-right: 20px;

            .patient-all-info {
                >.row-block {
                    background: #f8f8f8;
                    border-radius: 10px;
                    padding: 15px;
                    margin-bottom: 5px;

                    &:last-child {
                        margin-bottom: 5px;
                    }

                    >.head {
                        margin-bottom: 12px;

                        h3 {
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 150%;
                            margin-bottom: 5px;
                        }

                        h4 {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 150%;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    >.inner {
                        .owner-tab-blc {
                            .nav-tabs {
                                border: none;
                                margin-bottom: 10px;

                                .nav-item {
                                    border: 1px solid rgb(210 210 221);
                                    margin-left: -1px;
                                    min-width: 150px;

                                    &:first-child {
                                        border-radius: 6px 0px 0px 6px;
                                        margin-left: 0;
                                    }

                                    &:last-child {
                                        border-radius: 0 6px 6px 0;
                                    }

                                    .nav-link {
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 150%;
                                        border: none;
                                        color: $base-color-private;
                                        background-color: transparent;
                                        border-radius: 0;
                                        margin-bottom: 0;
                                        text-align: center;
                                        padding: 7px 10px;

                                        &.active {
                                            color: $primary;
                                            background: rgba($primary, 0.08);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .plans-wrapper {
                        margin: 0 -5px;

                        .col-cell {
                            width: 33.3%;
                            padding: 0 5px;

                            .plan-block {
                                background-color: #fff;
                                padding: 15px;
                                border-radius: 10px;

                                &.super-plan {
                                    .head {
                                        .plan-type-box {
                                            background: #d5e0ff;
                                        }
                                    }
                                }

                                &.golden-plan {
                                    .head {
                                        .plan-type-box {
                                            background: #fff6d5;
                                        }
                                    }
                                }

                                &.primary-plan {
                                    .head {
                                        .plan-type-box {
                                            background: #daffd5;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .proceed-btns {
                margin: 15px 0;
            }
        }
    }
}