#medication-plans-container {
  display: flex;
  gap: 15px;
  height: calc(100vh - 185px);
  overflow: auto;
  .bg-E4E4E447 {
    background: #e4e4e447;
  }
  .con-row {
    width: calc(100vw - 602px);
    overflow: auto;
    @media (max-width: 1440px) {
      width: calc(100vw - 422px);
    }
    @media (max-width: 1023px) {
      width: calc(100vw - 30px);
    }
  }
  .mw-250 {
    width: 250px;
    min-width: 250px;
  }
  .left-block {
    flex-grow: 1;
    .search-container {
      position: relative;
      .search-i {
        position: absolute;
        left: 8px;
        top: 0;
      }
      input {
        padding: 5px 0px;
        border-radius: 6px;
        // width: 220px;
        background: #e4e4e447;
        width: 100%;
        padding-left: 30px;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .dropdown-block {
      select {
        width: 150px;
        background: #e4e4e447;
        padding: 5px 5px;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .date-picker-blc {
      position: relative;
      background: #e4e4e447;

      .react-datepicker-popper {
        top: 0;
      }
      input {
        background-color: transparent;
        font-size: 14px;
        font-weight: 400;
        padding: 6px 10px 5px 30px;
        &:active {
          border: unset;
        }
      }
    }
  }
  .right-block {
    width: 320px;
    .medication-plan-item {
      width: 100%;
      background: #fff;
      border-radius: 10px;
      padding: 10px;
      box-shadow: 0px 2px 16px 0px #00000024;
    }
    .medication-list-container {
      // height: calc(100vh - 225px);
      // overflow: auto;
      padding-right: 5px;
    }
    .name-block {
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .medication-list-wrapper {
      color: #282e65;
      font-size: 16px;
      font-weight: 400;

      .taken-steps {
        color: #788c9f;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .not-taken-steps {
        color: #dc3545;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .note-container {
        color: #788c9f;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
      }

      ul.outer-list-items {
        font-size: 14px;

        &::before {
          position: absolute;
          left: 8px;
          top: 18px;
          content: "";
          border-left: 1px dashed #c4c8cc;
          height: 80%;
        }

        li.list-outer::before {
          position: absolute;
          left: -21px;
          top: 7px;
          content: "";
          height: 5px;
          width: 5px;
          border-radius: 50%;
          border: 2px solid #c4c8cc;
          padding: 3px;
          background: #fff;
        }
      }
    }
  }
  @media (max-width: 1023px) {
    flex-wrap: wrap;
  }
}
