.io-table {

    
/* Field Outline */

border-bottom: 1px solid rgba(9, 9, 84, 0.12);
border-radius: 6px 6px 0px 0px;

display: table;

    .first-col {
        position: sticky;
        top:0;
        left:0;
        z-index:1 ;
    }




/* Inside auto layout */


    tbody {
        background: #FFFFFF;


        .section-head {

        
           

            background: rgba(29, 29, 29, 0.05);

            /* Inside auto layout */

         

           
        }

        .section-header {

            div {
                width: fit-content;
                top:10;
                left:5;

            }
           
            padding: 5px 10px;

            //background: rgba(29, 29, 29, 0.05);

            /* Inside auto layout */

            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;

            text-align: left;
            /* identical to box height, or 21px */

          

            /* Dark Blue */

            color: #282E65;

           
        }

        tr {
            td{
                width: 98px;
                height: 38px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;

                
                text-align: center;
                /* identical to box height, or 21px */


                border-width: 1px 1px 0px 1px;
                border-style: solid;
                border-color: #EEEEF2;
               


                /* Dark Blue */

                color: #282E65;

                

            
            }

            .test-name {
                text-align: left;
                border-style: solid;
                border-color: #EEEEF2;

                border-width: 1px 1px 1px 0px;
                background-color: #FFFFFF;

            }
        }
    }

    thead {
        .table-header {
           

            /* Field Outline */

            border-bottom: 1px solid rgba(9, 9, 84, 0.12);
            background: rgba(61, 62, 253, 0.08);

            /* Inside auto layout */

           

            .table-name {

                padding: 0px 20px 0px 10px;
                gap: 10px;

                min-width: 240px;
                height: 40px;

            
                //background: rgba(61, 62, 253, 0.08);
                /* 16 */

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                /* identical to box height, or 24px */

              

                /* Dark Blue */

                color: #282E65;


            }

            .time {
                
                //background: rgba(61, 62, 253, 0.08);
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 150%;

                align-items: center;
                padding: 4px 0px;

                min-width: 100px;
                height: 40px;
                /* identical to box height, or 20px */

             
                text-align: center;
                text-transform: uppercase;

                border-left: 1px solid rgba(9, 9, 84, 0.12);

                /* Dark Blue */

                color: #282E65;

            }
        }
    }
}