.react-datepicker-popper .react-datepicker, .react-datepicker-popper .react-datepicker__month-container {
width: unset !important;
}
.react-datepicker-ignore-onclickoutside{
    border:solid 1px gray;
    border-radius: 7px;
}
.react-datepicker-wrapper:focus{
    width: unset;
}
.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
}