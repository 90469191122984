@import "../../../../../../assets/css/theme/variables";

.reports-block {
    >.inner {
        padding: 0 5px;

        .report-items-rw {
            margin: 0 -5px;

            .item-col {
                width: 25%;
                padding: 0 5px;

                @media (max-width:1199px) {
                    width: 30%;
                }

                * {
                    cursor: pointer;
                }
            }

            .more-item-col {
                width: 130px;

                @media (max-width:1199px) {
                    width: 62px;
                }

                .more-item-btn {
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 150%;
                    color: #788c9f;
                    transform: rotate(-90deg);

                    @media (max-width:1199px) {
                        padding: 0;
                    }

                    &:hover,
                    &:focus {
                        color: $primary;
                    }
                }
            }
        }
    }
}