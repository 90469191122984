@import "../../../assets/css/theme/variables";

.appointment-steps {
    margin: 20px 20px;
    max-width: 388px;
    width: 100%;
    ul {
        li {
            flex: 1;
            &::before,
            &::after {
                content: "";
                position: absolute;
                background-color: #788c9f;
                color: #fff;
                width: 100%;
                height: 2px;
                top: 15px;
                left: 50%;
                z-index: 0;
            }
            &:last-child {
                &::before,
                &::after {
                    display: none;
                }
            }

            &.active {
                &::before {
                    background-color: $base-color-private;
                    z-index: 1;
                }

                .icon {
                    background-color: $base-color-private;
                }
            }
            .icon {
                width: 32px;
                height: 32px;
                background-color: #788c9f;
                font-size: 10px;
                z-index: 1;
                color: #fff;
            }
            .txt {
                margin-top: 2px;
                h3,
                p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 150%;
                }

                p {
                    color: #788693;
                }
            }
        }
    }
}
