@import "../../../../../../assets/css/theme/variables";

.chat-block {
    &.patient_chat_block {
        .chat-room {
            height: calc(100vh - 126px);
            overflow: auto;
        }
    }

    .chat-room {
        .chat-row {
            margin-bottom: 20px;

            &.right-side-chat {
                .info {
                    h4 {
                        span {
                            order: -1;
                            margin-right: 10px;
                            margin-left: 0;
                        }
                    }

                    .chat-bubble {
                        border-radius: 8px;
                        background: rgba(115, 103, 113, 0.10);
                    }
                }
            }

            .pic {
                width: 32px;
                height: 32px;
                margin-right: 10px;

                .user-placeholder {
                    span {
                        font-size: 32px;
                    }
                }
            }

            .info {
                h4 {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 150%;

                    span {
                        color: #788c9f;
                        margin-left: 10px;
                    }
                }

                .chat-bubble {
                    background: #e0f0ff;
                    padding: 8px 10px;
                    border-radius: 8px;
                    margin-bottom: 2px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    p {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    .media-blc {
                        img {
                            max-width: 280px;
                        }
                    }
                }
            }

            .chat-time {
                font-size: 12px;
                color: #788C9F;
                font-weight: 300;
            }

            .chat-bubble {
                background: #f3f8ff;
                padding: 10px 15px;
                border-radius: 10px 10px 10px 0;
                font-size: 14px;
                line-height: 19px;
                margin-bottom: 6px;
            }
        }
    }

    .chat-foot {
        .input-block {
            .form-control {
                height: 41px;
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
            }
        }
    }
}