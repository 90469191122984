// Color-Variables
$blue: #3d3efd !default;
$blue-dark: #2424db !default;
$blue-dark-100: #1b1bcf !default;

$blue2: #132893 !default;

$red: #de2b2b !default;
$red-dark: #cf2424 !default;
$red-dark-100: rgb(211, 36, 36) !default;

$green: #11ca9d;
$green-dark: #0cbd91;

$yellow: #f5b400;

$info: #00b8d4 !default;

$primary: $blue !default;
$primary-dark: $blue-dark !default;
$primary-dark-100: $blue-dark-100 !default;

$secondary: $blue2 !default;

$primary-text: $blue;
$primary-dark: $blue-dark !default;
$danger: $red !default;
$danger-dark: $red-dark !default;
$danger-dark-100: $red-dark-100 !default;

$success: $green !default;
$success-dark: $green-dark !default;

$warning: $yellow !default;

// Font-Family
$primary-font: "Inter", sans-serif;
$secondary-font: "Nunito Sans", sans-serif;
$secondary-font-2: "Roboto", sans-serif;
$primary-font-700: "Inter", sans-serif;

// Base-Color
$base-color: #1d1d1d;
$base-color-private: #282e65;

$deleted: #202321;




