#pet-owner-health-records-container {
  padding: 15px 10px 10px 10px;
  height: 100%;
  .top-search-block {
    width: 50%;
    position: relative;
    svg {
      position: absolute;
      left: 12px;
    }
    input {
      background-color: #f8f8f8;
      height: 40px;
      padding-left: 50px;
      width: 100%;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 400;
      color: #788693;
      ::placeholder {
        color: #788693;
      }
    }
  }
  .pet-profile-view-card {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
    border-radius: 10px;
    background-color: #f8f8f8;
    overflow: auto;
    max-width: 86vw;
    .tab {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 2px;
      width: 120px;
      h4 {
        margin-bottom: 0;
        &.active {
          color: #198754;
        }
        &.inactive {
          color: red;
        }
      }
    }
  }
}

@media screen and (max-width: 1442px) {
  #pet-owner-health-records-container .pet-profile-view-card  {
    max-width: 92vw;
  }
}
@media screen and (max-width: 1025px) {
  #pet-owner-health-records-container .pet-profile-view-card {
    max-width: 97vw;
  }
}