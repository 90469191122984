@import "../../../../assets/css/theme/variables";

.treatment-block {
  .inner {
    padding: 0 5px;
    .plan-items-rw {
      margin: 0 -5px;
      .item-col-wrapper {
        .item-col {
          width: 25%;
          padding: 0 5px;

          @media (max-width: 1199px) {
            width: 30%;
          }

          &.more_link {
            @media (max-width: 1199px) {
              width: 10% !important;
            }

            .more-item-btn {
              font-size: 13px;
              font-weight: 600;
              line-height: 150%;
              color: #788c9f;
              transform: rotate(-90deg);

              @media (max-width: 1199px) {
                padding: 0;
              }

              &:hover,
              &:focus {
                color: $primary;
              }
            }
          }

          * {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.item-col {
  width: 25%;
  padding: 0 5px;
}
