.font-\[14\]{
    font-size:14px;
}
.state-done {
    color: #169677;
    text-align: center;
    border-radius: 3px;
    border: 0.5px solid rgba(255, 255, 255, 0.20);
    background: #DBF8F1;
    line-height: normal;
    background-color: #DBF8F1;
    padding: 1px ;

    h4 {
        color: #282E65;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
    }

    p {
        color: #282E65;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        text-transform: capitalize;
    }
}

.gray-bgg {
    border-radius: 5px;
    background: rgba(228, 228, 228, 0.28);

    h4 {
        color:  #282E65;
        font-size: 14px;
        font-weight: 600;
        border-radius: 5px;
    }

    p {
        font-size: 12px;

    }
}
 
.list-view-table {
    position: relative;
    padding: 10px 0px;
    &:hover{

        table {
        tr{
            td{
                &:nth-child(1) {
                

                    background-color: #e8e8e8
                }
            }
        }
        }
    }
    table{
        width: fit-content;
        border-collapse: separate;
        //border-spacing: 10px 0;
        table-layout: fixed !important;
        margin: 0;

        tr{

    
            td {
                background: #fff;
                border: 1px solid #EEEEF2;
                vertical-align: top;
                position: relative;
                padding:0;
                width: 150px;
                border-radius: 5px;
                &:nth-child(1) {
                    border-radius: 0;
                    width: 275px;
                    border-spacing: 0px;
                }
                
                .end-div{
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    left: 0;
                    border-radius: 5px;
                }
                &:first-child{
                    position: sticky;
                    left: 0;
                    z-index: 1;
                    background-color: #f7f7f7;
                    //padding-left: 10px;

                    width: 275px;
                    border: 0;
                  
                    //background-color: transparent;

                   .inner-table-view{
                        //background-color: transparent;
                        padding: 0;
                        position: relative;
                        border-spacing: 0;
                        td{
                            //background-color: transparent;
                            &:nth-child(1){
                             
                                    width: 100px;
                                    margin: auto;
                                    display: flex;
                                    flex-direction: column;
                            }
                            &:nth-child(2){
                                // width:320px;
                                vertical-align: top;
                                padding-left: 10px;
                            }
                        }
                      
                                          .profile-dog{
                            width: 90px;
                            height: 70px;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 4px;
                            }
                        
                        }

                        .mins-box{
                            width: 80px;
                            padding: 0px 4px;
                            border-radius: 13px;
                            background: #2829FF;
                            color: #FFF;
                            text-align: center;
                            font-size: 12px;
                            display: flex;
                            margin: auto;
                            justify-content: center;
                        }
                        .warning-img{
                            position: absolute;
                            top: -1px;
                            right: 0;
                        }
                   }
                }
                
            }

            td.cancelled-state{
            border: 1px solid  #EEEEF2;
            background: linear-gradient(0deg, rgba(255, 0, 0, 0.15) 0%, rgba(255, 0, 0, 0.15) 100%), #FFF;
            h4{
                background-color: transparent;
                color: #DE2B2B;
            }
            .end-div{
                background-color: transparent;
            }
            .state-cancel{
                color:  #DE2B2B;
                text-align: center;
                line-height: normal;
                text-transform: capitalize;
                border-radius: 3px;
                border: 0.5px solid rgba(255, 255, 255, 0.20);
                background: #FFECF0;
                padding: 1px;
            }
            }
        } 
    }

    .btn-wrappers{
        position: absolute;
        top:0;
        right: 0;
        display: none;
    }
    .add-btn,
    .edit-btn
    {
        background-color: #11CA9D;
        color: #fff;
        height: 100%;

        &:hover {
            background-color: #10ab86;
            color: #fff;
            height: 100%;  
        }
        
    }
    .edit-btn{
        background-color: #3D3EFD;
        &:hover{
            background-color: #2020b3;
        }
    }
    &:hover{
        background-color: rgb(179 179 179 / 28%);
        .btn-wrappers{
            display: block;
        }
    }
    
}




// @media (max-width: 1699.98px) { 
//     .list-view-table{
//         table{
//             tr{
//                 td{
//                     &:first-child{
//                         width: 250px;
//                     }
//                 }
//             }
//         }
//     } 
//  }


//  @media (max-width: 1499.98px) { 
//     .list-view-table{
//         table{
//             tr{
//                 td{
//                     &:first-child{
//                         width: 250px;
//                     }
//                 }
//             }
//         }
//     } 
//  }


//  .list-view-table{
//     table{
//         tr{
//             td{
//                 &:first-child{
//                     width: 250px;
//                 }
//             }
//         }
//     }
// } 

td.blankdata{
    background: transparent !important;
    border: 0 !important;
}
