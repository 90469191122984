.calendar-wrapper {
    &.staff_schedule_table {
        table {
            tbody {
                tr {
                    th {
                        .user-pic-cell {
                            padding: 8px 0;
                        }

                        &.user-pic-cell-wrapper {
                            z-index: 3;
                        }
                    }

                    td {
                        position: relative;

                        &:before {
                            content: "";
                            position: absolute;
                            left: 50%;
                            top: 0;
                            width: 1px;
                            height: 100%;
                            border: 1px dashed rgba(128, 128, 128, 0.1215686275);
                            z-index: 1;
                        }

                        .data-container {
                            position: relative;
                            min-height: auto;
                            top: 0;
                            height: 100%;

                            // &:before {
                            //     content: "";
                            //     position: absolute;
                            //     left: 50%;
                            //     top: 0;
                            //     width: 1px;
                            //     height: 100%;
                            //     border: 1px dashed rgba(128, 128, 128, 0.1215686275);
                            //     z-index: 1;
                            // }

                            .slot-item {
                                z-index: 2;
                                position: relative;

                                &.pnding_slot {
                                    background: #FDFCF3;
                                    border-left: 3px solid #E0CE2A;
                                }

                                &.slot_confirm {
                                    background: #EAF6FC;
                                    border-left: 3px solid #409DE0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

.table {
    tr {
        th {
            text-transform: unset !important;
        }
    }
}

.btn {
    &.schedule_srch_filte_btn {
        border-radius: 8px;
        border: 1px solid #EEEEF2;
        background: #FFF;
        /* Shadows/Gray/1 Level */
        box-shadow: none !important;
        outline: none !important;
        padding: 7px 10px;
        min-height: 32px;
    }
}

.schedule_srch {
    span {
        top: 50%;
        transform: translate(0, -50%);
        left: 9px;
    }

    input {
        padding-left: 40px;
        outline: none !important;
        box-shadow: none !important;
    }
}