.offcanvas-header {
  padding-bottom: unset !important;
  .btn-close {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
  }
}
#pet-owner-selection-offcanvas,
#pet-selection-offcanvas {
  max-width: 715px !important;
  width: 100% !important;
}

.search-cell {
  margin-bottom: 0px !important;
}
.pet-owner-selection {
  .react-tel-input .form-control {
    width: 100%;
    height: 48px;
  }
  .owner-tab-blc {
    .nav-tabs {
      border: none;
      margin-bottom: 10px;

      .nav-item {
        border: 1px solid rgb(210 210 221);
        margin-left: -1px;
        min-width: 150px;

        &:first-child {
          border-radius: 6px 0px 0px 6px;
          margin-left: 0;
        }

        &:last-child {
          border-radius: 0 6px 6px 0;
        }

        .nav-link {
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          border: none;
          color: #282e65;
          background-color: transparent;
          border-radius: 0;
          margin-bottom: 0;
          text-align: center;
          padding: 7px 10px;

          &.active {
            color: #3d3efd;
            background: rgba(#3d3efd, 0.08);
          }
        }
      }
    }
  }
  .pic-cell2 {
    .pic {
      width: 80px;
      height: 80px;
      &:hover {
        .upload-cell {
          opacity: 1;
        }
      }
      .user-placeholder {
        span {
          font-size: 80px;
        }
      }
      .upload-cell {
        background-color: rgba(#3d3efd, 0.8);
        z-index: 2;
        font-size: 25px;
        color: rgba(256, 256, 256, 0.7);
        cursor: pointer;
        opacity: 0;
        transition: all ease 0.3s 0s;
        * {
          cursor: pointer;
        }
        input {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0%;
          opacity: 0;
        }
      }
    }
  }
}
