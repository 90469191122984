@import "../../../../../../assets/css/theme/variables";



.new-result-panel-wrapper {
    .form-block > * {
        margin: 10px

    }
    
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    max-width: 715px;
    width: 100%;
    background-color: #fff;
    padding: 15px;
    transition: all ease 0.3s 0s;
    transform: translateX(100%);
    &.active {
        transform: translateX(0);
    }
    > .head {
        h2 {
            font-size: 24px;
            font-weight: 700;
            line-height: 33px;
        }
        .panel-close-btn {
            color: $base-color-private;
            padding: 10px;
            &:hover {
                color: $primary;
            }
        }
    }
    .table-listing-blc {
        .table {
            thead {
                tr {
                    th {
                        position: sticky;
                        top: 0;
                    }
                }
            }
        }
    }
}
.new-result-panel-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.2);
    visibility: hidden;
    opacity: 0;
    transition: all ease 0.3s 0s;
    &.active {
        visibility: visible;
        opacity: 1;
    }
}
