@import "../../../../../assets/css/theme/variables";

.medical-history-block {
	> .row-block {
		.section-head {
			margin-bottom: 0;
			> .left-col {
				margin-right: 15px;
				h3 {
					font-size: 18px;
					font-weight: 600;
					line-height: 150%;
				}
			}
			> .right-col {
				.btn {
					padding: 10px;
				}
				.view-all-btn {
					color: $base-color;
					&:hover,
					&:focus {
						color: $primary;
					}
				}
				.add-btn {
					font-size: 19px;
					color: $success;
					&:hover,
					&:focus {
						color: $success-dark;
					}
				}
			}
		}
		margin-bottom: 15px;
		.breif-medical-rw {
			.item-col {
				width: 33.3%;
				padding: 0 5px;
				@media (max-width: 767.98px) {
                    width: 40.3%;
                }
				@media (max-width: 575.98px) {
                    width: 70.3%;
                }
				* {
					cursor: pointer;
				}
				.more-item-btn {
					font-size: 13px;
					font-weight: 600;
					line-height: 150%;
					color: #788c9f;
					transform: rotate(-90deg);
					&:hover,
					&:focus {
						color: $primary;
					}
				}
			}
		}
	}
}
