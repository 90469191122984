.product_info_relative {
  padding-right: 170px;
}
.product_info_relative .qr_img1 {
  position: absolute;
  top: 39%;
  transform: translate(0, -50%);
  right: 15px;
}
.product_info_relative .qr_img1 p {
  font-size: 13px;
  color: #282E65;
  font-weight: 400;
}/*# sourceMappingURL=index.css.map */