@import "../../../../../assets/css/theme/variables";
.select-group-list{
    color: rgba(40, 46, 101, 1);
    .offcanvas-header{
      .btn-close{
        opacity: 1 !important;
        position: relative;
        right: 10px;
        &::before{
          position: absolute;
          top: 5px;
          right:15px;
          content: " ";
          height: 25px;
          width: 2px;
          background-color: #282e65;
          transform: rotate(45deg);
        }
      &::after {
          position: absolute;
          top: 5px;
          right:15px;
          content: " ";
          height: 25px;
          width: 2px;
          background-color: #282e65;
          transform: rotate(-45deg);
      }
      }
      .offcanvas-title{
        color:  #282E65;
        font-family: Nunito Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
       
      }
    }
    .group-add-name{
      input{
      border-radius: 8px;
      border: 1px solid rgba(9, 9, 84, 0.12);
      color: rgba(120, 134, 147, 1);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      height:38px;
      font-size: 14px;
      }
    }
    .search-group-wrapp{
      svg{
        position: absolute;
        top: 2px;
        left: 8px;
      }
      input{
        padding-left:35px;
        border-radius: 6px;
        background:  rgba(228, 228, 228, 0.28);
        color: rgba(120, 134, 147, 1);
        height: 32px;
        border:0;
      }
    }
  }