@import "../../../../../assets/css/theme/variables";

.form-floating {
    .form-control {
        padding: 10px 16px 0 !important;
    }
}

.small_text {
    font-size: 13px;
    color: #788693;
    font-weight: 300;
}




.vaccine-schedule-panel-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    min-width: 30%;
    max-width: 50%;
    width: 50%;
    background-color: #fff;
    padding: 15px;
    transition: all ease 0.3s 0s;
    transform: translateX(100%);

    &.active {
        transform: translateX(0);
    }

	.add-section-medication-btn {
		border: 1px dashed #788c9f;
		border-radius: 10px;
		color: $success;
		padding: 8px 15px;
		&:hover,
		&:focus {
			border-color: $success;
		}
		span {
			font-size: 22px;
		}
	}

    .head {
        h2 {
            font-size: 24px;
            font-weight: 700;
            line-height: 33px;

            @media(max-width:767px) {
                font-size: 19px;
            }
        }

        .panel-close-btn {
            color: $base-color-private;
            padding: 10px;

            &:hover {
                color: $primary;
            }
        }
    }

    .table-listing-blc {
        .table {
            thead {
                tr {
                    th {
                        position: sticky;
                        top: 0;
                    }
                }
            }
        }
    }
}

.vaccine-schedule-panel-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    min-width: 30%;
    max-width: 70%;
    width: 100%;
    background-color: #fff;
    padding: 15px;
    transition: all ease 0.3s 0s;
    transform: translateX(100%);

    &.active {
        transform: translateX(0);
    }

	.add-section-medication-btn {
		border: 1px dashed #788c9f;
		border-radius: 10px;
		color: $success;
		padding: 8px 15px;
		&:hover,
		&:focus {
			border-color: $success;
		}
		span {
			font-size: 22px;
		}
	}

    .head {
        h2 {
            font-size: 24px;
            font-weight: 700;
            line-height: 33px;

            @media(max-width:767px) {
                font-size: 19px;
            }
        }

        .panel-close-btn {
            color: $base-color-private;
            padding: 10px;

            &:hover {
                color: $primary;
            }
        }
    }

    .table-listing-blc {
        .table {
            thead {
                tr {
                    th {
                        position: sticky;
                        top: 0;
                    }
                }
            }
        }
    }
}




.border_btn {
    .add-btn {
        font-size: 13px !important;
        font-weight: 600 !important;
        min-width: 140px;
    }

    .btn {
        &.border-btn {
            border: 1px solid rgba(9, 9, 84, 0.12);
            filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.14));
            border-radius: 6px;
            text-transform: uppercase;
            color: #1d1d1d;
            padding: 6px 19px;
            margin: 0;
            font-size: 13px;
            font-weight: 600;
            min-width: 140px;
        }
    }
}