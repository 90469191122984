@import "../../../../../../assets/css/theme/variables";

.surgical-history-block {
    > .inner {
        .surgical-items-rw {
            .item-col {
                width: 33.3%;
                padding: 0 5px;
                @media (max-width: 991px) {
                    width: 70.3%;
                }
                * {
                    cursor: pointer;
                }
                .more-item-btn {
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 150%;
                    color: #788c9f;
                    transform: rotate(-90deg);
                    &:hover,
                    &:focus {
                        color: $primary;
                    }
                }
            }
        }
    }
}
