@import "../../../assets/css/theme/variables";

.patients-panel-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  max-width: 900px;
  width: 100%;
  background-color: #fff;
  padding: 15px;
  transition: all ease 0.3s 0s;
  transform: translateX(100%);
  &.active {
    transform: translateX(0);
  }
  > .head {
    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
    }
    .panel-close-btn {
      color: $base-color-private;
      padding: 10px;
      &:hover {
        color: $primary;
      }
    }
  }
  .table-listing-blc {
    .table {
      thead {
        tr {
          th {
            position: sticky;
            top: 0;
          }
        }
      }
    }
  }
}

.search-cell {
  // margin-bottom: 5px;
  .btn {
    width: 64px;
    background: #f8f8f8;
    border: 1px solid rgba(9, 9, 84, 0.12);
    border-radius: 0px 8px 8px 0px;
    font-size: 18px;
    padding: 0;
    box-shadow: none;
  }
}

select::after {
  border: 1px solid rgba(9, 9, 84, 0.12);
}

.pic {
  height: 50px;
  width: 50px;
  border-radius: 8px;
}

.card-text {
  margin-left: 10px;
}

.btns-blc {
  .btn {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    &.cancel-btn {
      min-width: 150px;
      background-color: #fff;
    }
    &.send-btn {
      min-width: 200px;
    }
  }
}

.card-name {
  /* Killer (VRN0005658) */
  height: 24px;

  /* 16 */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;

  /* Dark Blue */

  color: #282e65;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.info-title {
  /* Pet Information */

  width: 235px;
  height: 24px;

  /* 16 */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;

  /* Dark Blue */

  color: #282e65;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}

.select:invalid {
  color: gray;
}

.select::after {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: #34495e;
  cursor: pointer;
}

.panel-title {
  /* Hello, Scarlett! */

  height: 33px;

  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Dark Blue */

  color: #282e65;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.card-body {
  /* 5 years, 6 months old (DOB : 05/26/2022 ) / Male / Persian / Black */

  width: 400px;
  height: 40px;

  /* 13 */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* or 20px */

  display: flex;
  align-items: center;

  /* Black */

  color: #282e65;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.tab-row {
  background: #f8f8f8;
  padding: 20px;
  margin-bottom: 8px;
  border-radius: 8px;

  @media (max-width: 850px) {
    flex-direction: column;
  }

  .tab-col {
    &.lft {
      @media (max-width: 850px) {
        margin-right: 0;
        margin-bottom: 10px;
        max-width: none;
      }
    }
  }
}

.patient-panel-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.2);
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s 0s;
  &.active {
    visibility: visible;
    opacity: 1;
  }
}

.report-label-data {
  > .pic {
    height: 0px;
    width: 20px;
    border-radius: 8px;
  }
}
