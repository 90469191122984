@import "../../../../../../assets/css/theme/variables";

.reports-block {
  > .inner {
    padding: 0 5px;

    .report-items-rw {
      margin: 0 -5px;
      .offCanvas-Report-card {
        width: 33%;
        padding: 0 5px;
      }
      @media (max-width: 1199px) {
        width: 30%;
      }

      * {
        cursor: pointer;
      }
      .item-col {
        width: 25%;
        padding: 0 5px;
      }

      .more-item-col {
        width: 130px;

        @media (max-width: 1199px) {
          width: 62px;
        }

        .more-item-btn {
          font-size: 13px;
          font-weight: 600;
          line-height: 150%;
          color: #788c9f;
          transform: rotate(-90deg);

          @media (max-width: 1199px) {
            padding: 0;
          }

          &:hover,
          &:focus {
            color: $primary;
          }
        }
      }
    }
  }
}

.radiology {
  margin-bottom: 4px;
  .accordion-item {
    border: 0 !important;
    background-color: #f8f8f8 !important;
  }

  .accordion-button {
    background-color: #f8f8f8 !important;
    border: none !important;
    box-shadow: none;

    h3 {
      padding: 0 10px;
    }

    svg {
      position: absolute;
      left: 5px;
    }

    .add-btn {
      position: absolute;
      right: 10px;
      padding: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:focus {
      border: 0 !important;
      outline: 0 !important;
      box-shadow: none !important;
    }

    :not(.collapsed) {
      background-color: #f8f8f8 !important;
      border: none !important;
      box-shadow: none !important;
    }

    &[aria-expanded="true"] {
      svg {
        transform: rotate(180deg);
      }
    }

    &::after {
      content: none !important;
    }
  }
  .accordion-body {
    .report-item {
      background-color: #fff !important;
    }
  }
  .accordion-button:not(.collapsed) {
    box-shadow: none !important;
  }
}
