@import "../../../../assets/css/theme/variables";

.tab-wrapper.tab-wrapper-setting .tab-content {
    .apps-wrapper {
        >.tab-row {
            @media (max-width: 850px) {
                flex-direction: column;
            }

            .tab-col {
                &.lft {
                    @media (max-width: 850px) {
                        margin-right: 0;
                        margin-bottom: 10px;
                        max-width: none;
                    }
                }
            }
        }

        .app-list-blc {
            max-width: 400px;

            @media (max-width: 850px) {
                max-width: none;
            }

            .row-block {
                width: 100%;
                background: #fff;
                padding: 10px;
                border-radius: 8px;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                @media (max-width: 374px) {
                    flex-direction: column;
                }

                >.left-col {
                    margin-right: 20px;

                    @media (max-width: 374px) {
                        margin: 0 0 10px;
                    }

                    .app-icon {
                        width: 40px;
                        margin-right: 20px;
                        flex-shrink: 0;

                        @media (max-width: 480px) {
                            width: 30px;
                            margin-right: 15px;
                        }

                        img {
                            width: 40px;
                        }
                    }

                    .app-info {

                        h4,
                        h5 {
                            line-height: 150%;
                        }

                        h4 {
                            font-weight: 600;
                            font-size: 18px;
                            margin-bottom: 5px;

                            @media (max-width: 480px) {
                                font-size: 16px;
                            }
                        }

                        h5 {
                            font-weight: 400;
                            font-size: 13px;
                            margin-bottom: 0;
                        }
                    }
                }

                >.right-col {

                    .dropdown-cell>.btn,
                    .add-btn {
                        text-align: center;
                        width: 40px;
                    }

                    .add-btn {
                        font-size: 20px;
                        color: $success;

                        &:hover,
                        &:focus {
                            color: $success-dark;
                        }
                    }
                }
            }
        }
    }
}