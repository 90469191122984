@import "../../../../../../assets/css/theme/variables";

.notes-block {
	> .left-col {
		width: 316px;
		flex-shrink: 0;
		.note-head {
			padding: 3px 15px 0;
			margin-bottom: 10px;
			.search-cell {
				width: 100%;
				height: 35px;
				margin-bottom: 10px;
				> span {
					top: 0;
					left: 0;
					bottom: 0;
					width: 40px;
					color: #788693;
					span {
						font-size: 20px;
					}
				}
				.form-control {
					padding-left: 36px;
					height: 100%;
				}
			}
			.add-note-btn {
				border: 1px dashed #788c9f;
				border-radius: 10px;
				color: $success;
				padding: 8px 15px;
				&:hover,
				&:focus {
					border-color: $success;
				}
				span {
					font-size: 22px;
				}
			}
		}
		.note-list-wrapper {
			padding: 0 15px;
			.nav-tabs {
				white-space: normal;
				border: none;
				.nav-item {
					margin-bottom: 5px;
					&:last-child {
						margin-bottom: 0;
					}
					.nav-link {
						padding: 0;
						&:hover,
						&:focus {
							color: $base-color-private;
							.note-breif-box {
								background-color: rgba($primary, 0.08);
							}
						}
						&.active {
							color: $base-color-private;
							&::before {
								display: none;
							}
							.note-breif-box {
								background-color: rgba($primary, 0.08);
								border: 1px solid $primary;
								.date {
									color: $primary;
								}
							}
						}
					}
				}
			}
		}
	}
}


.notes-panel-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    max-width: 600px;
    width: 100%;
    background-color: #fff;
    padding: 15px;
    transition: all ease 0.3s 0s;
    transform: translateX(100%);

    &.active {
        transform: translateX(0);
    }

	.add-section-note-btn {
		border: 1px dashed #788c9f;
		border-radius: 10px;
		color: $success;
		padding: 8px 15px;
		&:hover,
		&:focus {
			border-color: $success;
		}
		span {
			font-size: 22px;
		}
	}

    >.head {
        h2 {
            font-size: 24px;
            font-weight: 700;
            line-height: 33px;

            @media(max-width:767px) {
                font-size: 19px;
            }
        }

        .panel-close-btn {
            color: $base-color-private;
            padding: 10px;

            &:hover {
                color: $primary;
            }
        }
    }

    .table-listing-blc {
        .table {
            thead {
                tr {
                    th {
                        position: sticky;
                        top: 0;
                    }
                }
            }
        }
    }
}

.notes-schedule-modal-wrapper {
    right: 52.5%;
    top: 16%;
    z-index: 99;
    max-width: 550px;
	max-height: 300px;
	height: 100%;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    transition: all ease 0.3s 0s;
    transform: translateX(100vw);

    &.active {
        transform: translateX(0);
    }
}

.notes-schedule-modal-selector{
	z-index: 99;
	max-height: 210px;
	margin-top: 5px;
	margin-bottom: 5px;
	width: 100%;
}