.waiting-room-list .item-row {
  background: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
}
@media (max-width: 1250px) {
  .waiting-room-list .item-row {
    flex-direction: column;
  }
}
.waiting-room-list .item-row > .left-col {
  margin-right: 20px;
}
@media (max-width: 1250px) {
  .waiting-room-list .item-row > .left-col {
    margin-right: 0;
  }
}
.waiting-room-list .item-row > .left-col .room-info h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}
.waiting-room-list .item-row > .left-col .room-info h5 {
  font-size: 13px;
  font-weight: 400;
}
.waiting-room-list .item-row > .right-col {
  margin-left: auto;
}
@media (max-width: 1250px) {
  .waiting-room-list .item-row > .right-col {
    margin-left: 0;
  }
}
.waiting-room-list .item-row > .right-col .product-pic-blc {
  max-width: 330px;
  width: 100%;
}
@media (max-width: 800px) {
  .waiting-room-list .item-row > .right-col .product-pic-blc {
    max-width: none;
    width: auto;
  }
}
.waiting-room-list .item-row > .right-col .product-pic-blc .pic-cell {
  width: 60px;
  height: 60px;
  margin-right: 5px;
  border-radius: 5px;
  flex-shrink: 0;
}
.waiting-room-list .item-row > .right-col .product-pic-blc .pic-cell img {
  width: 100%;
  height: 100%;
}
.waiting-room-list .item-row > .right-col .btn {
  color: #282e65;
  padding: 10px;
}
.waiting-room-list .item-row > .right-col .btn:hover, .waiting-room-list .item-row > .right-col .btn:focus {
  color: #3d3efd;
}/*# sourceMappingURL=index.css.map */