@import "../../../assets/css/theme/variables";

.product-detail-wrapper {
    .general-info,
    .cost-info-blc,
    .notes-info-blc {
        > .inner {
            .item-rw {
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 20px;
                }
                .label-cell {
                    width: 152px;
                    flex-shrink: 0;
                    margin-right: 15px;
                    text-align: left;
                }
                .desc-cell {
                   
                    .checkbox-wrapper {
                        margin-right: 22px;
                        &:last-child {
                            margin-right: 0;
                        }
                        .checkbox-cell {
                            margin-right: 8px;
                        }
                        .txt {
                            font-family: "Inter";
                            font-size: 14px;
                        }
                    }
                    .tags {
                        span {
                            font-size: 13px;
                            line-height: 16px;
                            font-weight: 400;
                            background: #eeeef2;
                            padding: 2px 4px;
                            margin-right: 5px;
                            border-radius: 3px;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
            .product-pics {
                .item-list {
                    margin-right: 10px;
                    .item {
                        width: 80px;
                        height: 80px;
                        border: 1px solid #ddd;
                        margin-right: 10px;
                        border-radius: 10px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                .add-btn {
                    width: 44px;
                    border: 1px dashed #788c9f;
                    border-radius: 10px;
                    &:hover,
                    &:focus {
                        border-color: $primary;
                    }
                }
            }
        }
    }
}
