@import "../../../../assets/css/theme/variables";

.profile-allergy {
    > .inner {
        .item-cell {
            background: #f8f8f8;
            padding: 9px 10px;
            margin-bottom: 5px;
            border-radius: 8px;
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
            .dash {
                width: fit-content;              
                    top: 6px;
                    position: absolute;
                    right: 6px;
                    background-color: #e74c3c;
                    width: 20px;
                    height: 20px;
            }
        }
    }
    .add-btn {
        font-size: 13px;
        font-weight: 400;
        line-height: 150%;
        color: #788693;
        padding: 10px;
        &:hover,
        &:focus {
            color: $primary;
        }
    }
}
