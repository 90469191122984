@import "../../assets/css/theme/variables";
.coming-soon-wrapper {
    padding: 40px;
    .inner {
        max-width: 980px;
        width: 100%;
        @media (max-height: 750px) {
            max-width: 400px;
        }
        .pic {
            margin-bottom: 48px;
        }
        .txt {
            a {
                text-decoration: none;
            }
            h2 {
                font-family: "Inter", sans-serif;
                font-size: 36px;
                line-height: 56px;
                letter-spacing: 0.05em;
                margin-bottom: 22px;
                @media (max-height: 750px) {
                    font-size: 25px;
                    line-height: normal;
                }
            }

            h3,
            h4 {
                color: rgba($base-color, 0.5);
            }
            h3 {
                font-family: "Inter", sans-serif;
                font-size: 26px;
                line-height: 46px;
                letter-spacing: 0.04em;
                margin-bottom: 29px;
                @media (max-height: 750px) {
                    font-size: 17px;
                    line-height: 24px;
                    margin-bottom: 20px;
                }
            }
            h4 {
                @media (max-height: 750px) {
                    font-size: 17px;
                    line-height: 28px;
                }
                a {
                    margin-left: 4px;
                }
            }
        }
    }
}
