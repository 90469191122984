@import "../.././../assets/css/theme/variables";

.price-plan-block {
  > .head {
    background-color: rgba($primary, 0.1);
    padding: 26px 20px;
    @media (max-width: 1919px) {
      padding: 16px;
    }
    @media (max-width: 767px) {
      padding: 10px 16px;
    }
    &.bg-1 {
      background-color: rgba($primary, 0.1);
    }
    &.bg-2 {
      background-color: rgba(24, 211, 76, 0.1);
    }
    &.bg-3 {
      background-color: rgba(253, 118, 112, 0.1);
    }
    h3 {
      font-family: "Inter", sans-serif;
      font-size: 26px;
      line-height: 31px;
      margin-bottom: 0;
      text-transform: uppercase;
      @media (max-width: 1919px) {
        font-size: 23px;
      }
    }
    h4 {
      font-family: "SFProDisplay-Medium";
      font-size: 16px;
      line-height: 23px;
      color: rgba(8, 8, 41, 0.5);
    }
  }
  > .inner {
    .price {
      font-family: "Inter", sans-serif;
      font-size: 36px;
      line-height: 43px;
      border-bottom: 1px solid rgba(8, 8, 41, 0.1);
      padding: 25px 20px;
      @media (max-width: 1919px) {
        font-size: 28px;
        padding: 16px;
      }
      @media (max-width: 767px) {
        font-size: 24px;
        padding: 12px 16px;
      }

      span {
        font-family: "Inter", sans-serif;
        font-size: 26px;
        line-height: 31px;
        color: rgba(8, 8, 41, 0.5);
        margin-left: 8px;
        @media (max-width: 1919px) {
          font-size: 20px;
        }
      }
    }
    .plan-items {
      padding: 18px 20px;
      @media (max-width: 640px) {
        padding: 16px;
      }
      ul {
        li {
          font-family: "SFProDisplay-Medium";
          font-size: 16px;
          line-height: 29px;
          padding: 0 0 42px 0;
          @media (max-width: 1919px) {
            padding: 0 0 36px 0;
          }
          @media (max-width: 767px) {
            padding: 0 0 16px 0;
          }
          &:last-child {
            padding-bottom: 0;
          }
          &.no-access {
            color: rgba(8, 8, 41, 0.5);
          }
          span {
            margin-right: 4px;
          }
        }
      }
    }
  }
  > .foot {
    padding: 20px 20px 40px;
    .btn {
      max-width: 237px;
      width: 100%;
    }
  }
  &.selected-plan {
    max-width: 437px;
    width: 100%;
  }
  &.responsive-layout {
    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
    > .inner {
      .price {
        @media (max-width: 1919px) {
          font-size: 32px;
          padding: 15px 20px;
        }
        span {
          @media (max-width: 1919px) {
            font-size: 24px;
          }
        }
      }
    }
  }
}
