@import "../../../../../../assets/css/theme/variables";


.past-visit-item {

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    // width: 1030px;
    //max-height: 156px;

    border-radius: 10px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    .doctor {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        gap: 10px;
      //  width: 30%;

       // max-width: 280px;
        // height: 156px;

        /* BG */

        background: rgba(248, 248, 248, 0.9);
        border-radius: 10px 0px 0px 10px;

        /* Inside auto layout */

        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;

        .visit-date-location {

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 10px;

            width: 260px;
            height: 52px;


            /* Inside auto layout */

            flex: none;
            order: 2;
            align-self: stretch;
            flex-grow: 0;

            .visit-date-time {

                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 8px;

                width: 260px;
                height: 21px;


                /* Inside auto layout */

                flex: none;
                order: 0;
                align-self: stretch;
                flex-grow: 0;

                .calendar-icon {
                    width: 20px;
                    height: 20px;


                    /* Inside auto layout */

                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }

                .date {
                    width: 87px;
                    height: 21px;

                    /* 14 */

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    /* identical to box height, or 21px */

                    display: flex;
                    align-items: center;

                    /* Dark Blue */

                    color: #282E65;


                    /* Inside auto layout */

                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }

                .line {
                    width: 0px;
                    height: 21px;

                    /* Main 4% Opacity */

                    border: 1px solid #EEEEF2;
                    /*transform: rotate(90deg);*/

                    /* Inside auto layout */

                    flex: none;
                    order: 2;
                    align-self: stretch;
                    flex-grow: 0;
                }

                .time {
                    width: 138px;
                    height: 21px;

                    /* 14 */

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    /* identical to box height, or 21px */

                    display: flex;
                    align-items: center;

                    /* Dark Blue */

                    color: #282E65;


                    /* Inside auto layout */

                    flex: none;
                    order: 4;
                    flex-grow: 0;
                }

            }


            .visit-location {

                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 8px;

                width: 260px;
                height: 21px;


                /* Inside auto layout */

                flex: none;
                order: 1;
                align-self: stretch;
                flex-grow: 0;

                .icon {
                    width: 20px;
                    height: 20px;


                    /* Inside auto layout */

                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }

                .location {
                    width: 116px;
                    height: 21px;

                    /* 14 */

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    /* identical to box height, or 21px */

                    display: flex;
                    align-items: center;

                    /* Dark Blue */

                    color: #282E65;


                    /* Inside auto layout */

                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }

            }
        }



        .doctor-info {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 10px;

            width: 260px;
            height: 50px;


            /* Inside auto layout */

            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;

            .picture {

                width: 50px;
                height: 50px;


                /* Inside auto layout */

                flex: none;
                order: 0;
                flex-grow: 0;

            }

            .doctor-text {

                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 0px;
                gap: 10px;

                width: 200px;
                height: 50px;


                /* Inside auto layout */

                flex: none;
                order: 1;
                align-self: stretch;
                flex-grow: 1;

                .doctor-name-title {

                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0px;
                    gap: 4px;

                    width: 170px;
                    height: 50px;


                    /* Inside auto layout */

                    flex: none;
                    order: 0;
                    align-self: stretch;
                    flex-grow: 1;

                    .doctor-name {

                        width: 170px;
                        height: 25px;

                        /* 16 */

                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 150%;
                        /* or 24px */

                        display: flex;
                        align-items: center;

                        /* Dark Blue */

                        color: #282E65;


                        /* Inside auto layout */

                        flex: none;
                        order: 0;
                        align-self: stretch;
                        flex-grow: 0;

                    }

                    .doctor-title {

                        width: 30px;
                        height: 20px;

                        /* 13 */

                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 150%;
                        /* identical to box height, or 20px */

                        display: flex;
                        align-items: center;

                        /* Dark Blue */

                        color: #282E65;


                        /* Inside auto layout */

                        flex: none;
                        order: 1;
                        flex-grow: 0;

                    }

                }

                .action-icon {


                    width: 20px;
                    height: 20px;


                    /* Inside auto layout */

                    flex: none;
                    order: 1;
                    flex-grow: 0;

                }



            }



        }


    }

    .visit-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 15px;
        gap: 10px;
        max-width: 100%;
        //max-width: 750px;
        // height: 156px;

        /* BG */

        background: rgba(248, 248, 248, 0.9);
        border-radius: 0px 10px 10px 0px;

        /* Inside auto layout */

        // flex: none;
        // order: 1;
        align-self: stretch;
        flex-grow: 1;


        .description {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 5px;

            // width: 720px;
            // height: 92px;


            /* Inside auto layout */

            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;

            .header {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 5px;

                // width: 720px;
                // height: 24px;
                margin-bottom: 10px;

                /* Inside auto layout */

                flex: none;
                order: 0;
                align-self: stretch;
                flex-grow: 0;

                .title {
                    // width: 543px;
                    // height: 24px;

                    /* 16 */

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    /* identical to box height, or 24px */


                    /* Dark Blue */

                    color: #282E65;


                    /* Inside auto layout */

                    flex: none;
                    order: 0;
                    flex-grow: 1;
                }

                .actions {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0px;
                    gap: 15px;

                    width: 172px;
                    // height: 20px;


                    /* Inside auto layout */

                    flex: none;
                    order: 1;
                    flex-grow: 0;

                    .add-notes {

                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 0px;
                        gap: 5px;

                        width: 66px;
                        // height: 20px;

                        border-radius: 30px;

                        /* Inside auto layout */

                        flex: none;
                        order: 0;
                        flex-grow: 0;

                        .add-notes-btn {
                            width: 66px;
                            // height: 20px;

                            /* 13 */

                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 150%;
                            /* identical to box height, or 20px */


                            /* Dark Blue */

                            color: #282E65;


                            /* Inside auto layout */

                            flex: none;
                            order: 0;
                            flex-grow: 0;
                        }

                    }

                    .view-details {


                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 0px;
                        gap: 7px;

                        width: 76px;
                        height: 20px;


                        /* Inside auto layout */

                        flex: none;
                        order: 2;
                        flex-grow: 0;


                        .view-details-btn {
                            width: 76px;
                            height: 20px;

                            /* 13 */

                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 150%;
                            /* identical to box height, or 20px */

                            display: flex;
                            align-items: center;

                            /* Dark Blue */

                            color: #282E65;


                            /* Inside auto layout */

                            flex: none;
                            order: 0;
                            flex-grow: 0;
                        }
                    }

                    .line {


                        width: 14px;
                        height: 0px;

                        border: 1px solid rgba(0, 0, 0, 0.1);
                        transform: rotate(90deg);

                        /* Inside auto layout */

                        flex: none;
                        order: 1;
                        flex-grow: 0;

                    }
                }


            }

            .body {
               // width:min-content;
                //max-width: 70%;
                height: auto;

                /* 14 */

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                /* or 21px */


                /* Dark Blue */

                color: #282E65;


                /* Inside auto layout */
                word-break: break-all;
                text-wrap: wrap;
                overflow-wrap: normal;
                flex: none;
                order: 1;
                align-self: stretch;
                flex-grow: 0;
            }
        }

        .measurements {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 10px;

            // width: 394px;
            // height: 34px;


            /* Inside auto layout */

            flex: none;
            order: 1;
            flex-grow: 0;

            .temp {
                box-sizing: border-box;

                /* Auto layout */

                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8px;
                gap: 5px;

                width: 102px;
                height: 34px;

                border: 1px solid rgba(40, 46, 101, 0.1);
                border-radius: 8px;

                /* Inside auto layout */

                flex: none;
                order: 0;
                flex-grow: 0;

                .temp-text {
                    width: 86px;
                    height: 21px;

                    /* 14 */

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    /* identical to box height, or 21px */


                    /* Dark Blue */

                    color: #282E65;


                    /* Inside auto layout */

                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }

            }

            .weight {

                box-sizing: border-box;

                /* Auto layout */

                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8px;
                gap: 5px;

                width: 115px;
                height: 34px;

                border: 1px solid rgba(40, 46, 101, 0.1);
                border-radius: 8px;

                /* Inside auto layout */

                flex: none;
                order: 1;
                flex-grow: 0;

                .weight-text {

                    width: 99px;
                    height: 21px;

                    /* 14 */

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    /* identical to box height, or 21px */


                    /* Dark Blue */

                    color: #282E65;


                    /* Inside auto layout */

                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }
            }

            .heart-rate {
                box-sizing: border-box;

                /* Auto layout */

                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8px;
                gap: 5px;

                width: 157px;
                height: 34px;

                border: 1px solid rgba(40, 46, 101, 0.1);
                border-radius: 8px;

                /* Inside auto layout */

                flex: none;
                order: 2;
                flex-grow: 0;

                .heart-rate-text {

                    width: 141px;
                    height: 21px;

                    /* 14 */

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    /* identical to box height, or 21px */


                    /* Dark Blue */

                    color: #282E65;


                    /* Inside auto layout */

                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }
            }
        }

    }

}

body {
    height: auto !important;
}