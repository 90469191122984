@import "../../assets/css/theme/variables";

.cal-filter-search-block {
  .search-blc {
    width: 220px;
    input {
      width: 100%;
      background-color: #e4e4e447 !important;
      border-color: #e4e4e447 !important;
      font-size: 14px;
      border-radius: 4px;
      box-shadow: unset !important;
      height: 37px;
      &:focus {
        box-shadow: unset !important;
      }
    }
  }
}

.user-placeholder span {
  font-size: 20px !important;
}
.calendar-wrapper {
  table {
    table-layout: fixed;
    width: 100%;
    min-width: 800px;

    tbody {
      tr {
        &:hover {
          td {
            background-color: transparent;
          }
        }

        td {
          position: relative;
          border: 1px solid #eeeef2;
          padding: 10px;
          font-size: 13px;

          &:hover {
            background-color: #f2f2fb !important;
          }

          &.no-hover {
            background-color: transparent !important;
          }
          &.current-day-cell {
            background-color: #f2f2fb !important;
            .btn.add-slot-btn {
              color: #d9d9e9;
              &:hover {
                color: $primary;
              }
            }
          }
          .data-container.week-vw {
            min-height: calc(100vh - 28rem);

            &.blank-slot {
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            }

            &.add-slot-btn-wrapper {
              padding: 0;
            }
          }

          .day-no {
            top: 5px;
            right: 5px;
            font-size: 13px;
            &.disable {
              color: #ccc;
            }
            &.current {
              width: 22px;
              height: 22px;
              background-color: $primary;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .calendar-no-data-rw {
    &:hover {
      background-color: transparent;
      td {
        &:hover {
          background-color: transparent !important;
        }
      }
    }
    .not-data-found-blc {
      > .inner {
        max-width: 210px;
        width: 100%;
        h2 {
          font-size: 18px;
        }
      }
    }
  }
}

.btn-blc {
  .appointment-btn {
    color: #282e65;
    min-width: 110px;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 8px;
    border: 0.5px solid #788c9f;
    //box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.14);
    background-color: transparent;
    padding: 4px 14px;
    height: 40px;
  }
  .live-btn {
    color: #282e65;
    min-width: 110px;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 8px;
    border: 0.5px solid #788c9f;
    //box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.14);
    background-color: transparent;
    padding: 4px 14px;
    height: 40px;
  }
  .viewrnd-btn {
    border-radius: 100px;
    background: #9584fc;
    height: 22px;
    padding: 0px 20px;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    border: 1px solid #9584fc;
    color: #fff;
  }
  .add-btn {
    height: 40px !important;
  }
}
