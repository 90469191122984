
@import "../../../assets/css/theme/variables";

.stats-item {
    background-color: #f8f8f8;
    border-radius: 20px;
    padding: 12px 16px;
    > .head {
        margin-bottom: 10px;
        h3 {
            font-size: 24px;
            font-weight: 700;
            line-height: 33px;
            margin: 0;
        }
        .btn {
            font-weight: 400;
            font-size: 13px;
            line-height: 150%;
            text-transform: capitalize;
            padding: 4px;
        }
    }
    > .inner {
        h4 {
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            margin: 0;
        }
    }
    background-image: url("../../../assets/img/vetRecorEMR/stat-left-bg.png"),
        url("../../../assets/img//vetRecorEMR/stat-right-bg.png");
    background-position: left top, right bottom;
    background-size: 80px, 120px;
    background-repeat: no-repeat, no-repeat;
}