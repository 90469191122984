* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.treatment_plan_add {
    width: 900px !important;

    .offcanvas-header {
        padding: 15px 10px 10px 10px;

        .btn-close {
            opacity: 1;
            margin-right: 6px;
            scale: 0.9;
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") !important;

            &:focus {
                box-shadow: none;
            }
        }

        .offcanvas-title {
            font-size: 24px;
            color: #282E65;
            line-height: 32.74px;
        }
    }

    .offcanvas-body {

        padding: 10px 16px 16px 16px;

        .serach_header .search-blc2 {
            height: 40px;
            max-width: 400px;
            width: 100%;
        }

        .serach_header {
            .search-blc2 {
                .form-control {
                    border-radius: 10px;
                    background-color: #EEEEF2;
                    height: 100%;
                    border: 0;
                    color: #788693;

                    &:focus {
                        border: 0;
                        box-shadow: none !important;
                    }
                }

                span {
                    svg {
                        color: #788693;
                    }
                }
            }

            .btn-success {
                height: 28px;
                padding: 0;
                font-size: 13px;
                line-height: 15.73px;


                &:focus,
                &:hover {
                    border-color: #11ca9d !important;
                    background-color: #11ca9d !important;
                }
            }


        }

        .instructions_box {
            margin: 10px 0;

            .instructions_row_box {
                background-color: #F8F8F8;
                padding: 10px;
                border-radius: 10px;

                .row {
                    position: relative;

                    .instructions_left {
                        .badge {
                            background: #E9E9E9 !important;
                            color: #788693 !important;
                            font-size: 13px;
                            line-height: 15.73px;
                            font-weight: 400;
                        }

                        .search_group {
                            position: relative;
                            height: 48px;
                            max-width: 317px;
                            width: 100%;

                            span {
                                position: absolute;
                                top: 50%;
                                color: #788693;
                                transform: translateY(-50%);
                                right: 10px;

                                svg {
                                    font-size: 17px;
                                }

                                label {
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 21px;
                                    cursor: pointer;
                                    color: #3D3EFD;
                                }
                            }

                            .form-control {
                                margin: 10px 0;
                                width: 100%;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 24px;
                                color: #282E65;

                                &::placeholder {
                                    color: #788693;
                                }

                                &:focus {
                                    box-shadow: none !important;
                                }
                            }
                        }

                        p {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 21px;
                            color: #788693;
                        }

                        .add_fils {

                            .file_box {
                                width: 50px;
                                height: 50px;
                                border-radius: 10px;
                            }

                            .add_file_btn {
                                width: 50px;
                                height: 50px;
                                border-radius: 10px;
                                border: 1px dotted rgba(120, 140, 159, 0.5);
                                font-size: 19px;
                                color: #11ca9d;
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                overflow: hidden;
                                justify-content: center;
                                position: relative;

                                input {
                                    opacity: 0;
                                    width: 100%;
                                    height: 100%;
                                    cursor: pointer;
                                    position: absolute;
                                }
                            }
                        }
                    }

                    .instructions_right {
                        display: flex;
                        gap: 7px;
                        flex-direction: column;

                        .info_box {
                            display: flex;
                            min-height: 25px;

                            .title {
                                max-width: 150px;
                                width: 100%;

                                label {
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 21px;
                                    color: #282E65;

                                    span {
                                        color: #DE2B2B;
                                    }
                                }


                            }

                            .add_info {
                                max-width: 304px;
                                width: 100%;

                                .form-control{
                                    padding: 10px !important;
                                    min-height: 42px !important;
                                }

                                textarea{
                                    width: 100%;
                                    border-radius: 10px;
                                    padding: 10px;
                                    border: 1px solid rgba(8, 8, 41, 0.1);
                                    &:focus{
                                        border-color: #3d3efd;
                                        box-shadow: 0 0 0 0.2rem rgba(61, 62, 253, 0.2) !important;
                                    }
                                }
                                p {
                                    color: #282E65;
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 21px;
                                    margin: 0;
                                }

                                label {
                                    color: #282E65;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #3D3EFD;
                                    line-height: 21px;
                                    cursor: pointer;
                                }


                            }
                        }


                    }

                    .delete_btn {
                        position: absolute;
                        top: 0;
                        padding: 0;
                        cursor: pointer;
                        right: 12px;
                        width: 24px;
                        color: #788C9F;
                        height: 24px;

                        img {
                            object-fit: contain;
                        }
                    }
                }





            }


            .btn-primary {
                font-weight: 600;
            }

            .btn-cancel {
                background-color: #fff !important;
                border: 1px solid rgba(9, 9, 84, 0.12);
                color: #282E65 !important;
                font-weight: 600;

                &:focus,
                &:hover {
                    border-color: rgba(9, 9, 84, 0.12) !important;
                }
            }


        }
        .file-delete-icon{
            position:absolute;
            top: 0;
            left: 0;
            cursor: pointer;
        }

    }
}



@media(max-width:768px) {
    .treatment_plan_add {
        .offcanvas-header {
            .offcanvas-title {
                font-size: 20px;
            }
        }

        .offcanvas-body {


            .serach_header {
                gap: 10px;

                .search-blc2 {
                    max-width: 100%;
                }



            }

            .instructions_box {
                .instructions_row_box {
                    .instructions_left {
                        .search_group {
                            max-width: 100% !important;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width:420px) {
    .treatment_plan_add {
        .offcanvas-header {
            .offcanvas-title {
                font-size: 17px;
            }
        }

    }
}