@import "../../../../../../assets/css/theme/variables";

.recommend-product-block {
    > .inner {
        padding: 0 5px;
        .product-item-rw {
            margin: 0 -5px;
            .item-col {
                width: 33.3%;
                padding: 0 5px;
                margin-bottom: 20px;
                * {
                    cursor: pointer;
                }
            }
        }
    }
}
