#pet-owner-pet-services-container {
   
        padding: 15px 10px 10px 10px;
        height: 100%;
        gap: 10px;
        display: flex;
        flex-direction: column;
        .top-search-block {
          width: 50%;
          position: relative;
          svg {
            position: absolute;
            left: 12px;
          }
          input {
            background-color: #f8f8f8;
            height: 40px;
            padding-left: 50px;
            width: 100%;
            border-radius: 10px;
            font-size: 16px;
            font-weight: 400;
            color: #788693;
            ::placeholder {
              color: #788693;
            }
          }
        }
      
}
