@import "../../../../assets/css/theme/variables";
.appointment-list-view {
    .time-cell-wrapper {
        .time-cell {
            width: 75px;
            height: 26px;
            line-height: 26px;
            background: #f8f8f8;
            padding: 3px;
            margin: 0 5px 5px 0;
            color: $base-color-private;
            border-radius: 5px;
            &:hover,
            &:focus,
            &:focus:active,
            &.active {
                color: $primary;
                background-color: rgba(61, 62, 253, 0.08);
            }
            &:disabled {
                background: #f8f8f8;
                color: rgba(120, 140, 159, 0.54);
                opacity: 1;
            }
        }
    }
}
