@import "../../../assets/css/theme/variables";

.chat-panel {
  height: 90%;
  padding: 13px;
  font-size: 14px;

  .chat-bubble {
    font-size: 16px;
  }

  .chat-date {
    text-wrap: nowrap;
  }
  .chater-name {
    text-wrap: nowrap;
  }
}

.chat-box-tray {
  display: flex;
  align-items: baseline;
  align-items: center;
  margin-top: 15px;
  bottom: 0;

  border-radius: 8px;
  border: 1px solid rgba(9, 9, 84, 0.12);

  font-size: 16px;
  // input {
  //   padding: 10px 18px;
  //   border-radius: 8px;
  //   border: 1px solid rgba(9, 9, 84, 0.12);
  //   width: 100%;
  // }
  .private-Message-input {
    padding: 10px 18px;
    width: 100%;
    background: transparent;
  }
  .message-type {
    padding: 10px 5px 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-right: 1px solid rgba(9, 9, 84, 0.12);
    color: #282e65;
    font-size: 13px;
  }

  i {
    color: grey;
    font-size: 30px;
    vertical-align: middle;

    &:last-of-type {
      margin-left: 25px;
    }
  }
}
.appointment-card-in-input {
  position: absolute;
  bottom: 80%;
  width: calc(100% - 10px);
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: rgba(216, 216, 216, 0.5);
  overflow-y: auto;
  .add_files {
    .file_box {
      width: 100px;
      min-width: 100px;
      height: 100%;
      aspect-ratio: 1 /1;
    }
  }
}
.attachment-btn {
  display: flex;
  cursor: pointer;
  overflow: hidden;
  justify-content: center;
  position: relative;
}

.attachment-btn {
  #myfile {
    position: absolute;
    opacity: 0;
    z-index: 10;
  }
}

.file_box {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.file-delete-icon {
  position: absolute;
  top: 0;
  right: 0;
}
.add_files {
  display: flex;
  gap: 10px;
  padding: 15px;
  height: 100%;
}
