@import "../../../../assets/css/theme/variables";

#medical-history-container {
  height: calc(100vh - 184px);
  overflow: auto;
  .conditon-box {
    background: #f8f8f8;
    padding: 10px;
    border-radius: 10px;
    > .head {
      margin-bottom: 15px;

      > .left-col {
        h4 {
          font-size: 16px;
          font-weight: 400;
          line-height: 150%;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &.brief_col {
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          margin-bottom: 15px;
          color: #282e65;
        }
      }

      > .right-col {
        .btn {
          color: $base-color;
          padding: 5px 15px;

          &:hover,
          &:focus {
            color: $primary;
          }

          &::after {
            content: "";
            position: absolute;
            right: 0;
            height: 14px;
            width: 2px;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: rgba(0, 0, 0, 0.1);
          }

          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }
    }

    > .inner {
      .breif-head-info {
        margin-bottom: 15px;

        .item-cell {
          border: 1px solid rgba(40, 46, 101, 0.1);
          padding: 6px 7px;
          border-radius: 8px;
          margin-right: 10px;
          margin-bottom: 5px;
          font-weight: 400;
          font-size: 13px;
          line-height: 150%;

          .pic {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }

          .txt {
            margin-right: 8px;
          }

          .icon {
            span {
              font-size: 16px;
            }
          }

          .status {
            border: 0.5px solid rgba(255, 255, 255, 0.2);
            border-radius: 3px;
            padding: 0px 5px;

            &.inprogress {
              background: #def1ff;
              color: #229cf4;
            }
          }
        }
      }

      .breif-desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 15px;
      }

      .thumbnail-list {
        span {
          width: 50px;
          height: 50px;
          border-radius: 10px;
          margin-right: 4px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .vaccination-listing {
    width: 96vw;
    overflow: scroll;
  }
}
