.medication-plan-item {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 10px;

  > .head {
    transition: all ease 0.3s 0s;

    margin-bottom: 12px;

    h2,
    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    h2 {
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 6px;
    }

    h3 {
      font-size: 13px;
      font-weight: 400;
      line-height: 150%;
      color: #788693;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .chart-blc {
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 15px;

    .chart-item {
      width: 60px;
      margin-right: 15px;
    }
  }

  .treat-by-cell {
    border: 1px solid rgba(40, 46, 101, 0.1);
    padding: 6px 7px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    max-width: 100%;

    .pic {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    .txt {
      margin-right: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .icon {
      span {
        font-size: 16px;
      }
    }

    .status {
      border: 0.5px solid rgba(255, 255, 255, 0.2);
      border-radius: 3px;
      padding: 0px 5px;

      &.inprogress {
        background: #def1ff;
        color: #229cf4;
      }
    }
  }
}

.medication-status-container {
  width: 25%;

  // .form-control {
  //   width: 100%;
  //   font-size: 16px;
  //   height: 40px;
  //   border-radius: 8px;
  //   color: #1d1d1d;
  //   padding: 0.375rem 15px;
  //   background-color: #fff;
  //   border: 1px solid rgba(8, 8, 41, 0.1);
  // }
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  select::-ms-expand {
    display: none;
  }

  .status {
    width: fit-content;
    box-sizing: border-box;
    text-align: center;
    /* Tags/12 M */
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 4px;
    gap: 10px;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    border-radius: 3px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .status-Ordered {
                  
    color: #9428FF;
   
    background:#EBE7FF;

}

  .status-Completed {
    color: #169677;

    background: #dbf8f1;
  }

  .status-Stopped {
    color: var(--main-buttons, #de2b2b);

    background: #ffecf0;
  }

  .status-Active {
    color: var(--main-buttons, #229cf4);

    background: #def1ff;
  }
}

.medication-plan-offcanvas {
  .offcanvas-title {
    color: #282e65;
    font-size: 23px;
    font-weight: 700;
  }

  .btn-close {
    opacity: 1 !important;
    position: relative;
    right: 2px;
    top: -10px;

    &::before {
      position: absolute;
      top: 7px;
      right: 15px;
      content: " ";
      height: 20px;
      width: 2px;
      background-color: #282e65;
      transform: rotate(45deg);
    }

    &::after {
      position: absolute;
      top: 7px;
      right: 15px;
      content: " ";
      height: 20px;
      width: 2px;
      background-color: #282e65;
      transform: rotate(-45deg);
    }
  }

  .profile-cell {
    .pic {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    .txt {
      margin-right: 8px;
      color: #282e65;
      font-size: 14px;
    }

    .icon {
      span {
        font-size: 16px;
      }
    }
  }

  &.x-ray-reports {
    color: #282e65;
    width: 1000px !important;

    .card-heading {
      font-size: 24px;
      font-weight: 600;
    }
    .card-subheading {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
    .doctor-notes-heading {
      font-size: 18px;
      font-weight: 600;
    }
    .doctors-msgs {
      height: 50vh;
      overflow-y: auto;
      overflow-x: hidden;
      flex-grow: 1;
    }
  }
}

.medication-profilee {
  h4 {
    color: #282e65;
    font-size: 18px;
    font-weight: 600;
  }

  p {
    color: #282e65;
    font-size: 14px;
    font-weight: 400;
  }
}

.state-wrapper-stopped {
  border-radius: 3px;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  background: #ebebeb;
  color: #464646;
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 2px 8px;
}

.state-wrapper-completed {
  border-radius: 3px;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  background: #dbf8f1;
  color: #169677;
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 2px 8px;
}

.p-medication-desc {
  color: #282e65;
  font-size: 14px;
  font-weight: 400;
}

.medication-list-wrapper {
  color: #282e65;
  font-size: 16px;
  font-weight: 400;

  .taken-steps {
    color: #788c9f;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .not-taken-steps {
    color: #dc3545;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .note-container {
    color: #788c9f;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  .taken-button-container {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  ul.outer-list-items {
    font-size: 14px;

    &::before {
      position: absolute;
      left: 8px;
      top: 18px;
      content: "";
      border-left: 1px dashed #c4c8cc;
      height: calc(100% - 10px);
    }

    li.list-outer::before {
      position: absolute;
      left: -21px;
      top: 5px;
      content: "";
      height: 5px;
      width: 5px;
      border-radius: 50%;
      border: 2px solid #c4c8cc;
      padding: 3px;
      background: #fff;
    }
  }
}

.font-13 {
  font-size: 13px;
}
.child-listt {
  line-height: 26px;
}

.before-meal-wrapper {
  border-radius: 10px;
  background: rgba(248, 248, 248, 0.9);
}
