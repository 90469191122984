@import "../../assets/css/theme/variables";
.back-btn {
  font-family: $secondary-font-2;
  line-height: 1 !important;
  flex-shrink: 0;
  padding: 0 20px 0 0;
  height: 40px;
  text-transform: uppercase;
  * {
    cursor: pointer;
  }
  .icon {
    font-size: 15px;
    margin-right: 12px;
    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
  label {
    font-size: 13px;
    font-weight: 500;
  }
}
