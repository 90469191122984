@import "../../../assets/css/theme/variables";

.label-dropdown-blc {
  max-width: 180px;
  width: 100%;
  margin-right: 10px;
  .close-btn {
    top: 1px;
    right: 1px;
    bottom: 1px;
    width: 26px;
    border-radius: 5px;
    background-color: #fff;
  }
  .input-blc-wrapper {
    padding-right: 30px;
  }
  .input-blc {
    .form-control {
      height: 31px;
      padding: 5px 8px;
      padding-right: 30px;
      width: 100%;
      background-size: 10px;
      border-color: #e1e5ed;
      color: #6b6b6b;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &::placeholder {
        color: #a1a7b3;
      }
    }
  }
  .css-f91fgu {
    font-family: $primary-font;
    font-weight: 400;
    box-shadow: 0 0 10px #cdcdcd;
    background-color: #ffffff;
    width: 300px;
    position: absolute;
    top: 100%;
    left: 0;
    padding: 10px;
    border-radius: 5px;
    max-height: 300px;
    overflow: auto;
    z-index: 2;
    .css-1u94gzr {
      margin: -12px 0 0 -7px;
    }
    .css-1u94gzr:after {
      content: "";
      border: 6px solid transparent;
      border-left: 6px solid #a3a3a3;
      display: inline-block;
    }
    .css-1u94gzr > svg {
      display: none;
    }
    .css-79elbk {
      > div {
        display: flex !important;
        background-color: transparent !important;
      }
      .css-80f7ae {
        color: $base-color;
      }
      .css-1l0glfn {
        fill: #686868;
      }
      .sd-header {
        color: #555;
        font-weight: 500 !important;
      }
      .sd-childs {
        position: relative;
        display: flex;
        align-items: center;
        color: #555;
        padding: 2px 0;
        &:before {
          content: "";
          display: inline-block;
          border: 4px solid transparent;
          border-left: 5px solid #a3a3a3;
        }
      }
    }
  }
}

.label-dropdown-blc
  .css-f91fgu
  .css-79elbk
  .css-1s1o8u8
  .css-79elbk
  .css-1s1o8u8
  .css-79elbk
  .css-1s1o8u8
  .css-79elbk:last-child
  .sd-childs:before {
  border: none;
  width: 6px;
  height: 6px;
  background: #a3a3a3;
  border-radius: 50%;
  margin-right: 3px;
  position: relative;
  left: 0;
}

// SPACE
.section-space {
  margin-bottom: 30px;
}

// Calendar Input Package
.react-datepicker-popper {
  width: 100%;
  .react-datepicker,
  .react-datepicker__month-container {
    width: 100%;
  }
  .react-datepicker__week {
    display: flex;
  }
  .react-datepicker__day {
    flex: 1;
    font-size: 14px;
  }
  .react-datepicker__navigation {
    top: 7px;
  }
}

// Custom Weight Dropdown
.weight-dropdown {
  .weight-dropdown-rw {
    height: 60px;
    border-radius: 10px;
    color: #080829;
    background-color: #fff;
    border: 1px solid rgba(8, 8, 41, 0.1);
    box-shadow: 0px 4px 10px rgb(61 57 137 / 5%);
    @media (max-width: 1919px) {
      height: 50px;
    }
    > .left-col {
      .form-control {
        background-color: transparent;
        outline: none;
        &:focus {
          box-shadow: none !important;
        }
      }
    }
    > .right-col {
      border-left: 1px solid rgba(8, 8, 41, 0.1);
      margin: 4px 0;
      .btn-group {
        align-self: stretch;
        .dropdown-toggle {
          font-family: "SFProDisplay-Medium";
          font-size: 16px;
          line-height: 19px;
          color: $base-color;
          padding: 0 22px;
          &::after {
            display: none;
          }

          .icon {
            margin-left: 32px;
            font-size: 10px;
            width: auto;
          }
        }
        .dropdown-menu {
          border-radius: 0 0 6px 6px !important;
          border: 1px solid rgba(8, 8, 41, 0.1);
          box-shadow: 0px 4px 10px rgb(61 57 137 / 5%);
          min-width: unset;
          width: 116px;
          padding: 0;
          .dropdown-item {
            padding: 10px 22px;
            font-size: 16px;
            font-family: "SFProDisplay-Medium";
            color: $base-color;
            line-height: 19px;
          }
        }
      }
    }
  }
}

// .inner-page-loader-layout {
//   margin: -28px -50px;
//   @media (max-width: 1919px) {
//     margin: -20px -30px;
//   }
// }
.not-data-found-blc {
  height: 350px;
  > .inner {
    .pic {
      img {
        max-width: 200px;
      }
    }
    h2 {
      font-size: 25px;
      color: $primary;
    }
  }
  &.position-absolute {
    top: 64px;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &.layout-md {
    height: 250px;
    > .inner {
      .pic {
        img {
          max-width: 180px;
        }
      }
      h2 {
        font-size: 18px;
      }
    }
  }
  &.layout-sm {
    height: 180px;
    > .inner {
      .pic {
        img {
          max-width: 160px;
        }
      }
      h2 {
        font-size: 15px;
      }
    }
  }
}

.react-time-picker {
  width: 100%;
  .react-time-picker__wrapper {
    background-color: #fff;
    border: 1px solid rgba(8, 8, 41, 0.1);
    box-shadow: 0px 4px 10px rgb(61 57 137 / 5%);
    height: 60px;
    border-radius: 6px;
    padding: 10px;
    @media (max-width: 1919px) {
      height: 50px;
    }
  }
}

// Form Elements
.input-cell {
  circle,
  path {
    stroke: #666;
  }
}
.input-required {
  color: $danger;
}

// Select Plan Screen

.planPriceWrapper {
  max-width: 558px;
  width: 100%;
  background: #fff;
  border: 1px solid rgba(8, 8, 41, 0.1);
  box-shadow: 0px 4px 10px rgba(61, 57, 137, 0.05);
  border-radius: 15px;
  padding: 25px;
  @media (max-width: 640px) {
    padding: 16px;
  }
  > .inner {
    max-width: 315px;
    width: 100%;
    margin: 0 auto;
    > h2 {
      font-family: "Inter", sans-serif;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.05em;
      margin-bottom: 16px;
      text-align: center;
    }
  }
}

.card-no {
  .form-control {
    padding-right: 70px;
  }
  .icon {
    background: #f5f5f5;
    border: 1px solid #d7d7d7;
    border-radius: 0 5px 5px 0px;
    overflow: hidden;
    margin-left: -58px;
    height: auto;
    width: 58px;
    img {
      width: 36px;
      height: 22px;
    }
  }
}

// Auto Populate Address Form
.mbx1837d5a4--Results {
  max-height: 260px;
}

// Tooltip
.custom-tooltip-cell {
  margin-left: 5px;
}
.custom-dynamic-tooltip-cell {
  text-align: center;
  max-width: 200px;
  white-space: normal;
  p {
    border: 1px solid red;
    margin-bottom: 6px;
    &:empty {
      display: none;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  p:last-child {
    margin-bottom: 0;
  }
}

// For Custom Scrollbar

@media (min-width: 798px) {
  // Chrome
  // ::-webkit-scrollbar {
  //   width: 7px;
  //   height: 7px;
  // }
  // ::-webkit-scrollbar-track {
  //   background-color: #fafafa;
  // }
  // ::-webkit-scrollbar-thumb {
  //   background-color: #ccc;
  //   border-radius: 6px;
  // }
  // // Firefox
  // .overflow-auto {
  //   scrollbar-width: thin;
  //   scrollbar-color: #ccc #fff;
  // }
}

// Address raectjs-package Dropdown in Modals.
mapbox-search-listbox {
  position: absolute;
  z-index: 10000 !important;
}

.waiting-room-blc {
  padding: 40px;
  @media (max-width: 1919px) {
    padding: 20px;
  }
  > .head {
    margin-bottom: 50px;
    @media (max-width: 480px) {
      margin-bottom: 0;
      text-align: center;
    }
    h2 {
      font-size: 40px;
      font-weight: 700;
      color: #4f4db6;
      @media (max-width: 1919px) {
        font-size: 30px;
      }
      @media (max-width: 950px) {
        font-size: 25px;
      }
      @media (max-width: 480px) {
        font-size: 20px;
      }
    }
    p {
      font-size: 25px;
      color: #1d1f20;
      @media (max-width: 1919px) {
        font-size: 22px;
      }
      @media (max-width: 950px) {
        font-size: 20px;
      }
      @media (max-width: 480px) {
        font-size: 18px;
      }
    }
  }
  > .inner {
    .man-aimation-blc {
      text-align: center;
      height: 100%;
      @media (max-width: 850px) {
        height: 400px;
      }
      @media (max-width: 480px) {
        margin-top: -50px;
      }
    }
  }
  .time-block {
    top: 160px;
    left: 50px;
    width: 190px;
    z-index: 2;
    @media (max-width: 1919px) {
      width: 150px;
      top: 110px;
    }
    @media (max-width: 1200px) {
      top: 150px;
    }
    @media (max-width: 850px) {
      width: 120px;
    }
    @media (max-width: 480px) {
      position: static !important;
      width: 100%;
    }

    lottie-player {
      @media (max-width: 480px) {
        width: 120px;
        margin: 0 auto;
      }
    }
    img {
      width: 202px;
    }
  }
}

// Firefox Select Font-Family
select,
select.form-control {
  font-family: "Source Sans Pro", sans-serif;
}

.overflow-auto {
  -webkit-overflow-scrolling: touch;
}

// Custom Select Dropdown Package
.select-clinic-refer__menu {
  max-height: 200px;
  overflow: auto;
  .select-clinic-refer__menu-list {
    max-height: none;
    overflow: visible;
  }
}
// #react-select-2-listbox,
// #react-select-3-listbox {
//   max-height: 160px;
//   overflow: auto;
// }

// listing-search-btn
// .list-srch-block2 {
//   height: 60px;
//   @media (max-width: 1919px) {
//       height: 50px;
//   }
//   .input-wrapper {
//       margin-bottom: 0;
//       .form-control {
//           height: 100%;
//           padding-right: 70px;
//       }
//   }
//   .reset-btn {
//       top: 1px;
//       right: 1px;
//       bottom: 1px;
//       background-color: #fefefe;
//       border-left: 1px solid #e4e4e8;
//       font-size: 18px;
//       border-radius: 0 6px 6px 0;
//       &:hover {
//           background-color: #f1f1f1;
//       }
//   }
// }

// ***************** TABLE LISTING ***************** //
// Filter Section
div {
  .listing-filter-block {
    margin-bottom: 35px;
    @media (max-width: 1919px) {
      margin-bottom: 25px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }
    > .left-col {
      margin-right: 20px;
      @media (max-width: 767px) {
        width: 100%;
        margin: 0 0 10px 0;
      }
      .input-wrapper {
        .input-cell {
          width: 100%;
        }
      }
    }
    > .right-col {
      @media (max-width: 767px) {
        width: 100%;
        flex-direction: column;
        align-items: stretch !important;
      }
      .input-col {
        margin-left: 11px;
        @media (max-width: 510px) {
          margin-left: 0;
        }
      }
      .filter-input-cell {
        @media (max-width: 767px) {
          margin-bottom: 10px;
          margin-left: 0%;
          flex-direction: column;
          align-items: stretch !important;
        }
        .filter-input {
          min-width: 253px;
          @media (max-width: 1919px) {
            min-width: 190px;
          }
        }
        label {
          font-family: "Inter", sans-serif;
          color: rgba($base-color, 0.5);
        }
      }
      .paging-dropdown {
        min-width: 219px;
        @media (max-width: 1919px) {
          min-width: 150px;
        }
      }
    }
    .list-srch-block {
      height: 60px;
      width: 485px;
      @media (max-width: 1919px) {
        height: 50px;
        width: 300px;
      }
      @media (min-width: 992px) and (max-width: 1050px) {
        width: 280px;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
      .input-wrapper {
        margin-bottom: 0;
        .form-control {
          height: 100%;
          padding-right: 70px;
        }
      }
      .reset-btn {
        top: 1px;
        right: 1px;
        bottom: 1px;
        background-color: #fefefe;
        border-left: 1px solid #e4e4e8;
        font-size: 18px;
        border-radius: 0 6px 6px 0;
        &:hover {
          background-color: #f1f1f1;
        }
      }
    }
  }
}

// PET EDIT DETAIL
.add-doc-rw {
  padding-bottom: 25px;
}

// Change-Password Input Messages
.password-warning {
  color: #f30909;
  margin-bottom: 20px;
  font-size: 14px;
  .icon {
    flex-shrink: 0;
    margin-right: 12px;
    svg {
      width: 18px;
    }
  }
  .txt {
    font-size: 14px;
    font-family: "SFProDisplay-Medium";
    line-height: 18px;
  }
}

//Thumb icon flip style
span.icomoon-thumb-up.down {
  transform: rotate(90deg);
  display: inline-flex;
}


