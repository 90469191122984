@import "../../../assets/css/theme/variables";

.table {
  &.no-data-table {
    >tbody>tr:nth-of-type(odd)>* {
      --bs-table-accent-bg: transparent;
      background: transparent;
    }

    .no-data-rw {
      pointer-events: none;
    }
  }

  a {
    text-decoration: none;
  }

  tr {
    &:hover {
      background: rgba(228, 228, 228, 0.28);
    }

    th,
    td {
      font-size: 14px;
      padding: 7px 4px 7px 13px;
      vertical-align: middle;
    }

    th {
      font-weight: 400;
      color: #788c9f;
      white-space: nowrap;
      text-transform: capitalize;
      border-bottom: 1px solid #eeeef2;

      &.adress-th-cell {
        min-width: 250px;
      }

      &.date-th-cell {
        min-width: 142px;
      }
    }

    td {
      font-size: 14px;
      word-wrap: break-word;

      .pic-cell {
        color: $base-color-private;
        text-transform: capitalize;

        &.pic-cell-type-2 {
          .pic {
            width: 32px;
            height: 32px;
            background-color: #fff;
            border-radius: 10px;
            padding: 2px;

            img {
              border-radius: 10px;
            }
          }
        }

        .pic {
          margin-right: 8px;
          width: 20px;
          height: 20px;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }

          .badge-icon {
            top: -4px;
            right: -3px;
          }

          .img-placeholder {
            span {
              svg {
                width: 16px;
              }
            }
          }
        }

        &.text-cell {
          &:hover {
            color: $base-color-private;
          }
        }

        &.btn {
          color: $primary;

          &:hover {
            color: $primary;
          }
        }
      }

      .time {
        color: rgb(156, 156, 156);
        margin-left: 4px;
      }
    }
  }

  > :not(:first-child) {
    border-top: none;
  }

  > :not(caption)>*>* {
    border-bottom-color: rgba($base-color, 0);
  }

  .action-cell {
    // min-width: 100px;
    white-space: nowrap;

    .btn {
      padding: 6px;
      color: #788c9f;

      &:hover,
      &:focus {
        color: $primary;
      }

      &.edit-btn {
        font-size: 16px;
      }
    }

    .btn-group {
      .btn {
        font-size: 5px;
        border-radius: 0px 8px 8px 0px !important;
      }

      .dropdown-menu.show {
        display: block;
        border: 1px solid rgba(9, 9, 84, 0.12);
        border-radius: 5px;

        .dropdown-item {
          font-size: 13px;
        }
      }
    }
  }

  .status-cell {
    padding: 2px 6px;
    background: #def1ff;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    color: #229cf4;
    border-radius: 3px;

    &.inactive-cell {
      background-color: rgba($danger, 0.15);
      color: $danger;
    }

    &.success-cell {
      background-color: rgba($success, 0.15);
      color: $success;
    }

    &.pending-cell {
      background-color: rgba(118, 96, 255, 0.15);
      color: rgba(118, 96, 255, 1);
    }
  }

  thead {
    .sort-btn {
      padding: 0;
      color: rgba(120, 140, 159, 1);
    }

    .checkbox-wrapper {
      &.bg-color-1 {
        .checkbox-cell input:checked+.icon-box {
          background-color: #e0ce2a;
          border-color: #e0ce2a;
        }
      }

      .txt {
        font-size: 14px;
        color: #788c9f;
      }
    }

    &.table-soft-priamry {

      th,
      td {
        background-color: rgba($primary, 0.05);
        color: $base-color;
      }
    }
  }

  thead,
  tbody {
    .checkbox-wrapper {
      margin-top: 4px;
    }
  }

  &.table-cell-height {

    thead th,
    tbody td {
      padding: 17px 26px;
    }
  }

  &.checkbox-listing {
    tr {

      th,
      td {
        &:first-child {
          width: 40px;
          padding-right: 0;
        }
      }
    }
  }

  .text-clip-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.table-striped {
  >tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: rgba(228, 228, 228, 0.28);
    background: transparent;
  }

  &.bg-striped {
    >tbody>tr:nth-of-type(odd)>* {
      --bs-table-accent-bg: #fff;
      background: #fff;
    }
  }
}

.accordtion-table .table-striped {
  >thead>tr{
    --bs-table-accent-bg: rgba(228, 228, 228, 0.28);
  }
}