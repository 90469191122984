.note-detail-wrapper {
    .note-content-block {
        &.content-box {
            background: #f8f8f8;
            padding: 15px;
            border-radius: 10px 10px 10px 10px;
            margin-bottom: 5px;
        }
        > h2 {
            font-size: 18px;
            font-weight: 600;
            line-height: 150%;
            margin-bottom: 10px;
        }
        > h3 {
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
        }
        > .inner {
            .items-info {
                .item-rw {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 150%;
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .title {
                        width: 150px;
                        flex-shrink: 0;
                        margin-right: 15px;
                    }
                    .desc {
                        p {
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                        .bullet-items {
                            ul {
                                padding: 0 0 0 20px;
                            }
                        }
                        .pic-cell {
                            .pic {
                                width: 20px;
                                height: 20px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}


.notes-panel-wrapper {
    .note-content-block {
        &.content-box {
            background: #f8f8f8;
            padding: 15px;
            border-radius: 10px 10px 10px 10px;
            margin-bottom: 5px;
        }
        > h2 {
            font-size: 18px;
            font-weight: 600;
            line-height: 150%;
            margin-bottom: 10px;
        }
        > h3 {
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
        }
        > .inner {
            .items-info {
                .item-rw {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 150%;
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .title {
                        width: 150px;
                        flex-shrink: 0;
                        margin-right: 15px;
                    }
                    .desc {
                        p {
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                        .bullet-items {
                            ul {
                                padding: 0 0 0 20px;
                            }
                        }
                        .pic-cell {
                            .pic {
                                width: 20px;
                                height: 20px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
