@import "./_variables.scss";

.form-control {
  font-size: 16px;
  height: 48px;
  border-radius: 8px;
  color: $base-color;
  padding: 0.375rem 15px;
  background-color: #fff;
  border: 1px solid rgba(8, 8, 41, 0.1);
  box-shadow: 0px 4px 10px rgba(61, 57, 137, 0.05);

  &:focus {
    color: $base-color;
    box-shadow: 0 0 0 0.2rem rgba($primary, 0.2) !important;
    border-color: $primary;

    &:hover {
      border-color: $primary;
    }
  }

  &::placeholder {
    color: #788693;
  }

  &:-moz-placeholder {
    color: #788693;
  }
}

textarea.form-control {
  height: 128px;
}

.invalid-feedback {
  font-size: 16px;
}

// Form Group
.form-group {
  margin-bottom: 20px;

  label,
  .label {
    margin-bottom: 6px;
    color: rgba($base-color, 0.75);
  }

  .label {
    .btn-link {
      padding: 0 !important;
      font-size: 13px;
    }
  }

  label {
    .sml-txt {
      font-size: 12px;
      font-family: "SFProDisplay-Medium";
      line-height: 15px;
      color: rgba($base-color, 0.5);
      margin-left: 4px;
    }
  }
}

textarea.form-control {
  height: 200px;
}

.phoneInputRequired {
  display: flex;
  font-size: 13px;
  color: $danger;
  margin-bottom: 15px;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background: none;
  padding-right: 15px;
}

.input-wrapper {
  .required {
    &:after {
      content: "*";
      color: $danger;
      margin-left: 2px;
    }
  }

  .input-cell {
    .icon {
      width: 55px;
      font-size: 26px;
      color: rgba(64, 77, 97, 0.5);
    }

    .show-pass-btn {
      right: 0;
      top: 0;
      color: $base-color;

      &:hover,
      &:focus {
        color: $base-color;
      }
    }

    &.icon {
      .form-control {
        padding-left: 58px;
      }
    }

    &.iconButton {
      .form-control {
        padding-right: 55px;
      }
    }

    &.forgot-pass-link-blc {
      .form-control {
        padding-right: 77px;
      }

      .forgot-pass-link {
        top: 0;
        right: 15px;
        bottom: 0;
        font-size: 16px;
        font-weight: 400;
        text-transform: capitalize;
      }
    }
  }

  &.weight-dropdown {
    >.left-col {
      margin-right: 20px;
    }
  }

  &.date-wrapper {
    .date-cell {
      .react-datepicker__input-container {
        .form-control {
          padding-right: 40px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &::after {
          content: "";
          font-size: 22px;
          position: absolute;
          top: 0;
          right: 8px;
          bottom: 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          background: url("../../../assets/img/icons/calendar.svg") calc(100% - 6px) center no-repeat;
          background-size: 17px;
        }
      }

      .icon {
        top: 0;
        right: 0;
        bottom: 0;
        width: 56px;
        font-size: 23px;
        display: none !important;
      }
    }
  }

  &.email {
    .input-cell {
      .icon {
        font-size: 21px;
      }
    }
  }
}

.required-input {
  &:after {
    content: "*";
    color: $danger;
    margin-left: 1px;
  }
}

.input-wrapper2 {
  .form-floating {
    height: 48px;
    border-radius: 10px;

    >.form-control {
      padding: 10px 16px 0;
      background: #fff;
      border: 1px solid rgba(9, 9, 84, 0.12);
      font-size: 14px;
      font-weight: 400;
      height: 100%;
      box-shadow: none;
    }

    >label {
      display: inline-flex;
      align-items: center;
      padding: 0;
      width: auto;
      transform: scale(0.97) translateY(1px) translateX(19px);
      font-weight: 500;
      font-size: 11px;
      line-height: 12px;
      color: #788693;
      text-transform: uppercase;
      margin: 0;
    }
  }

  .form-floating>.form-control-plaintext~label,
  .form-floating>.form-control:focus~label,
  .form-floating>.form-control:not(:placeholder-shown)~label,
  .form-floating>.form-select~label {
    opacity: 1;
    transform: scale(0.85) translateY(-9px) translateX(19px);
  }

  &.no-floating {
    .form-floating {
      >.form-control {
        padding: 1rem 0.75rem;
      }
    }
  }
}

.form-group-wrapper {
  margin-bottom: 10px;

  .input-wrapper2 {
    margin-bottom: 0 !important;

    .form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .btn {
    background: #f8f8f8;
    border: 1px solid rgba(9, 9, 84, 0.12);
    border-radius: 0px 8px 8px 0px;
    margin-left: -1px;
    padding: 0;
    width: 64px;
    font-size: 19px;
    color: #788693;

    &:hover,
    &:focus {
      color: $primary;
      border: 1px solid rgba(9, 9, 84, 0.12);
    }

    &.btn-arrow {
      font-size: 8px;
    }
  }

  .btn-group {
    .btn {
      font-size: 8px;
      border-radius: 0px 8px 8px 0px !important;
    }

    .dropdown-menu.show {
      display: block;
      border: 1px solid rgba(9, 9, 84, 0.12);
      border-radius: 5px;

      .dropdown-item {
        font-size: 13px;
      }
    }
  }
}