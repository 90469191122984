@import "../../../../assets/css/theme/variables";

.plan-type-box {
  height: 100%;
  width: 100%;
  .pic {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    img {
      width: 100%;
      //   height: 100%;
    }
  }
  .txt {
    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
    }

    h5 {
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      margin: 1px;
    }
  }
}
