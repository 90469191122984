@import "../../assets/css/theme/variables";

.swtichCell {
  cursor: pointer;
  * {
    cursor: pointer;
  }
  &:hover {
    .switch-btn {
      background-color: $success-dark;
    }
  }
  .switch-btn {
    width: 32px;
    height: 16px;
    background-color: $success;
    padding: 2px 4px;
    margin-right: 6px;
    border-radius: 8px;
    .icon {
      font-family: "icomoon";
      font-size: 7px;
      &::before {
        content: "\e947";
        color: #fff;
      }
    }
    .circle-icon {
      width: 10px;
      height: 10px;
      background-color: #fff;
    }
  }
  .txt {
    min-width: 39px;
    font-size: 13px;
  }
  &.disable {
    &:hover {
      .switch-btn {
        background-color: $danger-dark;
      }
    }
    .switch-btn {
      background-color: $danger;
      .icon {
        margin-left: auto;
        order: 2;
        font-size: 8px;
        margin-right: 1px;
        &::before {
          content: "\e940";
        }
      }
      .circle-icon {
        margin: 0 !important;
      }
    }
  }
}
