@import "../../assets/css/theme/variables";

.browse-file-blc {
    background: #fbfbfc;
    border: 1px dashed rgba(0, 0, 0, 0.2);
    height: 50px;
    padding: 10px 10px;
    border-radius: 6px;
    overflow: hidden;

    .form-control {
        width: 100%;
        height: 100%;
        opacity: 0;
    }
    span {
        font-size: 13px;
        line-height: 16px;
        color: #96a6b8;
        margin-right: 10px;
    }
    .btn {
        height: 24px;
        min-width: 100px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-transform: capitalize;
    }
}
