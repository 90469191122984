@import "../../../assets/css/theme/variables";





.profile-head {
    padding: 15px;

    .title {
        .btn {
            padding: 10px 15px;

            &.back-btn {
                font-weight: 600;
                text-transform: uppercase;
                color: $base-color;
            }

            &.view-user-btn {

                &:hover,
                &:focus {
                    svg path {
                        fill: $primary;
                    }
                }
            }
        }
    }

    .user-profile-cell {
        margin-bottom: 15px;

        .pic {
            width: 80px;
            height: 80px;
            margin-right: 15px;

            >img {
                border-radius: 8px;
            }

            .badge-icon {
                top: -8px;
                right: -8px;
            }

            .pet-placeholder {
                border-radius: 8px;
            }
        }

        .txt {
            h2 {
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
            }

            h3,
            h4 {
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
            }

            h3 {
                font-size: 14px;
                margin-bottom: 5px;
            }

            h4 {
                margin-bottom: 0;

                &.active {
                    color: $success;
                }
            }
        }
    }
  
    .profile-btns {
        margin: 0 -5px;
        padding: 0 5px;

        .btn {
            border: 1px solid rgba(9, 9, 84, 0.12);
            filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.14));
            border-radius: 6px;
            text-transform: uppercase;
            color: $base-color;
            padding: 6px 15px;
            margin: 0 5px;
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;

            &:hover,
            &:focus {
                color: $primary;
                border-color: $primary;
            }
        }
    }
}

.profile-description {
    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 9px;

        label {
            min-width: 144px;
            max-width: 144px;
            margin-right: 6px;
        }
    }

    h5 {
        font-size: 18px;
        color: #282E65;
        font-weight: 600;
    }
}

.petOwnerProfile {
    border-right: 1px solid #eeeef2;
    padding: 0 12px 0 7px;
}

.patient-detail-wrapper {
    .left-col {
        &.petOwnerProfile {
            width: 320px;
        }
    }
}

.petOwnerProfile {
    .profile-description {
        p {
            label {
                min-width: 110px;
                max-width: 110px;
            }
        }
    }
}

.owner_filter_right {
    width: 290px;
}

.outstanding_invoice {
    border-radius: 10px;
    background: #4999C6;
    padding: 12px 20px;

    h4 {
        font-size: 18px;
        color: #fff;
        font-weight: 600;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 15px;
        color: #fff;
        font-weight: 400;
    }

    svg {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translate(0, -50%);
    }
}

.my_pet_box1 {
    border-radius: 10px;
    padding: 10px 15px !important;
    background: rgba(248, 248, 248, 0.90);
    display: flex;
    flex-direction: column;
}

.addner_mypet_box {
    color: #11CA9D;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    border-radius: 10px;
    border: 1px dashed #11CA9D;
    background: #FAFFF9;
    height:511px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.position-relative {
	.tab-pane {
		.my-pets {
			.row {
				.col-md-4 {
					margin-bottom: 1rem;
				}
			}
		}
	}
}



.pet-owner-dashboard {
    overflow-x: hidden !important;
    .main_wrapper1 {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }

  .patient-detail-wrapper{
    overflow-y: auto;
    overflow-x: hidden !important;
  }