#dwv {
    height: 100%;
  }
  
  .button-row {
    text-align: center;
  }
  
  /* Layers */
  .layerGroup {
    position: relative;
    padding: 0 14px;
    display: flex;
    justify-content: center;
    // height: 100%;
    background-color: #000;
    flex-grow: 1;
    overflow: auto;
  }
  
  .layer {
    position: absolute;
    pointer-events: none;
  }
  
  .drawDiv {
    position: absolute;
    pointer-events: none;
  } 
  
  /* drag&drop */
  /* .dropBox {
    margin: auto;
    text-align: center;
    vertical-align: middle;
    width: 50%;
    height: 75%;
  }
  
  .dropBoxBorder {
    border: 5px dashed #e8eaf6;
  } */
  
  /* .dropBoxBorder.hover {
    border: 5px dashed #3f51b5;
  }
  
  #input-file-link {
    text-decoration-line: underline;
  } */
.icon-block-list{
display: flex;
gap: 2px;
margin-top: 20px;
  .img-icon-btn{
display: flex;
gap: 5px;
border: 1px solid #e3e3e3;
padding: 4px 10px;
border-radius: 2px;
cursor: pointer;
    .ic-title{
      font-size: 14px;
      font-weight: 500;
    }
  }
  .active-icon{
    background-color: #e3e3e3;
  }
}