.profile-tags {
    .popover {
        background-color: #fff
    }
    .tag-list {
        button {
            height: 20px;
            display: flex;
            background-color: #e74c3c;
            width: 20px;
            align-items: center;
          }
        span {
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #fff;
            padding: 4px 10px;
            border: 0.5px solid rgba(255, 255, 255, 0.2);
            border-radius: 3px;
            margin-bottom: 10px;
            margin-right: 5px;
            &.orange {
                background: #e75c30;
            }
            &.yellow {
                background: #dd9323;
            }
            &.cyan {
                background: #359cd6;
            }
            &.green {
                background: green;
            }
            
        }
        // span .tag {
        //     font-size: 16px;
        // }
    }
}

.behaviour-tag-font {
    font-size: 16px !important;
}
