.form-floating .form-control {
  padding: 10px 16px 0 !important;
}

.small_text {
  font-size: 13px;
  color: #788693;
  font-weight: 300;
}

.border_btn .add-btn {
  font-size: 13px !important;
  font-weight: 600 !important;
  min-width: 140px;
}
.border_btn .btn.border-btn {
  border: 1px solid rgba(9, 9, 84, 0.12);
  filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.14));
  border-radius: 6px;
  text-transform: uppercase;
  color: #1d1d1d;
  padding: 6px 19px;
  margin: 0;
  font-size: 13px;
  font-weight: 600;
  min-width: 140px;
}/*# sourceMappingURL=index.css.map */