@import "../../../../../../assets/css/theme/variables";

#vitals-container {
  .collapse {
    overflow-x: auto;
  }
  .title-head-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-block {
      font-size: 18px;
      font-weight: 600;
      color: $base-color-private;
    }
    .icomoon-plus-btn {
      cursor: pointer;
      filter: invert(60%) sepia(37%) saturate(4029%) hue-rotate(125deg)
        brightness(103%) contrast(87%);
    }
  }
  .vitals-filter-block {
    margin-top: 5px;
    .vitals-search-block {
      position: relative;
      .icon-block {
        position: absolute;
        left: 8px;
        top: 0;
      }
      input {
        background: #e4e4e447;
        border-radius: 6px;
        padding: 5px 10px 5px 30px;
      }
    }
    .right-filter-block {
      .list-view-type-btn {
        background: rgba(228, 228, 228, 0.28);
        border-color: transparent;
        color: black;
        padding: 6px 10px;
        font-size: 14px;
      }
      div {
        background: #e4e4e447;
        color: #282e65;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;
        font-size: 14px;
        font-weight: 400;
        user-select: none;
        &:first-child {
          padding: 0px 8px;
          cursor: pointer;
          border-radius: 6px 0px 0px 6px;
        }
        &:last-child {
          padding: 0px 8px;
          cursor: pointer;
          border-radius: 0px 6px 6px 0px;
        }
      }
    }
    .vitals-filter-day-container {
      display: flex;
      align-items: center;
      gap: 3px;
      margin-top: 10px;
      overflow: auto;

      .day-block {
        background: #e4e4e447;
        border-radius: 8px;
        padding: 5px 10px 3px 10px;
        text-align: center;
        cursor: pointer;
        text-wrap: nowrap;
        position: relative;
        .a-data-dot {
          position: absolute;
          top: 5px;
          right: 3px;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: blue;
        }
        div {
          &:first-child {
            color: #282e65;
            font-size: 14px;
            font-weight: 600;
          }
          &:last-child {
            color: #282e65;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
      .active {
        background: #3d3efd;
        .a-data-dot {
          background-color: #fff;
        }
        div {
          color: #fff;
        }
      }

      /*
      display: flex;
      align-items: center;
      gap: 3px;
      margin-top: 10px;
      overflow: auto;
      .day-block {
        background: #e4e4e447;
        border-radius: 8px;
        padding: 5px 10px 3px 10px;
        text-align: center;
        cursor: pointer;
        text-wrap: nowrap;
        div {
          &:first-child {
            color: #282e65;
            font-size: 14px;
            font-weight: 600;
          }
          &:last-child {
            color: #282e65;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
      .active {
        background: #3d3efd;
        div {
          &:first-child {
            color: #fff;
          }
          &:last-child {
            color: #fff;
          }
        }
      }*/
    }
  }
  .vitals-body-block {
    .vitals-item-title-block {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      background: #f8f8f8;
      cursor: pointer;
      user-select: none;
      font-size: 16px;
      font-weight: 500;
      color: #282e65;
    }

    .remove_btn {
      width: 15.83px;
      height: 15.83px;
      background-color: #de2b2b;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      line-height: 21px;
      font-size: 25px;
    }

    .header-date-row {
      display: flex;
      align-items: center;
      padding-left: 15px;
      background-color: #f8f8f8e5;
      min-width: fit-content;
      .left-title-block {
        min-width: 300px;
        width: 300px;
      }
      .date-title-block {
        min-width: 150px;
        width: 150px;
        padding: 10px 5px;
        position: relative;
        .date-block {
          font-size: 13px;
          font-weight: 500;
          color: #788c9f;
          display: flex;
          align-items: center;
          gap: 5px;
          .date-delete {
            cursor: pointer;
          }
        }
        .time-block {
          font-size: 13px;
          font-weight: 500;
          color: #788c9f;
        }
        .date-tile-edit-popover {
          position: absolute;
          top: 55px;
          right: 40%;
          background-color: #fff;
          z-index: 999;
          padding: 10px;
          width: fit-content;
          input {
            width: 100%;
          }
        }
        .css-1jqq78o-placeholder {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .body-row-container {
      .body-row {
        display: flex;
        align-items: center;
        padding-left: 15px;
        min-width: fit-content;
        .left-title-block {
          min-width: 300px;
          width: 300px;
          font-size: 14px;
          font-weight: 400;
          color: #282e65;
          padding: 10px 5px;
        }
        .body-cell {
          min-width: 150px;
          width: 150px;
          padding: 10px 5px;
          font-size: 12px;
          font-weight: 400;
          color: #282e65;
          // > div {
          //   white-space: nowrap;
          //   text-overflow: ellipsis;
          //   overflow: hidden;
          // }
          cursor: pointer;
          .text-input-block {
            input {
              height: 100%;
              width: 100%;
              border-radius: 1px;
              padding: 5px 10px 5px 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .add-info-block {
            color: $blue;
            font-size: 14px;
            font-weight: 400;
          }
          .css-tr4s17-option {
            background-color: $blue;
          }
          .css-t3ipsp-control {
            box-shadow: unset;
          }
        }
        &:nth-child(odd) {
          // background: #ebebebe6;
          background-color: #ebebeb8c;
        }
        &:nth-child(even) {
          background: #f8f8f8e5;
          .text-input-block {
            input {
              border: 1px solid #ebebebe6;
            }
          }
        }
      }
    }
    // .vital-list-data {
    //   display: flex;
    //   .vital-list-item {
    //     .left-block {
    //       width: 220px;
    //       font-size: 13px;
    //       font-weight: 400;
    //       height: 45px;
    //       display: flex;
    //       align-items: center;
    //       padding-left: 20px;
    //       &:first-child {
    //         height: 50px;
    //       }
    // &:nth-child(odd) {
    //   background: #ebebebe6;
    // }
    // &:nth-child(even) {
    //   background: #f8f8f8e5;
    // }
    //     }
    //     .body-cell-block {
    //       height: 45px;
    //       width: 150px;
    //       font-size: 13px;
    //       font-weight: 400;
    //       color: $base-color-private;
    //       display: flex;
    //       align-items: center;
    //       &:first-child {
    //         height: 50px;
    //       }

    //       &:nth-child(odd) {
    //         background: #ebebebe6;
    //       }
    //       &:nth-child(even) {
    //         background: #f8f8f8e5;
    //       }
    //     }
    //     .cell-date {
    //       font-size: 14px;
    //       font-weight: 500;
    //       color: #282e65;
    //     }
    //     .cell-time {
    //       font-size: 12px;
    //       font-weight: 400;
    //       color: #282e65;
    //     }
    //     &:last-child {
    //       flex-grow: 1;
    //       .body-cell-block {
    //         width: 100%;
    //       }
    //     }
    //   }
    // }
  }
}
