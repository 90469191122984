.tooltip-inner {
  position: relative;
  font-size: 12px;
  border-radius: 3px;
  &::before {
    content: "";
    left: -6px;
    top: 7px;
    width: 0px;
    height: 0px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 6px solid #191919;
    display: inline-flex;
    position: absolute;
  }
}

.tooltip[x-placement="top"] {
  .tooltip-inner {
    &::before {
      transform: rotate(-90deg);
      top: calc(100% - 2px);
      left: 50%;
      margin-left: -3px;
    }
  }
}
