@import "../../../../../assets/css/theme/variables";

.vitals-wrapper {
	&.flowsheet_tab {
		.nav-link {
			padding: 9px 8px !important;
		}
	}

	>.left-col {
		width: 145px;
		flex-shrink: 0;
		padding-right: 15px;

		.vital-menu-tab {
			white-space: normal;
			border: 1px solid rgba(9, 9, 84, 0.12);
			border-radius: 6px;

			.nav-item {
				&:last-child {
					margin-bottom: 0;

					.nav-link {
						border-bottom: none;
					}
				}

				.nav-link {
					width: 100%;
					justify-content: center;
					padding: 5px 8px;
					border-bottom: 1px solid rgba(9, 9, 84, 0.12);
					font-weight: 400;
					font-size: 14px;
					line-height: 150%;
					border-radius: 0;

					&:hover,
					&:focus {
						background-color: rgba($primary, 0.08);
						color: $primary;
					}

					&.active {
						background-color: rgba($primary, 0.08);

						&::before {
							display: none;
						}
					}
				}
			}
		}
	}

	>.right-col {
		.section-head {
			margin-bottom: 0;

			>.left-col {
				margin-right: 15px;

				h3 {
					font-size: 18px;
					font-weight: 600;
					line-height: 150%;
				}
			}

			>.right-col {
				.btn {
					padding: 10px;
				}

				.view-all-btn {
					color: $base-color;

					&:hover,
					&:focus {
						color: $primary;
					}
				}

				.add-btn {
					font-size: 19px;
					color: $success;

					&:hover,
					&:focus {
						color: $success-dark;
					}
				}
			}
		}
	}

}