.notes_section {
    h3 {
        font-size: 18px;
        color: #282E65;
        font-weight: 600;
    }

    table {
        tr {
            td {
                padding: 9px 4px 9px 13px;
                font-size: 14px;
                color: #282E65;
                font-weight: 400;
            }
        }
    }
}