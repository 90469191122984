@import "../../../assets/css/theme/variables";

.report-blc {
  background: #f8f8f8;
  border-radius: 8px;
  padding: 1px 10px;

  .head {
    > .left-col {
      h3,
      p {
        font-weight: 400;
        line-height: 150%;
      }

      h3 {
        font-size: 16px;
      }

      p {
        font-size: 14px;
      }
    }

    > .right-col {
      .add-btn {
        font-size: 19px;
        color: $success;

        &:hover,
        &:focus {
          color: $success-dark;
        }
      }
    }
  }

  .inner {
    > * {
      margin-top: 15px;
      margin-bottom: 10px;
    }
    .cardImage {
      width: 100%;
    }

    h4 {
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      margin: 0;
    }

    p {
      font-size: 13px;
      color: #979797;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; // Show ellipsis after 2 lines
      -webkit-box-orient: vertical;
      max-width: 100%; // Adjust based on your design
    }

    button {
      margin-top: 10px;
      font-size: 13px;
      width: 100%;
      height: 28px;
      line-height: 0.5;
      text-wrap: wrap;
    }
  }

  .table-listing-blc {
    .table {
      tr {
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
}
