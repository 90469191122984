@import "../../../assets/css/theme/variables";

.recommendation-blc {
    background: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
    > .head {
        margin-bottom: 18px;
        h3,
        h4,
        h6 {
            font-weight: 400;
            line-height: 150%;
        }
        h3 {
            font-size: 16px;
        }
        h4 {
            font-size: 14px;
            color: #788c9f;
        }
        h5 {
            font-size: 24px;
            font-weight: 600;
            line-height: 29px;
        }
        h6 {
            font-size: 14px;
        }
    }
    > .inner {
        .chart-block {
            margin-bottom: 10px;
        }
        .recommendation-stats {
            padding: 0;
            li {
                font-size: 14px;
                line-height: 150%;
                margin-bottom: 10px;
                label {
                    margin-right: 15px;
                    span {
                        width: 16px;
                        height: 16px;
                        background: #e0ce2a;
                        border: 3px solid #fff;
                        margin-right: 8px;
                        border-radius: 10px;
                    }
                    &.phone-call {
                        span {
                            background-color: #9584fc;
                        }
                    }
                    &.video-call {
                        span {
                            background-color: $success;
                        }
                    }
                    &.video-conference {
                        span {
                            background-color: #409de0;
                        }
                    }
                }
            }
        }
    }
}
