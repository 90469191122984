@import "../../../assets/css/theme/variables";

.form-group {
  .upload-clone-blc {
    margin-top: 30px;
    .uploaded-img,
    .upload-btn {
      margin-right: 28px;
      width: calc(100% - 88px);
      @media (max-width: 1919px) {
        width: calc(100% - 78px);
      }
      @media (max-width: 400px) {
        margin-right: 16px;
      }
    }
    .uploaded-img {
      padding: 8px 20px 8px 12px;
      height: 60px;
      background-color: rgba($primary, 0.1);
      border-radius: 10px;
      @media (max-width: 1919px) {
        height: 50px;
      }
      .close-btn {
        width: 24px;
        height: 24px;
        background-color: rgba(255, 92, 92, 1);
        color: #fff;
        top: -9px;
        right: -10px;
        font-size: 14px;
      }
      .pic {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 42px;
        margin-right: 10px;
        border-radius: 6px;
        flex-shrink: 0;
        @media (max-width: 1919px) {
          width: 40px;
          height: 35px;
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
          object-fit: cover;
        }
      }
      .txt {
        font-family: "SFProDisplay-Medium";
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.03em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .clone-btn {
      width: 60px;
      height: 60px;
      background: #ffffff;
      border: 1px solid rgba(8, 8, 41, 0.15);
      border-radius: 15px;
      color: rgba($primary, 0.75);
      &:hover,
      &:focus {
        border: 1px solid $primary;
        svg {
          path {
            fill: $primary;
            opacity: 1;
          }
        }
      }
      @media (max-width: 1919px) {
        height: 50px;
        width: 50px;
        border-radius: 6px;
      }
    }
    .upload-btn {
      * {
        cursor: pointer;
      }
      .btn {
        font-family: "SFProDisplay-Medium";
        width: 100%;
        height: 60px;
        border: 1px solid #3d3efd;
        box-shadow: 0px 4px 10px rgba(61, 57, 137, 0.05);
        border-radius: 10px;
        @media (max-width: 1919px) {
          height: 50px;
          padding: 10px 15px;
        }
      }
      .form-control-file {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        width: 100%;
      }
    }
  }
}
