@import "../../../../assets/css/theme/variables";

.form-template-blc {
  background: #f8f8f8;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 8px;

  .head {
    > .left-col {
      h3,
      p {
        font-weight: 400;
        line-height: 150%;
      }

      h3 {
        font-size: 16px;
      }

      p {
        font-size: 14px;
      }
    }

    > .right-col {
      .add-btn {
        font-size: 19px;
        color: $success;

        &:hover,
        &:focus {
          color: $success-dark;
        }
      }
    }
  }

  .table-listing-blc {
    .table {
      tr {
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
}

.table-listing-blc {
  margin-top: 15px;
}

.left-col {
  h3 {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
}

.btn {
  &.edt_btn-link {
    color: #788c9f;
    padding: 0;
    box-shadow: none;
  }
}

.notes-template-panel-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  max-width: 600px;
  width: 100%;
  background-color: #fff;
  padding: 20px;
  transition: all ease 0.3s 0s;
  transform: translateX(100%);

  &.active {
    transform: translateX(0);
  }

  .add-section-note-btn {
    border: 1px dashed #788c9f;
    border-radius: 10px;
    color: $success;
    padding: 8px 15px;
    &:hover,
    &:focus {
      border-color: $success;
    }
    span {
      font-size: 22px;
    }
  }

  > .head {
    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;

      @media (max-width: 767px) {
        font-size: 19px;
      }
    }

    .panel-close-btn {
      color: $base-color-private;
      padding: 10px;

      &:hover {
        color: $primary;
      }
    }
  }
}

.modal-header .btn-close {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
}
