.slot-time-frame {
  .frame-btn {
    padding: 0;
    color: #eeeef2;
    border-bottom: 1px dashed #eeeef2 !important;
    &:hover {
      background-color: #f2f2fb;
    }
    &:last-child {
      border-bottom: none;
    }
    &.no-time-slot {
      height: 31px;
    }
    span {
      font-weight: 300;
    }
  }
  .slot-item {
    top: 0;
    height: 25%;
  }
}
.br-4400 {
  border-radius: 4px 4px 0px 0px;
}
.br-0044 {
  border-radius: 0px 0px 4px 4px;
}
