@import "../../../../assets/css/theme/variables";


.vaccine-schedule-panel-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    min-width: 30%;
    max-width: 70%;
    width: 100%;
    background-color: #fff;
    padding: 15px;
    transition: all ease 0.3s 0s;
    transform: translateX(100%);

    &.active {
        transform: translateX(0);
    }

	.add-section-medication-btn {
		border: 1px dashed #788c9f;
		border-radius: 10px;
		color: $success;
		padding: 8px 15px;
		&:hover,
		&:focus {
			border-color: $success;
		}
		span {
			font-size: 22px;
		}
	}

    .head {
        h2 {
            font-size: 24px;
            font-weight: 700;
            line-height: 33px;

            @media(max-width:767px) {
                font-size: 19px;
            }
        }

        .panel-close-btn {
            color: $base-color-private;
            padding: 10px;

            &:hover {
                color: $primary;
            }
        }
    }

    .table-listing-blc {
        .table {
            thead {
                tr {
                    th {
                        position: sticky;
                        top: 0;
                    }
                }
            }
        }
    }
}

.vaccine-schedule-accordion-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
    align-self: stretch;

    .accordion-blc {

        display: flex;
        padding: 10px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        background: var(--table-bg, rgba(228, 228, 228, 0.28));

      

        &:first-child {
            border-radius: 10px 10px 0px 0px;
        }
      
        &:last-child {
            margin-bottom: 0;
        }

        .accordion-btn-wrapper {

            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 2px;
            align-self: stretch;

          

            .accordion-btn {

                label{
                    color: var(--Dark-Blue, #282E65);
                    /* 18 SB */
                    font-family: 'Inter';
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%; /* 27px */
                }

                color: var(--Dark-Blue, #282E65);


            
                padding: 8px 5px;
                margin-right: 15px;
             
                * {
                    cursor: pointer;
                }
            }
            label {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
            }
            .add-btn {
                color: $success;
                &:hover {
                    color: $success-dark;
                }
                span {
                    font-size: 20px;
                }
            }
        }
        .collapse-data-blc {
            margin: 0 0 0 12px;
          
            thead tr {
                :hover {
                    background: none;
                }
                th  { 

                    width: 120px;
                    padding: 5px 0px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 2px;
                    align-self: stretch;

                    border: 1px solid #C7C7C7;
                    align-self: stretch;
                    color: var(--Dark-Blue, #282E65);
                    text-align: center;
                    /* 13 SB */
                    font-family: Inter;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%; /* 19.5px */
                 }
                th:first-child { 
                    border: none

                 }
            }
            tbody tr {
                td { 
                    padding: 4px 0px;

                    input {
                        display: flex;
                        justify-content: center;
                        align-self: stretch;
                        align-items: center;
                        border-radius: 4px;
                        border: 1.5px solid rgba(40, 46, 101, 0.24);
                        background:  #F3F3F8
                    }
                    
                    
                    border: 1px solid #C7C7C7; 
                
                }
                td:first-child { 
                    width: 150px;
                    padding: 4px 0px;
        
                    align-items: center;
                    align-self: stretch;


                    height: 25px;
                    justify-content: center;
                    align-self: stretch;
                    overflow: hidden;
                    color: var(--Dark-Blue, #282E65);
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    /* 14 */
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%; /* 21px */
                    
                    border:none;}
            
            }
        }
        
    }
    .appointment-info-tab-blc {
        .nav-tabs {
            border: none;
            margin-bottom: 10px;

            .nav-item {
                border: 1px solid rgb(210 210 221);
                margin-left: -1px;
                min-width: 150px;

                &:first-child {
                    border-radius: 6px 0px 0px 6px;
                    margin-left: 0;
                }

                &:last-child {
                    border-radius: 0 6px 6px 0;
                }

                .nav-link {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    border: none;
                    color: $base-color-private;
                    background-color: transparent;
                    border-radius: 0;
                    margin-bottom: 0;
                    text-align: center;
                    padding: 7px 10px;

                    &.active {
                        color: $primary;
                        background: rgba($primary, 0.08);
                    }
                    &:hover {
                        background-color: rgba($primary, 0.04);
                    }
                }
            }
        }
        .appointment-form-blc {
            padding: 3px 3px 0 0;
            max-width: 700px;
            width: 100%;
            > .row-block {
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 10px;
                label {
                    width: 150px;
                    flex-shrink: 0;
                    margin-right: 15px;
                    margin-top: 10px;
                    &.required-input {
                        &:after {
                            margin-left: 3px;
                        }
                    }
                }
                .right-col {
                    .input-wrapper {
                        .form-control {
                            font-size: 14px;
                            height: 40px;
                            border-radius: 6px;
                        }
                        textarea.form-control {
                            height: 100px;
                        }
                    }
                    .form-control {
                        height: 40px;
                    }
                }
            }
            &.view-mode {
                .row-block {
                    align-items: center;
                    label {
                        margin-top: 0;
                    }
                    .right-col {
                        .form-control {
                            padding: 0;
                            background-color: transparent;
                            border-color: transparent;
                            box-shadow: none;
                            color: $base-color-private;
                            opacity: 1;
                        }
                        select.form-control {
                            background-image: none;
                        }
                    }
                }
            }
        }
    }
}
