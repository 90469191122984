.user-profile-head,
.user-profile-head2 {
  margin-bottom: 20px;
  @media (max-width: 640px) {
    margin-bottom: 10px;
  }
  > .left-col {
    margin-right: 20px;
    .user-profile-pic {
      .pic {
        width: 100px;
        height: 100px;
        margin-right: 20px;
        flex-shrink: 0;
        @media (max-width: 480px) {
          width: 70px;
          height: 70px;
          margin-right: 10px;
        }
        .user-placeholder {
          span {
            font-size: 100px;
          }
        }
      }
      .info {
        h3 {
          font-size: 24px;
          font-weight: 600;
          line-height: 29px;
          margin-bottom: 5px;
          @media (max-width: 480px) {
            font-size: 18px;
            margin-bottom: 2px;
          }
        }
        .feature {
          margin-bottom: 5px;
          @media (max-width: 480px) {
            margin-bottom: 2px;
            font-size: 15px;
          }
          label {
            padding-right: 10px;
            &.item-dot {
              &::after {
                content: "";
                display: inline-flex;
                width: 4px;
                height: 4px;
                background: rgba(9, 9, 84, 0.12);
                border-radius: 50%;
                margin-left: 10px;
                margin-top: 4px;
              }
            }
          }
        }
        p {
          margin-bottom: 0;
          @media (max-width: 480px) {
            font-size: 15px;
          }
        }
      }
    }
  }
  > .right-col {
    .share-btn {
      width: 40px;
      height: 40px;
      background: #f8f8f8;
      border-radius: 8px;
      &:hover {
        background-color: $primary;
        color: #fff;
      }
    }
  }
}

.user-profile-head2 {
  > .right-col {
    .btn-cell {
      .btn {
        width: 40px;
        height: 40px;
        background: #f8f8f8;
        margin-right: 10px;
        border-radius: 8px;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          background-color: $primary;
          color: #fff;
        }
      }
    }
  }
  &.responsive {
    @media (max-width: 550px) {
      flex-direction: column;
    }
    > .right-col {
      @media (max-width: 550px) {
        margin-left: 120px !important;
      }
      @media (max-width: 480px) {
        margin-left: 80px !important;
      }
    }
  }
}
