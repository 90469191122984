@import "../../../../../assets/css/theme/variables";

.waiting-room-list {
  .item-row {
    display: flex;
    height: 60px;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;

    > .left-col {
      margin-right: 20px;
      gap: 20px;
      display: flex;
      flex-direction: row;

      @media (max-width: 1250px) {
        flex-direction: column;
      }

      @media (max-width: 1250px) {
        margin-right: 0;
      }

      .color-square {
        width: 30px;
        height: 30px;
        border-radius: 10px;
        border: 3px solid #fff;
        background: var(--special-yellow, #e0ce2a);
      }

      .room-info {
        h4 {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 8px;
          flex-direction: column;
          justify-content: center;
        }

        h5 {
          font-size: 13px;
          font-weight: 400;
        }
      }
    }

    > .right-col {
      margin-left: auto;

      @media (max-width: 1250px) {
        margin-left: 0;
      }

      .product-pic-blc {
        max-width: 330px;
        width: 100%;

        @media (max-width: 800px) {
          max-width: none;
          width: auto;
        }

        .pic-cell {
          width: 60px;
          height: 60px;
          margin-right: 5px;
          border-radius: 5px;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .btn {
        color: #282e65;
        padding: 10px;

        &:hover,
        &:focus {
          color: $primary;
        }
      }
    }
  }
}
