@import "../../../../assets/css/theme/variables";
.no-time-slot {
    padding: 8px 10px;
    background-color: rgba($primary, 0.04);
    border-radius: 5px;
    border: 1px dashed rgba($primary, 0.05);
    .inner {
        font-size: 15px;
        span {
            margin-right: 6px;
            font-weight: 300;
        }
    }
}
