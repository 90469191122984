.inprogress_box {
    border-radius: 10px;
    background: rgba(149, 132, 252, 0.20);
    min-height: 250px;
    font-size: 18px;
    color: #282E65;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.preferences-wrapper {
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    flex: 1 0 0;
    .preferences-section {

        display: flex;
        padding: 20px;
        align-items: flex-start;
        gap: 30px;
        align-self: stretch;
        border-radius: 8px;
        background: var(--BG, rgba(248, 248, 248, 0.90));

        .left-col {
            display: flex;
            padding-top: 10px;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            .header {
                align-self: stretch;
                color: var(--Dark-Blue, #282E65);
                /* 22 SB */
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
            .description{
                align-self: stretch;
                color: var(--Font-Secondary, #788693);
                /* 16 */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 24px */
            }
            .checkbox {
                display: flex;
                align-items: center;
                gap: 8px;                color: var(--Dark-Blue, #282E65);
                /* 16 */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 24px */
            }
        }
        .right-col {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;
            flex: 1 0 0;
            .table-wrapper {

                display: flex;
                padding: 10px;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                align-self: stretch;
                align-items: stretch;
                border-radius: 8px;
                background: #FFF;

                .header {
                    display: flex;
                    align-items: flex-start;
                    gap: 10px;
                    align-self: stretch;

                }

                .action-cell {
                    align-items: center;
                    justify-content: flex-end;
                    padding: 6px 10px;

                    gap: 8px;
                    align-self: stretch;
                    text-align: right;
                }

            }
        }

    }
}


.tab-wrapper.tab-wrapper-setting .tab-content {
    .telehealth-tab {
        .tab-row {
            @media (max-width: 800px) {
                flex-direction: column;
            }

            .tab-col {
                &.lft {
                    @media (max-width: 800px) {
                        margin-right: 0;
                        margin-bottom: 10px;
                        max-width: none;
                    }
                }

                .add-note-btn {
                    border: 1px dashed #788c9f;
                    border-radius: 10px;
                    color: #11ca9d;
                    padding: 14px 15px;
                    width: 100%;

                    .icomoon-plus-btn {
                        &:before {
                            font-size: 22px;
                        }
                    }
                }

                >.inner {
                    @media (max-width: 800px) {
                        max-width: none;
                    }
                }
            }
        }
    }
}