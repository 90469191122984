.profile-description {
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 9px;

    label {
      min-width: 144px;
      max-width: 144px;
      margin-right: 6px;
    }
  }
}
