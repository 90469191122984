.staff-profile-overview-blc {
    > .row-block {
        margin-bottom: 15px;
        .section-title {
            font-size: 18px;
            font-weight: 600;
            line-height: 150%;
        }
    }
}
