@import "../../../assets/css/theme/variables";

.profile-vaccination {
    .item-list {
        .item-cell {
            background: #f8f8f8;
            border-radius: 5px;
            padding: 8px 10px;
            margin-bottom: 5px;
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
            color: $base-color;

            &:last-child {
                margin-bottom: 0;
            }


            .status-cell {
                padding: 2px 6px;
                border: 0.5px solid rgba(255, 255, 255, 0.2);
                border-radius: 3px;
            }

            .status-cell.inactive-cell {
                background-color: rgba(222, 43, 43, 0.15);
                color: #de2b2b;
              }
              .status-cell.success-cell {
                background-color: rgba(17, 202, 157, 0.15);
                color: #11ca9d;
              }
              .status-cell.pending-cell {
                background-color: rgba(118, 96, 255, 0.15);
                color: rgb(118, 96, 255);
              }

            span {


                margin-right: 10px;

                &.due_date {
                    color: #282E65;
                }

                &.overdue_date {
                    color: #DE2B2B;
                }
            }

            label {
                color: #282E65;
            }
        }
    }
}