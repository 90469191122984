.password-warning {
  color: #f30909;
  margin-bottom: 57px;
  .icon {
    flex-shrink: 0;
    margin-right: 12px;
    svg {
      width: 18px;
    }
  }
  .txt {
    font-size: 14px;
    font-family: "SFProDisplay-Medium";
    line-height: 18px;
  }
}
