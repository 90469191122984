.clinicCard-container {
  height: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--F8F8);
  .hide {
    // visibility: hidden;
    display: none;
  }

  .edit-icon-img {
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 5px 5px 5px 5px;
    border-radius: 50%;
    border: 1px solid #e6dfdf;
    cursor: pointer;
    img {
      height: 10px;
      width: 10px;
    }
  }
}
