@import "../../../../assets/css/theme/variables";

.pet-info-block {
    > .left-col {
        margin-right: 20px;
        .description-blc {
            > .row-block {
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 10px;
                label {
                    width: 150px;
                    flex-shrink: 0;
                    margin-right: 15px;
                    margin-top: 10px;
                    &.required-input {
                        &:after {
                            margin-left: 3px;
                        }
                    }
                }
                .right-col {
                    .input-wrapper {
                        .form-control {
                            font-size: 14px;
                            height: 40px;
                            border-radius: 6px;
                        }
                        textarea.form-control {
                            height: 100px;
                        }
                    }
                    .form-control {
                        height: 40px;
                    }
                    .form-group {
                        margin-bottom: 0;
                    }
                }

				.allergy-list {
					font-size: 16px;
					font-weight: 400;
				}
				.tag-list {
					font-size: 16px;
					font-weight: 400;
				}
            }
            &.view-mode {
                .row-block {
                    align-items: center;
                    label {
                        margin-top: 0;
                    }
                    .right-col {
                        .form-control {
                            padding: 0;
                            background-color: transparent;
                            border-color: transparent;
                            box-shadow: none;
                            color: $base-color-private;
                            opacity: 1;
                        }
                        select.form-control {
                            background-image: none;
                        }
                    }
                }
            }
        }
    }
    > .right-col {
        .profile-cell {
            h4 {
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
            }
            .pic-cell {
                &.pet {
                    .pic {
                        border-radius: 8px;
                    }
                }
                .pic {
                    width: 80px;
                    height: 80px;
                    &:hover {
                        .upload-cell {
                            opacity: 1;
                        }
                    }
                    .user-placeholder {
                        span {
                            font-size: 80px;
                        }
                    }
                    .upload-cell {
                        background-color: rgba($primary, 0.8);
                        z-index: 2;
                        font-size: 25px;
                        color: rgba(256, 256, 256, 0.7);
                        cursor: pointer;
                        opacity: 0;
                        transition: all ease 0.3s 0s;
                        * {
                            cursor: pointer;
                        }
                        input {
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0%;
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
}












$color_1: #727E8C;
$color_2: #475F7B;
$color_3: #fff;
$color_4: #8194aa;
$color_5: #475f7b;
$color_6: #3d546f;
$font-family_1: "Rubik", sans-serif;
$background-color_1: #e0e3e8;
$background-color_2: #fff;
$background-color_3: #5a8dee;
$background-color_4: #f0f2f7;
$background-color_5: #eaecf1;
$background-color_6: transparent;
$border-color_1: #5a8dee;

/* ------------ */
body {
	background-color: $background-color_1;
	font-family: $font-family_1;
	font-size: 15px;
	font-weight: 400;
	line-height: 24px;
	color: $color_1;
}
.h1 {
	color: $color_2;
}
.h2 {
	color: $color_2;
}
.h3 {
	color: $color_2;
}
.h4 {
	color: $color_2;
}
.h5 {
	color: $color_2;
}
.h6 {
	color: $color_2;
}
h1 {
	color: $color_2;
}
h2 {
	color: $color_2;
}
h3 {
	color: $color_2;
}
h4 {
	color: $color_2;
}
h5 {
	color: $color_2;
}
h6 {
	color: $color_2;
}
a {
	text-decoration: none;
	transition: 0.5s;
	outline: none;
	&:hover {
		text-decoration: none;
		transition: 0.5s;
		outline: none;
	}
	&:focus {
		text-decoration: none;
		transition: 0.5s;
		outline: none;
	}
}
.card {
	box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
	border-radius: 6px;
	overflow: hidden;
	margin-bottom: 30px;
	background-color: $background-color_2;
	border: none;
}
.card-body {
	padding: 30px;
}

.form-submit {
	padding: 13px 30px;
	font-size: 15px;
	letter-spacing: 0.3px;
	font-weight: 400;
}
.kb-data-box {
	width: 100%;
	flex: 1;
}
.kb-modal-data-title {
	margin-bottom: 10px;
}
.kb-data-title {
	h6 {
		margin-bottom: 0;
		font-size: 15px;
		font-weight: 600;
	}
}
.kb-file-upload {
	margin-bottom: 20px;
}
.file-upload-box {
	border: 1px dashed #b6bed1;
	background-color: $background-color_4;
	border-radius: 4px;
	min-height: 150px;
	position: relative;
	overflow: hidden;
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $color_4;
	font-weight: 400;
	font-size: 15px;
	.file-upload-input {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0;
		cursor: pointer;
	}
	.file-link {
		&:hover {
			text-decoration: none;
		}
	}
}
.file-link {
	color: $color_5;
	text-decoration: underline;
	margin-left: 3px;
}
.file-atc-box {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	&:last-child {
		margin-bottom: 0;
	}
}
.file-image {
	width: 130px;
	height: 85px;
	background-size: cover;
	border-radius: 5px;
	margin-right: 15px;
	background-color: $background-color_5;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	color: $color_5;
	padding: 3px;
	img {
		max-width: 100%;
		max-height: 100%;
		border-radius: 4px;
	}
}
.file-detail {
	flex: 1;
	width: calc(100% - 210px);
	h6 {
		word-break: break-all;
		font-size: 13px;
		font-weight: 500;
		line-height: 20px;
		margin-bottom: 8px;
	}
	p {
		font-size: 12px;
		color: $color_4;
		line-height: initial;
		font-weight: 400;
		margin-bottom: 8px;
	}
}
.file-actions {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	align-items: center;
}
.file-action-btn {
	font-size: 12px;
	color: $color_4;
	line-height: 20px;
	font-weight: 400;
	margin-bottom: 0;
	padding: 0;
	background-color: $background-color_6;
	border: none;
	text-decoration: underline;
	margin-right: 15px;
	cursor: pointer;
	&:hover {
		color: $color_6;
		text-decoration: underline;
	}
}
