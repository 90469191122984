@import "../../../../../assets//css//theme/variables";
#clinic-availability-id {
  overflow: auto;
  .search-cell-conatainer {
    height: 34px;
    position: relative;
    border-color: transparent;
    box-shadow: none;
    background-position-x: calc(100% - 13px);
    background-size: 10px;

    .search-icons {
      position: absolute;
      top: 11px;
      left: 15px;
    }

    .form-control {
      font-size: 15px;
      height: 34px;
      border-radius: 8px;
      color: #1d1d1d;
      padding: 0.375rem 40px;
      background-color: #fff;
      border: 1px solid rgba(8, 8, 41, 0.1);
      box-shadow: unset !important ;
    }
  }
  .role-cell-conatainer {
    .form-select,
    select.form-control {
      background: url(../../../../../assets/img/icons/angle-down.svg) #fff
        calc(100% - 14px) center no-repeat;
      background-size: 12px auto;
    }
    .form-control {
      height: 34px;
      box-shadow: unset !important ;
    }
  }
  .calendar-month-view {
    table {
      table-layout: fixed;
      height: 1px;
      tbody {
        tr {
          &:hover {
            td {
              background-color: transparent;
            }
          }

          td {
            position: relative;
            border: 1px solid #eeeef2;
            padding: 24px 10px 20px;
            font-size: 13px;
            vertical-align: top;
            height: 100px;
            &:hover {
              background-color: #f2f2fb !important;
            }
            // .current-day-cell {
            //   min-height: 110px !important;
            // }
            &.no-hover {
              background-color: transparent !important;
            }

            // .data-container {
            //   min-height: 110px !important;
            // }
            .br-6 {
              border-radius: 6px;
            }
            .user-placeholder {
              // width: 0px !important;
              span {
                font-size: 30px;
              }
            }
            .text-hide {
              text-wrap: nowrap;
              overflow-x: hidden;
              text-overflow: ellipsis;
            }
            .wth-0 {
              width: 32px !important;
            }
            .day-no {
              top: 5px;
              right: 5px;
              font-size: 13px;

              &.disable {
                color: #ccc;
              }

              &.current {
                width: 22px;
                height: 22px;
                background-color: $primary;
                color: #fff;
              }
            }
          }
        }
      }
      // .data-container {
      //   min-height: 110px;
      // }
    }
  }
}
