@import "../../../assets/css/theme/variables";

.profile-allergy {

    .add-btn {
        font-size: 13px;
        font-weight: 400;
        line-height: 150%;
        color: #788693;
        padding: 10px;
        &:hover,
        &:focus {
            color: $primary;
        }
    }


    
    .allergy-overview{
        .tag{
            color: white;
            padding: 5px;
            border-radius: 5px;
        }
        .tag-one{
            background-color: lightgreen;
        }
        .tag-two{
            background-color: lightblue;
        }
        .tag-three{
            background-color: lightcoral;
        }
        .tag-four{
            background-color: lightseagreen;
        }
        .tag-five{
            background-color: lightslategray;
        }
        .lightseagreen{
            background-color: lightseagreen;
        }
        .darkgoldenrod{
            background-color: darkgoldenrod;
        }
        .orange{
            background-color: orange;
        }


        

            .reactions-select-dropdown{
                width:100%
            }
           .severity-text{
            color: white;
            .low{
                padding: 5px 10px;
                border-radius: 5px;
                background-color: lightseagreen;
                cursor: pointer;
            }
            .moderate{
                padding: 5px 10px;
                border-radius: 5px;
                background-color: darkgoldenrod;
                cursor: pointer;
            }
            .high{
                padding: 5px 10px;
                border-radius: 5px;
                background-color: orange;
                cursor: pointer;
            }
           } 
        
        
    }
    
    
}
.select-group-list-right{
    color: rgba(40, 46, 101, 1);
    .offcanvas-header{
      .btn-close{
        opacity: 1 !important;
        position: relative;
        right: 10px;
        &::before{
          position: absolute;
          top: 5px;
          right:15px;
          content: " ";
          height: 25px;
          width: 2px;
          background-color: #282e65;
          transform: rotate(45deg);
        }
      &::after {
          position: absolute;
          top: 5px;
          right:15px;
          content: " ";
          height: 25px;
          width: 2px;
          background-color: #282e65;
          transform: rotate(-45deg);
      }
      }
      .offcanvas-title{
        color:  #282E65;
        font-family: Nunito Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
       
      }
    }
    .group-add-name{
      input{
      border-radius: 8px;
      border: 1px solid rgba(9, 9, 84, 0.12);
      //color: rgba(120, 134, 147, 1);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      height:48px;
      font-size: 14px;
      }

      .cancel-button{
        color: black !important;
        background-color: white;
        padding: 10px 35px;
        border: 1px solid black;
        border-radius: 5px !important;
      }
      .cancel-button:hover{
        color: black !important;
        background-color: white;
        border: 1px solid black;
        border-radius: 5px !important;
      }
      .save-button{
        color: white;
        background-color: lightseagreen;
        padding: 10px 35px;
        border: none;
        border-radius: 5px !important;
    }
    
    .save-button:hover{
        color: white;
        background-color: lightseagreen;
    }
    }
    
  }

  .cancel-allergy-btn {

        color: #282E65;
        font-size: 12px;
        text-transform: uppercase;
        border-radius: 8px;
        border: 0.5px solid #788C9F;
        //box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.14);
        background-color: transparent;
        padding: 4px 14px;
        //height:40px;
      
  }
