#AppointmentRequest-offcanvas {
  width: 900px !important;
  .btn-close {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
  }
  .accordion-button {
    background: #f8f8f8;
    border-color: unset;
    font-size: 18px;
    font-weight: 600;
    color: #282e65;
  }
  .accordion-button:focus {
    box-shadow: unset;
    border-color: unset;
  }
  .accordion-button:not(.collapsed) {
    background-color: #f8f8f8;
    color: #282e65;
    box-shadow: unset;
  }
  .accordion-item {
    border: unset;
    background: #f8f8f8;
    &:first-child {
      margin-bottom: 10px;
    }
  }
  .date-selection {
    display: flex;
    border: 1px solid #0909541f;
    border-radius: 6px;
    span {
      font-size: 12px;
      font-weight: 400;
      color: #282e65;
      text-align: center;
      padding: 5px 20px;
      &:first-child {
        border-right: 1px solid #0909541f;
      }
      &:last-child {
        border-left: 1px solid #0909541f;
        padding: 5px 0px;
      }
    }
  }
  .search-block {
    position: relative;
    background-color: #f3f3f3;
    border: 1px solid #0909541f;
    border-radius: 6px;
    width: 180px;
    .searhc-icon {
      position: absolute;
      top: 5px;
      left: 7px;
    }
    input {
      width: 100%;
      background-color: transparent;
      padding: 4px 0px 4px 30px;
      font-size: 12px;
      font-weight: 400;
      color: #788693;
    }
  }
  .select-block {
    select {
      font-size: 12px;
      font-weight: 400;
      background-color: #f3f3f3;
      border: 1px solid #0909541f;
      border-radius: 6px;
      padding: 4px 5px;
    }
  }
  .react-datepicker-wrapper {
    input {
      background-color: transparent;
      border: unset;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #282e65;
    }
  }
  .active {
    color: #3d3efd !important;
    background-color: #eaeaf9 !important;
    input {
      color: #3d3efd !important;
      ::placeholder {
        color: #3d3efd !important;
      }
    }
  }
}
