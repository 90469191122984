#filter-options-container {
  display: flex;
  align-items: center;
  div {
    height: 30px;
    font-size: 14px;
    font-weight: 400;
    color: var(--2E65);
    border: 1px solid #0909541f;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    cursor: pointer;
    &:first-child {
      border-radius: 6px 0px 0px 6px;
      border-right: unset;
    }
    &:last-child {
      border-radius: 0px 6px 6px 0px;
      border-left: unset;
    }
  }
  .active {
    background-color: #e9e9f8;
    color: #3d3efd;
  }
}
