.autocomplete-dropdown-container{
    position: absolute;
    z-index: 10;
    top: -11px;
    left: 0;
    right: 0;
    border: 1px solid rgba(9, 9, 84, 0.12);
    background-color: #ffffff;
    border-radius: 5px;
    transform: translate3d(-1px, 60px, 0px);
    div{
        font-size: 13px;
        color: #212529 !important;
        padding: 10px;
    }
}