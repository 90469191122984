#overview-container {
  .overview_box {
    background-color: #f8f8f8;
    padding: 15px;
    border-radius: 8px;
    margin: 5px 0;

    .box {
      h1 {
        color: #282e65;
        font-size: 18px;
        line-height: 27px;
        margin: 4px 0;
        font-weight: 600;
      }
      p {
        color: #788693;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }

      label {
        color: #1d1d1d;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 4px 0;
        display: flex;
        gap: 5px;
        span {
          color: #3d3efd;
          font-weight: 14px;
          cursor: pointer;
          font-weight: 400;
          line-height: 21px;
          margin: 0 10px;
        }
        img {
          width: 18px;
          height: 18px;
          object-fit: contain;
        }
      }

      table {
        tr {
          .custom-w {
            width: 152px;
          }
          td {
            color: #282e65;
            padding: 5px 4px 5px;
          }
          &:last-child {
            td {
              border: none;
            }
          }
          &:hover {
            background-color: unset;
          }
        }
      }
    }

    .map {
      iframe {
        width: 100%;
        height: 100%;
      }
    }

    .service_box {
      background-color: #ffffff;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      padding: 15px;
      .golden_care {
        margin-right: 10px;
        padding: 10px;
        width: 170px;
        border-radius: 10px;
        background-color: #fff6d4;
        img {
          width: 60px;
          height: 60px;
          margin-bottom: 15px;
          object-fit: contain;
        }
        h1 {
          font-weight: 600;
          font-weight: 18px;
          line-height: 27px;
          color: #282e65;
        }
        p {
          font-size: 13px;
          font-weight: 400;
          margin-bottom: 10px;
          line-height: 19.3px;
        }
      }

      .services_content {
        h1 {
          color: #282e65;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }

        ul {
          li {
            font-size: 14px;
            display: flex;

            gap: 5px;
            font-weight: 400;
            line-height: 21px;
            color: #282e65;

            margin: 6px 0;
            img {
              display: inline;
              width: 20px;
              height: 20px;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
