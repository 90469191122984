@import "../../../../assets/css/theme/variables";

.calendar-wrapper {
    .calendar-day-view {
        .user-pic-cell {
            width: 100%;
            .pic {
                width: 32px;
                height: 32px;
                margin-right: 12px;
                flex-shrink: 0;
            }
            .txt {
                overflow: hidden;
                text-overflow: ellipsis;
                h4,
                h5 {
                    font-weight: 400;
                    color: $base-color-private;
                    overflow: hidden;
                    text-overflow: inherit;
                }
                h4 {
                    font-size: 16px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                h5 {
                    font-size: 14px;
                    margin: 0;
                }
            }
        }
    }
}
