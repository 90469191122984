.sub-package-inputs{
    display: flex;
    width:81%;
    input {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    select {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: 0px !important;
    }
}