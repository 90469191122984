@import "../../../../../../assets/css/theme/variables";

.diagnostic-accordion-wrapper {
  table {
    padding: unset;
    margin: unset;
  }
  .accordion-blc {
    border-bottom: 2px solid #fff;
    &:last-child {
      margin-bottom: 0;
    }

    .accordion-btn-wrapper {
      label {
        font-weight: bold;
        font-size: 18px;
      }
      .accordion-btn {
        font-size: 18px;
        font-weight: 600;
        line-height: 150%;
        color: $base-color-private;
        padding: 8px 5px;
        margin-right: 15px;
        &:hover,
        &:focus {
          color: $base-color-private;
        }
        * {
          cursor: pointer;
        }
      }
      .add-btn {
        color: $success;
        &:hover {
          color: $success-dark;
        }
        span {
          font-size: 20px;
        }
      }
    }
    .collapse-data-blc {
      margin: 0 0 0 12px;
      border-top: 1px solid #eeeef2;
      tbody tr {
        .low-val {
          color: #3d3efd;
        }
        .high-val {
          color: #de2b2b;
        }
        .icon-cell {
          .icon {
            font-size: 20px;
            color: $success;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .appointment-info-tab-blc {
    .nav-tabs {
      border: none;
      margin-bottom: 10px;

      .nav-item {
        border: 1px solid rgb(210 210 221);
        margin-left: -1px;
        min-width: 150px;

        &:first-child {
          border-radius: 6px 0px 0px 6px;
          margin-left: 0;
        }

        &:last-child {
          border-radius: 0 6px 6px 0;
        }

        .nav-link {
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          border: none;
          color: $base-color-private;
          background-color: transparent;
          border-radius: 0;
          margin-bottom: 0;
          text-align: center;
          padding: 7px 10px;

          &.active {
            color: $primary;
            background: rgba($primary, 0.08);
          }
          &:hover {
            background-color: rgba($primary, 0.04);
          }
        }
      }
    }
    .appointment-form-blc {
      padding: 3px 3px 0 0;
      max-width: 700px;
      width: 100%;
      > .row-block {
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 10px;
        label {
          font-weight: 600;
          width: 150px;
          flex-shrink: 0;
          margin-right: 15px;
          margin-top: 10px;
          &.required-input {
            &:after {
              margin-left: 3px;
            }
          }
        }
        .right-col {
          .input-wrapper {
            .form-control {
              font-size: 14px;
              height: 40px;
              border-radius: 6px;
            }
            textarea.form-control {
              height: 100px;
            }
          }
          .form-control {
            height: 40px;
          }
        }
      }
      &.view-mode {
        .row-block {
          align-items: center;
          label {
            margin-top: 0;
          }
          .right-col {
            .form-control {
              padding: 0;
              background-color: transparent;
              border-color: transparent;
              box-shadow: none;
              color: $base-color-private;
              opacity: 1;
            }
            select.form-control {
              background-image: none;
            }
          }
        }
      }
    }
  }
}
