.general-info-blc {
    p {
        display: flex;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
        label {
            min-width: 185px;
            color: #788693;
            margin-right: 15px;
        }
    }
    &.responsive {
        p {
            @media (max-width: 450px) {
                flex-direction: column;
            }
            label {
                @media (max-width: 1150px) {
                    min-width: 175px;
                }
            }
        }
    }
}
