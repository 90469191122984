@import "../theme/variables";

.add-pet-health-form {
  .row-block {
    margin-bottom: 27px;
    &:last-child {
      margin-bottom: 0;
    }
    > h2 {
      font-size: 26px;
      font-weight: 600;
      margin-bottom: 12px;
      color: rgba($base-color, 0.75);
      @media (max-width: 1919px) {
        font-size: 22px;
      }
      @media (max-width: 767px) {
        font-size: 20px;
      }
    }
    > .inner {
      .form-options {
        background: rgba(61, 62, 253, 0.02);
        border: 1px solid rgba(8, 8, 41, 0.08);
        border-radius: 20px;
        padding: 48px 30px 8px;
        @media (max-width: 1919px) {
          padding: 38px 20px 8px;
          border-radius: 10px;
        }
        .checkbox-wrapper {
          padding-right: 20px;
          margin-bottom: 40px;
          width: 20%;
          @media (max-width: 1919px) {
            margin-bottom: 30px;
          }
          @media (max-width: 1250px) {
            width: 25%;
          }
          @media (max-width: 991px) {
            width: 50%;
          }
          @media (max-width: 700px) {
            width: 100%;
            padding-right: 0;
          }
          .txt {
            color: rgba($base-color, 0.75);
          }
        }

        .add-more-info {
          margin-bottom: 40px;
          .checkbox-wrapper {
            width: auto;
            margin-bottom: 0;
            @media (max-width: 700px) {
              margin-right: 20px;
            }
          }
          .other-input {
            width: 373px;
            margin-top: 15px;
            @media (max-width: 1919px) {
              width: 280px;
            }
            @media (max-width: 640px) {
              width: 100%;
            }
            .form-control {
              height: 47px;
              margin-bottom: 6px;
            }
            .msg {
              font-style: italic;
              font-family: "SFProDisplay-Medium";
              font-size: 14px;
              line-height: 17px;
              color: rgba($base-color, 0.5);
            }
          }
        }
      }
    }
  }
}
