#schedule-requests-container {
  table {
    tbody {
      tr {
        border-bottom: 1px solid #e6e6e6;
        td {
          border-bottom: unset;
          user-select: none;
        }
      }
    }
  }
}
