@import "../.././../../../assets/css/theme/variables";

.add-allergy {
    .allergy-list {
        background: white;
        border-radius: 6px;
        border: 1px solid rgba(8, 8, 41, 0.1);
        padding: 0.375rem 15px;

    }
   
    .item-cell {
        background: #f8f8f8;
        padding: 9px 10px;
        margin-bottom: 5px;
        margin-top: 5px;
        margin-right: 5px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
    }

    .close-btn {
        font-size: 19px;
        color: $danger;
        padding: 5px;

        &:hover,
        &:focus {
            color: $danger-dark;
        }

    }

    .add-btn {
        font-size: 19px;
        color: $success;
        padding: 5px;

        &:hover,
        &:focus {
            color: $success-dark;
        }
    }
}
