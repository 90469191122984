.profile-contact {
    > .inner {
        .contact-box {
            background: #f8f8f8;
            border-radius: 10px;
            margin-bottom: 5px;
            padding: 10px;
            .pic {
                width: 32px;
                height: 32px;
                margin-right: 12px;
            }
            .txt {
                label {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    margin-bottom: 6px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    span {
                        width: 22px;
                        margin-right: 8px;
                        flex-shrink: 0;
                    }
                }
            }
        }
        .add-btn {
            font-size: 13px;
            font-weight: 400;
            line-height: 150%;
            color: #788693;
            padding: 10px;
        }
    }
    .edit-btn1 {
        color: #788c9f;
        &:hover{
            color: #3d3efd;
        }
    }
}
