#patient-records-report-container {
  .bg-filter-class {
    background-color: rgba(248, 248, 248, 0.9);
    border-radius: 10px;
    background: rgba(228, 228, 228, 0.28);
    padding: 15px;

    .filter-block {
      .date-select-container {
        .date-wrapper {
          width: 100%;
          height: 48px;

          &.active {
            .form-control {
              // background-color: rgba($primary, 0.08);
              // color: $primary !important;
            }

            input[type="text"] {
              background-color: #fff;

              color: #1d1d1d;
            }
          }

          .react-datepicker-popper {
            width: 250px;
          }

          .date-cell {
            height: 100%;

            .react-datepicker-wrapper {
              height: 100%;

              input[type="text"] {
                background-color: transparent;
                display: inline-flex;
              }

              .react-datepicker__input-container {
                height: 100%;
                display: inline-flex;

                &::after {
                  display: none;
                }

                .form-control {
                  font-size: 16px;
                  height: 100%;
                  border-radius: 8px;
                  color: #1d1d1d;
                  padding: 0.375rem 15px;
                  background-color: #fff;
                  border: 1px solid rgba(8, 8, 41, 0.1);
                  box-shadow: 0px 4px 10px rgba(61, 57, 137, 0.05);
                  padding-right: 15px;
                  height: 100%;

                  &::placeholder {
                    color: #1d1d1d;
                  }

                  &:focus {
                    box-shadow: none !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .g-report {
    font-size: 13px;
    font-weight: 600;
    width: 100%;
    height: 40px;
    line-height: 0.5;
    text-wrap: wrap;
    border-radius: 8px;
    background: #3d3efd;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.14);
  }

  .details-section {
    border-radius: 10px;
    background: rgba(228, 228, 228, 0.28);

    .search-cell-conatainer {
      height: 45px;
      position: relative;
      background-color: #f8f8f8;
      border-color: transparent;
      box-shadow: none;
      background-position-x: calc(100% - 13px);
      background-size: 10px;

      .search-icons {
        position: absolute;
        top: 15px;
        left: 15px;
      }

      .form-control {
        font-size: 15px;
        height: 45px;
        border-radius: 8px;
        color: #1d1d1d;
        padding: 0.375rem 40px;
        background-color: #fff;
        border: 1px solid rgba(8, 8, 41, 0.1);
        box-shadow: 0px 4px 10px rgba(61, 57, 137, 0.05);
      }
    }
    .card-block {
      .avrage-card {
        .items-details {
          height: 160px;
          display: flex;
          flex-direction: column;
          justify-content: center; /* Center vertically */
          align-items: center; /* Center horizontally */
          background-color: #ffffff;
          border-radius: 10px;
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.14);
          .chart-item {
            width: 60px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .table-one {
    tbody {
      tr:nth-child(odd) {
        --bs-table-accent-bg: rgba(228, 228, 228, 0.28);
        background: transparent;
      }

      tr:nth-child(even) {
      }
      --bs-table-accent-bg: #fff;
      background: #fff;
    }
  }
}
