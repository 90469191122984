.sort-column-arrow {
    height: 21px;
    span {
        font-size: 17px;
        color: rgba(120, 140, 159, 0.5);
    }
    .up {
        top: 0;
    }
    .down {
        top: 5px;
    }
}
