.plan-price-wrapper {
  .plan-types {
    margin-bottom: 28px;
    .inner {
      padding: 7px 16px;
      border-radius: 40px;
      .btn {
        min-width: 152px;
        padding: 8px 15px;
        font-family: "Inter", sans-serif;
        line-height: 21px;
        transition: none;
        @media (max-width: 480px) {
          min-width: initial;
        }
      }
      .btn-link {
        color: $base-color;
      }
    }
  }
}
