.tab-wrapper.tab-wrapper-setting {
    .tab-content {

        // Common Style
        .tab-row {
            background: #f8f8f8;
            padding: 20px;
            margin-bottom: 8px;
            border-radius: 8px;

            @media (max-width: 640px) {
                padding: 15px;
            }

            .tab-col {
                .form-block {
                    .form-group {
                        margin-bottom: 10px;
                    }
                }

                &.lft {
                    max-width: 400px;
                    width: 100%;
                    margin-right: 30px;
                }

                >.inner {
                    max-width: 400px;
                    width: 100%;
                }

                .info-block {
                    margin-bottom: 11px;

                    h3 {
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 24px;
                        margin-bottom: 8px;
                    }

                    p {
                        font-size: 16px;
                        font-weight: 300;
                        color: #788693;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .msg-rw {
                    span {
                        margin-right: 11px;
                    }

                    .btn {
                        padding: 6px 10px;
                    }
                }
            }
        }

        // Each Tab Block Style
        .profile-setting-tab {
            .tab-row {
                @media (max-width: 800px) {
                    flex-direction: column;
                }

                .tab-col {
                    &.lft {
                        @media (max-width: 800px) {
                            margin-right: 0;
                            margin-bottom: 10px;
                            max-width: none;
                        }
                    }

                    >.inner {
                        @media (max-width: 800px) {
                            max-width: none;
                        }
                    }
                }

                .brand-logo-form {
                    >.row-block {
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .checkbox-wrapper {
                            margin-bottom: 6px;
                        }

                        .browse-file-blc {
                            margin-left: 34px;

                            @media (max-width: 800px) {
                                margin-left: 0;
                            }
                        }

                        &.prefer-check-item {
                            .checkbox-wrapper {
                                margin-bottom: 10px;

                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }

                .prefer-setting-blc {
                    >.row-block {
                        margin-bottom: 20px;

                        @media (max-width: 800px) {
                            flex-direction: column;
                            align-items: stretch !important;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        >label {
                            max-width: 400px;
                            width: 100%;
                            line-height: 150%;
                            margin-right: 30px;

                            @media (max-width: 800px) {
                                max-width: none;
                                margin-right: 0;
                                margin-bottom: 6px;
                            }
                        }

                        .form-item-blc {
                            .form-group-wrapper {
                                margin: 0 22px 0 0;

                                @media (max-width: 800px) {
                                    flex: 1;
                                }
                            }

                            .hint-btn {
                                color: #fff;

                                span {
                                    width: 12px;
                                    height: 12px;
                                    font-size: 8px;
                                    background-color: #0077ff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.input-wrapper2 {
    .form-floating {
        .form-control {
            min-height: unset;
        }

        label {
            text-transform: unset;
            font-size: 12px;
        }
    }
}

.dropdown_floating {
    .form-floating {
        .form-control {
            padding: 10px 16px 0 !important;
        }
    }
}