.text-clip {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.text-clip.clip-2 {
    -webkit-line-clamp: 2;
}
.text-clip.clip-3 {
    -webkit-line-clamp: 3;
}
.text-clip.clip-4 {
    -webkit-line-clamp: 4;
}
.text-clip.clip-5 {
    -webkit-line-clamp: 5;
}
.text-clip.clip-6 {
    -webkit-line-clamp: 6;
}
