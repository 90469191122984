@import "../../assets/css/theme/variables";

.message-center-wrapper {
  > .left-col {
    width: 25%;
    min-width: 200px;
    padding: 3px;
    margin-right: 44px;

    @media (max-width: 1919px) {
      min-width: 330px;
      max-width: 330px;
      margin-right: 20px;
    }

    @media (max-width: 991px) {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 999;
      transform: translateX(-100%);
      transition: all ease 0.3s 0s;
      background-color: #fff;
      padding: 10px;
      min-width: 320px;
      max-width: 320px;

      &.active {
        transform: translateX(0);
      }
    }

    .user-message-list-wrapper {
      background: #fff;

      .nav-tabs {
        flex-wrap: nowrap;
        background: #fff;
        height: 71px;

        @media (max-width: 1919px) {
          height: 50px;
        }

        .nav-item {
          .nav-link {
            font-weight: 500;
            color: rgba($base-color, 0.5);
            border-color: transparent;
            pointer-events: none;

            &:hover {
              color: $primary;
            }

            &.active {
              color: rgba($base-color, 1);
            }
          }
        }

        .new-chat-btn {
          align-self: center;
          font-family: 500;
          font-size: 13px;
          padding: 5px 10px;
          background-color: rgba($primary, 0.08);
          margin-right: 10px;
          border-radius: 5px;

          &:hover,
          &:focus,
          &:active {
            background-color: rgba($primary, 0.1);
            color: $primary;
          }

          span {
            margin-right: 6px;
            margin-top: 2px;
          }
        }
      }
    }

    .message-sidebar.loaderWrapper .spinner-blc {
      lottie-player {
        width: 42px;
      }
    }
  }

  .message-panel-overlayer {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 4;
    width: 0;
    height: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all ease 0.3s;

    &.active {
      @media (max-width: 991px) {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 1;
      }
    }
  }

  .btn.message-panel-toggle-btn {
    display: none !important;
    padding: 0;
    width: 40px;
    height: 40px;
    justify-content: center;

    @media (max-width: 991px) {
      display: inline-flex !important;
    }
  }

  > .mid-col {
    width: 50%;

    .chat-footer {
      height: 57px;
      margin: 0 35px 35px;
      position: relative;

      @media (max-width: 1919px) {
        height: 42px;
        margin: 0 25px 25px;
      }

      @media (max-width: 640px) {
        margin: 0 20px 20px;
      }

      &.clinic-footer {
        .input-cell {
          .form-control {
            padding-left: 15px;

            @media (max-width: 374px) {
              border-top-left-radius: 6px;
            }
          }
        }
      }

      .input-cell {
        margin-right: 25px;

        @media (max-width: 1919px) {
          margin-right: 15px;
        }

        .form-control {
          padding-left: 115px;

          @media (max-width: 374px) {
            padding-left: 0.75rem;
            border-top-left-radius: 0;
          }
        }

        .form-control::placeholder {
          color: rgba($base-color, 0.4);
          font-style: italic;
        }

        .form-control::placeholder:-moz-placeholder {
          color: rgba($base-color, 0.4);
          font-style: italic;
        }
      }

      .send-msg-btn {
        width: 55px;
        height: 55px;
        box-shadow: 0px 4px 10px rgba(61, 57, 137, 0.05);
        border-radius: 10px;
        flex-shrink: 0;

        @media (max-width: 1919px) {
          width: 40px;
          height: 40px;
          border-radius: 6px;

          svg {
            width: 20px;
          }
        }
      }

      .msg-type-btns {
        top: 0;
        left: 8px;
        bottom: 0;
        margin: auto;
        height: 24px;

        @media (max-width: 374px) {
          top: -67px;
          left: 0px;
        }

        .btn {
          font-family: 500;
          font-size: 10px;
          height: 100%;
          padding: 0 5px;
          margin-right: 4px;
          border-radius: 3px;
          box-shadow: none;

          &:hover {
            color: #fff;
          }

          &.active {
            .icon {
              span {
                opacity: 1;
              }
            }
          }

          &.direct-btn {
            background-color: rgba($primary, 0.09);
            color: $primary;

            &:hover {
              background-color: rgba($primary, 0.2);
              color: $primary;
            }

            &.active {
              background-color: rgba($primary, 1);
              color: #fff;
            }
          }

          &.sms-btn {
            background-color: rgba(235, 93, 1, 0.09);
            color: rgba(235, 93, 1, 1);

            &:hover {
              background-color: rgba(235, 93, 1, 0.2);
              color: rgba(235, 93, 1, 1);
            }

            &.active {
              background-color: rgba(235, 93, 1, 1);
              color: #fff;
            }
          }

          .icon {
            width: 11px;
            height: 11px;
            margin-right: 2px;
            margin-top: -1px;
            border: 1px solid;

            span {
              width: 5px;
              height: 5px;
              background-color: #fff;
              opacity: 0;
            }
          }

          @media (max-width: 374px) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }

  > .right-col {
    width: 25%;
    min-width: 300px;
    border-left: 1px solid rgba(8, 8, 41, 0.1);
    padding: 0px 10px;
    overflow-y: auto;
  }
}

.message-center-head {
  h2 {
    @media (max-width: 700px) {
      flex-direction: column;
      align-items: stretch !important;
    }
  }

  .phone-block {
    background-color: #f0f2ff;
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: -2px 5px 0px #e7e9ff;

    h3 {
      font-size: 18px;
      margin-bottom: 4px;
      color: #3d3ffd;

      span {
        margin-right: 7px;
        opacity: 0.8;
      }
    }

    h4 {
      font-size: 15px;
      font-family: $primary-font;
      margin-bottom: 0;
      color: #48485a;
    }
  }
}

.custom-card {
  background-color: $blue;
  color: white;

  .sub-title {
    color: rgba(245, 245, 245, 0.541);
  }
}

.custom-badge {
  background-color: #e9e9e99f;
  color: rgb(36, 36, 255);
  border-radius: 15px;
  margin-left: 10px;
  padding: 5px 8px;
  font-size: 12px;
}

.msg_row {
  width: 20%;
}

.group-wrapper {
  background-color: rgba(248, 248, 248, 0.9);
  border-radius: 8px;
  padding: 8px;
  font-size: 12px;
  color: rgba(40, 46, 101, 1);
}

.group-wrapper:hover {
  background-color: #3d3ffd;
}

.group-wrapper:hover p {
  color: #fff;
}
.group-active {
  background-color: #3d3ffd;
  p {
    color: #fff;
  }
}
.group-wrapper:hover .private-budge,
.group-active .private-budge {
  color: #e7e9ff !important;
  border: 1px solid #e7e9ff !important;
}
.img-overlay-wrapper img, .img-overlay-wrapper div {
  margin-left: -4px;
  border-radius: 50%;
  border: 1px solid #ffffff;
}

.img-overlay-wrapper div{height: 20px;
  width: 20px;
  background-color: #ffffff;
  color:#282E65;
  font-size: 12px;
  text-align: center;}

.chat-list {
  width: 300px !important;
}

.chat-message {
  width: 75%;
}

.side-tab-area {
  width: 80px;
}

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 40px;
}

.settings-tray {
  background: #eee;
  padding: 10px 15px;
  border-radius: 7px;

  .no-gutters {
    padding: 0;
  }
}

.search-box {
  background: #fafafa;
  padding: 10px 13px;

  .input-wrapper {
    background: #fff;
    border-radius: 40px;

    i {
      color: grey;
      margin-left: 7px;
      vertical-align: middle;
    }
  }
}

input {
  border: none;
  border-radius: 30px;
  width: 80%;

  &::placeholder {
    color: #e3e3e3;
    font-weight: 300;
    margin-left: 20px;
  }

  &:focus {
    outline: none;
  }
}

.chat-bubble {
  padding: 10px 14px;
  margin: 6px 0px;
  position: relative;
 // animation: fadeIn 1s ease-in;
  width: fit-content;
  border-radius: 8px;
}

.chat-bubble--left {
  background-color: rgba(235, 245, 255, 1);
}

.chat-bubble--right {
  background: rgba(115, 103, 113, 0.1);
  margin-left: auto;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.offset-md-9 {
  .chat-bubble {
    background: #74b9ff;
    color: #fff;
  }
}

.group-listt {
  li:not(:last-child) {
    margin-bottom: 10px;
  }
  .private-budge {
    color: rgba(40, 46, 101, 1);
    font-size: 14px;
    padding: 0px 10px;
    border: 1px solid rgba(9, 9, 84, 0.12);
    border-radius: 10px;
    background-color: transparent;
  }
}
.unreadMsg-dot {
  border-radius: 50%;
  padding: 3px;
  margin: 2px;
  background-color: #3d3ffd;
}
.direct-msg-wrapper .nav-pills .nav-link {
  color: #282e65;
  font-size: 14px;
  padding: 2px 10px;
  border: 1px solid rgba(9, 9, 84, 0.12);
  border-radius: 10px;
}

.direct-msg-wrapper .nav-pills .nav-link.active,
.direct-msg-wrapper .nav-pills .show > .nav-link {
  background: rgba(61, 62, 253, 0.08);
  color: rgba(61, 62, 253, 1);
}

.direct-msg-wrapper .nav-pills .nav-item:not(:last-child) {
  margin-right: 5px;
}

.left-side-user-list {
  border-right: 1px solid #eeeef2;
  height: calc(100vh - 10px);
  overflow-y: auto;
}

.private-wrapper {
  color: rgba(40, 46, 101, 1);
  border-radius: 10px;
  border: 1px solid rgba(9, 9, 84, 0.12);
  font-size: 13px;
  padding: 1px 10px;
}

.chat-panel-140 {
  height: calc(100vh - 140px);
  overflow-y: auto;
  overflow-x: hidden;
}
.chat-panel-250 {
  height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
}
.chater-name {
  color: #282e65;
  font-size: 14px;
  font-weight: 400;
}

.chat-date {
  color: #788c9f;
  font-size: 14px;
  font-weight: 400;
}

.chat-tagname {
  border-radius: 3px;
  background: #eeeef2;
  padding: 2px 4px;
  font-size: 13px;
}

.slip-detail {
  background-color: #fff;
  width: fit-content;
}

.suggestion-wrapper {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.6);
  position: relative;
}

.dr-name {
  font-size: 14px;
}

.suggestion-wrapper::before {
  position: absolute;
  top: 10%;
  left: 0;
  width: 4px;
  height: 80%;
  background-color: rgb(64, 157, 224);
  content: "";
}

.appointment-wrapper {
  background-color: #fff;
  width: 50%;
}

.fs-13 {
  font-size: 13px;
}

.rate-appointment p {
  font-size: 14px;
}

.rate-appointment h4 {
  color: #282e65;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  margin-top: 10px;
}

.thumb-btn {
  color: #282e65;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  padding: 2px 20px;
}

.thumb-btn svg {
  margin-right: 5px;
}

.group-add-listing {
  tr {
    th {
      font-size: 13px !important;
    }
    td {
      font-size: 13px !important;
      .pic-cell.btn {
        color: #282e65 !important;
      }
    }
  }
}
.modal-open {
  .cancel-btn {
    font-weight: 600;
    border: 1px solid rgba(9, 9, 84, 0.12);
    min-width: 150px;
  }
  .btn-3D3EFD {
    background-color: #3d3efd;
    font-weight: 600;
    color: #ffffff;
  }
}
