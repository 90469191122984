.profile-info {
  background-color: #f8f8f8;
  border-radius: 20px;
  margin-top: 20px;
  justify-content: space-between;
  .email {
    max-width: 220px;
  }
  .phone {
    max-width: 120px;
  }
  .address {
    max-width: 180px;
  }
  .add-plan {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    height: 100px;
    align-items: center;
    justify-content: center;
    background-color: #afe8773d;
    border-radius: 10px;
    padding: 0 10px;
    p {
      padding: 0 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    .add-btn {
      // width: 107px !important;
      height: 28px !important;
      font-size: 13px !important;
      margin: 0 !important;
      margin-top: 10px !important;
      padding: auto !important;
      // span {
      //   font-size: 13px !important;
      // }
    }
  }
}

.info {
  height: 100%;
  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 4px;
  }

  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
  }

  h5 {
    font-weight: 400;
    font-size: 13px;
    margin: 1px;
  }
}
