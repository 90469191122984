@import "../../../../../../assets/css/theme/variables";

.orders-accordion-wrapper {
    .accordion-blc {
        border-bottom: 2px solid #fff;

        &:last-child {
            margin-bottom: 0;
        }

        .accordion-btn-wrapper {
            .accordion-btn {
                font-size: 18px;
                font-weight: 600;
                line-height: 150%;
                color: $base-color-private;
                padding: 8px 5px;
                margin-right: 15px;

                &:hover,
                &:focus {
                    color: $base-color-private;
                }

                * {
                    cursor: pointer;
                }
            }

            .add-btn {
                color: $success;

                &:hover {
                    color: $success-dark;
                }

                span {
                    font-size: 20px;
                }
            }
        }

        .collapse-data-blc {
            margin: 0 0 0 12px;
            border-top: 1px solid #eeeef2;

            tbody tr {

                td {

                select {
                    -moz-appearance: none;
                    -webkit-appearance: none;

                }

                select::-ms-expand {
                    display: none;
                  }
                }

                .status {
                    width: fit-content;
                    box-sizing: border-box;
                    text-align: center;
                    /* Tags/12 M */
                    font-family: Inter;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;

                    /* Auto layout */

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 2px 4px;
                    gap: 10px;
                    border: 0.5px solid rgba(255, 255, 255, 0.2);
                    border-radius: 3px;

                    /* Inside auto layout */

                    flex: none;
                    order: 0;
                    flex-grow: 0;

                }

                

                .status-Ordered {
                  
                    color: #9428FF;
                   
                    background:#EBE7FF;
                
                }

                .status-Completed {
                  
                    color: #169677;
                   
                    background: #DBF8F1;
                
                }

                .status-Stopped {
                   
                    color: var(--main-buttons, #DE2B2B);
                   

                    background: #FFECF0;
                  
                }

                .status-Cancelled {
                   
                    color: var(--main-buttons, #DE2B2B);
                   

                    background: #FFECF0;
                  
                }

                .status-Active {
                   
                    color: var(--main-buttons, #229CF4);
                  

                    background: #DEF1FF;
             
                }

                .icon-cell {
                    .icon {
                        font-size: 20px;
                        color: $success;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .appointment-info-tab-blc {
        .nav-tabs {
            border: none;
            margin-bottom: 10px;

            .nav-item {
                border: 1px solid rgb(210 210 221);
                margin-left: -1px;
                min-width: 150px;

                &:first-child {
                    border-radius: 6px 0px 0px 6px;
                    margin-left: 0;
                }

                &:last-child {
                    border-radius: 0 6px 6px 0;
                }

                .nav-link {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    border: none;
                    color: $base-color-private;
                    background-color: transparent;
                    border-radius: 0;
                    margin-bottom: 0;
                    text-align: center;
                    padding: 7px 10px;

                    &.active {
                        color: $primary;
                        background: rgba($primary, 0.08);
                    }

                    &:hover {
                        background-color: rgba($primary, 0.04);
                    }
                }
            }
        }

        .appointment-form-blc {
            padding: 3px 3px 0 0;
            max-width: 700px;
            width: 100%;

            >.row-block {
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 10px;

                label {
                    width: 150px;
                    flex-shrink: 0;
                    margin-right: 15px;
                    margin-top: 10px;

                    &.required-input {
                        &:after {
                            margin-left: 3px;
                        }
                    }
                }

                .right-col {
                    .input-wrapper {
                        .form-control {
                            font-size: 14px;
                            height: 40px;
                            border-radius: 6px;
                        }

                        textarea.form-control {
                            height: 100px;
                        }
                    }

                    .form-control {
                        height: 40px;
                    }
                }
            }

            &.view-mode {
                .row-block {
                    align-items: center;

                    label {
                        margin-top: 0;
                    }

                    .right-col {
                        .form-control {
                            padding: 0;
                            background-color: transparent;
                            border-color: transparent;
                            box-shadow: none;
                            color: $base-color-private;
                            opacity: 1;
                        }

                        select.form-control {
                            background-image: none;
                        }
                    }
                }
            }
        }
    }
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    background: unset !important;
    --bs-table-color-type: unset !important;
    --bs-table-bg-type: unset !important;
}

.orders-block {
    .orders-listing-wrapper {
        .table {
            thead {
                th {
                    border-bottom: 1px solid #EEEEF2 !important;
                }
            }
        }
    }
}

.orders-block {
    .orders-listing-wrapper {
        .table tr {
            &:last-child {
                td {
                    border-bottom: 1px solid #EEEEF2 !important;
                }
            }
        }
    }
}

td {
    color: #282E65; //!important;
}