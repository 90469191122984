@import "../../assets/css/theme/variables";

.form-template-blc {
  background: #f8f8f8;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 8px;

  .head {
    > .left-col {
      margin-right: 20px;
      h3,
      p {
        font-weight: 400;
        line-height: 150%;
      }

      h3 {
        font-size: 16px;
      }

      p {
        font-size: 14px;
      }
    }

    > .right-col {
      .add-btn {
        font-size: 19px;
        color: $success;

        &:hover,
        &:focus {
          color: $success-dark;
        }
      }
    }
  }

  .table-listing-blc {
    .table {
      tr {
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
}
