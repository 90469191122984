.messages-block { 
    gap : 20px;
    .left-col { 
        width: 100%;
    }

    .right-col { 
        gap: 5px;

        display: flex;
        flex-direction: column;

        .filter-block
        {
            display: flex;
            flex-direction: column;

            background: rgba(248, 248, 248, 0.9);
            border-radius: 10px;

            padding: 15px;
            gap: 15px;

            .list-section {
                padding: 0;
                gap:15px;
                display: flex;
                flex-direction: column;
                .title {

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    /* identical to box height, or 24px */


                    /* Dark Blue */

                    color: #282E65;

                }


                .list {

                    display: flex;
                    flex-direction: column;
                
                    padding: 0px;
                    gap: 12px;

                    .list-item{
                        display: flex;
                        flex-direction: row;

                        max-height:24px;
                        align-items: center;
                        
                        padding: 2px 0px;
                        gap: 5px;

                        .icon {

                            width: 20px;
                            height: 20px;
                            img {
                                width: 20px;
                                height: 20px;
                            }

                        }

                        .name {
                            /* 13 */
                            display: flex;

                            align-content: center;

                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 150%;
                            /* identical to box height, or 20px */

                            /* Dark Blue */

                            color: #282E65;
                        }
                    }



                }

            }

            

        }
    }
    
}


@media (max-width: 884.98px) {
    .pet-owner-dashboard .messages-block {
        flex-wrap: wrap;
    }
}