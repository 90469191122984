@import "../../../../../../assets/css/theme/variables";


.upcoming-visit-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;

   // width: 280px;
    //height: 317px;

    /* BG */

    background: rgba(248, 248, 248, 0.9);
    border-radius: 10px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    .time-to-start-banner{

        box-sizing: border-box;

        /* Auto layout */

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 2px;

        // width: 260px;
        height: 25px;

        background: #FFF6E1;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 5px;

        /* Inside auto layout */

        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;

        .time-to-start-text{

            width: fit-content !important;
            height: 16px;

            /* Tags/13M */

            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            /* identical to box height */

            text-transform: uppercase;

            color: #E1AC03;


            /* Inside auto layout */

            flex: none;
            order: 0;
            flex-grow: 0;

        }
    }

    .appointment-info{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;

        // width: 260px;
        // height: 82px;


        /* Inside auto layout */

        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;


        .date{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 8px;

            // width: 260px;
            // height: 40px;


            /* Inside auto layout */

            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;

            .icon{


                width: 20px;
                height: 20px;


                /* Inside auto layout */

                flex: none;
                order: 0;
                flex-grow: 0;

            }

            .day {
                white-space: nowrap;
                // height: 21px;

                /* 14 */

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                /* identical to box height, or 21px */

                display: flex;
                align-items: center;

                /* Dark Blue */

                color: #282E65;


                /* Inside auto layout */

                flex: none;
                order: 1;
                flex-grow: 0;
            }

            .days-from-now{
                // width: 71px;
                // height: 21px;

                /* 14 */

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                /* identical to box height, or 21px */

                display: flex;
                align-items: center;

                /* Dark Blue */

                color: #282E65;


                /* Inside auto layout */

                flex: none;
                order: 2;
                flex-grow: 0;
            }
        }

        .time{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 8px;

            // width: 260px;
            // height: 20px;


            /* Inside auto layout */

            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;

            .icon {

                width: 20px;
                height: 20px;


                /* Inside auto layout */

                flex: none;
                order: 0;
                flex-grow: 0;

            }

            .time-range {


                // width: 128px;
                // height: 20px;

                /* 13 */

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 150%;
                /* identical to box height, or 20px */

                display: flex;
                align-items: center;

                /* Dark Blue */

                color: #282E65;


                /* Inside auto layout */

                flex: none;
                order: 1;
                flex-grow: 0;

            }
        }

        .location{
            /* Auto layout */

            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 8px;

            // width: 260px;
            // height: 21px;


            /* Inside auto layout */

            flex: none;
            order: 2;
            align-self: stretch;
            flex-grow: 0;

            .icon {

                width: 20px;
                height: 20px;


                /* Inside auto layout */

                flex: none;
                order: 0;
                flex-grow: 0;

            }

            .location-name {



                // width: 116px;
                // height: 21px;

                /* 14 */
                width: fit-content;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                /* identical to box height, or 21px */

                display: flex;
                align-items: center;

                /* Dark Blue */

                color: #282E65;


                /* Inside auto layout */

                flex: none;
                order: 1;
                flex-grow: 0;

            }
        }




    }

    .actions{

        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 12px;

        // width: 260px;
        //height: 20px;

        border-radius: 8px;

        /* Inside auto layout */

        flex: none;
        order: 4;
        align-self: stretch;
        flex-grow: 0;


        .start-video-btn{


            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px 10px;
            gap: 10px;

            // width: 260px;
            // height: 28px;

            /* Main */

            background: #3D3EFD;
            /* Button Drop Shadow */

            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.14);
            border-radius: 8px;

            /* Inside auto layout */

            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
                    


            .start-video-text{

                // width: 123px;
                // height: 16px;

                /* Button/M */

                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
                /* identical to box height */

                display: flex;
                align-items: center;
                text-transform: uppercase;

                /* Pure White */

                color: #FFFFFF;
                font-variation-settings: 'slnt' 0;


                /* Inside auto layout */

                flex: none;
                order: 0;
                flex-grow: 0;

            }
        }

        .action-btn-group{

            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 20px;

            // width: 176px;
            // height: 20px;


            /* Inside auto layout */

            flex: none;
            order: 1;
            flex-grow: 0;

            .action-btn{
                // width: 43px;
                // height: 20px;

                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                /* identical to box height, or 20px */

                display: flex;
                align-items: center;
                text-decoration-line: underline;

                /* Dark Blue */

                color: #282E65;


                /* Inside auto layout */

                flex: none;
                order: 0;
                flex-grow: 0;
                                        
            }
        }



    }


    .appointment-description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 5px;

        // width: 260px;
       

        /* Inside auto layout */

        flex: none;
        order: 3;
        flex-grow: 1;

        .appointment-title{
            // width: 260px;
            // height: 24px;

            /* 16 */

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            /* identical to box height, or 24px */


            /* Dark Blue */

            color: #282E65;


            /* Inside auto layout */

            flex: none;
            order: 0;
            flex-grow: 0;
        }
        .appointment-description-body{
            
            // width: 260px;

            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            /* or 21px */


            /* Dark Blue */

            color: #282E65;


            /* Inside auto layout */

            flex: none;
            order: 1;
            flex-grow: 1;
        }
    }
    .doctor-info{
        /* Doctors */


        /* Auto layout */

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;

        // width: 260px;
        // height: 50px;


        /* Inside auto layout */

        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;

        .picture {
            width: 50px;
            height: 50px;


            /* Inside auto layout */

            flex: none;
            order: 0;
            flex-grow: 0;

            img {
                width: 50px;
                height: 50px;
            }

        }
        .info {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 10px;

            // width: 200px;
            // height: 50px;


            /* Inside auto layout */

            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 1;

            .name-title {

                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                gap: 4px;

                // width: 170px;
                // height: 49px;


                /* Inside auto layout */

                flex: none;
                order: 0;
                flex-grow: 1;


                .name{
                    /* Dr. Brant Hillton */


                    // width: 170px;
                    // height: 25px;

                    /* 16 */

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    /* or 24px */

                    display: flex;
                    align-items: center;

                    /* Dark Blue */

                    color: #282E65;


                    /* Inside auto layout */

                    flex: none;
                    order: 0;
                    align-self: stretch;
                    flex-grow: 0;
                                                
                }

                .title {

                    /* DVM */


                    // width: 30px;
                    // height: 20px;

                    /* 13 */

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 150%;
                    /* identical to box height, or 20px */

                    display: flex;
                    align-items: center;

                    /* Dark Blue */

                    color: #282E65;


                    /* Inside auto layout */

                    flex: none;
                    order: 1;
                    flex-grow: 0;


                }

            }

            .action {

                width: 20px;
                height: 20px;


                /* Inside auto layout */

                flex: none;
                order: 1;
                flex-grow: 0;

            }
        }
    }

    .btn-cell {
        min-height:40px;
        align-self: stretch;

        align-items: flex-start;
        display: flex;
        width: 100%;
        .appointment-status {
            display: flex;
            padding: 5px 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            border-radius: 8px;
            //box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.14);
        }

        .in-progress {
            border: 1px solid rgba(0, 0, 0, 0.05);
            background: #EFE;
            color: var(--special-green, #11CA9D);
            font-family: Inter;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;

        }
    }
}