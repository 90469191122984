@import "../../assets/css/theme/variables";

.appointment-block-wrapper {
        .appointment-form-blc {
            padding: 3px 3px 0 0;
            max-width: 700px;
            width: 100%;
            > .row-block {
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 10px;
                label {
                    width: 150px;
                    flex-shrink: 0;
                    margin-right: 15px;
                    margin-top: 10px;
                    &.required-input {
                        &:after {
                            margin-left: 3px;
                        }
                    }
                }
                .right-col {
                    .input-wrapper {
                        .form-control {
                            font-size: 14px;
                            height: 40px;
                            border-radius: 6px;
                        }
                        textarea.form-control {
                            height: 100px;
                        }
                    }
                    .form-control {
                        height: 40px;
                    }
                }
            }
            &.view-mode {
                .row-block {
                    align-items: center;
                    label {
                        margin-top: 0;
                    }
                    .right-col {
                        .form-control {
                            padding: 0;
                            background-color: transparent;
                            border-color: transparent;
                            box-shadow: none;
                            color: $base-color-private;
                            opacity: 1;
                        }
                        select.form-control {
                            background-image: none;
                        }
                    }
                }
            }
        }
    


    }
