@import "../../../assets/css/theme/variables";
.card {
  background-color: #fff;
  border: 1px solid rgba(8, 8, 41, 0.1);
  box-shadow: 0px 10px 30px rgba(109, 141, 173, 0.1);
  border-radius: 20px;
  @media (max-width: 1919px) {
    border-radius: 15px;
  }
  &.shadow-md {
    box-shadow: 0px 4px 10px rgba(61, 57, 137, 0.05);
  }
}
.card.card-body {
  padding: 20px;
}
