#pet-owner-billing-container {
    width: 100%;

    .head {
        padding: 15px 18px;
    }

    .sort_head {
        flex-wrap: wrap;
        padding: 10px 18px;
        display: flex;
        gap: 20px;

        .date {
            display: flex;
            padding: 0 10px;
            position: relative;
            width: 250px;
            min-height: 35px;
            align-items: center;
            border-radius: 0.375rem;
            border: 1px solid rgba(8, 8, 41, 0.1);
            box-shadow: 0px 4px 10px rgba(61, 57, 137, 0.05);

            .react-datepicker__input-container {
                width: 100px;
                margin: 0 5px;
            }

            img {
                position: absolute;
                right: 3px;
                top: 6px;
                z-index: 9;
                width: 21px;
            }

            .react-datepicker-wrapper {
                width: fit-content;
            }

            input {
                width: 100%;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;

                &:focus {
                    border: none;
                }

                border: none;
            }
        }

        .select {
            .form-control {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                background-size: 12px auto;
            }
        }
    }

    table {
        thead {
            tr {
                th {
                    div {
                        display: flex;
                        align-items: center;

                        svg {
                            font-size: 10px;
                        }
                    }


                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    color: #282E65;

                    .pendding_bill {
                        color: #3D3EFD;
                        cursor: pointer;
                    }

                    .patient_detail {
                        display: flex;
                        gap: 10px;

                        img {
                            width: 20px;
                            height: 20px;
                            object-fit: contain;
                        }
                    }

                    .download_btn {
                        border: none;
                        background-color: #788C9F;
                        outline: none;
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 15px;
                        padding: 0;
                    }
                }

                &:nth-child(odd) {
                    background: rgba(228, 228, 228, 0.28);
                }
            }
        }

        .status-Fully-Paid {
            color: #169677;
            padding: 5px;
            font-size: 13px;
            border-radius: 4px;
            font-style: normal;
            font-weight: 400;
            background: #DBF8F1;
        }

        .status-Pending {
            padding: 5px;
            color: #7660FF;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            border-radius: 4px;
            background: #E0E9FF;
        }
    }

    .billpagination {
        padding: 15px 18px;

        .pagination-blc {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    @media(max-width:991px) {
        // .responsive_table {
        //     max-width: 1100px;
        //     overflow: auto;

        //     table {
        //         max-width: 1100px;
        //         overflow: auto;
        //     }
        // }

    }
}