@import "./variables";

.btn {
  font-size: 13px;
  border-radius: 8px;
  padding: 10px 20px;
  text-transform: uppercase;
  //box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.14);

  &:hover,
  &:focus,
  &:active:focus {
    box-shadow: none;
  }

  &.btn-outline-primary {
    border-radius: 40px;
  }
}

.btn-link {
  border-radius: 0;
  box-shadow: none;
  text-transform: capitalize;

  &.radius {
    border-radius: 8px;
  }
}




// ************************* Button-Fill-Color *************************//
// Primary
.btn.btn-primary {
  background-color: $primary;
  border-color: $primary;
  color: #fff !important;

  &:hover,
  &:focus,
  &:active {
    background-color: $primary-dark !important;
    border-color: $primary-dark !important;
  }

  &.rounded {
    border-radius: 40px !important;
  }
}

// Secondary
.btn.btn-secondary {
  background-color: $primary;
  border-color: $primary;
  color: #fff !important;

  &:hover,
  &:focus,
  &:active {
    background-color: $primary-dark;
    border-color: $primary-dark;
  }
}

// Danger
.btn.btn-success {
  background-color: $success;
  border-color: $success;
  color: #fff !important;

  &:hover,
  &:focus,
  &:active {
    background-color: $success-dark;
    border-color: $success-dark;
  }
}

// Danger
.btn.btn-danger {
  background-color: $danger;
  border-color: $danger;
  color: #fff !important;

  &:hover,
  &:focus,
  &:active {
    background-color: $danger-dark;
    border-color: $danger-dark;
  }
}

// Button-Link
.btn-link {
  color: $primary;
  text-decoration: none;
  border-radius: 0;

  &:hover {
    color: $primary-dark;
  }
}

.btn-link.cancel-btn {
  color: #767676;
  background-color: #f8f9fc;
  border: 1px solid #70707047;
  border-radius: 4px;

  &:hover {
    color: $primary;
  }
}

// ************************* Button-Outline-Color *************************//

//Primary-Outline
.btn-outline-primary {
  color: $primary;
  border-color: $primary;
  border-radius: 4px;

  &:hover,
  &:focus,
  &:active {
    background-color: $primary-dark;
    border-color: $primary-dark;
    color: #fff;
  }
}

//Secondary-Outline
.btn-outline-secondary {
  color: $primary;
  border-color: $primary;
  border-radius: 4px;

  &:hover,
  &:focus,
  &:active {
    background-color: $primary-dark;
    border-color: $primary-dark;
    color: #fff;
  }
}

//Success-Outline
.btn-outline-success {
  color: $success;
  border-color: $success;
  border-radius: 4px;

  &:hover,
  &:focus,
  &:active {
    background-color: $success-dark;
    border-color: $success-dark;
    color: #fff;
  }
}

//Danger-Outline
.btn-outline-danger {
  color: $danger;
  border-color: $danger;
  border-radius: 40px;

  &:hover,
  &:focus,
  &:active {
    background-color: $danger-dark;
    border-color: $danger-dark;
    color: #fff;
  }
}

//Light-Outline
.btn-link {
  &.btn-outline-light {
    border: 1px solid rgba(9, 9, 84, 0.12);
    border-radius: 8px;
    text-transform: uppercase;
    color: $base-color-private;
    font-weight: 600;
    font-size: 14px;

    &:hover,
    &:focus,
    &:active {
      color: $primary;
      border-color: $primary;
    }
  }
}

.btn.disabled,
.btn:disabled {
  opacity: 0.35;

  &:hover {
    cursor: not-allowed;
  }
}

//Primary-Outline for Cancel Button
.btn-outline-primary.cancel-btn {
  font-family: "SFProDisplay-Medium";
}