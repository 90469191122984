@import "../../../assets/css/theme/variables";

.calendar-wrapper {
    max-height: 80vh;
    .calendar-week-view {
        table {
            overflow-y: scroll !important;
            table-layout: unset;
            thead th,
            tbody th {
                position: sticky;
            }
            thead {
                position: sticky;
                top: 0;
                z-index: 3;
                background-color: #fff !important;

                
        
                th {
                    position: sticky;
                    top: 0;
                    z-index: 1;
                    background-color: #fff !important;
                    border-right: 1px solid #eeeef2;
                    width: 90px;
                    &:first-child {
                        background-color: #fff !important;
                        position: sticky;
                        top: 0;
                        left: 0;
                        z-index: 3;
                    }
                    &:last-child {
                        border-right: none;
                    }
                    &.cell-width {
                        width: 143px;
                    }
                    &.user-filter-blc-wrapper {
                        .user-filter-blc {
                            .ttl {
                                font-weight: 400;
                                font-size: 18px;
                                margin-right: 10px;
                                color: $base-color-private;
                            }
                            .right-col {
                                .btn {
                                    padding: 7px;
                                    span {
                                        font-weight: 300;
                                    }
                                }
                            }
                        }
                    }
                    &.day-cell-hd {
                        font-weight: 400;
                        font-size: 13px;
                        span {
                            color: $base-color-private;
                        }
                    }
                }
            }
            tbody {
                th {
                    left: 0;
                    z-index: 1;
                    background-color: #fff !important;
                    &.user-pic-cell-wrapper {
                        padding: 10px 10px 10px 0;
                        max-width: 230px;
                        .user-pic-cell {
                            width: 100%;
                            .pic {
                                width: 50px;
                                height: 50px;
                                margin-right: 12px;
                                flex-shrink: 0;
                            }
                            .txt {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                h4,
                                h5 {
                                    font-weight: 400;
                                    color: $base-color-private;
                                    overflow: hidden;
                                    text-overflow: inherit;
                                }
                                h4 {
                                    font-size: 16px;
                                }
                                h5 {
                                    font-size: 14px;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
