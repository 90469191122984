.subscription {
  width: 100%;
  height: 80px;
  padding: 10px;
  border-radius: 10px;
}

.super-plan {
  background: #d5e0ff;
}
.golden-plan {
  background: #fff6d5;
}
.primary-plan {
  background: #daffd5;
}
