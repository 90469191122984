@import "../../../../../assets/css/theme/variables";

.waiting-room-list {
  .item-row {
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;

    @media (max-width: 1250px) {
      flex-direction: column;
    }

    > .left-col {
      margin-right: 20px;

      @media (max-width: 1250px) {
        margin-right: 0;
      }

      .room-info {
        h4 {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 8px;
        }

        h5 {
          font-size: 13px;
          font-weight: 400;
        }
      }
    }

    > .right-col {
      margin-left: auto;

      @media (max-width: 1250px) {
        margin-left: 0;
      }

      .product-pic-blc {
        max-width: 330px;
        width: 100%;

        @media (max-width: 800px) {
          max-width: none;
          width: auto;
        }

        .pic-cell {
          width: 60px;
          height: 60px;
          margin-right: 5px;
          border-radius: 5px;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .btn {
        color: #282e65;
        padding: 10px;

        &:hover,
        &:focus {
          color: $primary;
        }
      }
    }
    .delete-btn {
      width: 34px;
      height: 34px;
    }
  }
}
div:where(.swal2-container) button:where(.swal2-close):focus {
  box-shadow: unset;
}
