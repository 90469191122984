.patient-detail-wrapper {
    >.left-col {
        width: 330px;

        height: 100vh;
        flex-shrink: 0;
        margin-right: 16px;
    }

    >.right-col {
        margin-right: 16px;
        height: 100vh;

        .tab-content {
            >.tab-pane {
                overflow-y: auto;
                //overflow-x: hidden;
            }
        }
    }
}