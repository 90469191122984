@import "../../assets/css/theme/variables";

.app-logo {
  // img {
  //   width: 269px;
  //   @media (max-width: 1919px) {
  //     width: 200px;
  //   }
  // }
  .icon {
    width: 33px;
    height: 33px;
    border-radius: 8px;
    background-color: $primary;
    margin-right: 7px;
    svg {
      width: 20px;
      .path-1 {
        fill: #fff;
      }
      .path-2 {
        fill: #f81049;
      }
    }
  }
  .txt {
    svg {
      path {
        fill: $base-color;
      }
    }
  }
}
