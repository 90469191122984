.profile-chart {
    margin: 0 -5px;

    .chart-col {
        height: 93px;
        background: #f8f8f8;
        flex: 1;
        overflow: hidden;
        max-width: 100%;
        border-radius: 10px;
        padding: 10px 10px 0;
        margin: 0 5px;

        .ttl {
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
            margin-bottom: 8px;

            .left-col {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 5px;
            }
            .right-col {
                white-space: nowrap;

            }
        }
        .inner {
            max-width: 112.5px;
        }
    }
}