.staff-overview-head {
	margin-bottom: 15px;
	> .left-col {
		margin-right: 20px;
	}
	> .right-col {
		max-width: 264px;
		width: 100%;
	}

	&.responsive {
		@media (max-width: 700px) {
			flex-direction: column;
		}
		> .left-col {
			@media (max-width: 700px) {
				margin: 0 0 15px;
			}
		}
		> .right-col {
			@media (max-width: 700px) {
				margin-left: unset !important;
			}
		}
	}
}
