.staff-profile-doc-item {
	background: #f8f8f8;
	padding: 10px;
	margin: 0 10px 10px 0;
	border-radius: 10px;
	.pic {
		height: 100px;
		margin-bottom: 12px;
		border-radius: 10px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.img-placeholder {
			background-color: #f0f0f0;
			span svg {
				width: 80px;
			}
		}
	}
	h3 {
		font-size: 16px;
		font-weight: 400;
		span {
			margin-right: 10px;
			flex-shrink: 0;
			img {
				width: 20px;
			}
		}
	}
	p {
		font-size: 13px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
