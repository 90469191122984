@import "../../../assets/css/theme/variables";

.calendar-month-view {
    max-height: 76vh;
    table {
        th,
        td {
            padding: 25px 10px;
        }
        thead {
            th {
                position: sticky;
                top: 0;
                z-index: 1;
                background-color: #fff !important;
                font-size: 13px;
                color: #788693;
                &:first-child {
                    z-index: 2;
                }
            }
        }
        tbody {
            tr {
                &:hover {
                    background-color: transparent;
                    td {
                    }
                }
            }
        }
    }
}
