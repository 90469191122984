.room-header {
  display: flex;
  padding: 15px 10px;
  align-items: center;
  gap: 10px;

  .end-call {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    flex-grow: 1;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      height: 50px;
      padding: 0px 10px;
      border-radius: 100px;
      background: var(--Main-Buttons, #de2b2b);
      box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.14);
      color: var(--Pure-White, #fff);
      text-align: center;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 21px;

    .icon {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      flex-shrink: 0;
      border-radius: 63px;
      background: rgb(228, 228, 228, 0.28);
      backdrop-filter: blur(3.5px);

      &.active {
        background: var(--Main, #3d3efd);
      }
    }
  }

  .room-info {
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 10px;

    img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 43px;
      padding: 0.784px 8.975px 0.87px 8.89px;
    }

    .text-info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 2px;

      .room-name {
        color: #282e65;
        font-family: "Nunito Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .appointment-time {
        color: #788693;
        font-family: "Roboto";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.lobby {
  margin-top: 100px;
  text-align: center;
  font-size: 1.25em;
}

.waiting-room {
  width: 100%;
  border-radius: 10px;
  background-color: #3d3efd;

  img {
    position: absolute;
    top: 0;
    box-shadow: 0px 0px 1px 1px #ddd;
    border-radius: 10px;
    object-fit: cover;
    width: 100%;
    height: auto;
  }
}

.remote-participants {
  .dominant-participant {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    background-color: black;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    .identity {
      position: absolute;
      left: 20px;
      top: 20px;
      color: white;
      box-sizing: border-box;
      z-index: 10;
      display: flex;
      padding: 0px 20px;
      justify-content: center;
      align-items: center;
      gap: 11.075px;
      height: min-content;
      width: min-content;
      text-wrap: nowrap;
      border-radius: 60.914px;
      background: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(2.7688093185424805px);
    }

    video {
      -webkit-transform: scale(-1, 1);
      transform: scale(-1, 1);
      object-fit: cover;
      width: 100%;
      height: 95vh;
    }
  }

  width: 100%;
  height: 100%;
}

.participants {
  display: flex;
  height: 100%;
  position: relative;
  flex-wrap: wrap;
  width: 100%;

  #my-screen-share-content-video {
    width: 100%;
    height: calc(100% - 150px);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  #my-screen-share-content-canvas {
    width: 100%;
    height: calc(100% - 150px);
    aspect-ratio: 16/9;
  }
}

.participant {
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  border-radius: 8px;
  margin-right: 0.75vw;
  margin-bottom: 0.75vw;
  width: 23.5vw;
  overflow: hidden;
  height: 50vh;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  mask-image: radial-gradient(white, black);
  display: flex;
  justify-content: center;
}

.joined-participants {
  display: flex;
  position: absolute;
  flex-wrap: wrap;
  width: 100%;
  min-height: 160px;
  gap: 5px;
  bottom: 0;
  justify-content: end;
  z-index: 1;
  padding: 0 10px;
}

.participant-details {
  width: 20%;
  min-width: 150px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  mask-image: radial-gradient(white, black);
  overflow: hidden;
  background-color: #282e65;
  display: flex;
  border-radius: 5px;
  position: relative;

  .participant-name {
    border-radius: 60.914px;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2.7688093185424805px);
    color: white;
    padding: 0 8px;
    margin: 0 0 4px 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
  }

  video {
    display: block;
    transform: scale(-1, 1);
    border-radius: 10px;
    width: auto;
    height: auto;
  }
}

.share-screen {
  canvas {
    width: fit-content;
    height: max-content;
  }
}

.remote-participant-full-screen {
  width: 100%;
  background-color: #f7f7f7;

  .participant-name {
    border-radius: 60.914px;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2.7688093185424805px);
    color: white;
    padding: 0 8px;
    margin: 4px;
    position: absolute;
    left: 0;
    z-index: 10;
  }

  video {
    display: block;
    transform: scale(-1, 1);
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }
}

.local-participant {
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 5vh;
  border-radius: 8px;
  margin-right: 0.75vw;
  margin-left: 0.75vw;
  height: 20%;
  width: 20%;
  min-width: 150px;
  overflow: hidden;
  background-color: #282e65;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  mask-image: radial-gradient(white, black);
  display: flex;
  justify-content: center;
}

.identity {
  position: absolute;
  left: 0px;
  bottom: 15%;
  color: white;
  box-sizing: border-box;
  z-index: 10;
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 11.075px;
  border-radius: 60.914px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2.7688093185424805px);
}

.screenshare {
  video {
    display: block;
    transform: none !important;
    border-radius: 10px;
    min-width: 100%;
    min-height: 100%;
    width: 100% !important;
    height: auto !important;
  }
}

video {
  display: block;
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
  border-radius: 10px;
  width: auto;
  height: auto;
}

.room-wrapper {
  display: flex;
  padding: 0px 10px 10px 10px;
  gap: 15px;
  width: 100%;
  height: calc(100% - 81px);
}

.room {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  .button-block {
    gap: 20px;
    display: flex;
    flex-direction: row;
    background: none;
    position: absolute;
    bottom: 0;

    button {
      margin-top: 25px;
      margin-left: 0;
    }
  }
}

#leaveRoom {
  margin-top: 25px;
  margin-left: 0;
}

@media (max-width: 1048px) {
  .app {
    padding: 1vw;
  }

  .participant {
    width: 47vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
    margin-left: 2vw;
    height: 42vh;
  }

  @media (orientation: landscape) {
    .participant {
      width: 48vw;
      margin-left: 1vw;
      height: 96vh;
    }
  }
} 

#participant-canvas {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}

#my-self-view-video,
#my-self-view-canvas {
  width: 100%;
  height: auto;
}

#my-self-view-video {
  position: absolute;
  bottom: 0;
  right: 0;
  height: auto;
  width: 150px;
}

.avatar {
  max-width: 260px;
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: visible;
  overflow: hidden;
  position: relative;

  .avatar-content {
    position: absolute;
    z-index: 10;
    left: 5px;
    right: 5px;
    bottom: 5px;
    display: flex;
    justify-content: space-between;
  }

  .avatar-name {
    background-color: rgba(119, 133, 146, 0.5);
    border-radius: 32px;
    padding: 0 5px;
  }

  .icon-bg-blue {
    background-color: #0060ff;
  }

  .icon-bg-red {
    background-color: #eb5757;
  }

  .avatar-audio {
    border-radius: 50%;
    width: 30px;

    img {
      margin: 5px auto;
    }
  }

  canvas {
    border-radius: 10px;
  }
}

.avatar .self-video {
  width: 100%;
  aspect-ratio: 16/9;
}


.self-user{
  position: relative;
  video{
    position: absolute;
    width: 100%;
    aspect-ratio: 16/9;
  }
}
.active-user-block {
  position: relative;
  width: 100%;
  height: 100%; 
  background-color: #f2f2f7  !important;
  border-radius: 10px;  
  align-items: center;
  justify-content: center;

  .active-user-canvas {
    width: 100%;
    height: 100%; 
    aspect-ratio: 16/9;
  }

  .active-user-name {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
    background-color: rgba(119, 133, 146, 0.5);
    border-radius: 32px;
    padding: 0 5px;
  }
}

.gallery-view-block {
  display: flex;
  justify-content: end;
  margin-top: 6px;
  gap: 6px; 
}

.share-screen-block {
  height: 100%;
  aspect-ratio: 16/9;
  background-color: #f2f2f7  !important;
  border-radius: 10px;
  overflow: hidden;  
  align-items: center;
  justify-content: center;

  video {
    transform: none;
    aspect-ratio: 16/9;
    width: 100%;
  }

  canvas {
    aspect-ratio: 16/9;
    width: 100%;
  }
} 

.room-inner-block{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: -webkit-fill-available;
  min-height: 1px;
}