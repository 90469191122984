.calendar-wrapper table {
  table-layout: fixed;
 /* min-width: 1239px;*/
}
.calendar-wrapper table tbody tr:hover td {
  background-color: transparent;
}
.calendar-wrapper table tbody tr td {
  position: relative;
  border: 1px solid #eeeef2;
  padding: 19px 10px 0;
  font-size: 13px;
}
.calendar-wrapper table tbody tr td:hover {
  background-color: #f2f2fb !important;
}
.calendar-wrapper table tbody tr td.no-hover {
  background-color: transparent !important;
}
.calendar-wrapper table tbody tr td.current-day-cell {
  background-color: #f2f2fb !important;
}
.calendar-wrapper table tbody tr td.current-day-cell .btn.add-slot-btn {
  color: #d9d9e9;
}
.calendar-wrapper table tbody tr td.current-day-cell .btn.add-slot-btn:hover {
  color: #3d3efd;
}
.calendar-wrapper table tbody tr td .data-container {
  min-height: 110px;
}
.calendar-wrapper table tbody tr td .data-container.blank-slot {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.calendar-wrapper table tbody tr td .data-container.add-slot-btn-wrapper {
  padding: 0;
}
.calendar-wrapper table tbody tr td .day-no {
  top: 5px;
  right: 5px;
  font-size: 13px;
}
.calendar-wrapper table tbody tr td .day-no.disable {
  color: #ccc;
}
.calendar-wrapper table tbody tr td .day-no.current {
  width: 22px;
  height: 22px;
  background-color: #3d3efd;
  color: #fff;
}
.calendar-wrapper .calendar-no-data-rw:hover {
  background-color: transparent;
}
.calendar-wrapper .calendar-no-data-rw:hover td:hover {
  background-color: transparent !important;
}
.calendar-wrapper .calendar-no-data-rw .not-data-found-blc > .inner {
  max-width: 210px;
  width: 100%;
}
.calendar-wrapper .calendar-no-data-rw .not-data-found-blc > .inner h2 {
  font-size: 18px;
}/*# sourceMappingURL=index.css.map */