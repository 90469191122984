.schedule-visit-offcanvas {
    .offcanvas-title {
        color: #282E65;
        font-size: 23px;
        font-weight: 700;
    }

    .btn-close {
        opacity: 1 !important;
        position: relative;
        right: 2px;
        top: -10px;

        &::before {
            position: absolute;
            top: 7px;
            right: 15px;
            content: " ";
            height: 20px;
            width: 2px;
            background-color: #282e65;
            transform: rotate(45deg);
        }

        &::after {
            position: absolute;
            top: 7px;
            right: 15px;
            content: " ";
            height: 20px;
            width: 2px;
            background-color: #282e65;
            transform: rotate(-45deg);
        }
    }

    .profile-cell {
        .pic {
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }

        .txt {
            margin-right: 8px;
            color: #282E65;
            font-size: 14px;
        }

        .icon {
            span {
                font-size: 16px;
            }
        }
    }
    &.x-ray-reports {
        color: #282e65;
        width: 1000px !important;  
        .card-heading { 
            font-size: 24px; 
            font-weight: 600; 
        }
        .card-subheading { 
            font-size: 14px; 
            font-weight: 400; 
            line-height: 20px;
        }
        .doctor-notes-heading { 
            font-size: 18px; 
            font-weight: 600; 
        }
        .doctors-msgs {
            height: 50vh;
            overflow-y: auto;
            overflow-x: hidden;
            flex-grow: 1;
        }
    } 
}

.schedule-visit-block .main-block {
    margin-bottom: 15px;
    padding: 3px;
    @media (max-width: 767px) {
        flex-direction:row /*column tim changeback*/;
    }
    .input-cell {
        height: 32px;
        margin-right: 8px;
        &:last-child {
            margin-right: 0;
        }
        .form-control {
            background-color: rgba(228, 228, 228, 0.28);
            border-color: transparent;
            border-radius: 6px;
            height: 100%;
            box-shadow: none;
            width: fit-content;
        }
    }
}

#next-upcoming-container {
    border-radius:  5px;
    .btn-block{
      padding-top: 10px;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
      background: #f8f8f8e5;
      width: 100%;
    }
    .item-block {
      display: flex;
      padding: 10px;
      background: #f8f8f8e5;
      width: 100%;
      gap: 10px;
      .data-block {
        min-width: 280px;
        max-width: 280px;
      }
      @media only screen and (max-width: 768px) {
        flex-wrap: wrap;
      }
    }
  }
  