@import "../../../../assets/css/theme/variables";

.owner-info {
    > .left-col {
        margin-right: 20px;
        .description-blc {
            > .row-block {
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 10px;
                label {
                    width: 150px;
                    flex-shrink: 0;
                    margin-right: 15px;
                    margin-top: 10px;
                    &.required-input {
                        &:after {
                            margin-left: 3px;
                        }
                    }
                }
                .right-col {
                    .input-wrapper {
                        .form-control {
                            font-size: 14px;
                            height: 40px;
                            border-radius: 6px;
                        }
                        textarea.form-control {
                            height: 100px;
                        }
                    }
                    .form-control {
                        height: 40px;
                    }
                }
            }
            &.view-mode {
                .row-block {
                    align-items: center;
                    label {
                        margin-top: 0;
                    }
                    .right-col {
                        .form-control {
                            padding: 0;
                            background-color: transparent;
                            border-color: transparent;
                            box-shadow: none;
                            color: $base-color-private;
                            opacity: 1;
                        }
                        select.form-control {
                            background-image: none;
                        }
                    }
                }
            }
        }
    }
    > .right-col {
        .profile-cell {
            h4 {
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
            }
            .pic-cell {
                .pic {
                    width: 80px;
                    height: 80px;
                    &:hover {
                        .upload-cell {
                            opacity: 1;
                        }
                    }
                    .user-placeholder {
                        span {
                            font-size: 80px;
                        }
                    }
                    .upload-cell {
                        background-color: rgba($primary, 0.8);
                        z-index: 2;
                        font-size: 25px;
                        color: rgba(256, 256, 256, 0.7);
                        cursor: pointer;
                        opacity: 0;
                        transition: all ease 0.3s 0s;
                        * {
                            cursor: pointer;
                        }
                        input {
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0%;
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
}
