@import "../../../assets/css/theme/variables";
.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
  color: $base-color;
}
.dropdown {
  .icon-btn {
    padding: 10px 6px;
    font-size: 16px;
    color: #1b2e4b;
    display: inline-flex;
    &:hover {
      color: $primary;
    }
    .badge {
      font-size: 11px;
      font-weight: 400;
      min-width: 17px;
      min-height: 16px;
      padding: 3px;
      top: -10px;
      right: 6px;
      border-radius: 15px;
    }
  }
}

.dropdown-cell {
  margin-left: auto;
  > .btn {
    font-size: 4px;
    color: #788693;
    padding: 0;
    width: 35px;
    height: 35px;
    text-align: right;
    &:hover,
    &:focus {
      color: $primary;
    }
  }
  .dropdown-menu {
    .dropdown-item {
      padding: 3px 9px;
      font-size: 13px;
    }
  }
}
