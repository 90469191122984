.add_fils {
  .file_box {
    width: 50px;
    height: 50px;
    border-radius: 10px;
  }
  .file-delete-icon {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .add_file_btn {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    border: 1px dotted rgba(120, 140, 159, 0.5);
    font-size: 19px;
    color: #11ca9d;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    justify-content: center;
    position: relative;

    input {
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;
    }
  }
  .reupload {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    font-size: 19px;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    justify-content: center;
    position: relative;

    input {
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;
    }
  }
}
