@import "../../../../assets/css/theme/variables";

.schedule-information-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 15px;
  gap: 10px;

  /* BG */

  background: rgba(248, 248, 248, 0.9);
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  .information-group {
    gap: 20px;
    width: 100%;
    div:first-child {
      width: 20%;
    }
  }

  .txt {
    h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      color: #282e65;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      /* identical to box height, or 21px */

      display: flex;
      align-items: center;

      /* Dark Blue */

      color: #282e65;
    }
  }

  .btn-cell {
    min-height: 40px;
    align-self: stretch;

    align-items: flex-start;
    display: flex;
    width: 100%;
    .appointment-status {
      display: flex;
      padding: 0px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      border-radius: 8px;
      //box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.14);
    }

    .in-progress {
      border: 1px solid rgba(0, 0, 0, 0.05);
      background: #efe;
      color: var(--special-green, #11ca9d);
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }

    .completed {
      border: 1px solid rgba(0, 0, 0, 0.05);
      color: #e1ac03;
      background: #fff6e1;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }

    .btn {
      display: flex;
      padding: 0px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;

      color: var(--Pure-White, #fff);
      text-align: center;
      /* Button/M */
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
    .btn-primary {
      background: #3d3efd;
    }
    .btn-outline-success {
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      background: #efe;
      color: #11ca9d;
    }
  }

  .action-btns {
    align-self: stretch;

    margin: 0 -5px;
    padding: 0 5px;
    .btn {
      border: 1px solid rgba(9, 9, 84, 0.12);
      filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.14));
      border-radius: 6px;
      text-transform: uppercase;
      color: $base-color;
      padding: 6px 15px;
      margin: 0 5px;
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      &:hover,
      &:focus {
        color: $primary;
        border-color: $primary;
      }
    }
  }
}
