@import "../../assets/css/theme/variables";
#pet-owner-dashboard-layout-container {
  display: flex;
  flex-direction: row !important;
  //overflow: hidden;
  //overflow: visible;
  .right-body-container {
    flex-grow: 1;
    padding-left: 240px;
  }

  @media (max-width: 1440px) {
    .right-body-container {
      padding-left: 80px;
    }
  }

  @media (max-width: 1023px) {
    .right-body-container {
      padding-left: 0;
    }
  }
}
