@import "../../../assets/css/theme/variables";

.user-profile {
  max-height: 144px;
  &:hover,
  &:focus {
    border-color: #ddd;
  }
  &.active {
    background-color: $primary;
    .txt {
      h4,
      h5 {
        color: #fff;
      }
    }
  }
  img {
    margin: 0 !important;
    width: 50px;
    height: 50px;
    // margin: 2px;
    flex-shrink: 0;
  }
  .txt {
    margin-top: 10px !important;
    h4 {
      margin: 0 !important;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px !important;
    }
    h6 {
      margin: 0 !important;
      font-weight: 400;
      font-size: 11px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .txt {
    // margin-left: 4px !important;
    h4 {
      margin-left: 6px !important;
      white-space: nowrap;
      //overflow: hidden;
      text-overflow: ellipsis;
      max-width: 60px;
    }
    .last-logged-in {
      display: none !important;
    }
    h6 {
      margin-left: 4px !important;
      max-width: 60px;
    }
  }
}
