.listing-filter-blc {
    margin-bottom: 20px;
    padding: 3px 3px 6px;
    > * {
        flex-shrink: 0;
    }
    .col-cell {
        margin-right: 4px;
    }
    .form-control,
    .toggle-view-cell {
        height: 34px;
        border: 1px solid #eeeef2;
        border-radius: 10px;
    }
    .input-cell {
        width: 150px;
        select.form-control {
            padding-top: 2px;
            padding-bottom: 2px;
            background-color: #fff;
            background-position-x: calc(100% - 13px);
            background-size: 10px;
            padding-right: 30px;
        }
    }
    .search-cell {
        width: 200px;
        .form-control {
            padding-left: 36px;
        }
        span {
            left: 0;
            top: 0;
            bottom: 0;
            width: 40px;
        }
    }
    .toggle-view-cell {
        width: 190px;
        padding: 4px 10px;
        .swtich-cell2 {
            margin: 0 0 0 10px;
        }
    }
}
