.tab-wrapper.tab-wrapper-setting .tab-content {
  .telehealth-tab {
    .tab-row {
      @media (max-width: 800px) {
        flex-direction: column;
      }

      .tab-col {
        &.lft {
          @media (max-width: 800px) {
            margin-right: 0;
            margin-bottom: 10px;
            max-width: none;
          }
        }

        .add-note-btn {
          border: 1px dashed #788c9f;
          border-radius: 10px;
          color: #11ca9d;
          padding: 14px 15px;
          width: 100%;

          .icomoon-plus-btn {
            &:before {
              font-size: 22px;
            }
          }
        }

        > .inner {
          @media (max-width: 800px) {
            max-width: none;
          }
        }

        .tags-colors {
          .tag {
            color: white;
            padding: 5px;
            border-radius: 5px;
          }
          .tag-one {
            background-color: lightgreen;
          }
          .tag-two {
            background-color: lightblue;
          }
          .tag-three {
            background-color: lightcoral;
          }
          .tag-four {
            background-color: lightseagreen;
          }
          .tag-five {
            background-color: lightslategray;
          }
          .lightseagreen {
            background-color: lightseagreen;
          }
          .darkgoldenrod {
            background-color: darkgoldenrod;
          }
          .orange {
            background-color: orange;
          }

          .allergy-add {
            border: 1px solid lightgray;
            border-radius: 10px;
            padding: 10px;

            .reactions-select-dropdown {
              width: 100%;
            }
            .severity-text {
              color: white;
              .low {
                padding: 5px 10px;
                border-radius: 5px;
              }
              .moderate {
                padding: 5px 10px;
                border-radius: 5px;
              }
              .high {
                padding: 5px 10px;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
  }
}
.medication-delay-input {
  width: 100px;
  input {
    width: 100%;
    border: 1px solid #dddbdb;
    height: 32px;
    border-radius: 4px;
    padding: 0px 10px 0px 10px;
  }
}
.medication-delay-select {
  width: 100px;
  select {
    width: 100%;
    border: 1px solid #dddbdb;
    height: 32px;
    border-radius: 4px;
    padding: 0px 10px 0px 10px;
  }
}
