div {
  .role-permission-blc {
    @media (max-width: 480px) {
      height: auto !important;
    }

    .table-listing-blc {
      > div {
        min-width: max-content;
        width: 100%;
      }
    }

    .head_row {
      display: flex;
      font-size: 13px;
      color: #788c9f;
      font-weight: 500;
      height: 30px;
      border-bottom: 1px solid #eeeef2;
      line-height: 13px;
      align-items: center;
      padding: 0 10px;
      .checkbox-cell {
        margin: 0;
      }
      div {
        display: flex;
        gap: 10px;
        align-items: center;
      }

      .head_check {
        .checkbox-cell {
          input:checked + .icon-box {
            background: #e0ce2a;
            border: 1px solid #e0ce2a;
            color: #fff;
          }
        }
      }
    }
    .data_row {
      margin: 3px 0;
      .accordion-item {
        border: 0;
        border-radius: 0;
        padding: 10px;
        // background-color: #e4e4e4   ;
        background: rgba(228, 228, 228, 0.28);
        .accordion-header {
          background-color: unset;
          .accordion-button {
            height: 27px;
            padding: 0 25px;
            background-color: unset;
            border-radius: 0;
            border-color: #eeeef2;
            // border: none;
            font-size: 18px;
            font-weight: 600;
            box-shadow: none !important;
            color: #282e65;
            text-transform: capitalize;
            &[aria-expanded="true"] {
              border-bottom: 1px solid #eeeef2;
            }
            .collapsed {
              border-color: unset;
              border: none;
              box-shadow: none !important;
            }
            :not(.collapsed) {
              background-color: unset;
              box-shadow: none !important;
              border-bottom: none;
            }
            :focus {
              box-shadow: none;
              border: none;
              border-color: #eeeef2;
            }

            &::after {
              position: absolute;
              left: 0;
              scale: 0.8;
            }
          }
        }
        .accordion-body {
          padding: 0;
          .detail_row {
            align-items: center;
            min-height: 40px;
            height: 40px;
            div {
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
            }
            .detail-label {
              text-transform: capitalize;
            }
          }
          > div:nth-child(even) {
            background-color: #f3f3f3;
          }
        }
      }
    }
  }
}

.search-group-input {
  svg {
    position: absolute;
    top: 2px;
    left: 8px;
  }
  input {
    padding-left: 35px;
    border-radius: 6px;
    background: #ffffff;
    color: rgba(120, 134, 147, 1);
    height: 32px;
    border: 0;
  }
}
// .accordion-body div:nth-child(even) {
//   background-color: #e4e4e4;
// }
#react-select-3-input {
  color: #000000 !important;
}
