@import "../../../assets/css/theme/variables";

.sidebar {
  width: 240px;
  height: 100vh;
  flex-shrink: 0;
  background-color: #fff;
  transition: all ease 0.3s 0s;
  //position: fixed;
  left: 0;
  top: 0;

  @media (max-width: 1023px) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
    transform: translateX(-100%);
    transition: all ease 0.3s 0s;

    &.active {
      transform: translateX(0);
    }
  }

  .inner-wrapper {
    padding: 10px 10px 0;

    .sidebar-menu {
      h2 {
        font-size: 14px;
        font-weight: 400;
        color: #788c9f;
        margin: 0 0 10px 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      ul {
        li {
          &.active {
            .btn {
              background: #f8f8f8;
              color: $primary;
              border-radius: 10px;

              span {
                &::before {
                  color: $primary;
                }
              }
            }
          }

          .btn {
            font-size: 16px;
            padding: 12px 15px;
            color: $base-color;
            text-transform: capitalize;

            * {
              cursor: pointer;
            }

            &.staff-icon-mini {
              display: none !important;
            }

            &:hover,
            &:focus {
              color: $primary;

              span {
                &::before {
                  color: $primary;
                }
              }
            }

            span {
              width: 20px;
              margin-right: 10px;
              text-align: center;
              flex-shrink: 0;

              &.material-icons {
                font-size: 18px;
              }
            }

            label {
              font-weight: 400;
            }
          }
        }
      }
    }

    .footer-blc {
      padding: 10px 10px 0;
    }
  }

  .footer-blc {
    padding: 10px;
  }

  .copyright-txt {
    font-size: 11px;
    line-height: 13px;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  @media (max-width: 1440px) {
    width: 80px;
    border: 1px solid #eeeef2;

    .logo-head2 {
      .icon {
        width: 42px;
        height: 42px;
        margin-bottom: 0;
      }

      .txt {
        display: none;
      }
    }

    .inner-wrapper {
      padding: 0;

      .sidebar-menu ul li {
        margin: 0 10px;

        .btn {
          justify-content: center;

          &.staff-icon {
            display: none !important;
          }

          &.staff-icon-mini {
            display: inline-flex !important;

            span {
              font-weight: 300;
            }
          }

          span {
            margin-right: 0;
          }

          label {
            display: none;
          }
        }
      }
    }

    .footer-blc {
      .btn.user-profile-btn {
        padding: 0;
        border: none;
        border-radius: 0;

        .pic {
          margin-right: 0;
        }

        .txt {
          display: none;
        }
      }

      .copyright-txt {
        span {
          display: none;
        }
      }
    }
  }
}

.sidebar-overlayer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 4;
  width: 0;
  height: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all ease 0.3s;

  &.active {
    @media (max-width: 1023px) {
      display: block;
      width: 100%;
      height: 100%;
      opacity: 1;
    }
  }
}

.btn.sidebar-toggle-btn {
  display: none !important;
  color: $base-color-private;
  padding: 0;
  width: 40px;
  height: 40px;
  align-self: self-start;
  align-items: flex-start !important;
  padding-top: 3px;
  flex-shrink: 0;

  &:hover,
  &:focus {
    color: $primary;
  }

  &.staff-detail {
    align-items: center !important;
    padding-top: 0;
  }

  @media (max-width: 1023px) {
    display: inline-flex !important;
  }
}

@media (max-width: 1440px) {
  .clinic-side-name {
    display: none;
  }
}
