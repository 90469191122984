:root {
  --ffff: #ffffff;
  --F8F8: #f8f8f8;
  --E4E4: #e4e4e4;
  --8C9F: #788c9f;
  --8693: #788693;
  --0000: #000000;
  --0d6e: #0d6efd;
  --3EFD: #3d3efd;
  --2E65: #282e65;
  --0dca: #0dcaf0;
  --8754: #198754;
  --CA9D: #11ca9d;
  --dc35: #dc3545;
  --ffc1: #ffc107;
  --e3e3: #e3e3e3;
  --eeee: #eeeeee;
  --cfcf: #cfcfcf;
  --eded: #ededed;
  --9d9e: #9d9d9e;
  --eef2: #eeeef2;
  --f8f8: #f8f8f8;
  --5129: #cf5129;
  --aaaa: #0caaaa;
  --d9d9: #d9d9d9;
  --8C9F28: rgba(228, 228, 228, 0.28);
  --cabf: #11cabf;
  --9323: #dd9323;
  --5c30: #e75c30;
  --2e65: #282e65;
}

$colors: (
  "ffff": var(--ffff),
  "F8F8": var(--F8F8),
  "E4E4": var(--E4E4),
  "8C9F": var(--8C9F),
  "8693": var(--8693),
  "0000": var(--0000),
  "0d6e": var(--0d6e),
  "3EFD": var(--3EFD),
  "2E65": var(--2E65),
  "0dca": var(--0dca),
  "8754": var(--8754),
  "CA9D": var(--CA9D),
  "dc35": var(--dc35),
  "ffc1": var(--ffc1),
  "e3e3": var(--e3e3),
  "eeee": var(--eeee),
  "cfcf": var(--cfcf),
  "eded": var(--eded),
  "9d9e": var(--9d9e),
  "eef2": var(--eef2),
  "8C9F28": var(--8C9F28),
  "f8f8": var(--f8f8),
  "5129": var(--5129),
  "aaaa": var(--aaaa),
  "d9d9": var(--d9d9),
  "cabf": var(--cabf),
  "9323": var(--9323),
  "5c30": var(--5c30),
  "2e65": var(--2e65),
);
@each $name, $color in $colors {
  .color-#{$name} {
    color: $color !important;
  }
  .bg-#{$name} {
    background-color: $color !important;
  }
  .b-#{$name} {
    border: $color 1px solid !important;
  }
  .bs-#{$name} {
    border-left: $color 1px solid !important;
  }
  .be-#{$name} {
    border-right: $color 1px solid !important;
  }
  .bt-#{$name} {
    border-top: $color 1px solid !important;
  }
  .bb-#{$name} {
    border-bottom: $color 1px solid !important;
  }
}
