@import "../../../../assets/css/theme/variables";

.patient-profile {
    .profile-head {
        padding: 8px 15px 15px;

        .title {
            .btn {
                padding: 10px 15px;

                &.back-btn {
                    font-weight: 600;
                    text-transform: uppercase;
                    color: $base-color;
                }

                &.view-user-btn {

                    &:hover,
                    &:focus {
                        svg path {
                            fill: $primary;
                        }
                    }
                }
            }
        }

        .user-profile-cell {
            margin-bottom: 15px;

            .pic {
                width: 80px;
                height: 80px;
                margin-right: 15px;

                >img {
                    border-radius: 8px;
                }

                .badge-icon {
                    top: -8px;
                    right: -8px;
                }

                .pet-placeholder {
                    border-radius: 8px;
                }
            }

            .txt {
                h2 {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 24px;
                }

                h3,
                h4 {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 150%;
                }

                h3 {
                    font-size: 14px;
                    margin-bottom: 5px;
                }

                h4 {
                    margin-bottom: 0;

                    &.active {
                        color: $success;
                    }
                }
            }
        }

        .profile-btns {
            margin: 0 -5px;
            padding: 0 5px;

            .btn {
                border: 1px solid rgba(9, 9, 84, 0.12);
                filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.14));
                border-radius: 6px;
                text-transform: uppercase;
                color: $base-color;
                padding: 6px 15px;
                margin: 0 5px;
                font-size: 12px;
                font-weight: 600;
                line-height: 15px;

                &:hover,
                &:focus {
                    color: $primary;
                    border-color: $primary;
                }
            }
        }

        .outstanding_invoice {
            border-radius: 10px;
            background: #3D3EFD;
            padding: 12px 20px;

            h4 {
                font-size: 18px;
                color: #fff;
                font-weight: 600;
                margin-bottom: 10px;
            }

            h3 {
                font-size: 15px;
                color: #fff;
                font-weight: 400;
            }

            svg {
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translate(0, -50%);
            }
        }

        .btn-blc {
            .btn.r_d {
                height: 24px;
                padding: 0;
                background-color: #9584FC;
                border-color: #9584FC;
                color: #fff !important;
                box-shadow: none;
                min-width: 100px;
                border-radius: 100px;
                margin-right: 9px;
                font-size: 12px;
            }
        }
    }
}
.modal {
	.form-group {
		.border-box {
			background: #f7f7f7;
		}
        .pic-cell {
            &.pet {
                .pic {
                    border-radius: 8px;
                }
            }
            .pic {
                width: 80px;
                height: 80px;
                &:hover {
                    .upload-cell {
                        opacity: 1;
                    }
                }
                .user-placeholder {
                    span {
                        font-size: 80px;
                    }
                }
                .upload-cell {
                    background-color: rgba($primary, 0.8);
                    z-index: 2;
                    font-size: 25px;
                    color: rgba(256, 256, 256, 0.7);
                    cursor: pointer;
                    opacity: 0;
                    transition: all ease 0.3s 0s;
                    * {
                        cursor: pointer;
                    }
                    input {
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0%;
                        opacity: 0;
                    }
                }
            }
        }
	}
}