.locations-wrapper {
    @media (max-width: 900px) {
        flex-direction: column;
    }
    > .left-col {
        max-width: 400px;
        width: 100%;
        margin-right: 20px;
        @media (max-width: 900px) {
            max-width: none;
            margin-right: 0;
            height: 250px;
        }
    }
    > .right-col {
        .row-block {
            margin-bottom: 10px;
        }
    }
}
