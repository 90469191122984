.steps-blc ul li {
  padding-bottom: 20px;
}
.horizontal.steps-blc ul li {
    padding: 12px 0;
}
.horizontal.steps-blc ul li::after {
  content: "";
  position: absolute;
  top: 27px;
  left: 72px;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: #b8b9c3;
  color: #fff;
  z-index: -1;
}
/* .steps-blc ul li::after {
  content: "";
  position: absolute;
  top: 0;
  left: 15px;
  bottom: 0;
  width: 2px;
  background-color: #b8b9c3;
  color: #fff;
} */
.steps-blc ul li:last-child::after {
  display: none;
  padding-bottom: 0;
}
.steps-blc ul li.active::after {
  background-color: #282e65;
}
.steps-blc ul li.active .icon {
  background-color: #282e65;
}
.steps-blc ul li .icon {
  width: 32px;
  height: 32px;
  margin-right: 16px;
  background-color: #b8b9c3;
  font-size: 10px;
  z-index: 1;
}
.steps-blc ul li .txt {
  margin-top: 2px;
}
.steps-blc ul li .txt h3,
.steps-blc ul li .txt p {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}
.steps-blc ul li .txt p {
  color: #788693;
}/*# sourceMappingURL=index.css.map */