@import "../../../../../../assets/css/theme/variables";
#blood-products-container {
  .collapse {
    overflow-x: auto;
  }
  .title-head-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-block {
      font-size: 18px;
      font-weight: 600;
      color: $base-color-private;
    }
    .icomoon-plus-btn {
      cursor: pointer;
      filter: invert(60%) sepia(37%) saturate(4029%) hue-rotate(125deg)
        brightness(103%) contrast(87%);
    }
  }
  .blood-products-filter-block {
    margin-top: 5px;
    .blood-products-search-block {
      position: relative;
      .icon-block {
        position: absolute;
        left: 8px;
        top: 0;
      }
      input {
        background: #e4e4e447;
        border-radius: 6px;
        padding: 5px 10px 5px 30px;
      }
    }
    .right-filter-block {
      .list-view-type-btn {
        background: rgba(228, 228, 228, 0.28);
        border-color: transparent;
        color: black;
        padding: 6px 10px;
        font-size: 14px;
      }
      div {
        background: #e4e4e447;
        color: #282e65;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;
        font-size: 14px;
        font-weight: 400;
        user-select: none;
        &:first-child {
          padding: 0px 8px;
          cursor: pointer;
          border-radius: 6px 0px 0px 6px;
        }
        &:last-child {
          padding: 0px 8px;
          cursor: pointer;
          border-radius: 0px 6px 6px 0px;
        }
      }
    }
    .blood-products-filter-day-container {
      display: flex;
      align-items: center;
      gap: 3px;
      margin-top: 10px;
      overflow: auto;
      .day-block {
        background: #e4e4e447;
        border-radius: 8px;
        padding: 5px 10px 3px 10px;
        text-align: center;
        cursor: pointer;
        text-wrap: nowrap;
        position: relative;
        .a-data-dot {
          position: absolute;
          top: 5px;
          right: 3px;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: blue;
        }
        div {
          &:first-child {
            color: #282e65;
            font-size: 14px;
            font-weight: 600;
          }
          &:last-child {
            color: #282e65;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
      .active {
        background: #3d3efd;
        .a-data-dot {
          background-color: #fff;
        }
        div {
          color: #fff !important;
        }
      }
    }
  }
  .blood-products-body {
    overflow: auto;
    .head-block {
      display: flex;
      align-items: center;
      .head-cell {
        background: #f9f9f9;
        color: #282e65;
        width: 180px;
        min-width: 180px;
        padding: 10px;
        border-bottom: 1px solid #0909541f;
        border-right: 1px solid #0909541f;
        height: 55px;
        font-size: 13px;
        font-weight: 400;
        cursor: pointer;
        div {
          justify-content: center;
        }
        &:first-child {
          min-width: 240px;
          width: 240px;
          font-size: 16px;
          font-weight: 400;
          display: flex;
          align-items: center;
        }
        &:last-child {
          flex-grow: 1;
          min-width: fit-content;
          width: fit-content;
          padding: 0px;
          border-right: unset;
        }
        .remove_btn {
          width: 15.83px;
          height: 15.83px;
          background-color: #de2b2b;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          line-height: 21px;
          font-size: 25px;
        }
      }
    }
    .body-content {
      height: calc(100vh - 300px);
      min-width: fit-content;
      overflow-y: auto;
      .body-block {
        display: flex;
        align-items: center;
        .body-cell {
          width: 180px;
          min-width: 180px;
          padding: 0px 10px;
          border-bottom: 1px solid #0909541f;
          border-right: 1px solid #0909541f;
          font-size: 14px;
          font-weight: 400;
          color: #282e65;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 45px;
          cursor: pointer;
          &:first-child {
            justify-content: flex-start;
            min-width: 240px;
            width: 240px;
          }
          &:last-child {
            flex-grow: 1;
            min-width: fit-content;
            width: fit-content;
            padding: 0px;
            border-right: unset;
          }
          .add_info {
            color: #3d3efd;
            cursor: pointer;
          }
        }
      }
    }
    .css-13cymwt-control:hover,
    .css-t3ipsp-control:hover {
      border: 1px solid hsl(0, 0%, 70%) !important;
      box-shadow: unset;
    }
    .css-tr4s17-option {
      background-color: #3d3efd;
    }
    .date-tile-edit-popover {
      position: absolute;
      top: 25px;
      right: 40%;
      background-color: #fff;
      z-index: 999;
      padding: 10px;
      width: fit-content;
      input {
        width: 100%;
      }
    }
    .text-input-block {
      input {
        height: 100%;
        width: 100%;
        border-radius: 1px;
        padding: 5px 10px 5px 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border: 1px solid #0909541f;
      }
    }
  }
  .blod_grp_details {
    border-radius: 13px;
    margin-top: 20px;
    background: rgba(228, 228, 228, 0.28);
    padding: 12px;

    h3 {
      font-size: 13px;
      font-weight: 400;
      margin-left: 10px;
      margin-bottom: 10px;
      position: relative;

      span {
        font-size: 13px;
        color: #282e65;
        font-weight: 600;
      }
    }
  }
}
