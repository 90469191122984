@import "../../../../assets/css/theme/variables";
#test-results-container {
  height: 100%;
  .report-items-rw {
    margin: 0 -5px;
    padding: 5px 8px 8px 8px;
    overflow: auto;
    width: calc(100vw - 250px);

    .item-col {
      // width: 25%;
      width: 240px;
      padding: 0 5px;
      .report-item {
        background-color: #fff;
      }
      @media (max-width: 1199px) {
        // width: 30%;
      }

      * {
        cursor: pointer;
      }
    }

    .more-item-col {
      width: 130px;

      @media (max-width: 1199px) {
        width: 62px;
      }

      .more-item-btn {
        font-size: 13px;
        font-weight: 600;
        line-height: 150%;
        color: #788c9f;
        transform: rotate(-90deg);

        @media (max-width: 1199px) {
          padding: 0;
        }

        &:hover,
        &:focus {
          color: $primary;
        }
      }
    }
    @media (max-width: 1023px) {
      width: calc(100vw - 10px);
    }
    @media (max-width: 1440px) {
      width: calc(100vw - 50px);
    }
  }
}
