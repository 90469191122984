@import "../../../../../assets/css/theme/variables";

.overview-block {
	> .row-block {
		.section-head {
			margin-bottom: 0;
			> .left-col {
				margin-right: 15px;
				h3 {
					font-size: 18px;
					font-weight: 600;
					line-height: 150%;
				}
			}
			> .right-col {
				.btn {
					padding: 10px;
				}
				.view-all-btn {
					color: $base-color;
					&:hover,
					&:focus {
						color: $primary;
					}
				}
				.add-btn {
					font-size: 19px;
					color: $success;
					&:hover,
					&:focus {
						color: $success-dark;
					}
				}
			}
		}
		margin-bottom: 15px;
	}
}
