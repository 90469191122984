@import "../../../assets/css/theme/variables";

.modal {
  .modal-dialog {
    margin: 0 auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 15px;
    /*border: 1px solid rgba(8, 8, 41, 0.1);
    box-shadow: 0px 4px 10px rgba(61, 57, 137, 0.05);*/

    &.add-pet-modal {
      max-width: 1280px;
    }

    &.code-modal {
      max-width: 570px;

      @media (max-width: 1919px) {
        max-width: 480px;
      }

      .code-input-rw {
        margin: 0 -8px;
        padding: 0 3px;
        @media (max-width: 480px) {
          margin: 0 -6px;
        }

        .input-wrapper {
          padding: 2px 10px;

          @media (max-width: 480px) {
            padding: 0 6px;
            width: 60px;
          }
          .form-control {
            padding: 0;
            text-align: center;
            @media (max-width: 480px) {
              height: 40px;
              font-size: 20px;
            }
          }
        }
      }
      .form-group {
        max-width: calc(60px + 1.5rem);

        @media (max-width: 1919px) {
          max-width: calc(50px + 1.5rem);
        }

        @media (max-width: 480px) {
          max-width: calc(50px + 1.5rem);
        }

        > label {
          display: none;
        }

        font-size: 24px;
      }

      .remember-code {
        margin-bottom: 20px;
        padding: 0 7px;

        .checkbox-wrapper .txt {
          font-size: 16px;
        }
      }
      .remember-code {
        margin-bottom: 20px;
        .checkbox-wrapper .txt {
          font-size: 16px;
        }
      }
      .instruction-msg {
        background-color: rgba($success, 0.09);
        font-size: 15px;
        padding: 15px;
        margin-bottom: 20px;
        border-radius: 8px;
      }
    }

    &.add-user-modal {
      max-width: 720px;

      .modal-content {
        .modal-footer {
          @media (max-width: 480px) {
            flex-direction: column;
            align-items: stretch;
          }

          .btn {
            @media (max-width: 480px) {
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
      }
    }

    .modal-content {
      border: none;
      border-radius: 20px;
      box-shadow: 0 3px 6px #00000029;
      overflow: auto;
      max-height: 100%;

      .modal-close-btn {
        right: 8px;
        top: 8px;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.3);
        padding: 6px 17px;
        line-height: 1;
        z-index: 2;

        &:hover {
          color: $primary;
        }

        &.dark-btn {
          font-size: 26px;
          color: #080829;

          &:hover {
            color: $primary;
          }
        }
      }

      form {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: auto;
      }

      .modal-header {
        padding: 15px 30px 15px;

        .modal-title {
          font-size: 20px;
          font-weight: 600;
          color: #000;
          flex: 1;
          display: flex;
          align-items: center;

          .btn {
            margin-left: auto;
            padding: 2px 2px;

            svg {
              width: 25px;
            }

            &:hover {
              g {
                stroke: $primary;
                opacity: 1;
              }

              path {
                fill: $primary;
                opacity: 1;
              }
            }
          }
        }
      }

      .modal-body {
        padding: 35px 48px;
        flex: 1;
        overflow: auto;

        @media (max-width: 1919px) {
          padding: 22px 24px;
        }

        @media (max-width: 767px) {
          padding: 30px;
        }
      }

      .modal-footer {
        padding-left: 44px;
        padding-right: 44px;

        @media (max-width: 1919px) {
          padding-left: 24px;
          padding-right: 24px;
        }

        .btn {
          font-family: "Inter", sans-serif;
          margin-left: 33px;
          min-width: 197px;

          @media (max-width: 1919px) {
            margin-left: 16px;
            min-width: 160px;
          }

          @media (max-width: 767px) {
            margin-left: 12px;
            min-width: 130px;
          }
        }
      }
    }
  }

  .checkBoxCell {
    margin-top: 45px;
    cursor: pointer;
    line-height: normal;

    @media (max-width: 575px) {
      margin-top: 0;
    }

    .form-check-input {
      margin: 0 8px 0 0;
      flex-shrink: 0;
    }

    &:hover {
      color: #555;
    }
  }
}

// Form
.modal {
  .form-group {
    > label {
      font-size: 16px;
      font-family: "SFProDisplay-Medium";
      color: #767676;
      margin-bottom: 10px;
    }

    .form-control {
      border-color: #70707047;
      border-radius: 6px;
    }

    textarea.form-control {
      height: 100px;
    }
  }
}

// refer-pet-modal
.modal {
  .modal-dialog {
    &.refer-pet-modal-md {
      max-width: 800px;
      width: 100%;

      .modal-body {
        height: 360px;
        flex: none !important;
      }
    }
  }
}
