@import "../../../assets/css/theme/variables";

.new-order-panel-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    max-width: 715px;
    width: 100%;
    background-color: #fff;
    padding: 15px;
    transition: all ease 0.3s 0s;
    transform: translateX(100%);
    &.active {
        transform: translateX(0);
    }
    > .head {
        width: 100%;
        h2 {
            font-size: 24px;
            font-weight: 700;
            line-height: 33px;
        }
        .panel-close-btn {
            color: $base-color-private;
            padding: 10px;
            &:hover {
                color: $primary;
            }
        }
    }
    .btns-blc {
        .btn {
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
          &.cancel-btn {
            min-width: 150px;
          }
          &.book-btn {
            min-width: 200px;
          }
        }
      }
    .table-listing-blc {
        .table {
            thead {
                 tr {
                     th {
                        position: sticky;
                        top: 0;
                    }
                }
            }

            .selected {
                td {background: none;}
                background: #DBF8F1;
                .btn-custom {
                    color: $danger;
                }   
            }
        }
    }
}
.new-order-panel-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.2);
    visibility: hidden;
    opacity: 0;
    transition: all ease 0.3s 0s;
    &.active {
        visibility: visible;
        opacity: 1;
    }
}

.duration-inputt{
    input{
        width: 92px;
    }
}
.padding-start{
    padding-left:4px;
}

.vertical-top{
    vertical-align: top;
}

.add-dose-inputt{
    input{
        width: 72px;
    }
}

.search-input-field{
    span{
        border-radius: 0px 6px 6px 0;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
    }
    select{
        border-radius: 7px !important;
        
    }
}