@import "../../../assets/css/theme/variables";

.user-list-month-view {
    margin-bottom: 20px;
    @media (max-width: 767px) {
        flex-direction: column;
    }
    .user-cell {
        background: #f8f8f8;
        &.active {border-bottom: 3px solid $primary;}
        padding: 10px;
        white-space: nowrap;
        @media (max-width: 767px) {
            order: 2;
        }
        .pic {
            width: 50px;
            height: 50px;
            margin-right: 12px;
            .user-placeholder {
                span {
                    font-size: 50px;
                }
            }
        }
        .txt {
            h3,
            h4 {
                font-weight: 400;
            }
            h3 {
                font-size: 16px;
            }
            h4 {
                font-size: 14px;
                margin: 0;
            }
        }
    }
    .user-list-blc {
        .pic-cell {
            width: 70px;
            height: 70px;
            background: #f8f8f8;
            border-bottom: 1px solid #eeeef2;
            margin: 0 4px;
            flex-shrink: 0;

            .pic {
                width: 50px;
                height: 50px;
                .user-placeholder {
                    span {
                        font-size: 50px;
                    }
                }
            }


            &.active {
                border-bottom-color: $primary;
                .pic {
                    border: 2px solid $primary;
                }
            }
            &:hover {
                border-bottom-color: $primary;
            }
        }


        .txt {
            h3,
            h4 {
                font-weight: 400;
            }
            h3 {
                font-size: 16px;
            }
            h4 {
                font-size: 14px;
                margin: 0;
            }
        }
    }
}
