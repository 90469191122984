@import "./_variables";

.text-primary {
  color: $primary !important;
}

.text-secondary {
  color: $secondary !important;
}

.text-success {
  color: $success !important;
}

.text-warning {
  color: $danger !important;
}

.text-success {
  color: $success !important;
}

.text-dark {
  color: $base-color !important;
}

