@import "./_variables";

.alert-primary {
  background-color: rgba($primary, 0.15);
  border-color: rgba($primary, 0.15);
  color: $primary;
}

.alert-danger {
  background-color: rgba($danger, 0.03);
  border-color: rgba($danger, 0.15);
  color: $danger-dark-100;
}

.alert {
  &.alert-rounded {
    border-radius: 1rem;

    @media (min-width: 992px) {
      border-radius: 20px;
      padding: 28px;
    }
  }
  &.alert-icon {
    display: flex;
    align-items: center;
    @media (max-width: 640px) {
      flex-direction: column;
    }
    .icon {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background-color: #fff;
      border: 1px solid rgba($danger, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;
      flex: none;
      margin-right: 16px;
      @media (max-width: 640px) {
        margin: 0 0 15px;
      }

      @media (min-width: 992px) {
        width: 72px;
        height: 72px;
        margin-right: 24px;
      }
    }
    .alert-text {
      color: $base-color;
      @media (max-width: 640px) {
        text-align: center;
      }
      .soft {
        color: rgba($base-color, 0.75);
      }
      h5 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.03em;
        @media (max-width: 1919px) {
          font-size: 16px;
          line-height: 24px;
        }
        a {
          font-family: "Inter", sans-serif;
        }
      }
    }
  }
}
