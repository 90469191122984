.plan-name-input{
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    width: 200px;
}


.add_fils {

    .file_box {
        width: 50px;
        height: 50px;
        border-radius: 10px;
    }

    .add_file_btn {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        border: 1px dotted rgba(120, 140, 159, 0.5);
        font-size: 19px;
        color: #11ca9d;
        display: flex;
        align-items: center;
        cursor: pointer;
        overflow: hidden;
        justify-content: center;
        position: relative;

        input {
            opacity: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            position: absolute;
        }
    }
}

.file-delete-icon{
    position:absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}