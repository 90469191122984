@import "../../assets/css/theme/variables";

.logo-head2 {
  margin: 23px 24px 24px;
  .icon {
    width: 45px;
    height: 45px;
    background-color: $primary;
    border-radius: 10px;
    margin-bottom: 10px;
    svg {
      width: 25px;
    }
  }
  .txt {
    svg {
      width: 128px;
      path {
        fill: #1d1d1d;
      }
    }
  }
}
