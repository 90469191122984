#availability-or-unavailability-container {
  .f-select {
    select {
      border: 1px solid #eeeef2;
      border-radius: 10px;
      height: 32px;
      min-width: 150px;
      padding-left: 5px;
    }
  }
  .year-block {
    user-select: none;
    .left-nav-btn {
      padding: 0px 12px;
      border-radius: 6px 0px 0px 6px;
      cursor: pointer;
    }
    .center-btn {
      padding: 0px 14px;
    }
    .right-nav-btn {
      padding: 0px 12px;
      border-radius: 0px 6px 6px 0px;
      cursor: pointer;
    }
  }
  .calendar-month-block {
    .week-title {
      width: 14.28571428571429%;
      font-size: 13px;
      font-weight: 400;
      color: #788693;
      display: flex;

      justify-content: center;
    }
    .month-day {
      padding: 6px;
      width: 14.28571428571429%;

      min-height: 52px;
    }
    .with-day {
      border: 1px solid #dfdfdf;
      background-color: #f8f8f8;
      border-right: unset;
      border-top: unset;
    }
    .blank-cell {
      border-bottom: 1px solid #dfdfdf;
    }
    .f-row-cell {
      border-top: 1px solid #dfdfdf;
    }
  }
  .right-form-block {
    min-width: 300px;
    .top-block {
      background: #e4e4e447;
      padding: 15px;
      border-radius: 10px;
    }
  }
}
