@import "../../../../assets/css/theme/variables";
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: var(--bs-form-invalid-border-color) !important;
}

.new-clinic-panel-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  max-width: 550px;
  width: 100%;
  background-color: #fff;
  padding: 15px;
  transition: all ease 0.3s 0s;
  transform: translateX(100%);
  &.active {
    transform: translateX(0);
  }
  > .head {
    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
    }
    .panel-close-btn {
      color: $base-color-private;
      padding: 10px;
      &:hover {
        color: $primary;
      }
    }
  }
  .table-listing-blc {
    .table {
      thead {
        tr {
          th {
            position: sticky;
            top: 0;
          }
        }
      }
    }
  }
  .inner {
    max-width: 510px;
    .form-group-wrapper {
      width: 510px;
      height: 48px;
      Button {
        height: 48px;
      }
    }

    .address-block {
      display: inline-block;
      width: 250px;
    }
  }
}

.border_btn {
  .add-btn {
    font-size: 13px !important;
    font-weight: 600 !important;
    min-width: 140px;
  }

  .btn {
    width: 150px;
    &.cancle-btn {
      font-size: 13px !important;
      font-weight: 600 !important;
      background: none;
      border: 2px solid rgba(8, 8, 41, 0.1) !important;
      box-shadow: none;
      color: #282e65 !important;
      &:hover {
        background: none !important;
        color: initial !important;
        box-shadow: none !important;
      }
    }
  }
}

.input-wrapper-clinic {
  max-height: 48px !important;

  .form-floating {
    .form-control {
      height: 48px;
      min-height: 48px;

      &.no-right-border {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    // .form-control:empty ~ label {
    //   padding: 0.6rem 0.75rem !important;
    // }
    .form-control:focus ~ label {
      font-size: 12px;
      padding: 0.6rem 0.75rem !important;
    }
    .form-control:not(:placeholder-shown) ~ label {
      font-size: 12px;
      padding: 0.6rem 0.75rem !important;
    }

    label {
      padding: 0.6rem 0.75rem !important;
    }
  }
}

@media screen and (max-width: 570px) {
  .inner {
    width: 100%;
    .form-group-wrapper {
      width: 100% !important;
      max-width: 100vw !important;

      Button {
        height: 48px;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .inner {
    padding-right: 5px !important;
  }
}
