@import "../../../../../assets/css/theme/variables";


.orders-block{

    .add-btn {
        font-size: 19px;
        color: $success;
        &:hover,
        &:focus {
            color: $success-dark;
        }
    }
    .orders-listing-wrapper {
        .table {
            tr {
                &:hover {
                    background-color: transparent;
                }
                td {
                    background-color: transparent;
                }
            }

            thead {
                th {
                    border-bottom: none;
                    background-color: #f8f8f8;
                    padding: 10px 15px;
                    position: sticky;
                    border-bottom: 2px solid #fff;
                    top: 0;
                    &:first-child {
                        border-radius: 10px 0;
                    }
                    &:last-child {
                        border-radius: 0 10px;
                    }
                    &.reange-cell {
                        color: #9584fc;
                    }
                }
            }
            tbody {
                background-color: #f8f8f8;
            }
        }
    }
}