.package-info-blc table tr th,
.package-info-blc table tr td {
  padding: 1px 15px;
}
.package-info-blc table tr th .form-control,
.package-info-blc table tr td .form-control {
  height: 36px;
}/*# sourceMappingURL=index.css.map */
.information input[type="date"]::-webkit-inner-spin-button, .information input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  z-index: 99999;
  width: 20px;
}
.information .w-50.me-2.relative .absolute {
  position: absolute;
  top: 8px;
  font-size: 10px;
  right: 8px;
  z-index: 0;
}
.information textarea{
  height: 70px;
}
.information input, .information textarea  {

  background: var(--table-bg, rgba(228, 228, 228, 0.28))!important;
  border: none!important;
}
.information select .css-13cymwt-control {
  border: none!important;
  background: var(--table-bg, rgba(228, 228, 228, 0.28))!important;
  border-style: unset!important;
  border-width: 0px!important;
}
.custom-table thead, .custom-table {
  border-bottom: 1px solid #f2f2f2;
}
.custom-select input#react-select-2-input {
  background: transparent!Important;
}
.custom-select .css-13cymwt-control, span.css-1u9des2-indicatorSeparator {
  background: #f2f2f2;
  border: none;
}
.css-t3ipsp-control:hover, .css-13cymwt-control:hover{
  border:none!important;
  outline:none!important;
}
.add-btn:hover {
  background: #3d3efd;
  border-color: #3d3efd;
  color: #fff;
}