.education-info-blc {
    .education-info {
        background: #fff;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 10px;
        &:last-child {
            margin-bottom: 0;
        }
        .pic {
            width: 50px;
            height: 50px;
            margin-right: 15px;
            border-radius: 10px;
            flex-shrink: 0;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .info {
            h3,
            h4 {
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 6px;
            }
            h4 {
                color: #788693;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
