@import "../../../assets/css/theme/variables";

.appointment-detail-panel-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    max-width: 1075px;
    width: 100%;
    gap: 10px;
    background-color: #fff;
    padding: 10px 10px 0px 10px;
    transition: all ease 0.3s 0s;
    transform: translateX(100%);

  .schedule-cards {
    padding: 0px;
    gap: 5px;
    overflow: hidden;
  }
  &.active {
    transform: translateX(0);
  }
  > .head {
    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
    }
    .panel-close-btn {
      color: $base-color-private;
      padding: 10px;
      &:hover {
        color: $primary;
      }
    }
  }
}
.patient-panel-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.2);
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s 0s;
  &.active {
    visibility: visible;
    opacity: 1;
  }
}
.appointment-chat {
  // max-height: 50vh;
    padding: 10px 15px;
  margin-bottom: 20px;
  background: rgba(248, 248, 248, 0.9);
  border-radius: 10px;
 
  .overflow-auto {
    scrollbar-width: 0px !important;
    scrollbar-color: transparent;
  }
  .chat-panel{
    max-height: calc(50vh - 120px);
    padding: 13px;
    background: #ffffff;
    border-radius: 8px;
  }
}


