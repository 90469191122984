.document-info-blc {
    .document-cell {
        background-color: #fff;
        padding: 10px;
        margin: 0 10px 10px 0;
        border-radius: 10px;
        width: calc(33.3% - 10px);
        &:last-child {
            margin-bottom: 0;
        }
        .pic {
            height: 100px;
            margin-bottom: 12px;
            border-radius: 10px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        h3 {
            font-size: 16px;
            font-weight: 400;
            span {
                margin-right: 10px;
                img {
                    width: 20px;
                }
            }
        }
        p {
            font-size: 13px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &.responsive {
        .document-cell {
            @media (min-width: 901px) and (max-width: 1350px) {
                width: calc(50% - 10px);
            }
            @media (max-width: 750px) {
                width: calc(50% - 10px);
            }
            @media (max-width: 550px) {
                width: calc(100%);
            }
        }
    }
}
