@import "../../../../assets/css/theme/variables";

.user-pic-cell2 {
    color: $base-color-private;
    max-width: 280px;
    width: 100%;
    .row-block {
        margin-bottom: 10px;
        .pic {
            width: 50px;
            height: 50px;
            margin-right: 10px;
            .user-placeholder {
                span {
                    font-size: 50px;
                }
            }
        }
        .txt {
            margin-right: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            h3,
            h4 {
                font-weight: 400;
                line-height: 150%;
                color: $base-color-private;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            h3 {
                font-size: 16px;
            }
            h4 {
                font-size: 13px;
            }
        }
        .icon {
            margin: 5px 0 0;
            span {
                font-size: 16px;
                font-weight: 300;
            }
        }
    }
    .location-cell {
        .icon {
            margin-right: 10px;
            span {
                font-weight: 200;
            }
        }
        .txt {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
