@import "../../assets/css/theme/variables";

.user-thumbnail-list {
    .btn {
        width: 32px;
        height: 32px;
        margin-right: 10px;
        flex-shrink: 0;
        img {
            width: 100%;
            height: 100%;
        }
        &.active {
            border: 3px solid $primary;
        }
    }
}

.user-thumbnail-list-blc {
    > h3 {
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        color: #788693;
        margin-bottom: 10px;
    }
    .btns {
        .btn {
            width: 32px;
            height: 32px;
            margin-right: 5px;
            flex-shrink: 0;
            cursor: default;
            img {
                width: 100%;
                height: 100%;
            }
            &.active {
                border: 3px solid $primary;
            }
        }
    }
    .more-btn {
        padding: 5px 0px;
    }
}
