.img-placeholder {
    border-radius: 8px;
    span {
        margin: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #cfcfcf;
        svg {
            width: 50px;
            height: auto;
        }
    }
}
