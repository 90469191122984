.addnewrole {
  padding: 15px;
  width: 440px !important;
  .offcanvas-header {
    padding: 10px 10px 15px 10px;
    .btn-close{
        scale: 0.8;
        right: 5px;
        top: -4px;
        &:focus{
            box-shadow: none;
        }
    }
    .offcanvas-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
    }
  }
  .offcanvas-body {
    .input-wrapper2 {
    }
    .form-floating {
      .form-control {
        font-size: 16px;
        font-weight: 400;
        //   min-height: 40px;
        color: #282e65;
        box-shadow: none;
        line-height: 24px;
        
        &:focus {
          box-shadow: none !important;
        }
      }
         >label{
            color: #788693;
        //   padding: 0.6rem 0.75rem;
         }
    }
  }

  p {
    font-size: 13px;
    color: #788693;
    line-height: 19.5px;
    font-weight: 400;
  }

  .btn.btn-secondary{
    color: #282e65 !important;
  }

  .cancel_btn {
    background-color: #fff !important;
    color: #282e65 !important;
    border-color: rgba(9, 9, 84, 0.12) !important ;
    width: 150px;
    height: 40px;
    box-shadow: none;
  }
  .add-btn {
    width: 150px;
    box-shadow: none;
    height: 40px;
  }
}
