#date-popover-container {
  position: absolute;
  top: 30px;
  right: 40%;
  background-color: #fff;
  z-index: 999;
  padding: 8px 10px !important;
  width: fit-content;
  input {
    height: 100%;
    width: 100%;
    border-radius: 1px;
    padding: 5px 10px 5px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid #0909541f;
  }
}
