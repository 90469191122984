@import "../../../../assets/css/theme/variables";

.patient-profile {
    border-radius: 10px 10px 0px 0px;
    border: 1px solid var(--main-4-opacity, #EEEEF2);
    max-width: 330px;

    >.inner {
        padding: 0 15px;

        .row-block {
            margin-bottom: 15px;
        }

        .new-tag {
            padding-top: 10px;
        }
    }

    .section-head {
        margin-bottom: 12px;

        .left-col {
            line-height: 150%;
            margin-right: 10px;
        }

        .right-col {
            .close-btn {
                font-size: 19px;
                color: $danger;
                padding: 0;

                &:hover,
                &:focus {
                    color: $danger-dark;
                }

            }

            .add-btn {
                font-size: 19px;
                color: $success;
                padding: 0;

                &:hover,
                &:focus {
                    color: $success-dark;
                }
            }
        }
    }
}