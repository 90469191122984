.tab-row {
    @media (max-width: 800px) {
        flex-direction: column;
    }

    .tab-col {
        &.lft {
            @media (max-width: 800px) {
                margin-right: 0;
                margin-bottom: 10px;
                max-width: none;
            }
        }

        .add-note-btn {
            border: 1px dashed #788c9f;
            border-radius: 10px;
            color: #11ca9d;
            padding: 14px 15px;
            width: 100%;

            .icomoon-plus-btn {
                &:before {
                    font-size: 22px;
                }
            }
        }

        >.inner {
            @media (max-width: 800px) {
                max-width: none;
            }
        }
    }
}