#medication-report-container {
  // width: 320px;
  background-color: #f8f8f8;
  height: 100vh;
  overflow: auto;
  padding: 20px;
  .medication-list-container {
    // height: calc(100vh - 225px);
    // overflow: auto;
    padding-right: 5px;
  }
  .name-block {
    width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .medication-list-wrapper {
    color: #282e65;
    font-size: 16px;
    font-weight: 400;

    .taken-steps {
      color: #788c9f;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .not-taken-steps {
      color: #dc3545;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .taken-button-container {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    ul.outer-list-items {
      font-size: 14px;

      &::before {
        position: absolute;
        left: 8px;
        top: 18px;
        content: "";
        border-left: 1px dashed #c4c8cc;
        height: 80%;
      }

      li.list-outer::before {
        position: absolute;
        left: -21px;
        top: 7px;
        content: "";
        height: 5px;
        width: 5px;
        border-radius: 50%;
        border: 2px solid #c4c8cc;
        padding: 3px;
        background: #fff;
      }
    }
  }
  .before-meal-wrapper {
    border-radius: 10px;
    background: #eeeef2;
  }
  .p-block {
    position: relative;
    padding-bottom: 110px;
    margin-top: 20px;
  }
  .f-block {
    position: absolute;
    top: 0;
    background-color: #d8d8d8;
    border-radius: 10px;
    width: 88%;
    height: 20px;
    left: 20px;
  }
  .s-block {
    position: absolute;
    top: 11px;
    background-color: #ededed;
    border-radius: 10px;
    width: 94%;
    height: 20px;
    left: 10px;
  }
  .t-block {
    position: absolute;
    top: 25px;
    left: 0;
    background-color: #ffffff;
    border-radius: 10px;
    width: 100%;
  }
  .medication-plan-item {
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 2px 16px 0px #00000024;
  }
}
