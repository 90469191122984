@import "../../assets/css/theme/variables";

.checkbox-wrapper {
  user-select: none;
  cursor: pointer;

  .checkbox-cell {
    margin-right: 10px;

    input {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      width: 16px;
      height: 16px;
    }

    input:checked+.icon-box {
      background: $primary;
      border: 1px solid $primary;
      color: #fff;

      .icon {
        opacity: 1 !important;
        margin: 0;
      }
    }

    .icon-box {
      width: 16px;
      height: 16px;
      background: #ffffff;
      border: 1px solid rgba(8, 8, 41, 0.2);
      box-shadow: 0px 4px 10px rgba(61, 57, 137, 0.05);
      border-radius: 5px;

      .icon {
        font-size: 13px;
        opacity: 0;
      }
    }
  }

  .txt {
    font-size: 16px;
    font-weight: 400;
  }

  &.no-label {
    .checkbox-cell {
      margin-right: 0;
    }
  }
}