@import "../../../../../../assets/css/theme/variables";

.dental-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 15px;


    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;

    
	.add-btn {
        font-size: 19px;
        color: $success;

        &:active,
        &:hover,
        &:focus {
            color: $success-dark;
        }
    }

    .body{
        gap: 15px;
        width: 100%;
        display: flex;
        flex-direction: row;

        .right-col{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
            flex: 1 0 0;

            .note {

                display: flex;
                align-items: flex-start;
                gap: 10px;
                flex-direction: row;
                align-self: stretch;

                .doctor-img {
                    display: flex;
                    width: 32px;
                    height: 32px;
                    justify-content: center;
                    align-items: center;
                }
                .body{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 5px;
                    flex: 1 0 0;


                    .comment-box {

                        display: flex;
                        padding: 10px;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 5px;
                        align-self: stretch;
                        border-radius: 8px;
                        background: #EEF4FF;
                        .teeth {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                        }

                        
                    }

                    .note-metadata {
                        display: flex;
                        width: 273px;
                        align-items: center;
                        gap: 10px;

                        .name {
                            color:  #282E65;
                            /* 13 */
                            font-family: Inter;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 150%; /* 19.5px */

                        }

                        .date {

                            color:  #788C9F;
                            /* 13 */
                            font-family: Inter;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 150%; 

                        }
                    }
                }

            }

            .text-lg {
                color: var(--dark-blue, #282E65);
                /* 18 SB */
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%; 
            }

            .text-sm {
                color: var(--dark-blue, #282E65);
                /* 13 */
                font-family: Inter;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 19.5px */

            }

            
                .brief {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 5px;

                }

                .description {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 10px;
                    align-self: stretch;
                    .description-row {
                        display: flex;
                        flex-direction: row;
                        gap: 5px;
                        align-items: flex-start;
                        *{
                         min-width: 160px;
                        }
                    }
                    
                }
            
        }
        
        .left-col {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            
            
            
            /* Inside auto layout */
            
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
        }
    }
    .head{
        width: 100%;
        h4 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            /* identical to box height, or 21px */


            /* Dark Blue */

            color: #282E65;
        }
    }


.pic-frame { 
    
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
    
    width: 400px;
    height: 645px;
    
    /* BG */
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;

    h2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */

        text-align: center;

        /* Dark Blue */

        color: #282E65;
    }

}

}


