@import "../../../../assets/css/theme/variables";

.profile-vaccination {
  .item-list {
    .item-cell {
      background: #f8f8f8;
      border-radius: 5px;
      padding: 8px 10px;
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      color: $base-color;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        margin-right: 10px;

        &.due_date {
          color: #282e65;
        }

        &.overdue_date {
          color: #de2b2b;
        }

        &.hover-view {
            color: blue;
        }

        &.more-data {
            color: black;
        }
      }

      label {
        color: #282e65;
      }
    }
  }
}
.item-cell .hover-hide {
    display: block;
  }
.item-cell .hover-view {
    display: none;
  }
.profile-vaccination .item-list .item-cell:hover {
  background: white;
  border-radius: 5px;
  border: gray;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.3); 
  padding: 8px 10px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  color: #1d1d1d;
  
}
.item-cell:hover .hover-hide {
  display: none;
}
.item-cell:hover .hover-view {
  display: block;
}
