@import "../../../../assets/css/theme/variables";

.product-accordion-wrapper {
    .accordion-blc {
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        .accordion-btn {
            font-size: 18px;
            font-weight: 600;
            line-height: 150%;
            padding: 10px 0;
            color: $base-color-private;
            > label {
                margin-right: 20px;
            }
            .icon-btn {
                color: $success;
            }
        }
    }
}
