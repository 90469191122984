@import "../../../assets/css/theme/variables";
#pet-owner-health-snapshot-container {
  // padding: 15px 10px;
  .hw-20 {
    height: 20px;
    width: 20px;
  }
  .hw-40 {
    height: 40px;
    width: 40px;
  }
  .hw-50 {
    height: 50px;
    width: 50px;
  }
  .card-container-block {
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .chunks {
    background-color: #def1ff;
    color: #0085e5;
  }
  .info-block {
    background-color: #fff7e7;
    // height: 212px;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    gap: 20px;
    // align-items: center;
    padding: 20px;
    .left-container {
      padding: 10px;
      border-radius: 10px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .top-search-block {
    width: 400px;
    position: relative;
    svg {
      position: absolute;
      left: 12px;
    }
    input {
      background-color: #f8f8f8;
      height: 40px;
      padding-left: 50px;
      width: 100%;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 400;
      color: #788693;
      ::placeholder {
        color: #788693;
      }
    }
  }
  .vaccination-list-item {
    cursor: pointer;
    &:hover {
      box-shadow: 0px 4px 16px 0px #0000000f;
      background-color: var(--ffff);
      border-radius: 4px;
    }
  }
  .status-cell {
    padding: 2px 6px;
    // background: #def1ff;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    color: #229cf4;
    border-radius: 3px;

    &.inactive-cell {
      // background-color: rgba($danger, 0.15);
      color: $danger;
    }

    &.success-cell {
      // background-color: rgba($success, 0.15);
      color: $success;
    }

    &.pending-cell {
      // background-color: rgba(118, 96, 255, 0.15);
      color: rgba(118, 96, 255, 1);
    }
  }
}
#medication-details-offcanva {
  width: 320px;
}
// .offcanvas {
//   width: fit-content !important;
// }
