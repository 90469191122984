@import "../../assets/css/theme/variables";
@import url('https://v1.fontapi.ir/css/SFProDisplay');

body .signup-wrapper {
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  -webkit-overflow-scrolling: touch;
  height: 100vh !important;

  // @media (min-width: 1439px) {
  //   width: 100%;
  // }

  @media (max-width: 991px) {
    flex-direction: column !important;
  }

  @media only screen and (min-width: 901px) and (min-height: 1200px) {
    flex: none !important;
   

    margin: auto;
    height: 100%;
  }

  .col-blc {
    .signup-box {
      width: 100%;

    }

    .bg {
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 0;
    }
  }

  .left-col {
    flex: 1;
    background: url("../../assets/img/vetRecorEMR/login-pic2.png") center center no-repeat;
    background-size: cover;
    padding: 40px 54px 0;
    width: 60%;
    height: 100%;

    @media (max-width: 575px) {
      padding: 40px 19px 0;
    }

    &.signup_box {
      background: url("../../assets/img/vetRecorEMR/login_img3.png")center center no-repeat;
      background-size: cover;

      @media (max-width: 991px) {
        width: 100%;
        min-height: 300px;
        background-color: #dfe0e2;
        background-image: unset;
      }


    }

    @media (max-width: 991px) {
      width: 100%;
      min-height: 300px;
      background-color: #dfe0e2;
      background-image: unset;
    }

    // &:before {
    //   @media (max-width: 900px) {
    //     content: "";
    //     background-color: rgba(256, 256, 256, 0.6);
    //     backdrop-filter: blur(1px);
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //   }
    // }

    .head {
      font-family: "Roboto", sans-serif;
      position: relative;
      z-index: 1;

      @media (max-width: 900px) {
        max-height: 100%;
      }

      .logo-rw {
        margin-bottom: 58px;

        @media (max-height: 800px) {
          margin-bottom: 48px;
        }

        @media (max-width: 640px) {
          margin-bottom: 20px;
        }
      }

      h1 {
        margin-top: 50px;
        margin-bottom: 0;

        span {
          font-weight: 500;
          letter-spacing: -0.01em;
          line-height: 60px;

          @media (max-width: 1400px) and (max-height: 890px) {
            line-height: 45px;
          }
        }

        .txt-1 {
          font-size: 80px;

          @media (max-width: 1400px) and (max-height: 890px) {
            font-size: 70px;
          }

          @media (max-width: 1100px) and (max-height: 890px) {
            font-size: 43px;
          }
        }

        .txt-2 {
          font-size: 40px;

          @media (max-width: 1400px) and (max-height: 890px) {
            font-size: 32px;
          }

          @media (max-width: 1100px) and (max-height: 890px) {
            font-size: 20px;
            line-height: normal;
          }

          span {
            display: block;
          }
        }
      }
    }
  }

  .right-col {
    padding: 45px 50px 20px;
    width: 40%;
    height: 100%;
    overflow: auto;

    @media (max-width: 1199px) {
      padding: 50px 30px 20px;
    }

    @media (max-width: 991px) {
      width: 93%;
      margin: -40px auto;
      background: white;
      border-radius: 20px;
      position: relative;
    }

    @media (max-width: 575px) {
      padding: 30px 15px 20px 15px;
    }

    .signup-head {
      margin-bottom: 22px;

      @media (max-width: 900px) {
        margin-bottom: 25px;
      }

      @media (max-width: 767px) {
        margin-bottom: 16px;
      }

      h2 {
        font-weight: 600;
        font-size: 34px;
        line-height: 41px;
        color: #262422;

        @media (max-width: 767px) {
          font-size: 22px;
        }
      }

      h3 {
        font-size: 16px;
        font-weight: 400;
        margin-top: 20px;
        line-height: 24px;
        color: #282E65;

        span {
          display: block;
        }
      }
    }

    .term-blc {
      margin-bottom: 20px;

      >label {
        cursor: pointer;
        user-select: none;
        font-size: 12px;

        * {
          cursor: pointer;
        }

        .checkbox-blc {
          margin-right: 10px;
        }
      }
    }

    .form-control {
      font-size: 16px;
      font-weight: 600;
      background: rgba(0, 0, 0, 0.03);
      border-color: transparent;
      box-shadow: none !important;
    }

    .invalid-feedback {
      font-size: 13px;
    }
  }

  .btn-cell {
    margin-bottom: 15px;

    .btn {
      min-width: 200px;
    }
  }

  .form-link {
    font-size: 13px;

    .btn {
      padding: 0;
      font-size: 13px;
    }
  }

  .signup-page {
    margin-bottom: 0;

    h3 {
      margin-bottom: 0;
    }
  }

  .already-account {
    font-size: 16px;
    font-family: "SFProDisplay-Medium";
    margin-bottom: 15px;

    .btn {
      padding: 10px 2px;
    }
  }

  .remember-rw {
    margin: 16px 0 20px 0;
  }

  .form-group.password {
    margin-bottom: 0;
  }

  .form-control {
    height: 54px;

    @media (max-width: 640px) {
      height: 50px;
    }
  }
}

.inner_link {
  font-size: 16px;
  font-family: SF Pro Display, sans-serif !important;
  font-weight: 500;
  color: #404D61;
  margin-bottom: 80px;

  @media (max-width:991px) {
    margin-bottom: 30px;
  }

  a {
    margin-left: 7px;
  }
}

.social_link {
  height: 44px;
  padding: 4px 10px 4px 10px;
  border-radius: 8px;
  min-width: 44px;
  font-size: 16px;
  font-weight: 400;
  color: #282E65;
  background: rgba(0, 0, 0, 0.03);
  margin-right: 15px;

  @media (max-width:1199px) {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width:575px) {
    font-size: 14px;

    svg {
      height: 14px;
    }
  }
}

.or_line {
  span {
    font-size: 14px;
    color: #788693;
    font-weight: 400;
    padding: 0 25px;
  }

  svg {
    width: 100%;
  }
}

.create_new_password {
  label {
    display: none;
  }
}