@import "../../../assets/css/theme/variables";

.swtich-cell {
  cursor: pointer;
  .form-check-input {
    opacity: 0;
  }
  .icon {
    width: 32px;
    height: 18px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all ease 0.3s 0s;
    span {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #fff;
      top: 2px;
      left: 2px;
      transform: translateX(0);
      transition: all ease 0.3s 0s;
    }
  }
  .form-check-input:checked + {
    .icon {
      background-color: $primary;
      span {
        transform: translateX(100%);
      }
    }
  }
}

.swtich-cell2 {
  width: 32px;
  height: 16px;
  background-color: $success;
  padding: 2px 2px 2px 4px;
  margin-right: 12px;
  font-size: 7px;
  border-radius: 8px;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  &:hover,
  &:focus {
    background-color: $success-dark;
  }
  .icon {
    font-family: "icomoon";
    &::before {
      content: "\e947";
      color: #fff;
    }
  }
  .circle-icon {
    width: 10px;
    height: 10px;
    background-color: #fff;
  }
  &.disable {
    background-color: $danger;
    padding-right: 0;
    .icon {
      order: 2;
      margin: auto;
      &::before {
        content: "\e940";
      }
    }
    .circle-icon {
      margin: 0 !important;
    }
  }
}
